import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'
// import bannerImage from "../../assets/images/digital-asset-moderate.png";

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// icons
// import Icon from "react-crypto-icons";

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const digitalModerate =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userInvest, setInvest] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);
  const [userProductTotal, setProductTotal] = React.useState('');
  
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])




const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    
  }

},[auth])

useEffect(async res =>{
  if(user !== 'undefined' && user !== null)
  {
    getuserBalance(user.user.userid)
    getUserProducts('15', user.user.userid)

  }


})

const getuserBalance = async userid => {
  var balanceObject = {
    userid: userid,
    currency: '1'
  }



  await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{

    console.log('set the balance to ', res.data)
    return setuserBalance(res.data.total);
 
  }).then(async res1 =>{
    var transactionObject = {
      'userid': userid
    }
    
  
    if(!checkedTransactions)
    {
        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_API_BASE_URL+"/view-transaction",transactionObject).then( res2 =>{

          if(res2.data.status == 200)
          {
            setAllTransactions(res2.data.transactions)
            console.log('transactions is ', viewAllTransactions)
          }
          setCheckedTransactions(true);
          
        })
    }
  })



}


function setInvestAmount(amount) {

    // const userid = req.body.userid
    // const amount = req.body.amount
    // const productid = req.body.productid
    console.log(parseFloat(userBalance), parseFloat(userInvest))
    if(userBalance && parseFloat(userInvest) > 0 && parseFloat(userInvest) > 70 && parseFloat(userBalance) > parseFloat(userInvest))
    {
        const investObject = {
            userid: user.user.userid,
            amount: userInvest,
            productid: '15',
          }
        console.log('continue with purchase')
          axios.post(process.env.REACT_APP_API_BASE_URL+"/create-productsale",investObject).then(async res =>{
        
            setInvest(0);
            // toastr.options.onHidden = function() { window.location.href="/digital-moderate" } 
            toastr.success("Product Successfully purchased!") 
        
          });
    }else{
        if(parseFloat(userInvest) < 0 || parseFloat(userInvest) < 70)
        {
            toastr.error("Insure Amount Meets Minimum requirement!") 
        }else{
            toastr.error("Insufficient Funds!") 
        }

    }

}

const getUserProducts = async (productid, userid) => {
    var totalObject = {
      'userid': userid,
      'productid': productid
    }
  
    await axios.post(process.env.REACT_APP_API_BASE_URL+"/productid-total",totalObject).then(async res =>{
  
      return setProductTotal(res.data.total);
   
    })
  
  }




    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 Holdings - Digital Asset Moderate Overview</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="Digital Asset Moderate" />

          <Row>
            <Col lg="12">

                  <Row>
                      <Col xl="6">
                          <a rel="noreferrer" href="https://www.finvestwealth.com/moderate-fund" target="_blank" >
                            {/* <img src={bannerImage} alt="bannerImage" /> */}
                          </a>
                          
                      </Col>

                      <Col sm="3">
                              <a href="./my-usdt-wallet">
                              <Card className="mini-stats-wid rounded">
                              <CardBody>
                                  <div className="d-flex">
                                  <div className="me-3 align-self-center">
                                      {/* <Icon name="usdt" size={25} /> */}
                                  </div>
                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">USDT(TRC20) Wallet</p>
                                      <h5 className="text-left mb-0">
                                      Total: $ {userBalance} 
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>

                          <Col sm="3">
                              <a href="./vault-product-info">
                              <Card className="mini-stats-wid rounded">
                              <CardBody>
                                  <div className="d-flex">

                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">Product Total</p>
                                      <h5 className="text-left mb-0">
                                      {userProductTotal} 
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>
                      

                  </Row>

            </Col>
          </Row>
          <Row>

            <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Digital Asset Income</h4>

                <div className="mt-4 mb-3">

                  <TabContent activeTab={activeTab} className="mt-4">
                    <TabPane tabId="1" id="buy-tab">


                      <div>
                        <div>
                          <Label>Add Amount :</Label>

                          <InputGroup className="mb-3">
                            <Label className="input-group-text">$</Label>
                            <Input type="number" min="70" step="1" max={userBalance} start="70" onChange={(e)=>setInvest(e.target.value)} value={userInvest} className="form-control" />
                          </InputGroup>
                        </div>

                        <div className="text-center">
                        <a href="#" onClick={(e)=>setInvestAmount(e.target.value)} className="btn c1-primary c1-primary-inline-btn btn-block">Invest</a>
                        </div>
                      </div>
                    </TabPane>
                   
                  </TabContent>
                </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}