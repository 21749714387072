import React from "react"
import { Redirect } from "react-router-dom"

// // Pages Component
// import Chat from "../pages/Chat/Chat"

// // File Manager
import FileManager from "../pages/FileManager/index"

// Profile
import AdminProfile from "../pages/Authentication/user-profile"



// Admin user referrals
import {AdminUserReferrals} from "../pages/Admin/Referrals/user-referrals/all-referrals"
import {AdminUserReferralPayments} from "../pages/Admin/Referrals/user-referrals/referral-payment"
import {uservaultProductInfo} from "../pages/Admin/Products/user-products/user-vault-info"


// Pages Calendar
// import Calendar from "../pages/Calendar/index"

// //Tasks
// import TasksList from "../pages/Tasks/tasks-list"
// import TasksKanban from "../pages/Tasks/tasks-kanban"
// import TasksCreate from "../pages/Tasks/tasks-create"

// //Projects
// import ProjectsGrid from "../pages/Projects/projects-grid"
// import ProjectsList from "../pages/Projects/projects-list"
// import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"
// import ProjectsCreate from "../pages/Projects/projects-create"

// //Ecommerce Pages
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
// import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
// import EmailInbox from "../pages/Email/email-inbox"
// import EmailRead from "../pages/Email/email-read"
// import EmailBasicTemplte from "../pages/Email/email-basic-templte"
// import EmailAlertTemplte from "../pages/Email/email-template-alert"
// import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Invoices
// import InvoicesList from "../pages/Invoices/invoices-list"
// import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import Signup from "../pages/Authentication/Signup"
// inf

import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"


// My profile
import {myAccount} from "../pages/Authentication/my-account"


//  // Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login"
// import Login2 from "../pages/AuthenticationInner/Login2"
// import Register1 from "../pages/AuthenticationInner/Register"
// import Register2 from "../pages/AuthenticationInner/Register2"
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
// import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
// import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
// import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
// import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
// import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
// import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
// import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
// import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
// import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-saas/index"
import DashboardCrypto from "../pages/Dashboard-crypto/index"
import Blog from "../pages/Dashboard-Blog/index"


//Crypto
// import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet"
// import CryptoBuySell from "../pages/Crypto/crypto-buy-sell"
import CryptoExchange from "../pages/Crypto/crypto-exchange"
// import CryptoLending from "../pages/Crypto/crypto-lending"
// import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
// import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application"
// import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"

// Maps
// import MapsGoogle from "../pages/Maps/MapsGoogle"
// import MapsVector from "../pages/Maps/MapsVector"
// import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"
import DragDropTables from '../pages/Tables/DragDropTables'

//Blog
import BlogList from "../pages/Blog/BlogList/index"
import BlogGrid from "../pages/Blog/BlogGrid/index"
import BlogDetails from "../pages/Blog/BlogDetails"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormLayouts from "../pages/Forms/FormLayouts"

import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"
import DualListbox from "../pages/Tables/DualListbox"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiOffCanvas from "pages/Ui/UiOffCanvas"
import UiBreadcrumb from '../pages/Ui/UiBreadcrumb';
import UiPlaceholders from "../pages/Ui/UiPlaceholders";
import UiToasts from "../pages/Ui/UiToast";

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"







// Interests
import {Users} from "../pages/Admin/UserPage/Users"
import {PendingMembers} from "../pages/Admin/UserPage/PendingMembers"
import {ProductSales} from "../pages/Admin/UserPage/ProductSales"
import {UserBalances} from "../pages/Admin/UserPage/UserBalance"
import {Deposits} from "../pages/Admin/UserPage/Deposits"
import {ProductInterest} from "../pages/Admin/UserPage/ProductInterests"

// B2B Reports
import {B2BProductSales} from "../pages/Admin/UserPage/B2BProductSales"
import {B2BDeposits} from "../pages/Admin/UserPage/B2BDeposits"
import {B2BProductInterest} from "../pages/Admin/UserPage/B2BProductInterests"


import {UserProfile} from "../pages/Admin/UserProfilePage/UserProfile"
import {AdminPendingProfile} from "../pages/Admin/UserProfilePage/UserPendingProfile"


import UserDashboard from "pages/Dashboard/UserDashboard"
import { DigitalAsset } from "pages/Dashboard/DigitalAssetPage/DigitalAsset"
import emailResetPassword from "pages/Email/emailResetPassword"
import emailWithdraw from "pages/Email/emailWithdraw"
import emailAccountVerification from "pages/Email/emailAccountVerification"
import {adminInterests} from "../pages/Interests/newInterest"

// Assets
import {finvestotc} from "../pages/Assets/otc"

// Digital Income
import {digitalModerate} from "../pages/digitalasset/digital-moderate"
import {vaultProductInfo} from "../pages/digitalasset/vault-product-info"
import {userSavingsPocket6Info} from "../pages/digitalasset/savings-pocket-6-info"
import {userSavingsPocket6InfoZar} from "../pages/digitalasset/savings-pocket-6-info-zar"
import {userSavingsPocket12Info} from "../pages/digitalasset/savings-pocket-12-info"
import {userSavingsPocket12InfoZar} from "../pages/digitalasset/savings-pocket-12-info-zar"

// Vault Index Fund
import {userVIFInfo} from "../pages/digitalasset/vif-info"
import {userVIF6Info} from "../pages/digitalasset/vif-6-info"
import {userVIF12Info} from "../pages/digitalasset/vif-12-info"

// Vault Index Fund
import {vaultIndex3} from "../pages/users/products/vault-index-fund"
import {vaultIndex6} from "../pages/users/products/vault-index-fund-6"
import {vaultIndex12} from "../pages/users/products/vault-index-fund-12"


import {digitalHigh} from "../pages/digitalasset/digital-high"
import {digitalModerateSavings} from "../pages/digitalasset/digital-moderate-savings"
import {digitalHighSavings} from "../pages/digitalasset/digital-high-savings"
import {digitalModerateSavingsInfo} from "../pages/digitalasset/digital-moderate-savings-info"
import {digitalHighSavingsInfo} from "../pages/digitalasset/digital-high-savings-info"
import UserProductListingPage from "pages/User/Products/ListingPage"

// Transactions
import {pendingWithdrawals} from "../pages/transactions/pending-withdrawals"
import {pendingWithdrawalTXID} from "../pages/transactions/pending-withdrawalstxid"

import {pendingDeposits} from "../pages/transactions/pending-deposits"
import {allTransactions} from "../pages/transactions/all-transactions"
import {pendingMemberships} from "../pages/transactions/pending-memberships"



import {digitalHighInfo} from "../pages/digitalasset/digital-high-info"

// Wallets
import {finvestusdtwallet} from "../pages/wallet/my-usdt-wallet"
import {finvestzarwallet} from "../pages/wallet/my-zar-wallet"
import {allexchanges2} from "../pages/wallet/allexchanges"
import {HandleExchange} from "../pages/wallet/handle-exchange"
import {walletOverview} from "../pages/wallet/wallet-overview"
import {WalletExchange} from "../pages/wallet/wallet-exchange"



// ============================================ C1 Holdings =================================
import {buyProduct} from "../pages/users/products/buy-product"
import {savingsPocket6} from "../pages/users/products/savings-pocket-6"
import {savingsPocket12} from "../pages/users/products/savings-pocket-12"

// Referral Settings
import {referralSettings} from "../pages/Admin/Referrals/referral-settings"
import {allReferrals} from "../pages/User/Referrals/all-referrals"
import {ReferralPayment} from "../pages/User/Referrals/referral-payment"


// 3rdParty Settings
import {thirdPartySettings} from "../pages/3rdParties/thirdPartySettings"

// Reports
import {customReports} from "../pages/Admin/Reports/custom-reports"
import { MyReports } from "pages/Reporting/MyReports"

// ========================================== END C1 Holdings =================================

const authProtectedRoutes = [

// ===================================== START C1 Holdings =================================

{ path: "/buy-products", component: buyProduct},
{ path: "/savings-pocket-6", component: savingsPocket6},
{ path: "/savings-pocket-12", component: savingsPocket12},

// Referrals
{ path: "/referral-settings", component: referralSettings},
{ path: "/my-referrals", component: allReferrals},
{ path: "/referral-payments", component: ReferralPayment},








// ==================================== END C1 Holdings =================================








  { path: "/dashboard", component: UserDashboard },
  { path: "/dashboard-saas", component: DashboardSaas },
  { path: "/dashboard-crypto", component: DashboardCrypto },
  { path: "/blog", component: Blog },
  { path: "/dashboard-digital-asset/:userId/:productId", component: DigitalAsset },

    // new signup


      // Digital Income
  { path: "/digital-moderate", component: digitalModerate},
  { path: "/vault-product-info", component: vaultProductInfo},
  { path: "/savings-pocket-6-info", component: userSavingsPocket6Info},
  { path: "/savings-pocket-6-info-zar", component: userSavingsPocket6InfoZar},
  
  { path: "/savings-pocket-12-info", component: userSavingsPocket12Info},
  { path: "/savings-pocket-12-info-zar", component: userSavingsPocket12InfoZar},
  
  
  { path: "/digital-high", component: digitalHigh},
  { path: "/digital-high-info", component: digitalHighInfo},

  { path: "/digital-moderate-savings", component: digitalModerateSavings},
  { path: "/digital-moderate-savings-info", component: digitalModerateSavingsInfo},
  { path: "/digital-high-savings", component: digitalHighSavings},
  { path: "/digital-high-savings-info", component: digitalHighSavingsInfo},

    // Vault Index Fund
    { path: "/vif-info", component: userVIFInfo},
    { path: "/vif-6-info", component: userVIF6Info},
    { path: "/vif-12-info", component: userVIF12Info},

    // Vault index fund
{ path: "/vif-3", component: vaultIndex3},
{ path: "/vif-6", component: vaultIndex6},
{ path: "/vif-12", component: vaultIndex12},

  // 3rd Parties
  { path: "/third-party-settings", component: thirdPartySettings},
  

    // EMAIL Templates
    { path: "/reset-password-email", component: emailResetPassword },
    { path: "/withdraw-email", component: emailWithdraw },
 

  // Admin Interest
  { path: "/new-product-interest", component: adminInterests},


  
  // Transactions
  { path: "/pending-withdrawals", component: pendingWithdrawals},
  { path: "/handle-pendingin-withdrawaltxid/:id", component: pendingWithdrawalTXID },
  { path: "/pending-deposits", component: pendingDeposits},
  { path: "/all-transactions", component: allTransactions},
  { path: "/pending-memberships", component: pendingMemberships},
  

  // User  
  { path: "/user-product-list/:id", component: UserProductListingPage },

  // Assets
  { path: "/my-usdt-wallet", component: finvestusdtwallet},
  { path: "/my-zar-wallet", component: finvestzarwallet},
  { path: "/allexchanges", component: allexchanges2},
  { path: "/wallet-overview", component: walletOverview },
  { path: "/wallet-exchange", component: WalletExchange },
  { path: "/handle-exchange/:id", component: HandleExchange },


  { path: "/my-account", component: myAccount},
  { path: "/my-reports", component: MyReports},

  //Crypto
  // { path: "/crypto-wallet", component: CryptoWallet },
  // { path: "/crypto-buy-sell", component: CryptoBuySell },
  { path: "/crypto-exchange", component: CryptoExchange },
  // { path: "/crypto-lending", component: CryptoLending },
  // { path: "/crypto-orders", component: CryptoOrders },
  // { path: "/crypto-kyc-application", component: CryptoKYCApplication },

  //chat
  // { path: "/chat", component: Chat },

  //File Manager
  { path: "/apps-filemanager", component: FileManager },

  // //calendar
  // { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: AdminProfile },

  //Ecommerce
  // { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
  // { path: "/ecommerce-products", component: EcommerceProducts },
  // { path: "/ecommerce-orders", component: EcommerceOrders },
  // { path: "/ecommerce-customers", component: EcommerceCustomers },
  // { path: "/ecommerce-cart", component: EcommerceCart },
  // { path: "/ecommerce-checkout", component: EcommerceCheckout },
  // { path: "/ecommerce-shops", component: EcommerceShops },
  // { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //Email
  // { path: "/email-inbox", component: EmailInbox },
  // { path: "/email-read", component: EmailRead },
  // { path: "/email-template-basic", component: EmailBasicTemplte },
  // { path: "/email-template-alert", component: EmailAlertTemplte },
  // { path: "/email-template-billing", component: EmailTemplateBilling },

  //Invoices
  // { path: "/invoices-list", component: InvoicesList },
  // { path: "/invoices-detail/:id?", component: InvoiceDetail },

  // Tasks
  // { path: "/tasks-list", component: TasksList },
  // { path: "/tasks-kanban", component: TasksKanban },
  // { path: "/tasks-create", component: TasksCreate },

  //Projects
  // { path: "/projects-grid", component: ProjectsGrid },
  // { path: "/projects-list", component: ProjectsList },
  // { path: "/projects-overview", component: ProjectsOverview },
  // { path: "/projects-overview/:id", component: ProjectsOverview },
  // { path: "/projects-create", component: ProjectsCreate },

  //Blog
  // { path: "/blog-list", component: BlogList },
  // { path: "/blog-grid", component: BlogGrid },
  // { path: "/blog-details", component: BlogDetails },

  // Contacts
  // { path: "/contacts-grid", component: ContactsGrid },
  // { path: "/contacts-list", component: ContactsList },
  // { path: "/contacts-profile", component: ContactsProfile },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },
  { path: "/re-charts", component: ReCharts },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },
  { path: "/tables-dragndrop", component: DragDropTables },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-layouts", component: FormLayouts },

  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },
  { path: "/dual-listbox", component: DualListbox },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-offcanvas", component: UiOffCanvas },
  { path: "/ui-breadcrumb", component: UiBreadcrumb },
  { path : "/ui-placeholders" , component : UiPlaceholders},
  { path : "/ui-toasts" , component : UiToasts},



// Admin Interests
{ path: "/user-list", component: Users },
{ path: "/pending-members", component: PendingMembers },

{ path: "/product-sales", component: ProductSales },
{ path: "/user-balances", component: UserBalances },
{ path: "/user-profile/:id", component: UserProfile },
{ path: "/user-pending-profile/:id", component: AdminPendingProfile },

{ path: "/deposits", component: Deposits },
{ path: "/product-interest", component: ProductInterest },


// B2B Reports
{ path: "/b2bproductsales", component: B2BProductSales },
{ path: "/b2balldeposits", component: B2BDeposits },
{ path: "/b2bproductinterest", component: B2BProductInterest },

// Assets
{ path: "/otc", component: finvestotc},


// Admin User Interests
{ path: "/admin-user-interests/:id", component: AdminUserReferrals },
{ path: "/admin-user-referral-payments/:id", component: AdminUserReferralPayments },
{ path: "/admin-user-vault-info/:id", component: uservaultProductInfo },


// Reports
{ path: "/custom-reports", component: customReports },


  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPassword },
  // { path: "/register", component: Register },
  { path: "/intro", component: Signup },
  // { path: "/intro", component: infoIntro },
  // { path: "/pinfo-1", component: pinfoStage1 },
  
  { path: "/email-account-verification", component: emailAccountVerification },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  // { path: "/pages-login", component: Login1 },
  // { path: "/pages-login-2", component: Login2 },
  // { path: "/pages-register", component: Register1 },
  // { path: "/pages-register-2", component: Register2 },
  // { path: "/page-recoverpw", component: Recoverpw },
  // { path: "/page-recoverpw-2", component: Recoverpw2 },
  // { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  // { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  // { path: "/auth-lock-screen", component: LockScreen },
  // { path: "/auth-lock-screen-2", component: LockScreen2 },
  // { path: "/page-confirm-mail", component: ConfirmMail },
  // { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  // { path: "/auth-email-verification", component: EmailVerification },
  // { path: "/auth-email-verification-2", component: EmailVerification2 },
  // { path: "/auth-two-step-verification", component: TwostepVerification },
  // { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
