import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const pendingDeposits =()=>{
    const [viewAllTransactions, setAllTransactions] = React.useState([]);
    const [viewAllUserInfo, setAllUserInfo] = React.useState([]);
    

    const{auth,user} = React.useContext(UserContext)
    const[StartpackList,setStartpackList] = React.useState([])

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])




const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

    if(user !== 'undefined' && user !== null)
    {
        await axios.get(process.env.REACT_APP_BASE + "api/pending-deposits").then((res)=>{
            console.log('all deposits ', res.data)
            setAllTransactions(res.data.deposits)
            setAllUserInfo(res.data.users)
            console.log('see the user info ', res.data.users)
        });
    }

},[auth])

 const approveDeposit = (deposit) => {

    const updateDepObj = {
        transactionId: deposit.id,
        status: '1',
        trans_id: deposit.trans_id
    }
    
    axios.post(process.env.REACT_APP_BASE + "api/update-deposit", updateDepObj).then((res)=>{
        console.log('updated deposits ', res.data)
        if(res.data.status == 200)
        {
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.success(res.data.message); 
        }else{
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.warning("Unable to update deposit"); 
        }
    });
 }

 const declineDeposit = (deposit) => {
 

    const updateDepObj = {
        transactionId: deposit.id,
        status: '2',
        trans_id: deposit.trans_id
    }

    axios.post(process.env.REACT_APP_BASE + "api/update-deposit", updateDepObj).then((res)=>{
        console.log('updated deposits ', res.data)
        if(res.data.status == 200)
        {
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.success(res.data.message); 
        }else{
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.danger("Unable to update deposit"); 
        }
    });

 }

 

    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - All Pending Deposits</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="All Pending Deposits" />

          <Row>

            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Pending Deposits</h4>

                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>

                        <tr>
                          <th scope="col">Userinfo</th>
                          <th scope="col">Userid</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Info</th>
                          <th scope="col">Action</th>
                          <th scope="col">Date</th>
                        </tr>

                      </thead>
                      {typeof viewAllTransactions !== 'undefined' && viewAllTransactions.length > 0 ? 
                      <tbody>

                        
                        {viewAllTransactions.map((viewAllTransaction, key) => (
                             <tr key={viewAllTransaction.id}>
                                <td>{viewAllTransaction.userid ? 
                                viewAllUserInfo[viewAllTransaction.userid]: ''}
                                </td>

                               <td>{viewAllTransaction.userid ? 
                                viewAllTransaction.userid: ''}</td>

                                <td>{viewAllTransaction.currency == '3' ? 
                                  'BTC': viewAllTransaction.currency == '2' ? 'ZAR' : 'USDT'}</td>

                                <td>{viewAllTransaction.amount ? 
                                  viewAllTransaction.amount: ''}</td>
                                <td>
                                  {viewAllTransaction.txid ? 
                                  viewAllTransaction.txid: ''}
                                </td>

                                <td > <button className="btn btn-success"  onClick={e => approveDeposit(viewAllTransaction)}>Approve</button>
                                <button className="btn btn-danger"  onClick={e => declineDeposit(viewAllTransaction)}>Decline</button> </td>

                                <td>{viewAllTransaction.createdAt ? 
                                 viewAllTransaction.createdAt : ''}</td>
                    
                          </tr>
                        ))}
  
                   

                      </tbody>
                      :
                      <tbody>
                        <td>No Records found</td>
                      </tbody>}
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
        </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}