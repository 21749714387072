import React, { Component, useState, useEffect  } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import axios from 'axios';
// import cryptoWalletColumns from "./cryptoWalletColumns"

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cryptoWalletColumns from "pages/Crypto/CryptoWallet/cryptoWalletColumns";

import Swal from "sweetalert2"

import { UserContext } from "../../UserContext";

const WalletTransactions = ({ walletHistory, activeTab, toggleTab }) => {

  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);
  const{auth,user} = React.useContext(UserContext)

  // const data = {
  //   columns: cryptoWalletColumns,
  //   rows: walletHistory,
  // }
  

  const getuserBalance = async userid => {
    if(!checkedTransactions)
    {
      var transactionObject = {
        'userid': userid,
      }

        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then( res2 =>{

          if(res2.data.status == 200)
          {
            setAllTransactions(res2.data.transactions)
          }
          setCheckedTransactions(true);
          
        })
    }
  }

  React.useEffect(()=>{
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    if(auth!=null)
    {
     if(!auth) {
      return Swal.fire({
        title: 'Error!',
        text: 'Your session has expired, click below to start a new session ',
        icon: 'error',
        confirmButtonText: 'Login',
        confirmButtonColor: '#29a927',
        allowOutsideClick:false
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.href = "/login" 
        }
      })
     
     }else{
      if(user !== 'undefined' && user !== null)
      {
        getuserBalance(user.user.userid)  
      } 
     }
    }
   },[auth])


  // const columns = [{
  //   dataField: 'date',
  //   text: 'Date'
  // },{
  //   dataField: 'status',
  //   text: 'Status'
  // }, {
  //   dataField: 'amount',
  //   text: 'Amount'
  // }, {
  //   dataField: 'type',
  //   text: 'Deposit/Withdraw'
  // }];

  const columns = [{
    dataField: 'idno',
    text: 'Id No'
  },{
    dataField: 'pdate',
    text: 'Date'
  }, {
    dataField: 'type',
    text: 'Type'
  },{
    dataField: 'status',
    text: 'Status'
  }, {
    dataField: 'coin',
    text: 'Currency'
  },{
    dataField: 'amount',
    text: 'Amount'
  }];

  // Table Data
// const productData = [
//   { id: 1, date:"2022-05-17 19:30:08", status : "Pending",amount:"$ -10",type:"Withdraw"},
//   { id: 2, date:"2022-05-17 19:30:08", status : "Pending",amount:"$ -10",type:"Withdraw"},
//   { id: 3, date:"2022-05-19 13:30:38", status : "Approved",amount:"$ 100",type:"Deposit"},
//   { id: 4, date:"2022-05-19 13:30:38", status : "Pending",amount:"$ -10",type:"Withdraw"},
//   { id: 5, date:"2022-05-19 13:30:38", status : "Approved",amount:"$ -10",type:"Withdraw"},
//   { id: 6, date:"2022-05-17 19:30:08", status : "Pending",amount:"$ -10",type:"Withdraw"},
//   { id: 7, date:"2022-05-17 19:30:08", status : "Approved",amount:"$ 100",type:"Deposit"},
//   { id: 8, date:"2022-05-19 13:30:38", status : "Pending",amount:"$ 100",type:"Deposit"},
//   { id: 9, date:"2022-05-19 13:30:38", status : "Approved",amount:"$ 100",type:"Deposit"},
//   { id: 10, date:"2022-05-19 13:30:38", status : "Pending",amount:"$ 100",type:"Deposit"},
//   { id: 11, date:"2022-05-19 13:30:38", status : "Approved",amount:"$ -10",type:"Withdraw"},
//   { id: 12, date:"2022-05-19 13:30:38", status : "Pending",amount:"$ -10",type:"Withdraw"},
//   { id: 13, date:"2022-05-17 19:30:08", status : "Pending",amount:"$ -10",type:"Withdraw"},
//   { id: 14, date:"2022-05-17 19:30:08", status : "Approved",amount:"$ -10",type:"Withdraw"},
//   { id: 15, date:"2022-05-19 13:30:38", status : "Approved",amount:"$ -10",type:"Withdraw"},
//   { id: 16, date:"2022-05-19 13:30:38", status : "Pending",amount:"$ -10",type:"Withdraw"},
//   { id: 17, date:"2022-05-17 19:30:08", status : "Pending",amount:"$ -10",type:"Withdraw"},
 
//   ];

const productData = [];

var x = 0;
viewAllTransactions.forEach(transaction => {
   var numid = x.toString();

   var transtype = '---';
   if(transaction.tran_cat == '1')
   {
    transtype = 'Deposit'
   }else if(transaction.tran_cat == '2'){
    transtype = 'Withdraw'
  }else if(transaction.tran_cat == '4' || transaction.tran_cat == '6'){
    transtype = transaction.tran_type
   }else if(transaction.tran_cat == '5'){
    transtype = 'Membership Payment'
   }else if(transaction.tran_cat.toString() == '8'){
    transtype = 'Exchange Added'
   }else{
    transtype = 'Purchased Product'
   }

   var transtatus = '---';
   if(transaction.status == '1')
   {
    transtatus = 'Approved'
   }else if(transaction.status == '2'){
    transtatus = 'Declined'
   }else{
    transtatus = 'Pending'
   }

   var currencyObj = {
    '1': 'USDT(TRC20)',
    '2': 'ZAR',
    '3': 'BTC'
   }

   var currency = currencyObj[transaction.currency]
  //  if(transaction.currency == '2')
  //   currency

    var productDataObj = {
        id: numid,
        idno: transaction.trans_id,
        pdate: transaction.createdAt.toString(),
        type: transtype,
        status: transtatus,
        coin: currency,
        amount: transaction.amount
    };
    productData.push(productDataObj)
    x = x+1;
});


  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Activities</h4>
        <ul className="nav nav-tabs nav-tabs-custom">
          <NavItem className="text-dark">
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
         
            >
              Wallet Activities
            </NavLink>
          </NavItem>
        </ul>

        <div className="mt-4">
        <BootstrapTable className="table-responsive" keyField='id' responsive="md" data={ productData } columns={ columns } pagination={ paginationFactory() } />
        </div>
      </CardBody>
    </Card>
  )
}

WalletTransactions.propTypes = {
  walletHistory: PropTypes.array,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
}

export default WalletTransactions
