import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { UserContext } from "../../../UserContext";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { CSVLink, CSVDownload } from "react-csv";
import Datatable from "components/Datatable/datatable.component"
import axios from "axios"
import {UserProfile} from "pages/Admin/UserProfilePage/UserProfile"

export const UserBalances = () => {
  const [sales, setAllinfo] = useState([])
  const{auth,user} = React.useContext(UserContext)
  const columns = [
    {
      dataField: "userid",
      text: "UserID",
      sort: true,
    },
    {
      dataField: "userInfo",
      text: "User",
      sort: true,
    },
    {
      dataField: "TotalInVaultUSD",
      text: "Total In Vault",
      sort: true,
    },
    
    {
      dataField: "WithdrawableUSD",
      text: "Total in Withdrawable Wallet USD",
      sort: true,
    },
    {
        dataField: "ContributionUSD",
        text: "Total Contribution Wallet + Vault USD",
        sort: true,
      },
      {
        dataField: "TotalInSavingsZAR",
        text: "Product Total Amount ZAR",
        sort: true,
      },
    {
        dataField: "WithdrawableZAR",
        text: "Total in Withdrawable Wallet ZAR",
        sort: true,
    },
    {
        dataField: "ContributionZAR",
        text: "Total Contribution Wallet + Vault ZAR",
        sort: true,
      },
    {
        dataField: "WithdrawableBTC",
        text: "Total in Withdrawable Wallet BTC",
        sort: true,
    },

      {
        dataField: "ContributionBTC",
        text: "Total Contribution Wallet + Vault BTC",
        sort: true,
      },
  ]

  React.useEffect(async res =>{

    if(user !== 'undefined' && user !== null)
    {
        await axios.get(process.env.REACT_APP_BASE + "api/all-user-balances").then((res)=>{
            console.log('all productsales ', res.data)
            setAllinfo(res.data.total)
        });
    }

},[auth])

  const datatableInfo = {
    title: "Product Sales View",
    shortDescription: "List of product sales",
    longDescription: "This shows a list of all product sales",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 - User Balances</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={sales}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                      <CSVLink data={sales} filename={"user-balances-new1.csv"} className="btn btn-primary" target="_blank">Download me</CSVLink>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
// ProductSales.propTypes = {
//     viewAllProductSales: PropTypes.array,
// }

export default UserBalances