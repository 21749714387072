import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import { Link, useParams, withRouter } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Form,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// icons
// import Icon from "react-crypto-icons";

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const pendingWithdrawalTXID =()=>{

    
  const [userName, setuserName] = React.useState('');
  const [newTXID, setNewTXID] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [currentPercentage, setCurrentPercentage] = React.useState('');
  const [foundProducts, setFoundProducts] = React.useState(false);
  const [newamount, setNewAmount] = React.useState('');
  const [allWithdrawals, setAllWithdrawals] = React.useState({
    excahnge_type: "",
    amount: "",
    userid: ""
  })
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])





    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])







const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}


React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    
  }

},[auth])

let { id } = useParams()

useEffect(async res =>{
  if(user !== 'undefined' && user !== null && !foundProducts)
  {

    if(typeof allWithdrawals !== 'undefined' &&  typeof allWithdrawals.id == 'undefined')
      setWithdrawalInfo(id)

  }


})






function setWithdrawalInfo(withdrawalid) {

  console.log("Running withdrawal info " + withdrawalid)
  const WithdrawalObject = {
    withdrawalid: withdrawalid,
  }

  axios.post(process.env.REACT_APP_API_BASE_URL + "/withdrawal-info",WithdrawalObject).then(async res =>{
    console.log('see al withdrawal info ' + res.data)
      return setAllWithdrawals(res.data.withdrawal[0])
    

  });
}

const addTXIDBtn = async e => {

      const withdrawalObject = {
          txid: newTXID,
          withdrawalid: id,
        }

      await axios.post(process.env.REACT_APP_API_BASE_URL + "/handle-new-withdrawaltxid",withdrawalObject).then(async res2 =>{

          if(res2.data.status == 200)
          {
            toastr.options.onHidden = function () {
              window.location.href = "/pending-withdrawals"
            }
              toastr.success(res2.data.message)
          }else{
              toastr.error("Unable to Add TXID!")
          }

      })


}



// allProducts


    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>FinvestWealth - Add Product Interest</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="Withdrawal" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <h4 className="card-title mb-4">Edit Withdrawal - { allWithdrawals.userid }</h4>
                  <div className="crypto-buy-sell-nav">
                    <Nav tabs className="nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                            Withdrawal TXID
                        </NavLink>
                      </NavItem>
                      
                    </Nav>

                    <TabContent
                      activeTab={activeTab}
                      className="crypto-buy-sell-nav-content p-4"
                    >
                      <TabPane tabId="1" id="buy">
                        <form>

                          <div className="mb-3">
           

                            <Row>
                              <Col sm="6">
                                <InputGroup className="mb-2" onChange={(e)=>setNewTXID(e.target.value)} >
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">TXID</span>
                                  </div>
                                  <input type="text" className="form-control form-control" />
                                </InputGroup>
                              </Col>

                            </Row>
                          </div>
                          <div className="text-center mt-4">
                            <Button type="button" onClick={e => addTXIDBtn(e)} color="success">
                              Add TXID
                            </Button>
                          </div>

                        </form>
                      </TabPane>

                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}