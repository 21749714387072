import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

import CookieBot from "react-cookiebot"
const domainGroupId = '7df7b637-7f70-47b3-8af6-e9dea4fcdc1a';

import store from "./store"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      {/* <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent> */}
      <CookieBot domainGroupId={domainGroupId} />
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
