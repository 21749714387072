import React from "react"
import { Container, Row, Col } from "reactstrap"
import loginlogo from "assets/images/backgroundomc.jpeg";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" >
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © C1 HOLDINGS (PTY) LTD.</Col>

          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
