import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import { Container, Row, Col, Card, CardBody, Button, Alert, Input,  UncontrolledAlert, Progress, Spinner} from "reactstrap"
import { Link } from "react-router-dom"


//import action
// import { getChartsData as onGetChartsData } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { UserContext } from "../../UserContext"

import toastr from 'toastr';
import "toastr/build/toastr.min.css"
import Swal from "sweetalert2"
import ProfileBalanceWidget from "components/User/ProfileBalanceWidget"
import ProductWidget from "components/ProductWidget/ProductWidget"
// import Carousel from "react-multi-carousel"

// import "react-multi-carousel/lib/styles.css"
import axios from "axios"
import WalletActivities from "pages/Crypto/CryptoWallet/walletActivities"
import { isEmpty } from "lodash"
import { getWallet as onGetWallet } from "store/actions"
import WalletTransactions from "components/Wallet/WalletTransactions"


import FicaPopupModal from "./FicaPopupModal"
import FicaPopupModal1 from "./FicaPopupModal1"
import FicaPaymentModal from "./FicaPaymentModal"
import LoginTermsModal from "./LoginTermsModal"


// import images
// import bgdashimage from "assets/images/clouds-brackground-dash.jpg";
import MiniWidget from "../Dashboard-crypto/mini-widget"
import CurrencyWidget from "../Dashboard-crypto/currency-widget"

const UserDashboard = props => {
  const { auth, user } = React.useContext(UserContext)

  const [viewAllProductSales, setAllProductSales] = React.useState([])
  const dispatch = useDispatch()

  const { wallet } = useSelector(state => ({
    wallet: state.crypto.wallet,
  }))

  const [isMenu, setIsMenu] = useState(false)
  const [ficaStatus, setFicaStatus] = useState(false)
  const [userPayStatus, setUserPayStatus] = useState('')
  
  const [activeTab, setActiveTab] = useState("1")



  const [displayFirstSpinner, setDisplayFirstSpinner] = useState(false)
  const [displayLastSpinner, setDisplayLastSpinner] = useState(false)


  const [kycProcess, setKYCProcess] = useState(false)
  const [kycStep, setKYCStep] = useState(0)
  // const [kycOption, setKYCOption] = React.useState({ 1: 0, idNumber: '', currency: '', amount: '', txid: '',  dob: '', country: '', address: '', sourceFunds: '', useridUpload: [], userpoaupload: [], country: ''});
  const [kycOption, setKYCOption] = React.useState({ 1: 0, currency: '', amount: '', txid: ''});
  const [checkTerms, setcheckTerms] = useState(false)
  const [skipPayment, setSkipPayment] = useState(false)
  const [upgradingKYCOption, setUpgradingKYCOption] = useState(false)
  const [currentKYCOption, setCurrentKYCOption] = useState(0)
  const [currentKYCStatus, setCurrentKYCStatus] = useState('')
  const [paymentLabel, setPaymentLabel] = useState('')
  const [userNames, setUserNames] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userUsdtBalance, setUserUsdtBalance] = React.useState("")
  
  const [modalView, setModalView] = useState(true)
  const [loginModalView, setLoginModalView] = useState(true)
  const [termsNotChecked, setTermsNotChecked] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  
  const [completionProgress, setCompletionProgress] = useState(25)
  const [completionText, setCompletionText] = useState("Registered")
  const [showKYCMessage, setShowKYCMessage] = useState(false);
  const [newOtpOption, setNewOtpOption] = useState("email")

  const [cryptoOptions, setCryptoOptions] = useState([])
  const [currencyOptons, setCurrencyOptons] = useState([])
  const [sponsorCode, setSponsorCode] = React.useState('');
  const [mainParent, setUserMainParent] = React.useState(0);
  const [adminThirdParty, setAdminThirdParty] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)

  const [shouldShowData, setShouldShowData] = useState(false)
  const [dataTransactions, setDataTransactions] = useState([])
  const [dataVaultOpen, setDataVaultOpen] = useState([])
  const [dataVaultClosed, setDataVaultClosed] = useState([])
  
  
  

  React.useEffect(()=>{
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    if(auth!=null)
    {
     if(!auth) {
      return Swal.fire({
        title: 'Error!',
        text: 'Your session has expired, click below to start a new session ',
        icon: 'error',
        confirmButtonText: 'Login',
        confirmButtonColor: '#29a927',
        allowOutsideClick:false
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.href = "/login" 
        }
      })
     
     }else{
      setAuthenticated(true)
     }
    }
   },[auth])

  useEffect(() => {

    dispatch(onGetWallet())
  }, [onGetWallet])

  const startKYCProcess = () => {
    kycOption[1] = 1;
    // setKYCProcess(true)
    // if(kycStep == 0)
      setKYCStep(1)
  }

  const startTermsProcess = () => {
    setcheckTerms()
  }
    

  const nextStepFica = () => {

    // if(kycStep == 1 )
    // {
      // Now we need to save the details and also update the RICA status
      const updateUserData = updateUserFicaDetails();
     
      if(!updateUserData)
      {
        // Do nothing
      }else{
        onCloseClick
      }
       

    
}


  function updateUserFicaDetails()
  {
    // 1: 0, idNumber: '', currency: '', amount: '', txid: '',  dob: '', country: '', address: '', sourceFunds: '', useridUpload: [], userpoaupload: [], country: ''
    // first check the KYC Option
    // 1 = Basic (amount, txid)
    // 2 = H (dob, country, useridUpload, userpoaupload, amount, txid)
    // 3 = F (idNumber, dob, address country, useridUpload, userpoaupload, sourceFunds, amount, txid)

    const ficaOption = kycOption[1]
    var objUpgradingFicaOption = '0'

    if(kycStep == 2 && upgradingKYCOption)
      objUpgradingFicaOption = '3'

    if(kycStep == 3 && upgradingKYCOption)
      objUpgradingFicaOption = '2'

    if(ficaOption == 3)
    {
      // Full Fica
      if(kycOption['idNumber'] !== '' && kycOption['dob'] !== '' && kycOption['address'] !== '' && kycOption['country'] !== '' && kycOption['sourceFunds'] !== '' && typeof kycOption['useridUpload'] !== 'undefined' && kycOption['useridUpload'].length !== 0 && typeof kycOption['userpoaupload'] !== 'undefined' && kycOption['userpoaupload'].length !== 0)
      {

 
        const ficaObj = {
          userid: user.user.userid,
          ficaOption: ficaOption,
          idNumber: kycOption['idNumber'],
          dob: kycOption['dob'],
          address: kycOption['address'],
          country: kycOption['country'],
          amount: kycOption['amount'],
          txid: kycOption['txid'],
          sourceFunds: kycOption['sourceFunds'],
          idUpload: kycOption['useridUpload'][0],
          poaUpload: kycOption['userpoaupload'][0],
        }

        axios.post(process.env.REACT_APP_BASE + "api/update-ficadata",ficaObj).then(async res =>{
        
          if(res.data.status == 200)
          {
            toastr.options.onHidden = function() { window.location.href="/dashboard" } 
              toastr.success(res.data.message) 
              setKYCStep(kycStep + 1)
          }
          else
              toastr.error(res.data.message) 
              return false
        });

      }else{
        toastr.error("Require missing details for full fica!") 
        return false
      }
    }else if(ficaOption == 2)
    {
      // Half Fica
     
      if(kycOption['dob'] !== '' && kycOption['country'] !== ''  && typeof kycOption['useridUpload'] !== 'undefined' && kycOption['useridUpload'].length !== 0 && typeof kycOption['userpoaupload'] !== 'undefined' && kycOption['userpoaupload'].length !== 0)
      {
   
        const ficaObj = {
          userid: user.user.userid,
          ficaOption: ficaOption,
          idNumber: kycOption['idNumber'],
          dob: kycOption['dob'],
          address: kycOption['address'],
          country: kycOption['country'],
          amount: kycOption['amount'],
          txid: kycOption['txid'],
          sourceFunds: kycOption['sourceFunds'],
          idUpload: kycOption['useridUpload'][0],
          poaUpload: kycOption['userpoaupload'][0],
        }

        axios.post(process.env.REACT_APP_BASE + "api/update-ficadata",ficaObj).then(async res =>{
        
          if(res.data.status == 200)
          {
            toastr.options.onHidden = function() { window.location.href="/dashboard" } 
              toastr.success(res.data.message) 
              setKYCStep(kycStep + 1)
          }
          else
              toastr.error(res.data.message) 
              return false
        });
      }else{
        toastr.error("Require missing details for light fica!") 
        return false
      }
    }else{
      // Basic
      // if(kycOption['amount'] !== '' && kycOption['txid'] !== '')
      // {
        
        const ficaObj = {
          userid: user.user.userid,
          currency:kycOption['currency'],
          amount: kycOption['amount'].toString(),
          txid: kycOption['txid'],
        }

        axios.post(process.env.REACT_APP_BASE + "api/update-membershippayment",ficaObj).then(async res =>{
        
          // return false
          if(res.data.status == 200)
          {
            toastr.options.onHidden = function() { window.location.href="/dashboard" } 
            toastr.success(res.data.message) 
            setModalView(false)
            return true
          }
          else
              toastr.error(res.data.message) 
              return false
        });

      // }else{
      //   toastr.error("Require missing details!") 
      // }
    }



  }

var reports = cryptoOptions

var currencies = currencyOptons

function resetCurrencies() {
  reports = cryptoOptions
  
  currencies = currencyOptons
}

  const prevStepFica = () => {


      setKYCStep(kycStep - 1)
    // }

}

  function handleFicaChange(selectedOption) {
 
}
    

    // handle field change
    const handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    }


    


  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleMenu = () => {
    setIsMenu(!isMenu)
  }

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const [allTransactionsUsers, setAllTransactions] = useState([])
  const [savingsVaultOpen, setSavingsVaultOpen] = useState([])

  const [firstData, setFirstData] = useState({'a': 0, 'b': 0})
  const [lastData, setlastData] = useState({'a': 0, 'b': 0})

  const [getMTO, setMTO] = useState('')
  const [getRTO, setRTO] = useState('')

  const [userUserid, setuserUserid] = useState('')

  React.useEffect( async () => {
  
    if (auth != null) {
      if (auth) {
        setUserMainParent(user.user.mainParent)

        if(user.user.userid == 'C10000001')
        {
          setShouldShowData(true)
          // dataTransactions
          // dataVaultOpen
          // dataVaultClosed

          // Lets now get all the data
          await axios.get(process.env.REACT_APP_BASE + "api/amount-open-vaults").then(async res =>{

            if(res.data.status == 200)
            {

              setDataVaultOpen(parseFloat(res.data.total).toFixed(2))
            }
          });

          await axios.get(process.env.REACT_APP_BASE + "api/amount-open-transactions").then(async res =>{

            if(res.data.status == 200)
            {
              console.log("the open tranactions is ", res.data)
              setAllTransactions(res.data.total)
            }
          });

          await axios.get(process.env.REACT_APP_BASE + "api/get-savings-vault-open").then(async res =>{

            if(res.data.status == 200)
            {
              setSavingsVaultOpen(res.data.total)
            }
          });
        }

        var isAdminThridParty = {
          userid: user.user.userid,
          company_id: user.user.mainParent
        }

        await axios.post(process.env.REACT_APP_BASE + "api/is-user-part-of-thirdparties",isAdminThridParty).then(async res =>{
          if(res.data.status == 200)
          {
            setAdminThirdParty(true)
          }
        });

      
        setNewOtpOption(user.user.otp_type)
        if(user.user.terms == 'true' || user.user.terms == true)
        {
          setTermsNotChecked(false);
          setLoginModalView(false);
          setSponsorCode(user.user.sponsor_code)
        }else{
          setTermsNotChecked(true);
        }
        if(user.user.payment_status == 'N')
        {
        Swal.fire({
          title: "No Membership",
          text: "Oops, it appears that you are not yet an C1 Holdings member. Please click on the button below to become part of the club.",
          icon: "info",
          confirmButtonText: "Lets get started",
          confirmButtonColor: "#1b75bb",
          allowOutsideClick: false,
        }).then(result => {
          if (result.isConfirmed) {
            startKYCProcess()
          }
        })
      }else{
        //check login terms and POPI here\
       
        setSkipPayment(true)
        setcheckTerms(true)
      }

      // completionProgress
      // 1. Registered
      // 2. Membership
      // 3. Provided Fica Documents
      // 4. Done

      if(user.user.payment_status == "N")
      {
        setPaymentLabel("Require payment")
      }else if(user.user.payment_status == "P")
      {
        setPaymentLabel("Pending")
        setCompletionText("Membership Pending")
      }else if(user.user.payment_status == "A")
      {
        setPaymentLabel("Active")
        setCompletionProgress(50)
        setCompletionText("Require KYC")
      }

      if(user.user.fica_status == "N")
      {
        setCurrentKYCStatus("Require KYC")
        setShowKYCMessage(true)
        // if(completionProgress <= 75)
        // {
        //   setCompletionProgress(75)
        //   setCompletionText("Completed Membership")
        // }
          
      }else if(user.user.fica_status == "P")
      {
        setCurrentKYCStatus("Pending")
        setCompletionText("KYC Pending")
      }else if(user.user.fica_status == "A")
      {
        setCurrentKYCStatus("Active")
        setCompletionProgress(100)
        setCompletionText("Done")
      }

      setUserNames(user.user.name + ' ' + user.user.lastname)
      setUserEmail(user.user.email)

      const userBalance = {
        userid: user.user.userid,
        currency: "1",
        crypto: 'false'
      }

      await axios.post(process.env.REACT_APP_BASE + "api/user-balance",userBalance).then(async res =>{
        setUserUsdtBalance(res.data.total)
      }).then( async res2 =>{
        const cryptoBalance = {
          userid: user.user.userid,
          crypto: 'true'
        }
  
        await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",cryptoBalance).then(async res =>{
          setCryptoOptions(res.data.currencyInfo)
        })
      
        const currencyBalance = {
          userid: user.user.userid,
          crypto: 'false'
        }
  
        await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",currencyBalance).then(async res =>{

          
          setCurrencyOptons(res.data.currencyInfo)
     
        })

        resetCurrencies()
      })

     

      
  
      }
    }
  }, [auth])


  // useEffect(() => {
  //   dispatch(onGetChartsData("yearly"))
  // }, [dispatch])

  const moderateFund = {
    name: "Moderated Fund",
  }

  const highFund = {
    name: "High Fund",
  }

  const moderateSaving = {
    name: "Moderate Saving",
  }

  const highSaving = {
    name: "High Saving",
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  useEffect(async () => {

    if (typeof user !== "undefined" && user !== null && auth) {
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL +
            "/find-all-products-total-returns-by-userId",
          {
            userid: user.user.userid,
          }
        )
        .then(async res2 => {
          if (res2.data.status == 200) {
            setAllProductSales(res2.data.sales)
          }
        })

        // Lets see the user fica status
        user.user.fica_status == 'A' ? setFicaStatus(true) : setFicaStatus(false)
        // user.user.u_status == 'A' ? setUserPayStatus(true) : setUserPayStatus(false)
        // user.user.fica_option != 'F' ? setFicaStatus(true) : setFicaStatus(false)

        
    }
  }, [user])


  function openPaymentMembership()
  {
    setKYCStep(3)
    setModalView(true)
    // startKYCProcess()
  }

  function openFicaOptions()
  {
   
    setKYCStep(1)
  }

  function onCloseClick()
  {
    setModalView(false)
  }

  var check  = function() {
    return jQuery(window).width() <=800
  }

  function onDoneModalTermsClick()
  {
    setTermsNotChecked(false);
    setLoginModalView(false);
  }

if(check)
{
  jQuery('.table').wrapAll('<div class="table-responsive"></div>');
}

function setOTPType(otpOption) {

  console.log('option type ', otpOption)
  // var otpObject = {
  //   option: otpOption,
  // }
  // axios.post(process.env.REACT_APP_BASE + "api/update-otp-type",otpObject).then(async res =>{
  //   setNewOtpOption(otpOption)
  // })
}

function handleOTPChange(e) {
  setShowSpinner(true)
  

  var otpObject = {
    option: e.target.value,
    userid: user.user.userid
  }
  axios.post(process.env.REACT_APP_BASE + "api/update-otp-type",otpObject).then(async res =>{

    setNewOtpOption(e.target.value);
    setShowSpinner(false)
  })
}

const  getFirstPayments = async (e) => {
  setDisplayFirstSpinner(true)
  var objToShow = {
    "showfirst": true
  }


  await axios.post(process.env.REACT_APP_BASE + "api/getNextDatesPayments", objToShow).then(async res =>{



    if(res.data.status == 200)
    {
      console.log(res.data.data);
      firstData['a'] = parseFloat(res.data.data.a).toFixed(2);
      firstData['b'] = parseFloat(res.data.data.b).toFixed(2);
      
      setDisplayFirstSpinner(false)
    }else{
      setDisplayFirstSpinner(false)
      toastr.error("Unable to find data!") 
    }
    console.log(firstData)

  });

  return true
}

const  getSecondPayments = async (e) => {

  setDisplayLastSpinner(true)
  var objToShow = {
    "showfirst": false
  }


  await axios.post(process.env.REACT_APP_BASE + "api/getNextDatesPayments", objToShow).then(async res =>{

    if(res.data.status == 200)
    {

      lastData['a'] = parseFloat(res.data.data.a).toFixed(2);
      lastData['b'] = parseFloat(res.data.data.b).toFixed(2);
      setDisplayLastSpinner(false)
    }else{
      setDisplayLastSpinner(false)
      toastr.error("Unable to find data!") 
    }


  });
  return true
}

function changeMTO(e) {
  setMTO(e.target.value)
}

function changeRTO(e) {
  setRTO(e.target.value)
}



const  updateMTO = async (e) => {


  var objToShow = {
    userid: userUserid,
    meta_key: 'mto',
    meta_value: getMTO
  }


  await axios.post(process.env.REACT_APP_BASE + "api/update-meta", objToShow).then(async res =>{

    if(res.data.status == 200)
    {
      toastr.success("Updated Successfully!")
      
    }


  });
  return true
}


const  updateRTO = async (e) => {

  var objToShow = {
    userid: userUserid,
    meta_key: 'rto',
    meta_value: getRTO
  }


  await axios.post(process.env.REACT_APP_BASE + "api/update-meta", objToShow).then(async res =>{

    if(res.data.status == 200)
    {
      toastr.success("Updated Successfully!")
      
    }

  });
}



  return (
    <React.Fragment>
    {termsNotChecked && authenticated ? 
    <LoginTermsModal 
        modalView={loginModalView}
        onCloseClick={onCloseClick}
        onDoneModalTermsClick={onDoneModalTermsClick}
      />
    :''
    // kycStep == 1?
      // <FicaPaymentModal 
      //   nextStep ={ kycStep}
      //   ContinueNextStep ={ nextStepFica }
      //   option={ kycOption }
      //   modalView={modalView}
      //   onCloseClick={onCloseClick}
      //>
    // : ''}
}


      <div className="page-content" >
        <MetaTags>
          <title>C1 Holdings - Dashboard</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12" md="12" className="m-2">

              {/* Onboarding Desciption and OTP settings */}
              <Row>
                <Col md={3}>
                  <Card className="mini-stats-wid c1-primary-dark-card">
                    <CardBody>
                      <p className="card-title-desc">Receive OTP via:</p>
                      {showSpinner?
                        <Spinner className="ms-2" color="success" />
                        :
                    
                        <div className="btn-group-horizontal">

                            <input type="radio" className="btn-check" name="vbtn-radio" checked={newOtpOption === 'email'}  onClick={(e) => handleOTPChange(e)} value="email" id="emailOption" />
                            <label className="btn btn-outline-success" htmlFor="emailOption">Email</label>
                            <input type="radio" className="btn-check" name="vbtn-radio" checked={newOtpOption === 'sms'} onClick={(e) => handleOTPChange(e)} value="sms" id="smsOption"/>
                            <label className="btn btn-outline-success" htmlFor="smsOption">SMS</label>
                          </div>
                      }
                    </CardBody>
                  </Card>
                </Col>

                <Col md={9}>
                  <Card className="mini-stats-wid c1-primary-dark-card">
                    <CardBody>
                      <p className="card-title-desc">Completion of C1 Holdings Account</p>
                      <div className="mt-2">
                        <div className="position-relative m-4">
                          <Progress color="success" value={completionProgress} max="100" style={{ height: '20px'}}>{completionText}</Progress>
                        </div>
                      </div>
                      {showKYCMessage ? 
                      <div className="mt-2">
                        <a className="btn btn-outline-success btn-success" href="/my-usdt-wallet?type=deposit">Complete KYC</a>
                      </div>
                      : ''}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {shouldShowData ? 
              <Row>
                <Col md={4}>
                  <Card className="mini-stats-wid c1-primary-dark-card">
                    <CardBody>
                      <p className="card-title-desc">Total Amounts (Wallet)</p>
                      <div className="mt-2">
                        <div className="position-relative m-4">
                          <div><h5 style={{ display: "inline"}}>USDT: </h5><h6 className="mb-0" style={{ display: "inline"}}>{parseInt(allTransactionsUsers.usdt).toFixed(2)}</h6></div><br />
                          <div><h5 style={{ display: "inline"}}>ZAR: </h5><h6 className="mb-0" style={{ display: "inline"}}>{parseInt(allTransactionsUsers.zar).toFixed(2)}</h6></div><br />
                          <div><h5 style={{ display: "inline"}}>BTC: </h5><h6 className="mb-0" style={{ display: "inline"}}>{parseInt(allTransactionsUsers.btc).toFixed(2)}</h6></div><br />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="mini-stats-wid c1-primary-dark-card">
                    <CardBody>
                      <p className="card-title-desc">Total In Vault</p>
                      <div className="mt-2">
                        <div className="position-relative m-4">
                          <div><h5 style={{ display: "inline"}}>Total Vault: </h5><h6 className="mb-0" style={{ display: "inline"}}>{'$' + dataVaultOpen}</h6></div><br />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="mini-stats-wid c1-primary-dark-card">
                    <CardBody>
                      <p className="card-title-desc">Next Monthly Interest 1st</p>
                      {displayFirstSpinner ?
                        <Spinner className="ms-2" color="success" />
                        :
                      <div className="mt-2">
                        <div className="position-relative m-4">
                          <div><h5 style={{ display: "inline"}}>Total: </h5><h6 className="mb-0" style={{ display: "inline"}}>{'$' + firstData.a}</h6></div><br />
                          <div><h5 style={{ display: "inline"}}>Total VIP: </h5><h6 className="mb-0" style={{ display: "inline"}}>{'$' + firstData.b}</h6></div><br />
                          <button className="btn btn-outline-success" onClick={(e) => getFirstPayments(e)}>Load</button>
              
                        </div>
                      </div>
                }
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="mini-stats-wid c1-primary-dark-card">
                    <CardBody>
                      <p className="card-title-desc">Next Monthly Interest 15th</p>
                      {displayLastSpinner ?
                        <Spinner className="ms-2" color="success" />
                        :
                      <div className="mt-2">
                        <div className="position-relative m-4">
                        <div><h5 style={{ display: "inline"}}>Total: </h5><h6 className="mb-0" style={{ display: "inline"}}>{'$' + lastData.a}</h6></div><br />
                          <div><h5 style={{ display: "inline"}}>Total: </h5><h6 className="mb-0" style={{ display: "inline"}}>{'$' + lastData.b}</h6></div><br />
                          <button className="btn btn-outline-success" onClick={(e) => getSecondPayments(e)}>Load</button>
                        </div>
                      </div>
                        }
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="mini-stats-wid ">
                    <CardBody>
                      <p className="card-title-desc">Manual To OMC</p>
                      <div className="mt-2">
                        <div className="position-relative m-4">
                          <div><input className="form-control" style={{color: 'black !important'}} onChange={(e) => changeMTO(e)} value={getMTO} type="text" placeholder="Manual to OMC" /></div><br />
                          <button className="btn btn-outline-success" onClick={(e) => updateMTO(e)}>Update</button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="mini-stats-wid ">
                    <CardBody>
                      <p className="card-title-desc">Manual Return to OMC</p>
                      <div className="mt-2">
                      <div className="position-relative m-4">
                          <div><input className="form-control" style={{color: 'black !important'}} onChange={(e) => changeRTO(e)} value={getRTO} type="text" placeholder="Return to OMC" /></div><br />
                          <button className="btn btn-outline-success" onClick={(e) => updateRTO(e)}>Update</button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>


                {/* <Col md={4}>
                  <Card className="mini-stats-wid omc-primary-dark-card">
                    <CardBody>
                      <p className="card-title-desc">Total Closed Vaults</p>
                      <div className="mt-2">
                        <div className="position-relative m-4">
                          <div><h5 style={{ display: "inline"}}>Total: </h5><h6 className="mb-0" style={{ display: "inline"}}>{'$' + dataVaultClosed}</h6></div><br />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}

              </Row>
              : ''}

              {/* User Information */}
              <Row>
                <Col md="4">
                    <Card className="mini-stats-wid c1-primary-dark-card">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Profile:
                            </p>
                            <div><h5 style={{ display: "inline"}}>Membership: </h5><h6 className="mb-0" style={{ display: "inline", textAlign:"right"}}>{paymentLabel}</h6></div><br />
                            <div><h5 style={{ display: "inline"}}>KYC Status: </h5><h6 className="mb-0" style={{ display: "inline"}}>{currentKYCStatus}</h6></div><br />
                            <div><h5 style={{ display: "inline"}}>Username: </h5><h6 className="mb-0" style={{ display: "inline"}}> {userNames}</h6></div><br />
                            <div><h5 style={{ display: "inline"}}>Email: </h5><h6 className="mb-0" style={{ display: "inline"}}>{userEmail}</h6></div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col>

              </Row>

              {/* Wallet Information */}
              <Row>
                <MiniWidget reports={reports} />
              {/* </Row>

              <Row> */}
                <CurrencyWidget currencies={currencies} />
              </Row>
           
            {/* <ProfileBalanceWidget userProfile={user?.user} /> */}
            </Col>

          </Row>

          <Row>
            <Col lg="12">
              {/* {!isEmpty(wallet["walletHistory"]) && ( */}
              <WalletTransactions
                walletHistory={wallet["walletHistory"]}
                activeTab={activeTab}
                toggleTab={toggleTab}/> 
               {/* )}  */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  // onGetChartsData: PropTypes.func,
}
export default UserDashboard
