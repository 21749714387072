import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import axios from "axios"

import { Bar } from "react-chartjs-2"

const CapitalReturnChart = (data) => {
  var labelData = [];

  
  console.log("HERE", data);

  var barData = []
  data.viewAllProductSales.forEach(productsale => {
    labelData.push(productsale.expire_date)
    barData.push(Number(productsale.amount))
  })

  const dataBar = {
    labels: labelData,
    datasets: [
      {
        label: "Sales Analytics",
        backgroundColor: "rgba(52, 195, 143, 0.8)",
        borderColor: "rgba(52, 195, 143, 0.8)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
        hoverBorderColor: "rgba(52, 195, 143, 0.9)",
        data: barData,
      },
    ],
  }

  const optionBar = {
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Capital Return</CardTitle>

          <Bar width={474} height={300} data={dataBar} options={optionBar} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

CapitalReturnChart.propTypes = {
  viewAllProductSales: PropTypes.array
}

export default CapitalReturnChart
