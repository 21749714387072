import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import { Link, useParams, withRouter } from "react-router-dom"

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const pendingWithdrawals =()=>{
    const [viewAllTransactions, setAllTransactions] = React.useState([]);

    const{auth,user} = React.useContext(UserContext)
    
    const [newTXID, setNewTXID] = React.useState('');
    // const [newAllTXID, setNewAllTXID] = React.useState([]);
    const [inputList, setInputList] = React.useState([{ newAllTXID: ""}]);

    const[StartpackList,setStartpackList] = React.useState([])
    const [viewAllUserInfo, setAllUserInfo] = React.useState([]);

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])


const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const [addedBankingDetails, setAddedBankingDetails] = useState(false)
const [bankDetails, setBankDetails] = useState('')
const [uploadedBank, setUploadedBank] = useState('')
const [useruserid, setUserid] = useState('')

const defaultId = 4;


const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

    if(user !== 'undefined' && user !== null)
    {

      setUserid(user.user.userid)
        await axios.get(process.env.REACT_APP_BASE + "api/pending-withdrawals").then((res)=>{
            console.log('all withdrawals ', res.data)
            setAllTransactions(res.data.withdrawals)
            setAllUserInfo(res.data.users)
        });

        const userBankObj = {
          userid: user.user.userid,
          meta_key: 'banking'
        }
    
        await axios.post(process.env.REACT_APP_BASE + "api/get-meta",userBankObj).then(async res =>{
          if(res.data.status == 200)
          {
            setBankDetails(res.data.body.bankDetails)
            setUploadedBank(res.data.body.uploadeddoc)
            if(res.data.body.status == 1)
            {
              setAddedBankingDetails(true)
              console.log("the bank details ", res.data.body.bankDetails)
           
            }

          }
        })
    }

},[auth])

 const approveWithdrawal = (deposit) => {

    const updateDepObj = {
        transactionId: deposit.id,
        status: '1', 
        trans_id: deposit.trans_id
    }

    console.log(updateDepObj)
    
    axios.post(process.env.REACT_APP_BASE + "api/update-withdrawal", updateDepObj).then((res)=>{
        console.log('updated deposits ', res.data)
        if(res.data.status == 200)
        {
            toastr.options.onHidden = function() { window.location.href="/pending-withdrawals" } 
            toastr.success(res.data.message); 
        }else{
            toastr.options.onHidden = function() { window.location.href="/pending-withdrawals" } 
            toastr.warning("Unable to update deposit"); 
        }
    });
 }

 const declineWithdrawal = (deposit) => {
 

    const updateDepObj = {
        transactionId: deposit.id,
        status: '2', 
        trans_id: deposit.trans_id
    }

    axios.post(process.env.REACT_APP_BASE + "api/update-withdrawal", updateDepObj).then((res)=>{
        console.log('updated deposits ', res.data)
        if(res.data.status == 200)
        {
            toastr.options.onHidden = function() { window.location.href="/pending-withdrawals" } 
            toastr.success(res.data.message); 
        }else{
            toastr.options.onHidden = function() { window.location.href="/pending-withdrawals" } 
            toastr.danger("Unable to update deposit"); 
        }
    });

 }

 const addNewTXID = (transactionID, txid) => {
  console.log('new transaction ID ', transactionID, txid)

 }

 function handletableclick(data) {
  return "/pathToRoute=" + data;
}

const approveBankWithdrawal = async(deposit) => {
  const userBankObj = {
    userid: useruserid,
  }

  await axios.post(process.env.REACT_APP_BASE + "api/update-bank-meta",userBankObj).then(async res =>{
    if(res.data.status == 200)
    {
      approveWithdrawal(deposit)
    }else{
      toastr.error("Unable to accept withdrawal!")
    }
  })
}

console.log('user info? ', viewAllUserInfo)
    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - All Pending Withdrawals</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="All Pending Withdrawals" />

          <Row>

            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Pending Withdrawals</h4>

                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>

                        <tr>
                          <th scope="col">UserInfo</th>
                          <th scope="col">Userid</th>
                          <th scope="col">Amount</th>
                          <th scope="col">To Wallet</th>
                          <th scope="col">Currency</th>
                          <th scope="col">TXID</th>
                          <th scope="col">Action</th>
                          <th scope="col">Date</th>
                        </tr>

                      </thead>
                      <tbody>

                     
                        { typeof viewAllTransactions !== "undefined" && viewAllTransactions.length > 0 ?
                        
                        viewAllTransactions.map((viewAllTransaction ,key) => (
                             <tr key={viewAllTransaction.id}>
                               
                                <td>{viewAllTransaction.userid ? 
                                viewAllUserInfo[viewAllTransaction.userid]: ''}
                                </td>

                               <td>{viewAllTransaction.userid ? 
                                viewAllTransaction.userid: ''}</td>
                                <td>{viewAllTransaction.amount ? 
                                 viewAllTransaction.amount: ''}</td>
                                <td>{viewAllTransaction.amount ? 
                                viewAllTransaction.w_address: ''}</td>
                                {/* <td>{viewAllTransaction.amount ? 
                                'USDT': ''}</td> */}
                                <td>{viewAllTransaction.currency == '3' ? 
                                  'BTC': viewAllTransaction.currency == '2' ? 'ZAR' : 'USDT'}</td>

                                <td>{viewAllTransaction.txid ? 
                                viewAllTransaction.txid:
                                <Link className="text-success" to={`/handle-pendingin-withdrawaltxid/${viewAllTransaction.id}`}>
                                <i
                                  className="bx bx-edit bx-sm mt-1 font-size-18"
                                  id="edittooltip"
                                  onClick={() => handletableclick(viewAllTransaction.id)}
                                ></i>
                              </Link>
                                }</td>

                                {addedBankingDetails ?

                                <td > 
                                  <button className="btn btn-success"  onClick={e => approveWithdrawal(viewAllTransaction)}>Approve</button>
                                  <button className="btn btn-danger"  onClick={e => declineWithdrawal(viewAllTransaction)}>Decline</button> 
                                </td>

                                :

                                <td>{
                                  bankDetails !== '' ?
                                  <div>
                                    <p>Bank Details: {bankDetails}</p>
                                    <p>Uploaded Proof :</p>
                                    {uploadedBank && !uploadedBank.includes('.png') && !uploadedBank.includes('.jpeg') && !uploadedBank.includes('.jpg')?
                                      <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+uploadedBank} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                    :
                                      <Zoom><img src={process.env.REACT_APP_BASE + 'assets/'+uploadedBank} className="w-25 img-thumbnail"/></Zoom>
                                      }
                                          <button className="btn btn-success"  onClick={e => approveBankWithdrawal(viewAllTransaction)}>Approve</button>
                                  </div>
                                  
  
                                   :
                                   <p>Bank Details: No Bank Details</p>

                                  }
                              
                                   <button className="btn btn-danger"  onClick={e => declineWithdrawal(viewAllTransaction)}>Decline</button> 
                                </td>
                                }

                                <td>{viewAllTransaction.createdAt ? 
                                 viewAllTransaction.createdAt : ''}</td>
                    
                          </tr>
                          ))
                          :

                          <tr>
                            <td >No Withdrawals</td>
                          </tr>
                        }
  
                   

                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
        </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}