import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const adminInterests =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [allDates, setAllDates] = React.useState([]);

  const [currentDateId, setCurrentDateId] = React.useState('');
  const [vipOption, setVipOption] = React.useState('false');
  
  
  
  const [currentPercentage, setCurrentPercentage] = React.useState('');
  const [foundProducts, setFoundProducts] = React.useState(false);
  const [getProductid, setCurrentProductId] = React.useState('');
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])





    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])







const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    
  }

},[auth])

useEffect(async res =>{
  if(user !== 'undefined' && user !== null && !foundProducts)
  {
    getAllProducts(user.user.userid)
    setFoundProducts(true)
    getAllDates(user.user.userid)

  }


})

const getAllProducts = async userid => {
 
  await axios.get(process.env.REACT_APP_BASE + "api/all-products").then(async res =>{

    console.log('set all products ', res.data.Products)
    return setAllProducts(res.data.Products);
 
  })

}

const getAllDates = async userid => {
 
  await axios.get(process.env.REACT_APP_BASE + "api/payment-dates").then(async res =>{
    // allDates
    return setAllDates(res.data.dates);
 
  })

}




function setProductInterest(percentage) {


  const InterestObject = {
    productid: getProductid,
    dateid: currentDateId,
    percentage: currentPercentage,
    vip: vipOption,
  }

  console.log(getProductid)

  axios.post(process.env.REACT_APP_BASE + "api/create-productinterest",InterestObject).then(async res =>{
    
    // toastr.options.onHidden = function() { window.location.href="/new-product-interest" } 
    toastr.success(res.data.message) 

  });
}

// allProducts


    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Add Product Interest</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="Add Product Interest" />

          <Row>

          <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Product Interest</h4>

                <div className="mt-4 mb-3">

                  <TabContent activeTab={activeTab} className="mt-4">
                    <TabPane tabId="1" id="buy-tab">


                      <div>
                        <div>
                        <Label>Dates:</Label>
                        <InputGroup className="mb-3">
                            <select className="form-control" name="allproducts" id="allproducts" onChange={(e)=>setCurrentDateId(e.target.value)} value={currentDateId}>
                                <option className="form-control"  value="">Select Payment Date</option>
                                {allDates.map((date, key) => (
                                    <option className="form-control" key={date.id} value={date.id}>{date.payment_date}</option>
                                ))}

                            </select>
                        </InputGroup>

                        <Label>Products:</Label>
                        <InputGroup className="mb-3">
                            <select className="form-control" name="allproducts" id="allproducts" onChange={(e)=>setCurrentProductId(e.target.value)} value={getProductid}>
                                <option  value="">Select Product</option>
                                {allProducts.map((product, key) => (
                                    <option className="form-control" key={product.id} value={product.productid}>{product.name}</option>
                                ))}

                            </select>
                        </InputGroup>

                        <Label>Is this for VIP&apos;s:</Label>
                        <InputGroup className="mb-3">
                            <select className="form-control" name="allproducts" id="allproducts" onChange={(e)=>setVipOption(e.target.value)} value={vipOption}>
                                <option  value="">Select Option</option>
                                <option className="form-control" value="false">No</option>
                                <option className="form-control" value="true">Yes</option>
                            </select>
                        </InputGroup>

                          <Label>Percentage :</Label>

                          <InputGroup className="mb-3">
                        
                            <Input type="number" min="1" step="1" max={userBalance} start="70" onChange={(e)=>setCurrentPercentage(e.target.value)} value={currentPercentage} className="form-control" />
                            <Label className="input-group-text">%</Label>
                          </InputGroup>
                        </div>

                        <div className="text-center">
                          <Button
                            onClick={(e)=>setProductInterest(e.target.value)}
                            type="button"
                            color="success"
                            className="w-md bg-success"
                          >
                            Add Interest
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                   
                  </TabContent>
                </div>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}