import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import {useState,useEffect} from "react";
import axios from 'axios'
import { Row, Col, CardBody, Card, Alert, Container, Form, Modal, Input, ModalBody, FormFeedback, Label } from "reactstrap";


// import LoginAuthModal from "../../components/Common/LoginAuthModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import termsPDF from "./Terms.pdf";
import { withRouter, Link } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import loginlogo from "assets/images/backgroundomc.jpeg";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/c1_favicon.png";
import logo from "assets/images/c1_login_logo.png";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
//Import config
import { facebook, google } from "../../config";
import {UserContext} from '../../UserContext'

const Login = props => {
  const dispatch = useDispatch();
  const [apassword,setPassword] = useState('')
  const [username,setEmail] = useState('')
  const [loader,setLoader] = useState(false)
  const [termsa, setTerms] = React.useState(false);
  const [popi, setPOPI] = React.useState(false);
  const [loginAuthModal, setLoginAuthModal] = useState(false);
  const [continueLogin,setContinueLogin] = useState(false)

  const [reCaptcha,setReCaptcha] = useState('')

  const [getAuthTokenData,setAuthTokenData] = useState('')

  const [newVerificationCode, setNewVerificationCode] = React.useState('');
  
  // const [canContinueLogin,setContinueLogin] = useState(false)

  const {user,setUser,auth,setAuth,isAuthenticated} = React.useContext(UserContext)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin001@mulaconnect.com" || '',
      email: username,
      // password: "admin0011234" || '',
      password: apassword,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),

  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  // handleValidSubmit
  // 
  const loginFunc = () => {
    var formD = new FormData();
    formD.append('username',username)
    formD.append('password',apassword)
    formD.append('isAdmin',true)
    // setLoader(true)
    axios.create({
      
    })

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`,formD,{withCredentials:true}).then((res)=>{

      if(res.data.status == 200)
      {
        // It is successfull client is logged in
        if(res !== undefined){
          // toastr.success(res.data.message)
        }
        if(res.data.auth)
        {
          toastr.options.onHidden = function() { window.location.href="/dashboard" }
          toastr.success(res.data.message)
        }
      }else{
        toastr.error(res.data.message)
      }

    })
  };

  const adminLogin = (values) => {
   
    const findUserObj = {
      email: username
    }

    if(reCaptcha == '' || reCaptcha == null)
    {
      toastr.error("Google Recaptcha required")
      return false;
    }
    
    axios.post(process.env.REACT_APP_DATABASEURL + "api/find-one-user-by-email",findUserObj).then(async res =>{

      if(res.data.status == 200)
      {
        if(res.data.user.authid)
        {
          setLoginAuthModal(true)
        }else{
          loginFunc()
        }
      }else{
        toastr.error("Unable to find user")
      }

    });
    

  }

  function onChangeRecaptcha(value)
  {
    setReCaptcha(value);
  }

 
  function handleLoginAuthModal(token) {

    const findUserObj = {
      email: username
    }
    
    axios.post(process.env.REACT_APP_DATABASEURL + "api/find-one-user-by-email",findUserObj).then(async res =>{

      
      if(res.data.status == 200)
      {
     
        var handleLoginModalObject = {
          userid: res.data.user.authid, 
          token: newVerificationCode
        }

        axios.post(process.env.REACT_APP_DATABASEURL + "api/validate-auth",handleLoginModalObject).then(async res =>{
       
          if(res.data.status == 200)
          {
            // It is successfull client is logged in
            if(res.data.validated)
            {
              axios.post(process.env.REACT_APP_DATABASEURL + "api/update-login-terms",findUserObj).then(async res =>{
              
              })
              loginFunc()
            }
          }else{
            toastr.error("Invalid")
          }
      
        })
      }else{
    
        toastr.error("Invalid")
      }
    })


  };

  React.useEffect(()=>{

  
    if(auth)
    {
      
      window.location.href="/"
    }
  },[auth])
  function canContinue(e){
    if(!termsa || !popi)
        {
          toastr.error("Please agree to the terms and conditions and POPI"); 
          return false;
        }
  }
  function toggle(value){
    setTerms(!value)
   
    return !value;
  }
  
  function popiToggle(value){
    setPOPI(!value)
    
    return !value;
  }

  return (
    <React.Fragment>

<div id="playground">
</div>
      {/* <LoginAuthModal
        show={loginAuthModal}
        newVerificationCode={setAuthTokenData}
        onDeleteClick={handleLoginAuthModal}
        onCloseClick={() => setLoginAuthModal(false)}
      /> */}
      <Modal isOpen={loginAuthModal} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>2FA Authentication</h2>
                <Input
                    name="newverifyLink"
                
                    className="form-control"
                    placeholder="Verification Code"
                    type="password"
                    value={newVerificationCode}
                    onChange={(e)=>setNewVerificationCode(e.target.value)}
                    // onClick={(e)=>setNewVerificationCode(e.target.value)}
                    // onClick={(e)=>setNewVerificationCode(e.target.value)}
                />



            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={(e)=>handleLoginAuthModal(e.target.value)}
              >
                Verify
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={(e)=>setLoginAuthModal(false)}
                //  onClick={setLoginAuthModal(false)}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
      <MetaTags>
        <title>C1 HOLDINGS (PTY) LTD - Login</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/login" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages" >
        <Container className="pt-sm-5 mw-100 fvw-login-container" >
         {/* <> {(auth)?window.location.href="/dashboard":''}</> */}

          <Row className="justify-content-center login-user-content" style={{
            position: "absolute",
            left: "40%",
            top: "34%",
            transform: "translate(-50%, -50%)",
        }}>
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden fwv-card-login" style={{
                // borderRadius: "50%",
                // width: "600px",
                // height: "600px",
                // paddingTop: "50px",
                // background: "rgba(25, 24, 24, 0.32)"
              }}>
                <CardBody className="pt-0">
                  <div className="mb-4">
                    <Link to="/login" className="auth-logo-light">
                      <div className="w-100 profile-user-wid">
                        <span className="avatar-title ">
                          <img
                            src={logo}
                            alt=""
                            className="mt-2 fvw-login-logo-image"
                            // height="100"
                            // width="180"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <Row className="mb-2 text-center">
                    <Col className="col-12">
                      <h2 className="">Client Login</h2>
                    </Col>

                  </Row>
                  <div className="p-1">
                    <Form className="form-horizontal fvw-login-form"  >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="loginLabel">Email</Label>
                        <Input
                          name="email"
                          autoComplete="new-password"
                          className="form-control fwv-input-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={(e)=>setEmail(e.target.value)}
                          onBlur={validation.handleBlur}
                         
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="loginLabel">Password</Label>
                        <Input
                          name="password"
                          className="fwv-input-control mb-3"
                          type="password"
                          autoComplete="new-password"
                          placeholder="Enter Password"
                          onChange={(e)=>setPassword(e.target.value)}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}

                        <ReCAPTCHA
                          sitekey="6LeETtIhAAAAAKswCX3gQFe3fEcsuCRrivyF2qup"
                          onChange={onChangeRecaptcha}
                        />


                        <Link to="/forgot-password" className="text-muted pt-2">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>

                      <div className="mt-4 d-grid" style={{height: "50px"}}>
                   {/* {(loader ==false)?      */}
                        <button
                          className="btn c1-primary fwv-primary-btn btn-block "
                          type="button"
                          onClick={adminLogin}
                        >
                          Log In
                        </button> 
                    
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
