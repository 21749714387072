import React, { Component,useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags";
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import { Link } from "react-router-dom";

import { Row, Button, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroup

} from "reactstrap";

// import images
import profileImg from "../../assets/images/c1_favicon.png";
import logo from "assets/images/c1logo.png";
import logoImg from "../../assets/images/c1_favicon.png";
import termsPDF from "./Terms.pdf";
import popiPDF from "../../assets/attachments/C1_POPI_AGREEMENT.pdf"

import classnames from "classnames"

import loginlogo from "assets/images/backgroundomc.jpeg";

// export class PersonalDetails extends Component {
  const PinfoStage1 = props => {

    const [isMenu, setisMenu] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const [isSameAsPassword, setisSameAsPassword] = React.useState('');
    const [termsa, setTerms] = React.useState(false);
    const [popi, setPOPI] = React.useState(false);

    const queryParams = new URLSearchParams(window.location.search);


  function canContinue(e){

    var pass = props.values.password;
    var matchPass = pass.match(/(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}/) ? true : false;


    if(!termsa || !popi)
    {
        toastr.error("Please agree to the terms and conditions and POPI"); 
        return false;
    }

    if(!isSameAsPassword)
    {
    toastr.error("Password and Confirm Password do not match"); 
    return false;
    }

    if(!matchPass)
    {
    toastr.error("Password Require min of 8 characters, 1 special character, 1 numerical character"); 
    return false;
    }


    //We now need to register this user
    var newUserid = "NEW" + Math.floor(Math.random() * 1000) + 1
    const userObject =
    {   
        userid: "#" + newUserid,
        meta_key: "signup",
        meta_value: {
            status: '0',
            unique: newUserid,
            usertype: props.values.usertype,
            email: props.values.email,
            name: props.values.name,
            last: props.values.last,
            company_name: props.values.company_name,
            cipc: props.values.cipc,
            cell: props.values.cell,
            compliance: props.values.compliance,
            id_num: props.values.id_num,
            terms: termsa.toString(),
            popi: popi.toString(),
            progress: "Signed Up",
            password:props.values.password
        }
    }
    
    axios.post(process.env.REACT_APP_BASE + "api/create-meta",userObject).then(async res =>{

      if(res.data.status == 200)
      {
        props.nextStep();
        return false;

      }else{
        toastr.error(res.data.message); 
      }
   
    });


  };

  function goToPrevious(e){
    // e.preventDefault();
    props.prevStep();
    return false;
  };


  function checkConfirmPassword(confirmPassword){
    if(confirmPassword == props.values.password && props.values.password != '')
    {
      setisSameAsPassword(true)
    }else{
      setisSameAsPassword(false)
    }
  }


  function toggle(value){
    setTerms(!value)

    return !value;
  }

  function popiToggle(value){
    setPOPI(!value)
  
    return !value;
  }

  // popi

  // render() {
    // const { props.values } = props;

  
    return (



        <React.Fragment>
          <MetaTags>
            <title>C1 Holdings - Step 1</title>
          </MetaTags>
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="fas fa-home h2" />
            </Link>
          </div>
          <div className="account-pages" >
          <Container className="pt-sm-5 mw-100 fvw-login-container" 
        //   style={{
        //       backgroundImage: `url(${loginlogo})`,
        //       boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 20%)"
        //     }}
            >
            <Row className="justify-content-center select-user-content" style={{
            position: "absolute",
            left: "40%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            }}>
    
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden fwv-card-usertype" >
                    <CardBody className="pt-0">
                        <div>
                            <Link to="/login" className="auth-logo-light">
                                <div className="w-100 profile-user-wid mb-4">
                                    <span className="avatar-title ">
                                    <img
                                        src={logo}
                                        alt=""
                                        className="mt-2 fvw-login-logo"
                                    
                                    />
                                    </span>
                                </div>
                            </Link>
                        </div>

                        <Row>
                            <Col className="col-12">
                                <h2 className="intro-text">Personal Info</h2>
                            </Col>
                        </Row>
    
    
                      <div className="mt-4 mb-3">

                      {/* user */}
                      <TabContent activeTab={activeTab} className="mt-4">
                        <TabPane tabId="1" id="buy-tab">
                          <div>
                          <div className="p-2">
                        <Form
                          className="form-horizontal fvw-login-form"

                        >
                      
                          <Row>
                            <Col className='col-12'>

                            <Row>
                              {/* <Col className='col-2'>
                              </Col> */}

                                <Col className='col-12'>

                                <div className="mb-3">
                                    <Label className="loginLabel">User Type</Label>
                                        <select name="usertype" className="form-control"
                                            onChange={props.handleChange('usertype')}
                                            value={props.values.usertype}>
                                                <option value="">Select Option</option>
                                                <option value="0">User</option>
                                                <option value="1">Company</option>
                                        </select>

                                  </div>

                                <div className="mb-3">
                                    <Label className="loginLabel">{props.values.usertype == '1' ? 'Director First Name' : 'First Name'}</Label>
                                    <Input
                                      name="firstname"
                                      autoComplete="new-password"
                                      className="form-control fwv-input-control"
                                      placeholder="Enter Your First Name"
                                      type="text"
                                      onChange={props.handleChange('name')}
                                      value={props.values.name}
                                    
                                    />

                                  </div>

                                  <div className="mb-3">
                                        <Label className="loginLabel">{props.values.usertype == '1' ? 'Director Last Name' : 'Last Name'}</Label>
                                        <Input
                                        name="lastname"
                                        autoComplete="new-password"
                                        className="form-control fwv-input-control"
                                        placeholder="Enter Your Last Name"
                                        type="text"
                                        onChange={props.handleChange('last')}
                                        value={props.values.last}
                                        
                                        />
                            
                                    </div>

                                {props.values.usertype == '0' ? '' : 
                                    <div className="mb-3">
                                        <Label className="loginLabel">Company Name</Label>
                                        <Input
                                        name="lastname"
                                        autoComplete="new-password"
                                        className="form-control fwv-input-control"
                                        placeholder="Enter Your Company Name"
                                        type="text"
                                        onChange={props.handleChange('company_name')}
                                        value={props.values.company_name}
                                        
                                        />
                            

                                      <Label className="loginLabel">Company Registration Number (CIPC)</Label>
                                      <Input
                                      name="lastname"
                                      autoComplete="new-password"
                                      className="form-control fwv-input-control"
                                      placeholder="Enter Company Registration Number"
                                      type="text"
                                      onChange={props.handleChange('cipc')}
                                      value={props.values.cipc}

                                      />

                                      </div>
                                }


                                  <div className="mb-3">
                                    <Label className="loginLabel">{props.values.usertype == '1' ? 'Company Email' : 'Email'}</Label>
                                    <Input
                                      name="email"
                                      autoComplete="new-password"
                                      className="form-control fwv-input-control"
                                      placeholder="Enter Your Email Address"
                                      type="email"
                                      onChange={props.handleChange('email')}
                                      value={props.values.email}
                                    
                                    />

                                  </div>


                                  <div className="mb-3">
                                    <Label className="loginLabel">Cell</Label>
                                    <Input
                                      name="cell"
                                      autoComplete="new-password"
                                      className="form-control fwv-input-control"
                                      placeholder="Enter Your Cell Number"
                                      type="text"
                                      onChange={props.handleChange('cell')}
                                      value={props.values.cell}
                                    
                                    />

                                  </div>

                                  <div className="mb-3">
                                    <Label className="loginLabel">{props.values.usertype == '1' ? 'Director ' : '' }ID Number</Label>
                                    <Input
                                      name="id_num"
                                      autoComplete="new-password"
                                      className="form-control fwv-input-control"
                                      placeholder="Enter ID Number"
                                      type="text"
                                      onChange={props.handleChange('id_num')}
                                      value={props.values.id_num}
                                    
                                    />

                                  </div>
                                  

                                  <div className="mb-3">
                                    <Label className="loginLabel">Do you want to be contacted by our Compliance Team?</Label>
                                        <select name="usertype" className="form-control"
                                            onChange={props.handleChange('compliance')}
                                            value={props.values.comliance}>
                                                <option value="">Select Option</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                        </select>

                                  </div>

                                  <div className="mb-3">
                                    <Label className="loginLabel">Choose your Password </Label>
                                    <Input
                                      name="companyPassword"
                                      autoComplete="new-password"
                                      className="form-control fwv-input-control"
                                      placeholder="Enter Password"
                                      type="password"
                                      onChange={props.handleChange('password')}
                                      value={props.values.password}
                                    
                                    />
                                    
                                    <small className="text-muted">
                                      Requirements:
                                      <ul className="password-requir-list">
                                        <li>Min of 8 characters</li>
                                        <li>1 Special character</li>
                                        <li>1 Numerical character</li>
                                      </ul>
                                    </small>
                                  </div> 

                              <div className="mb-3">
                                    <Label className="loginLabel">Confirm Password </Label>
                                    <Input
                                      name="confirmPassword"
                                      autoComplete="new-password"
                                      className="form-control fwv-input-control"
                                      placeholder="Confirm Password"
                                      type="password"
                                      onChange={(e)=>checkConfirmPassword(e.target.value)} 
                                      // value={checkedConfirmPassword}
                                    
                                    />
                                    {isSameAsPassword?<Label className="text-success">Same as password</Label>:<Label className="text-danger">Not same as password</Label>}

                                </div>



                                  <div className="mb-3">
                                    <div className="row">
                                      <div className="col-md-10">
                                        <Label className="loginLabel">
                                            I hereby have read, understand and accept the Terms and Conditions of C1 Holdings{" "}
                                          <a  rel="noreferrer"  href={termsPDF} target="_blank" className="c1-primary-no-fill"> Terms
                                          </a>
                                        </Label>
                                      </div>
                                      <div className="col-md-2">                               
                                        <Input
                                            style={{width: "15px"}}
                                            name="terms"
                                            autoComplete="new-password"
                                            className="form-control fwv-input-control mt-1"
                                            placeholder="Enter Your Cell Number"
                                            type="checkbox"
                                            checked={termsa}
                                            onChange={() => toggle(termsa)}
                                            // onChange={() => setTerms(toggle)}
                                            // onChange={setTerms}
                                            // value={terms}
                                          
                                          />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <div className="row">
                                      <div className="col-md-10">
                                        <Label className="loginLabel">
                                          POPI{" "}
                                          <a  rel="noreferrer"  href={popiPDF} target="_blank" className="c1-primary-no-fill">
                                            Agreement
                                          </a>
                                        </Label>
                                      </div>
                                      <div className="col-md-2">                               
                                        <Input
                                            style={{width: "15px"}}
                                            name="terms"
                                            autoComplete="new-password"
                                            className="form-control fwv-input-control mt-1"
                                            placeholder="POPI"
                                            type="checkbox"
                                            checked={popi}
                                            onChange={() => popiToggle(popi)}
                                            // onChange={() => setTerms(toggle)}
                                            // onChange={setTerms}
                                            // value={terms}
                                          
                                          />
                                      </div>
                                    </div>
                                  </div>

                                  {/* <TextField
                                    placeholder="Enter Password"
                                    label="Password"
                                    type="password"
                                    className="form-control fwv-input-control"
                                    onChange={props.handleChange('password')}
                                    defaultValue={props.values.password}
                                    margin="normal"
                                    fullWidth
                                  /> */}
                                  
                                </Col>

                              {/* <Col className='col-2'>
                              </Col> */}
                            </Row>

                            </Col>
                            <Row className="mt-3 mb-2"> 

                            <Col md='6' sm='12' style={{marginTop: "10px"}}>
                                <Button
                                className="ml-2 btn c1-primary c1-primary-inline-btn btn-block"
                            
                                variant="contained"
                                // onClick={canContinue}
                                onClick={(e)=>goToPrevious(e.target.value)}
                                ><span><i className="bx bxs-left-arrow" style={{float: "left",
                                  marginTop: "5px"}}></i></span>Previous</Button>
                              </Col>
                              <Col md='6' sm='12' style={{textAlign: "right", marginTop: "10px"}}>
                                <Button
                      
                                variant="contained"
                                className="btn c1-primary c1-primary-inline-btn btn-block"
                                // onClick={canContinue}
                                onClick={(e)=>canContinue(e.target.value)}
                                >Continue<span><i className="bx bxs-right-arrow" style={{float: "right",
                                marginTop: "5px"}}></i></span></Button>
                              </Col>

                            </Row> 
                          </Row >               
    
                        </Form>
                      </div>
                            {/* Company */}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
    
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>

    );
  }

  PinfoStage1.propTypes = {
    prevStep: PropTypes.any,
    nextStep: PropTypes.any,
    handleChange: PropTypes.any,
    values: PropTypes.any,
  }
// }

export default PinfoStage1;