import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"

import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ReactApexChart from "react-apexcharts"

import MiniWidget from "../Dashboard-crypto/mini-widget"
import CurrencyWidget from "../Dashboard-crypto/currency-widget"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// icons
// import Icon from "react-crypto-icons";

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const walletOverview =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');

  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);

  const [cryptoOptions, setCryptoOptions] = useState([])
  const [currencyOptons, setCurrencyOptons] = useState([])
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])





    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])


const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
  
    const cryptoBalance = {
      userid: user.user.userid,
      crypto: 'true'
    }
  
      await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",cryptoBalance).then(async res =>{
        console.log('crypto options is ', res.data.currencyInfo)
        setCryptoOptions(res.data.currencyInfo)
      })
    
      const currencyBalance = {
        userid: user.user.userid,
        crypto: 'false'
      }
  
      await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",currencyBalance).then(async res =>{
  
        
        setCurrencyOptons(res.data.currencyInfo)
   
      })
  
      resetCurrencies()
      getuserBalance(user.user.userid)
    
  }

},[auth])

const getuserBalance = async userid => {

    var transactionObject = {
      'userid': userid,
    }
    
    if(!checkedTransactions)
    {
        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then( res2 =>{

          if(res2.data.status == 200)
          {
            setAllTransactions(res2.data.transactions)
          }
          setCheckedTransactions(true);
          
        })
    }


}

var reports = cryptoOptions

var currencies = currencyOptons

function resetCurrencies() {
  reports = cryptoOptions
  
  currencies = currencyOptons
}




const columns = [{
    dataField: 'idno',
    text: 'Id No'
  },{
    dataField: 'pdate',
    text: 'Date'
  }, {
    dataField: 'type',
    text: 'Type'
  },{
    dataField: 'status',
    text: 'Status'
  }, {
    dataField: 'coin',
    text: 'Currency'
  },{
    dataField: 'amount',
    text: 'Amount'
  }];

  // Table Data
const productData = [
//   { id: 1, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
//   { id: 2, idno:"#SK3336", pdate : "03 Mar, 2020",type:"Sell",coin:"Ethereum",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
//   { id: 3, idno:"#SK3226", pdate : "13 Jun, 2020",type:"Sell",coin:"Litecoin",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
//   { id: 4, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Ethereum",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
//   { id: 5, idno:"#SK3226", pdate : "23 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
//   { id: 6, idno:"#SK3116", pdate : "03 Mar, 2020",type:"Sell",coin:"Ethereum",amount:"0.00413 LTC",valueInUsd:"$ 1773.01"},
//   { id: 7, idno:"#SK3336", pdate : "13 Mar, 2020",type:"Buy",coin:"Ethereum",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
//   { id: 8, idno:"#SK3226", pdate : "03 Mar, 2020",type:"Buy",coin:"Litecoin",amount:"0.00413 ETH",valueInUsd:"$ 1773.01"},
 
  ];

var x = 0;
viewAllTransactions.forEach(transaction => {
   var numid = x.toString();

   var transtype = '---';
   if(transaction.tran_cat == '1')
   {
    transtype = 'Deposit'
   }else if(transaction.tran_cat == '2'){
    transtype = 'Withdraw'
   }else if(transaction.tran_cat == '4' || transaction.tran_cat == '6'){
    transtype = transaction.tran_type
   }else if(transaction.tran_cat == '8'){
    transtype = 'Exchange Added'
   }else{
    transtype = 'Purchased Product'
   }

   var transtatus = '---';
   if(transaction.status == '1')
   {
    transtatus = 'Approved'
   }else if(transaction.status == '2'){
    transtatus = 'Declined'
   }else{
    transtatus = 'Pending'
   }

   var currency = transaction.currency == '2' ?'ZAR':'USDT(TRC20)'
  //  if(transaction.currency == '2')
  //   currency

    var productDataObj = {
        id: numid,
        idno: transaction.trans_id,
        pdate: transaction.createdAt.toString(),
        type: transtype,
        status: transtatus,
        coin: currency,
        amount: transaction.amount
    };
    productData.push(productDataObj)
    x = x+1;
});


//   viewAllTransactions.map((viewAllTransaction, key) => (
//     var productDataObj = {};

//     <tr key={viewAllTransaction.id}>
//       <td>{viewAllTransaction.amount > 0? 
//        'USDT' + viewAllTransaction.amount: ''}</td>
//        <td>{viewAllTransaction.amount > 0? 
//        viewAllTransaction.createdAt: ''}</td>
//         <td>{viewAllTransaction.amount > 0? viewAllTransaction.status == '1' ? 
//        'Approved' : viewAllTransaction.status == '0' ? 'Pending' : 'Declined' : ''}</td>

//        <td>{viewAllTransaction.amount < 0? 
//        '-USDT' + viewAllTransaction.amount * -1: ''}</td>
//        <td>{viewAllTransaction.amount < 0? 
//        viewAllTransaction.createdAt: ''}</td>
//            <td>{viewAllTransaction.amount < 0? viewAllTransaction.status == '1' ? 
//        'Approved' : viewAllTransaction.status == '0' ? 'Pending' : 'Declined' : ''}</td>

//  </tr>
// ))

var check  = function() {
  return jQuery(window).width() <=800
}

if(check)
{
jQuery('.table').wrapAll('<div class="table-responsive"></div>');
}


    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - My Wallet</title>
        </MetaTags>



        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="My Wallet" />

          <Row>
            <Col xl="12" md="12" className="m-2">

              {/* Wallet Information */}
              <Row>
                <MiniWidget reports={reports} />
              {/* </Row>

              <Row> */}
                <CurrencyWidget currencies={currencies} />
              </Row>
           
            {/* <ProfileBalanceWidget userProfile={user?.user} /> */}
            </Col>

          </Row>

          <Row>
            <Col lg="12">
              <Row>
                  {/* <Col md="4" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              USD Wallet Value
                            </p>
                            <h4 className="mb-0">${userBalance}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col> */}
                  <Col sm="8">
                    <Card>
                        <CardBody className="border-top">
                            <div className="text-center">
                                <Row>
                                    <Col sm="6">
                                        <div className="h-100">
                                            <div className="font-size-24 text-primary mb-2">
                                            <i className="bx bx-send" />
                                            </div>
                                            <p className="text-muted mb-2">Vault</p>
                                            <div >
                                            <a href="buy-products" className="btn c1-primary c1-primary-inline-btn btn-block">
                                                Enter Vault
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mt-sm-0">
                                            <div className="font-size-24 text-primary mb-2">
                                            <i className="bx bx-import" />
                                            </div>
                                            <p className="text-muted mb-2">Deposit/Withdraw</p>
                                            <div className="mt-3">
                                            <a href="./my-usdt-wallet" className="btn c1-primary c1-primary-inline-btn btn-block">
                                                Deposit/Withdraw
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                  </Col>
              </Row>
            </Col>
          </Row>

                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Activities</h4>
                        <ul className="nav nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: activeTab === "1",
                            })}
                            onClick={() => {
                                toggleTab("1")
                            }}
                            >
                            All
                            </NavLink>
                        </NavItem>
                       
                        </ul>

                        <div className="mt-4">
                        <BootstrapTable keyField='id' data={ productData } columns={ columns } pagination={ paginationFactory() } />
                        </div>
                    </CardBody>
                    </Card>

         

        </Container>
      </div>
    </React.Fragment>
    )
}