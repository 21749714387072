import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Spinner
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { UserContext } from "../../../UserContext";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { CSVLink, CSVDownload } from "react-csv";
import Datatable from "components/Datatable/datatable.component"
import axios from "axios"
import {UserProfile} from "pages/Admin/UserProfilePage/UserProfile"

import Swal from "sweetalert2"

export const customReports = () => {
//   const [deposit, setAllDeposits] = useState([])
//   const{auth,user} = React.useContext(UserContext)
//   const columns = [
//     {
//       dataField: "userid",
//       text: "UserID",
//       sort: true,
//     },
//     {
//         dataField: "amount",
//         text: "Amount",
//         sort: true,
//     },
//     {
//         dataField: "remarks",
//         text: "Remarks",
//         sort: true,
//     },
//     {
//         dataField: "trans_id",
//         text: "TransactionID",
//         sort: true,
//     },
//     {
//         dataField: "txid",
//         text: "TxID",
//         sort: true,
//     },
//     {
//         dataField: "status",
//         text: "Status",
//         sort: true,
//     },
//     {
//         dataField: "currency",
//         text: "Currency",
//         sort: true,
//     },
//   ]
const{auth,user} = React.useContext(UserContext)
  React.useEffect(async res =>{

    if(!auth) {
        return Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }else{
        if(user !== 'undefined' && user !== null)
        {
            // We need to get all the months that proceeds have been given so far
            await getProceedsMonths()

            console.log(proceedsMonths)
        }
       }



},[auth])

//   const datatableInfo = {
//     title: "Deposits View",
//     shortDescription: "List of deposits",
//     longDescription: "This shows a list of all deposits",
//   }

    const [totalWallets, setTotalWallets] = React.useState(0);
    const [totalProducts, setTotalProducts] = React.useState({});
    const [totalCombined, setTotalCombined] = React.useState(0);
    const [todaySales, setTodaySales] = React.useState(0);
    const [weekSales, setWeekSales] = React.useState(0);
    const [monthSales, setMonthSales] = React.useState(0);
    const [quaterlySales, setQuaterlySales] = React.useState(0);



    
    const [proceedsMonths, setProceedsMonths] = useState([])
    const [clientSummaryProceedsMonths, setClientSummaryProceedsMonths] = useState('')
    const [clientSummaryProceeds, setClientSummaryProceeds] = useState(0)

    const [clientSummaryProceedsB2B, setclientSummaryProceedsB2B] = useState(0)
    const [clientSummaryProceedsTotal, setclientSummaryProceedsTotal] = useState(0)
    
    
    const [clientSummaryProceedsPercentage, setClientSummaryProceedsPercentage] = useState(0)
    const [b2bSummaryProceeds, setb2bSummaryProceeds] = useState(0)
    const [clientNetSummaryProceeds, setclientNetSummaryProceeds] = useState(0)
    const [clientNetSummaryProceedsB2B, setclientNetSummaryProceedsB2B] = useState(0)
    const [clientNetSummaryProceedsTotal, setclientNetSummaryProceedsTotal] = useState(0)
    const [c1SummaryProceeds, setc1SummaryProceeds] = useState(0)

    const [clientNetSummaryProceedsPercentage, setClientNetSummaryProceedsPercentage] = useState(0)
    const [clientNetSummaryProceedsPercentageB2B, setClientNetSummaryProceedsPercentageB2B] = useState(0)
    
    const [b2bSummaryProceedsPercentage, setb2bSummaryProceedsPercentage] = useState(0)
    const [c1SummaryProceedsPercentage, setc1SummaryProceedsPercentage] = useState(0)
    const [totalClientSummary, settotalClientSummary] = useState(0)
    
    
    const [showTotals, setShowTotals] = useState(false)
    

    // Spinners
    const [displayCSVSpinner, setDisplayCSVSpinner] = useState(false)
    const [displayFullProductspinner, setDisplayFullProductspinner] = useState(false)
    const [displayClientNetProductspinner, setDisplayClientNetProductspinner] = useState(false)
    const [displayB2Bspinner, setDisplayB2Bspinner] = useState(false)
    
    
    // CSV Reports
    const [combinedReportData, setCombinedReportData] = useState([])
    const [clientFullProductReportData, setClientFullProductReportData] = useState([])

    // CSV Triggers
    const [generateCombinedCSV, setGenerateCombinedCSV] = React.useState(false);
    const [clientFullProductCSV, setClientFullProductCSV] = React.useState(false);
    
    // setClientSummaryProceedsMonths


    const getTotalInWallet = async() => {

        await axios.get(process.env.REACT_APP_BASE + "api/amount-open-transactions").then((res)=>{
            console.log('all deposits ', res.data.total.usdt)
            setTotalWallets(res.data.total.usdt.toFixed(2))
        });
        
    }

    const getTotalOpenProducts = async() => {

        await axios.get(process.env.REACT_APP_BASE + "api/get-total-open-products").then((res)=>{
            console.log('all deposits ', res.data)
            setTotalProducts(res.data.total)
            // setTotalWallets(res.data.total.usdt.toFixed(2))
        });
        
    }

    
    const getTotalOpenCOmbined = async() => {

        var totalProducts = 0;
        var totalBalance = 0;

        await axios.get(process.env.REACT_APP_BASE + "api/get-total-open-products").then((res)=>{
            console.log('all deposits ', res.data)
            totalProducts = res.data.total.feebased + res.data.total.indexFund + res.data.total.savingsPocket
        });

        await axios.get(process.env.REACT_APP_BASE + "api/amount-open-transactions").then((res)=>{
            totalBalance = res.data.total.usdt;
        });

        setTotalCombined(totalProducts + totalBalance)
        
    }

    const getTodaySales = async() => {

        await axios.get(process.env.REACT_APP_BASE + "api/get-today-sales").then((res)=>{
            console.log('all deposits ', res.data)
            if(res.data.total.today && typeof res.data.total.today !== 'undefined')
                setTodaySales(res.data.total.today.toFixed(2))
        });
        
    }

    const getWeekSales = async() => {

        await axios.get(process.env.REACT_APP_BASE + "api/get-week-sales").then((res)=>{
            console.log('all deposits ', res.data)
            setWeekSales(res.data.total.week.toFixed(2))
        });
        
    }

    const getMonthlySales = async() => {

        await axios.get(process.env.REACT_APP_BASE + "api/get-month-sales").then((res)=>{
            console.log('all deposits ', res.data)
            setMonthSales(res.data.total.month)
        });

        
        
    }

    const getQuaterlySales = async() => {

        await axios.get(process.env.REACT_APP_BASE + "api/get-quaterly-sales").then((res)=>{     
            setQuaterlySales(res.data.total.quarter.toFixed(2))
        });
        
    }

    const getCombinedReport = async() => {
        setDisplayCSVSpinner(true)

        await axios.get(process.env.REACT_APP_BASE + "api/get-combined-total-report").then((res)=>{
            console.log(res.data)
            setCombinedReportData(res.data.data)
        });

        setGenerateCombinedCSV(true);
        setDisplayCSVSpinner(false)
    }

    const getProceedsMonths = async() => {

        await axios.get(process.env.REACT_APP_BASE + "api/get-proceed-months").then((res)=>{
            console.log(res.data)
            setProceedsMonths(res.data)
        });
    }

    
    const getClientSummaryProceeds = async() => {

        console.log("clientSummaryProceedsMonths ", clientSummaryProceedsMonths)
        var clientSummary = {
            dateid: clientSummaryProceedsMonths
        }
        await axios.post(process.env.REACT_APP_BASE + "api/get-client-summary-proceeds", clientSummary).then((res)=>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                setClientSummaryProceeds(res.data.amount)
                setClientSummaryProceedsPercentage(res.data.info.percentage)

                setclientSummaryProceedsB2B(res.data.amountB2B)
                setclientSummaryProceedsTotal(res.data.amount + res.data.amountB2B)
            

                getb2bSummaryProceeds();
                getC1SummaryProceeds();
                getClientNetProceeds();
           
                setShowTotals(true)

            }else{
                toastr.error(res.data.message) 
            }
            // setClientSummaryProceeds(1)
        });
    }

    const getShowTotals = async() => {
        console.log(clientSummaryProceeds, clientNetSummaryProceeds, b2bSummaryProceeds, c1SummaryProceeds);
        var total =  clientSummaryProceedsTotal - (clientNetSummaryProceedsTotal + b2bSummaryProceeds + c1SummaryProceeds)
        settotalClientSummary(total)
    }
    
    
    const getb2bSummaryProceeds = async() => {

        var total = 0;
        console.log("clientSummaryProceedsMonths ", clientSummaryProceedsMonths)
        var clientSummary = {
            dateid: clientSummaryProceedsMonths,
            b2b: 'C10000002'
        }
        await axios.post(process.env.REACT_APP_BASE + "api/get-b2b-summary-proceeds", clientSummary).then((res)=>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                setb2bSummaryProceeds(res.data.amount)
                total = res.data.amount
                setb2bSummaryProceedsPercentage(res.data.avg)
                // setClientSummaryProceedsPercentage(res.data.info.percentage)
            }else{
                toastr.error(res.data.message) 
            }
            // setClientSummaryProceeds(1)
        });

        return total;
    }

    const getC1SummaryProceeds = async() => {

        var total = 0;
        var clientSummary = {
            dateid: clientSummaryProceedsMonths,
            b2b: 'C10000001'
        }
        await axios.post(process.env.REACT_APP_BASE + "api/get-b2b-summary-proceeds", clientSummary).then((res)=>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                setc1SummaryProceeds(res.data.amount)
                total = res.data.amount;
                setc1SummaryProceedsPercentage(res.data.avg)
                // setClientSummaryProceedsPercentage(res.data.info.percentage)
            }else{
                toastr.error(res.data.message) 
            }
            // setClientSummaryProceeds(1)
        });

        return total
    }

    const getClientNetProceeds = async() => {
        var total = 0;
        console.log("clientSummaryProceedsMonths ", clientSummaryProceedsMonths)
        var clientSummary = {
            dateid: clientSummaryProceedsMonths
        }
        await axios.post(process.env.REACT_APP_BASE + "api/get-client-net-summary-proceeds", clientSummary).then((res)=>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                setclientNetSummaryProceeds(res.data.amount)
                setclientNetSummaryProceedsB2B(res.data.amountB2B)
                setclientNetSummaryProceedsTotal(res.data.amount + res.data.amountB2B)

                total = res.data.amount
                
                setClientNetSummaryProceedsPercentage(res.data.avg)
                // setClientNetSummaryProceedsPercentageB2B(res.data.avgB2B)
                // setClientSummaryProceedsPercentage(res.data.info.percentage)
            }else{
                toastr.error(res.data.message) 
            }
            // setClientSummaryProceeds(1)
        });

        return total;
    }

    
    const getClientFullProductReport = async() => {

        console.log("clientSummaryProceedsMonths ", clientSummaryProceedsMonths)
        var clientSummary = {
            dateid: clientSummaryProceedsMonths
        }
        await axios.post(process.env.REACT_APP_BASE + "api/get-client-net-summary-proceeds", clientSummary).then((res)=>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                setclientNetSummaryProceeds(res.data.amount)
                // setClientSummaryProceedsPercentage(res.data.info.percentage)
            }else{
                toastr.error(res.data.message) 
            }
            // setClientSummaryProceeds(1)
        });
    }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Blue Fern Digital- Custom Reports</title>
        </MetaTags>
        <Container fluid>
          <Row>
          <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                      <Col lg="12">
                        <h3>Custom Reports</h3>

                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                    <h5>Total In Wallets</h5>

                                    <div>
                                        <br />
                                        <h6>$ {totalWallets}</h6>
                                        <br />
                                        <button
                                            onClick={(e)=>getTotalInWallet(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <h5>Open Product Sales</h5>

                                        <div>
                                            <br />
                                            <h6>Fee Based Vault: $ {parseFloat(totalProducts.feebased).toFixed(2)}</h6>
                                            <h6>Savings Pocket: $ {parseFloat(totalProducts.indexFund).toFixed(2)}</h6>
                                            <h6>Index Vault: $ {parseFloat(totalProducts.savingsPocket).toFixed(2)}</h6>
                                            <br />
                                            <button
                                                onClick={(e)=>getTotalOpenProducts(e.target.value)}
                                                type="button"
                                                color="success"
                                                className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                            >
                                                Refresh
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="4">
                                <Card>
                                    <CardBody>
                                    <h5>Combined Balances</h5>

                                    <div>
                                        <br />
                                        <h6>$ {parseFloat(totalCombined).toFixed(2)}</h6>
                                        <br />
                                        <button
                                            onClick={(e)=>getTotalOpenCOmbined(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Today Sales</h5>

                                    <div>
                                        <br />
                                        <h6>$ {todaySales}</h6>
                                        <br />
                                        <button
                                            onClick={(e)=>getTodaySales(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Week Sales</h5>

                                    <div>
                                        <br />
                                        <h6>$ {weekSales}</h6>
                                        <br />
                                        <button
                                            onClick={(e)=>getWeekSales(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Monthly Sales</h5>

                                    <div>
                                        <br />
                                        <h6>$ {monthSales}</h6>
                                        <br />
                                        <button
                                            onClick={(e)=>getMonthlySales(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Quarterly Sales</h5>

                                    <div>
                                        <br />
                                        <h6>$ {quaterlySales}</h6>
                                        <br />
                                        <button
                                            onClick={(e)=>getQuaterlySales(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Client Summary Proceeds Report</h5>
                                    <div>
                                        <select className="form-control" name="client_summary" onChange={(e) => setClientSummaryProceedsMonths(e.target.value)}>
                                        {/* {typeof proceedsMonths !== "undefined" && proceedsMonths.length > 0 ?
                                        Object.keys(proceedsMonths).map(function(key) {
                                            return <option key={key} value={key}>{proceedsMonths[key]}</option>
                                        })
                                        :
                                            <option value="1">1</option>
                            
                                        } */}
                                        <option value="">Select Date</option>
                                        {typeof proceedsMonths !== "undefined" && proceedsMonths.length > 0 ?
                                        proceedsMonths.map(function(month) {
                                            return <option key={month.id} value={month.id}>{month.payment_date}</option>
                                        })
                                        :
                                            ''
                            
                                        }
                                        </select>
                                        <br />
                                        <h6>C1: ${clientSummaryProceeds.toFixed(2)}</h6>
                                        <h6>B2B: ${clientSummaryProceedsB2B.toFixed(2)}</h6>
                                        <h6>Total: ${clientSummaryProceedsTotal.toFixed(2)}</h6>
                                        <h6>{clientSummaryProceedsPercentage}%</h6>
                                        <br />

                                        <h6>Total: {totalClientSummary}</h6>
                                        
                                        <br />
                                        <button
                                            onClick={(e)=>getClientSummaryProceeds(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>

                                        <br />
                                        {showTotals?
                                        <button
                                            onClick={(e)=>getShowTotals(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Calculate Totals
                                        </button>
                                        : ''}
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Client Net Proceeds Report</h5>
                                    <div>
                                        <select className="form-control" name="client_summary" onChange={(e) => setClientSummaryProceedsMonths(e.target.value)}>

                                        <option value="">Select Date</option>
                                        {typeof proceedsMonths !== "undefined" && proceedsMonths.length > 0 ?
                                        proceedsMonths.map(function(month) {
                                            return <option key={month.id} value={month.id}>{month.payment_date}</option>
                                        })
                                        :
                                            ''
                            
                                        }
                                        </select>
                                        <br />
                                        <label>C1</label>
                                        <h6>${clientNetSummaryProceeds.toFixed(2)}</h6>
                                       
                                        <br />
                                        <label>B2B</label>
                                        <h6>${clientNetSummaryProceedsB2B.toFixed(2)}</h6>
                                        {/* <h6>{clientNetSummaryProceedsPercentageB2B.toFixed(2)}%</h6> */}
                                        < br />
                                        <h6>{clientNetSummaryProceedsPercentage.toFixed(2)}%</h6>
                                        
                                        <br />
                                        <button
                                            onClick={(e)=>getClientNetProceeds(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>B2B Summary Proceeds Report</h5>
                                    <div>
                                        <select className="form-control" name="client_summary" onChange={(e) => setClientSummaryProceedsMonths(e.target.value)}>
                                        {/* {typeof proceedsMonths !== "undefined" && proceedsMonths.length > 0 ?
                                        Object.keys(proceedsMonths).map(function(key) {
                                            return <option key={key} value={key}>{proceedsMonths[key]}</option>
                                        })
                                        :
                                            <option value="1">1</option>
                            
                                        } */}
                                        <option value="">Select Date</option>
                                        {typeof proceedsMonths !== "undefined" && proceedsMonths.length > 0 ?
                                        proceedsMonths.map(function(month) {
                                            return <option key={month.id} value={month.id}>{month.payment_date}</option>
                                        })
                                        :
                                            ''
                            
                                        }
                                        </select>
                                        <br />
                                        <h6>${b2bSummaryProceeds.toFixed(2)}</h6>
                                        <h6>{b2bSummaryProceedsPercentage.toFixed(2)}%</h6>
                                        
                                        <br />
                                        <button
                                            onClick={(e)=>getb2bSummaryProceeds(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>C1 Summary Proceeds Report</h5>
                                    <div>
                                        <select className="form-control" name="client_summary" onChange={(e) => setClientSummaryProceedsMonths(e.target.value)}>
                                        <option value="">Select Date</option>
                                        {typeof proceedsMonths !== "undefined" && proceedsMonths.length > 0 ?
                                        proceedsMonths.map(function(month) {
                                            return <option key={month.id} value={month.id}>{month.payment_date}</option>
                                        })
                                        :
                                            ''
                            
                                        }
                                        </select>
                                        <br />
                                        <h6>${c1SummaryProceeds.toFixed(2)}</h6>
                                        <h6>{c1SummaryProceedsPercentage.toFixed(2)}%</h6>
                                        
                                        <br />
                                        <button
                                            onClick={(e)=>getC1SummaryProceeds(e.target.value)}
                                            type="button"
                                            color="success"
                                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                        <Row>
                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Combined CSV</h5>
                                    {displayCSVSpinner?
                                         <Spinner className="ms-2" color="success" />
                                         :
                                        <div>
                                            <br />
                                            { !generateCombinedCSV ?
                                                <button
                                                    onClick={(e)=>getCombinedReport(e.target.value)}
                                                    type="button"
                                                    color="success"
                                                    className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                                >
                                                    Generate Report
                                                </button>
                                            :
                                                <CSVLink data={combinedReportData} filename={"combined-data.csv"} className="w-md btn c1-primary c1-primary-inline-btn btn-block" target="_blank">Download CSV</CSVLink>
                                            }
                                        </div>
                                    }
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card>
                                    <CardBody>
                                    <h5>Full Client Product Report</h5>
                                    {displayFullProductspinner?
                                         <Spinner className="ms-2" color="success" />
                                         :
                                        <div>
                                            <br />
                                            { !generateCombinedCSV ?
                                             <div>
                                                <select className="form-control" name="client_summary" onChange={(e) => setClientSummaryProceedsMonths(e.target.value)}>
                                                <option value="">Select Date</option>
                                                {typeof proceedsMonths !== "undefined" && proceedsMonths.length > 0 ?
                                                proceedsMonths.map(function(month) {
                                                    return <option key={month.id} value={month.id}>{month.payment_date}</option>
                                                })
                                                :
                                                    ''
                                    
                                                }
                                                </select>
                                                <br />
                                                               
                                                <button
                                                    onClick={(e)=>getClientFullProductReport(e.target.value)}
                                                    type="button"
                                                    color="success"
                                                    className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                                                >
                                                    Generate Report
                                                </button>
                                            </div>
                                            :
                                                <CSVLink data={combinedReportData} filename={"combined-data.csv"} className="w-md btn c1-primary c1-primary-inline-btn btn-block" target="_blank">Download CSV</CSVLink>
                                            }
                                        </div>
                                    }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                      </Col>
                     
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={deposit}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                      <CSVLink data={deposit} filename={"all-deposits.csv"} className="btn btn-primary" target="_blank">Download me</CSVLink>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
// ProductSales.propTypes = {
//     viewAllProductSales: PropTypes.array,
// }

export default customReports