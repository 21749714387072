import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import PropTypes from "prop-types"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"
import axios from "axios"

import { UserContext } from "../../../../UserContext";

import Swal from 'sweetalert2'


const DirectP = ({ userDetails }) => {
  let { id } = useParams()
  const [referrals, setReferrals] = useState([])
  const [referralSetting, setReferralSetting] = useState(false)
  const [userUserid, setuserUserid] = React.useState('');
  const [newReferralSettings, setNewReferralSettings] = React.useState({ level: 0, percentage: '0'});
  const [user, setUser] = React.useState('');
  const{auth} = React.useContext(UserContext)




  React.useEffect(async res =>{

    axios
    .post(process.env.REACT_APP_API_BASE_URL + `/find-one-user-by-id`, {
      id: id,
    })
    .then(res => {
      console.log('the id we found is 1 ', id)
      console.log('user data received ', res.data)
      setUser(res.data.user)
      setuserUserid(res.data.user)

      if(!referralSetting && userUserid)
      {
          setReferralSetting(true)
          getReferrals();
      }
    })

    // if(user !== 'undefined' && user !== null)
    // {
    //   setuserUserid(user); 
    //   console.log("we have set the user ", user)
    // }
  
  },[auth])



  const columns = [
    {
      dataField: "userinfo",
      text: "User",
      sort: true,
    },
    {
      dataField: "from_userid",
      text: "Userid",
      sort: true,
    },
    {
      dataField: "percentage",
      text: "Percentage",
      sort: true,
    },
    {
        dataField: "amount",
        text: "Amount",
        sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },

  ]

 

//   useEffect(async res => {
//     var userObj = 
//     {
//         userid:userUserid,
//         level: 1
//     }
//     await axios.post(process.env.REACT_APP_API_BASE_URL+`/show-myreferrals`, userObj).then(res => {
//       console.log("all referrals ", res.data)
//       setReferrals(res.data.referrals)
//     })
//   }, [])

  const getReferrals = async () => {

    console.log('user we need to get is ', userUserid)
    var userObj = 
    {
        userid: userUserid,
        level: 1
    }
    await axios.post(process.env.REACT_APP_API_BASE_URL+`/show-myreferral-payments`, userObj).then(res => {
        console.log('getting ', res.data.users)
        if(res.data.status == 200)
            setReferrals(res.data.users)
      })
  }

  const datatableInfo = {
    title: "My Referral Payments",
    shortDescription: "Direct Referral Payments",
    longDescription: "This table displays all of your direct referral payments.",
  }



  return (
    <React.Fragment>

        <Datatable
          columns={columns}
          productData={referrals}
          datatableInfo={datatableInfo}
          options={{
            selectableRows: false // <===== will turn off checkboxes in rows
          }}
        />

    </React.Fragment>
  )

}

DirectP.propTypes = {
    userDetails: PropTypes.any,
}

export default DirectP
