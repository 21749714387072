import React, { Component,useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags";
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import { Link } from "react-router-dom";

import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroup

} from "reactstrap";

// import images
import profileImg from "../../assets/images/c1_favicon.png";
import logo from "assets/images/c1logo.png";
import logoImg from "../../assets/images/c1_favicon.png";

import termsPDF from "./Terms.pdf";
import classnames from "classnames"

import loginlogo from "assets/images/backgroundomc.jpeg";

// export class Confirmation extends Component {
  const Confirmation = props => {

    const [isMenu, setisMenu] = useState(false)
    const [activeTab, setActiveTab] = useState("1")

    function resendEmail(email)
    {
      var userObject = {
        email: props.values.email
      }
      axios.post(process.env.REACT_APP_BASE + "api/send-alternative-email",userObject).then(async res =>{

        if(res.data.status == 200)
        {
          toastr.success("We have successfully sent another confirmation email!"); 
  
        }else{
          toastr.error(res.data.message); 
        }
     
      });
    }



  // render() {
    // const { props.values } = props;

    return (



        <React.Fragment>
          <MetaTags>
            <title>C1 HOLDINGS (PTY) LTD - Register</title>
          </MetaTags>
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="fas fa-home h2" />
            </Link>
          </div>
          <div className="account-pages" >
          <Container className="pt-sm-5 mw-100 fvw-login-container" 
          // style={{
          //     backgroundImage: `url(${loginlogo})`,
          //     boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 20%)"
          //   }}
            >
            <Row className="justify-content-center select-user-content" style={{
            position: "absolute",
            left: "40%",
            top: "34%",
            transform: "translate(-50%, -50%)",
            }}>
    
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden fwv-card-usertype" >
                    <CardBody className="pt-0">
                      <div>
                      <Link to="/login" className="auth-logo-light">
                          <div className="w-100 profile-user-wid mb-4">
                            <span className="avatar-title ">
                              <img
                                src={logo}
                                alt=""
                                className="mt-2 fvw-login-logo"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
    
    
                      <div className="mt-4 mb-3">

                      {/* user */}
                      <TabContent activeTab={activeTab} className="mt-4">
                        <TabPane tabId="1" id="buy-tab">
                          <div>
                          <div className="p-2">
                        <Form
                          className="form-horizontal fvw-login-form"

                        >
                      
                          <Row>
                            <Col className='col-12'>

                            <Row>
                                <Col className='col-12'>

                                  <Card className="cardType-fill">
                                    <CardBody className="text-center">
                                      
                                        <i
                                          className="bx bx-envelope text-center bx-lg mt-1 font-size-48"
                                          id="edittooltip"
                                          
                                        ></i>
                                        < br />
                                        <h5 className="text-center mt-2">Thank you for submitting the info form, one of our team members
                                        will reach out to your shortly.</h5>

                                    </CardBody>
                                  </Card>

                                </Col>
                            </Row>

                            </Col>
                            {/* <Row> 
                              <Col className='col-12 text-center'>
                                <Link to="/login" className="btn c1-primary c1-primary-inline-btn btn-block">Login</Link>
                              </Col>
                            
                            </Row>  */}

                          </Row>               
    
    
                          <div className="mt-4 text-center">

                            <p className="mb-0 text-muted">
                              C1 HOLDINGS (PTY) LTD{" "}
                              <a  rel="noreferrer"  href={termsPDF} target="_blank" className="c1-primary-no-fill"> Terms
                              </a>
                            </p>
                          </div>
                        </Form>
                      </div>
                            {/* Company */}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
    
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>

    );
  }

  Confirmation.propTypes = {
    values: PropTypes.any,
  }
// }

export default Confirmation;