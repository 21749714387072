import PropTypes from 'prop-types'
import React, {useState} from "react"


import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    Button,
    NavItem,
    NavLink,
    InputGroup,
    Modal,
    ModalBody

  } from "reactstrap";

  import { UserContext } from "../../UserContext";
  
  import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"


const OTPModal = ({ closeClick, otpOption, handleOtp}) => {

    const{auth,user} = React.useContext(UserContext)

    // const [riskQuestionNumber, setRiskQuestionNumber] = React.useState(1);
    const [riskQuestion, setRiskQuestion] = React.useState('');
    const [displayQuestion, setDisplayQuestion] = React.useState(false);
    const [otpNumber, setOTP] = React.useState('');
    const [dob, setDOB] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [sourceFunds, setSourceFunds] = React.useState('');
    
    
    const [useridUpload, setuseridUpload] = useState([])
    const [userpoaupload, setuserpoaupload] = useState([])
    const [selectedFiles, setselectedFiles] = useState([])


    React.useEffect(async res =>{

        

        // if(user !== 'undefined' && user !== null)
        // {
            // if(!displayQuestion)
            // {
                // getQuestions()
                // setDisplayQuestion(true)
            // }
 
        // }
      
      })

      const onOptionClick = (newmodalView) => {

        if(otpNumber != '') {
        
        otpOption['otp'] = otpNumber;

        handleOtp()
        }else{
            toastr.error("Please fill in all required information") 
        }
      }

  return (
    <div>
   
    <Modal isOpen={true}  centered={true}>
      <ModalBody className="py-3 px-5">

        <div>

            <div className="p-1">
                <Col className='col-12'>
                    <h2 className="text-center">OTP</h2>
                </Col>

                <Form className="form-horizontal fvw-login-form">

                    <Row>
                        <div>
                            <Row>
                            <Col xl="12" className='col-12'>
                                <Row>
                                 
                                    <Col md="12"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                        <label>Enter OTP:</label>
                                        <Input
                                            name="id"
                                            className="form-control"
                                            placeholder="OTP"
                                            type="text"
                                            onChange={(e)=>setOTP(e.target.value)}
                                            value={otpNumber}
                                        />

                                    </Col>

                                </Row>
                            </Col>

                            <Row>
                        
                                <Col md="1"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>
                                <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                    <a href="#" onClick={closeClick} className="btn c1-primary btn-block">Close</a>
                                </Col>
                                <Col md="2"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>
                                <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                    <a href="#" onClick={()=>onOptionClick()} className="btn c1-primary btn-block">Submit</a>
                                          
                                </Col>
                                <Col md="1"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>

                            </Row>

                            </Row>
                        </div>
                    </Row>

                    <Row>
                    <Col className='col-12'>

                    <Row className="mt-3 mb-2"> 

                        {/* <Col className='col-6'>
                            <Button
                            className="ml-2 btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"

                            variant="contained"
                            // onClick={canContinue}
                            // onClick={(e)=>goToPrevious(e.target.value)}
                            onClick={onPrevClick}
                            ><span><i className="bx bxs-left-arrow" style={{float: "left",
                            marginTop: "5px"}}></i></span>Back</Button>
                        </Col> */}
                        {/* <Col className='col-6 text-right' style={{textAlign: "right"}}>
                        {question == 7 ?
                            <Button

                            variant="contained"
                            className="btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"
                            // onClick={canContinue}
                            // onClick={(e)=>canContinue(e.target.value)}
                            onClick={onNextClick}
                            >Continue<span><i className="bx bxs-right-arrow" style={{float: "right",
                            marginTop: "5px"}}></i></span></Button>
                            : 
                            <Button

                            variant="contained"
                            className="btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"
                            // onClick={canContinue}
                            // onClick={(e)=>canContinue(e.target.value)}
                            onClick={onNextClick}
                            >Next<span><i className="bx bxs-right-arrow" style={{float: "right",
                            marginTop: "5px"}}></i></span></Button>
                            }
                        </Col> */}

                    </Row> 

                    </Col>
                    </Row>               

                </Form>
            </div>
        </div>
      </ModalBody>
    </Modal>

    </div>
  )
}

OTPModal.propTypes = {
    closeClick: PropTypes.func,
    otpOption: PropTypes.any,
    handleOtp: PropTypes.func,
}

export default OTPModal