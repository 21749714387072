import PropTypes from 'prop-types'
import React from "react"


import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    Button,
    NavItem,
    NavLink,
    InputGroup,
    Modal,
    ModalBody
  
  } from "reactstrap";


const ReferralSettingsModal = ({ show, onCloseClick, referralSettings, saveSetting }) => {

    // const [riskQuestionNumber, setRiskQuestionNumber] = React.useState(1);
    const [riskQuestion, setRiskQuestion] = React.useState('');
    const [displayQuestion, setDisplayQuestion] = React.useState(false);
    const [questionText, setQuestionText] = React.useState('');

    // Modals
    const [screen, setScreen] = React.useState(1);
    const [walletOption, setWalletOption] = React.useState(1);


    React.useEffect(async res =>{

        

        // if(user !== 'undefined' && user !== null)
        // {
            // if(!displayQuestion)
            // {
                // getModalView()
                // getQuestions()
                // setDisplayQuestion(true)
            // }
 
        // }
      
      })

      const onUSSD = (thisQScore) => {

        console.log("the question text is ", questionText)
        qScore[questionText] = thisQScore
        console.log("this score is ", qScore)
        // qScore = thisQScore
        score += thisQScore

        console.log("overall score is ", score)
        onNextClick()
        
      }

      const HandleNextModal = () => {
        onNextClick()
      }

      const levelChange = (level) => {
          console.log("referralSetting is ", referralSettings)
          referralSettings['level'] = level
      }

      const percentageChange = (percentage) => {
        referralSettings['percentage'] = percentage
      }


  return (
    <Modal isOpen={show}  centered={true}>
      <ModalBody className="py-3 px-5">

        <div>

            <Row className="mb-2 text-center">
                <Col className="col-12">
                    <h3 className="">Create Referral Setting</h3>
                </Col>
            </Row>

            <div className="p-1">
                <Form className="form-horizontal fvw-login-form">

                    <Row>
                        <Col className='col-12'>
                        
                            <Row>
 
                                <Col md="12"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                    <Label className="loginLabel ">Level</Label>
                                    <Input
                                        name="levelSetting"
                                        autoComplete="new-password"
                                        className="form-control fwv-input-control "
                                        placeholder="1"
                                        type="number"
                                        min="0"
                                        max="10"
                                        step="1"
                                        onChange={(e)=>levelChange(e.target.value)}
                                    />
                                </Col>

                                <Col md="12"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                    <Label className="loginLabel ">Percentage</Label>
                                    <Input
                                        name="percentageSetting"
                                        autoComplete="new-password"
                                        className="form-control fwv-input-control "
                                        placeholder='10'
                                        type="number"
                                        min="0"
                                        max="100"
                                        step="1"
                                        onChange={(e)=>percentageChange(e.target.value)}
                                    />
                                </Col>
            
                            </Row>

                            <Row className="mt-3 mb-2"> 
                                <Col className='col-6'> 
                                    <Button
                                        className="ml-2 btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"
                                        variant="contained"
                                        onClick={onCloseClick}
                                        ><span><i className="bx bxs-left-arrow" style={{float: "left",
                                        marginTop: "5px"}}></i></span>Close
                                    </Button>
                                </Col>
                                <Col className='col-6'> 
                                    <Button
                                        variant="contained"
                                        className="btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"
                                        onClick={saveSetting}
                                        >Save<span><i className="bx bxs-right-arrow" style={{float: "right",
                                        marginTop: "5px"}}></i></span>
                                    </Button>
                                </Col>
                            </Row> 

                        </Col>
                    </Row>               

                </Form>
            </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

ReferralSettingsModal.propTypes = {
    show: PropTypes.any,
    onCloseClick: PropTypes.func,
    referralSettings: PropTypes.any,
    saveSetting: PropTypes.func
}

export default ReferralSettingsModal