import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Row,
  NavItem,
  NavLink,
  CardTitle,
} from "reactstrap"
import classnames from "classnames"
import "./ProductTableCard.styles.scss"
import axios from "axios"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

const ProductTableCard = viewAllProductSales => {
  // Table Data
  const productData = []
  var x = 0

  viewAllProductSales.viewAllProductSales.forEach(productsale => {
    var numid = x.toString()
    //=================  Donut chart ===========================================================
    var transtatus = "---"
    if (productsale.status == "1") {
      transtatus = "Open"
    } else if (productsale.status == "2") {
      transtatus = "Closed"
    } else {
      transtatus = "Closed"
    }

    var productDataObj = {
      id: numid,
      plan: productsale.planid,
      invest_d: productsale.invest_date,
      start_d: productsale.start_date,
      expire_d: productsale.expire_date,
      first_q: "$0",
      second_q: "$0",
      t_value: "$" + productsale.amount,
      status: transtatus,
    }
    productData.push(productDataObj)
    x = x + 1
  })

  const columns = [
    {
      dataField: "plan",
      text: "Plan ID",
    },
    {
      dataField: "invest_d",
      text: "Contribution Date",
    },
    {
      dataField: "start_d",
      text: "Start Date",
    },
    {
      dataField: "expire_d",
      text: "Expire Date",
    },
    {
      dataField: "first_q",
      text: "1-3 Month",
    },
    {
      dataField: "t_value",
      text: "Total Value",
    },
    {
      dataField: "status",
      text: "Status",
    },
  ]

  const [isMenu, setisMenu] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Activities</h4>
              <ul className="nav nav-tabs nav-tabs-custom">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
              </ul>
              <Row>
                <Col className="col-12">
                  <Card>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            data={productData}
                            columns={columns}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        {/* <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div> */}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

ProductTableCard.propTypes = {
  viewAllProductSales: PropTypes.array,
}

export default ProductTableCard
