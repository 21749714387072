import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"
import axios from "axios"

import { UserContext } from "../../../../UserContext";

import Swal from 'sweetalert2'

// Referral levels
import DirectP from "./directp"
import IndirectP from "./indirectp"


export const AdminUserReferralPayments = () => {
  let { id } = useParams()
  const [referral, setReferrals] = useState([])
  const [referralSettingModal, setReferralSettingModal] = useState(false)
  const [userDetails, setuserUserid] = React.useState('');
  const [referralLevelState, setReferralLevelState] = React.useState(1);
  const [user, setUser] = React.useState('');
  const{auth} = React.useContext(UserContext)


  React.useEffect(()=>{
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    if(auth!=null)
    {
     if(!auth) {
      Swal.fire({
        title: 'Error!',
        text: 'Your session has expired, click below to start a new session ',
        icon: 'error',
        confirmButtonText: 'Login',
        confirmButtonColor: '#29a927',
        allowOutsideClick:false
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.href = "/login" 
        }
      })
     
     }
    }
   },[auth])

   React.useEffect(async res =>{

    axios
    .post(process.env.REACT_APP_API_BASE_URL + `/find-one-user-by-id`, {
      id: id,
    })
    .then(res => {
      console.log('the id we found is 1 ', id)
      console.log('user data received ', res.data)
      setUser(res.data.user)
      setuserUserid(res.data.user)
    })

    // if(user !== 'undefined' && user !== null)
    // {
    //   setuserUserid(user); 
    //   console.log("we have set the user ", user)
    // }
  
  },[auth])

  //  React.useEffect(async res =>{

  //   if(user !== 'undefined' && user !== null)
  //   {
  //     setuserUserid(user); 
  //     console.log("we have set the user ", user)
  //   }
  
  // },[auth])



// proceed to the next level
const nextLevelView = (level) => {
  setReferralLevelState(level)
}


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 Holdings - Referral Payments</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                    <a href={"/user-profile/" + id} className="btn badge bg-dark badge-pill mb-1 font-size-16" style={{margin: "0 5px"}}>Back To User</a>
                      <Col lg="12">

                      <Button
                      className="btn c1-primary c1-primary-inline-btn btn-block"
                      // onClick={canContinue}
                      onClick={()=>nextLevelView(1)}
                      >Direct</Button>

                      <Button
                      className="btn c1-primary c1-primary-inline-btn btn-block"
                      // onClick={canContinue}
                      onClick={()=>nextLevelView(2)}
                      >Indirect</Button>


                        {referralLevelState == 1 ?
                          <DirectP 
                            userDetails={ userDetails }
                          />
                          : referralLevelState == 2 ?
                          <IndirectP 
                          userDetails={ userDetails }
                        /> :
                        ''
                        }

                        

                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

}
