import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import profile1 from "assets/images/c1_favicon.png"

const ProfileWidget = ({ userProfile }) => {
  return (
    <div>
      <Col xl="12">
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary"> {userProfile.name}</h5>
                  <p>It will seem like simplified</p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profile1} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm={12}>
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      <p className="text-muted mb-0">Email</p>
                      <h5 className="font-size-15">{userProfile.email}</h5>
                    </Col>
                    <Col xs="6">
                      <p className="text-muted mb-0">Status</p>
                      <h5 className="font-size-15">{userProfile.u_status}</h5>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Link to="" className="btn btn-primary  btn-sm">
                      View Profile <i className="mdi mdi-arrow-right ms-1" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

ProfileWidget.propTypes = {
  userProfile: PropTypes.object,
}

export default ProfileWidget
