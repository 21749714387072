import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Spinner,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"
import { CSVLink, CSVDownload } from "react-csv";

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { UserContext } from "../../../UserContext";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"
import axios from "axios"
import {UserProfile} from "pages/Admin/UserProfilePage/UserProfile"

export const B2BProductInterest = () => {
  const [interest, setAllInterest] = useState([])
  const{auth,user} = React.useContext(UserContext)
  const [displaySpinner, setDisplaySpinner] = useState(false)

  const columns = [
    {
        dataField: "productid",
        text: "ProductID",
        sort: true,
      },
      {
        dataField: "percentage",
        text: "Percentage",
        sort: true,
      },
    {
      dataField: "userid",
      text: "UserID",
      sort: true,
    },
    {
        dataField: "planid",
        text: "PlanID",
        sort: true,
      },
      {
        dataField: "amount",
        text: "Amount",
        sort: true,
      },
      {
        dataField: "date",
        text: "Date",
        sort: true,
      },
  ]

  React.useEffect(async res =>{

    setDisplaySpinner(true);
    if(user !== 'undefined' && user !== null)
    {
        await axios.get(process.env.REACT_APP_BASE + "api/b2b-find-all-productinterest").then((res)=>{
            console.log('all interest ', res.data)
            console.log('all interest array ', res.data.interest)
            setAllInterest(res.data.interest)
            setDisplaySpinner(false);
        });
    }

},[auth])

  const datatableInfo = {
    title: "Interest View",
    shortDescription: "List of Interests",
    longDescription: "This shows a list of all product interests",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Interest</title>
        </MetaTags>
        <Container fluid>
          <Row>
          <CSVLink data={interest}>Download me</CSVLink>
            <Col xl="12">
              <Card>
                <CardBody>
                  
                  <div className="mt-4 mb-3">
                    <Row>
                    {displaySpinner ? 
                          <Spinner className="ms-2" color="success" />
                          :
                        <div>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={interest}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                     
                      <CSVLink data={interest} filename={"product-interests.csv"} className="btn btn-primary" target="_blank">Download me</CSVLink>
                      </div>}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
// ProductSales.propTypes = {
//     viewAllProductSales: PropTypes.array,
// }

export default B2BProductInterest