import PropTypes from 'prop-types'
import React from "react"


import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    Button,
    NavItem,
    NavLink,
    InputGroup,
    Modal,
    ModalBody
  
  } from "reactstrap";

  import { UserContext } from "../../UserContext";

const ThankyouModal = ({ prevStepFica, modalView }) => {

    const{auth,user} = React.useContext(UserContext)

    // const [riskQuestionNumber, setRiskQuestionNumber] = React.useState(1);
    const [riskQuestion, setRiskQuestion] = React.useState('');
    const [displayQuestion, setDisplayQuestion] = React.useState(false);
    const [optionSelected, setOptionSelected] = React.useState('');


    React.useEffect(async res =>{

        

        // if(user !== 'undefined' && user !== null)
        // {
            // if(!displayQuestion)
            // {
                // getQuestions()
                // setDisplayQuestion(true)
            // }
 
        // }
      
      })

      const onOptionClick = (newmodalView) => {

        ContinueNextStep()
      }


  return (
    <div>
   
    <Modal isOpen={modalView}  centered={true}>
      <ModalBody className="py-3 px-5">

        <div>

            <div className="p-1">
                <Col className='col-12'>
                    <h2 className="text-center">Thank you</h2>
                </Col>

                <Form className="form-horizontal fvw-login-form">

                    <Row>
                        <div>
                        <Row>
                        <Col xl="12" className='col-12'>
                     

                                <Col md="12"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                    <p>Thank you for submitting your KYC Information. Our support team will review this information and 
                                        you will be notified via Email once completed. Thank you for your patience.
                                    </p>
                                </Col>

                                <Row>
                        
                                    <Col md="1"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>
                                    <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}> </Col>
                                    <Col md="2"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>
                                    <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                        <a href="#" onClick={()=>prevStepFica()} className="btn c1-primary btn-block">Back To My Wallet</a>
                                            
                                    </Col>
                                    <Col md="1"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>

                                </Row>
        
                        </Col>
                        </Row>
                        </div>
                    </Row>             

                </Form>
            </div>
        </div>
      </ModalBody>
    </Modal>

    </div>
  )
}

ThankyouModal.propTypes = {
    prevStepFica: PropTypes.func,
    modalView:PropTypes.any,

}

export default ThankyouModal