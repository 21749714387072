import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"
import  { Redirect } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  FormGroup,
  Button,
  Label,
  Input,
  Spinner
} from "reactstrap"
import Select from "react-select"
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'



//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { UserContext } from "../../UserContext";

//Import Images
import axios from "axios"


import makeAnimated from 'react-select/animated'

import Datatable from "components/Datatable/datatable.component"

export const thirdPartySettings = () => {

  const{auth,user} = React.useContext(UserContext)

  const [detailsDisplay, setDetailsDisplay] = React.useState(false);
  const [allParties, setAllParties] = React.useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [partyAdmins, setPartyAdmins] = React.useState([]);
  const [allOptions, setAllOptions] = React.useState([]);
  const [allOptionsNotSelected, setAllOptionsNotSelected] = React.useState([]);
  const [newSelectedOptions, setNewSelectedOptions] = React.useState([]);
  const [newThirdPartyValue, setNewThirdPartyValue] = React.useState(0);
  const [selectedReferral, setSelectedReferral] = React.useState("false");
  

  React.useEffect(()=>{
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    if(auth!=null)
    {
     if(!auth) {
      Swal.fire({
        title: 'Error!',
        text: 'Your session has expired, click below to start a new session ',
        icon: 'error',
        confirmButtonText: 'Login',
        confirmButtonColor: '#29a927',
        allowOutsideClick:false
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.href = "/login" 
        }
      })
     }
    }
   },[auth])

   React.useEffect(async res =>{

    if(user !== 'undefined' && user !== null)
    {
      await axios.get(process.env.REACT_APP_BASE + "api/get-all-thirdparties").then(async res =>{
    
        if(res.data.status == 200)
          console.log(res.data.body)
          setAllParties(res.data.body)
 
      })
    }

  },[auth])
  const optionGroup = [
    { label: "A", value: "A" },
    { label: "P", value: "P" },
  ]
  const ficaStatus = [
    { label: "N", value: "N" },
    { label: "P", value: "P" },
    { label: "A", value: "A" },
  ]
  const ficaOption = [
    { label: "N", value: "N" },
    { label: "B", value: "B" },
    { label: "F", value: "F" },
  ]
  const paymentOption = [
    { label: "N", value: "N" },
    { label: "P", value: "P" },
    { label: "A", value: "A" },
  ]
  const vipOption = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]

  const [userProfile, setUserProfile] = useState({
    img: "",
    name: "",
    designation: "",
    phone: "",
    email: "",
    location: "",
  })

  const handleThirdPartyChange = async (thirdPartyValue) => {
    setShowSpinner(true)
    setDetailsDisplay(true)
    if(thirdPartyValue === "")
    {
      setDetailsDisplay(false)
      setShowSpinner(false)
      return false;
    }


    setNewThirdPartyValue(thirdPartyValue)

    var companyObjReferral = {
      id: thirdPartyValue,
      meta_key: "referrals"
    }
    await axios.post(process.env.REACT_APP_BASE + "api/get-company-meta", companyObjReferral).then(async res =>{
      if(res.data.status ==200)
      {
        setSelectedReferral(res.data.body)
      }
    })

    // Lets find all the admin users of this third party
    var companyObj = {
      id: thirdPartyValue,
      meta_key: "admins"
    }
    await axios.post(process.env.REACT_APP_BASE + "api/get-company-meta", companyObj).then(async res =>{
      console.log("response back", res.data.body)
      if(typeof res.data.body !=='undefined' && res.data.body.length > 0)
      {
        setAllOptions(res.data.body)
        setNewSelectedOptions(res.data.body)
      }

      var userObj = {
        thirdparty: thirdPartyValue
      }
    await axios.post(process.env.REACT_APP_BASE + "api/get-user-thirdparties", userObj).then(async res =>{
      
      if(typeof res.data.body !=='undefined' && res.data.body.length > 0)
      {
  
      res.data.body.forEach((item, index)=>{
        var newObjectHolderA = {label: item.userid, value: item.userid}
        allOptionsNotSelected.push(newObjectHolderA)
      })
    }
    })

    var mainParentObj = {
      mainParent: thirdPartyValue
    }

    if(thirdPartyValue && thirdPartyValue != null)
    {
      console.log("seeeingggg thiiissss")
      await axios.post(process.env.REACT_APP_API_BASE_URL+`/find-all-users-thirdparty`, mainParentObj).then(res => {
        console.log("all users ", res.data)
        setUsers(res.data.users)
      })
    }




      // if(typeof res.data.body !=='undefined' && res.data.body > 0)
      //   res.data.body.map((admins, id) => (
      //     var name = admins
      //     allOptions  = { , value: "true" }
      //   ))
      setShowSpinner(false)
    });
   
  };

  const handleThirdPartyReferralChange = async (values) => {
    console.log("the values is ", values)
    setSelectedReferral(values)
  }

  

  const handleSelectedOption = (value) => {
      setNewSelectedOptions(value)
  }


  const handleSubmit = async () => {
      console.log("all options selected ", newSelectedOptions)
      var newSaveObj = {
        id: newThirdPartyValue,
        meta_key: 'admins',
        meta_value: JSON.stringify(newSelectedOptions)
      }
      await axios.post(process.env.REACT_APP_BASE + "api/update-company-meta", newSaveObj).then(async res =>{
          console.log(res.data)

      })

      var newSaveObjReferral = {
        id: newThirdPartyValue,
        meta_key: 'referrals',
        meta_value: selectedReferral
      }
      await axios.post(process.env.REACT_APP_BASE + "api/update-company-meta", newSaveObjReferral).then(async res =>{
          console.log(res.data)

      })

      toastr.success("Updated Options successfully!") 

  }
  const animatedComponents = makeAnimated();

  const [users, setUsers] = useState([])
  const [contact, setContact] = useState()

  const columns = [
    {
      className: 'dt-control',
      orderable: false,
      data: null,
      defaultContent: '',
  },
    {
      dataField: "userid",
      text: "User ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "lastname",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "u_status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "payment_status",
      text: "PayStatus",
      sort: true,
    },
    {
      dataField: "fica_status",
      text: "FicaStatus",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to={`/user-profile/${user.id}`}>
            <i
              className="bx bx-user-circle bx-sm mt-1 font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(user)}
            ></i>
          </Link>
          {/* <Link className="text-info" to={`/user-product-list/${user.id}`}>
            <i
              className="bx bx-detail bx-sm mt-1 font-size-18"
              id="deletetooltip"
              // onClick={() => onClickDelete(user)}
            ></i>
          </Link> */}
        </div>
      ),
    },
  ]

  const handleUserClick = arg => {
    const user = arg

    setContact({
      id: user.id,
      name: user.name,
      status: user.u_status,
    })
  }


  const datatableInfo = {
    title: "Users list view",
    shortDescription: "List of users in the system",
    longDescription: "This shows a list of users in the system long text",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Third Party Settings</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="User List" breadcrumbItem="3rd Party Settings" />
          <Row>
            <Col xl="10">
              <Card>
                <CardBody>
            
                  <div className="table-responsive">
                    <FormGroup className="mb-3">

                    {showSpinner?
                        <Spinner className="ms-2" color="success" />
                    :
                    <div>

                      <div className="col-12">
                        <Label>Change Password:</Label>
                        <div className="form-group">
                          <select className="form-control" onChange={e => {handleThirdPartyChange(e.target.value)}}>
                            <option value="">Select Company</option>
                            {typeof allParties !=='undefined' && allParties.length > 0 ?
                              allParties.map((thirdParty, id) => (
                                <option key={id} className="form-control" value={thirdParty.company_id} >{thirdParty.name}</option>
                              ))
                              :
                              ''
                            }
                          </select>
                        </div>
                      </div>

                      {detailsDisplay ?
                
                          <Row>
                            <div className="col-12">
                              <Label>Users can see referral page:</Label>
                              <div className="form-group">
                                  <select className="form-control" value={selectedReferral} onChange={e => {handleThirdPartyReferralChange(e.target.value)}}>
                                    <option value="">Select Option</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                  </select>
                              </div>
                            </div>

                            <div className="col-12">
                              <Label>Admins:</Label>
                              <div className="form-group">
                                {/* <MakeAnimated options={ficaOption} /> */}
 
                                <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                defaultValue={allOptions}
                                isMulti
                                options={allOptionsNotSelected}
                                onChange={handleSelectedOption}
                              />
                              </div>
                            </div>
                          </Row>
                        
                        :
                        ''
                      }
                      
                      <div className="col-12 mt-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={handleSubmit}
                          className="btn c1-primary btn-block form-control"
                        >
                          Submit form
                        </Button>
                      </div>
                      {detailsDisplay ?

                      <div className="form-group mt-3">
                        <Row>
                          <Col lg="12">
                            <Datatable
                              columns={columns}
                              productData={users}
                              datatableInfo={datatableInfo}
                              wrapperClasses="table-responsive"
                            />
                            
                          </Col>
                        </Row>
                      </div>
                    : ''}

                      </div>
                    }

                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
