import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"
import axios from "axios"


import ReferralSettingsModal from "./ReferralSettingsModal"

export const referralSettings = () => {
  const [referrals, setReferrals] = useState([])
  const [referralSettingModal, setReferralSettingModal] = useState(false)
  const [newReferralSettings, setNewReferralSettings] = React.useState({ level: 0, percentage: '0'});

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "level",
      text: "Level",
      sort: true,
    },
    {
      dataField: "percentage",
      text: "Percentage",
      sort: true,
    }
    // {
    //   dataField: "menu",
    //   isDummyField: true,
    //   editable: false,
    //   text: "Action",
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, user) => (
    //     <div className="d-flex gap-3">
    //       <Link className="text-success" to={`/user-profile/${user.id}`}>
    //         <i
    //           className="bx bx-user-circle bx-sm mt-1 font-size-18"
    //           id="edittooltip"
    //           onClick={() => handleUserClick(user)}
    //         ></i>
    //       </Link>
    //       <Link className="text-info" to={`/user-product-list/${user.id}`}>
    //         <i
    //           className="bx bx-detail bx-sm mt-1 font-size-18"
    //           id="deletetooltip"
    //           // onClick={() => onClickDelete(user)}
    //         ></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ]

  const handleUserClick = arg => {
    const user = arg

    setContact({
      id: user.id,
      name: user.name,
      status: user.u_status,
    })
  }

  const redirectToUserProducts = state => {
    console.log("clicked to products")
  }

  useEffect(async res => {
    await axios.get(process.env.REACT_APP_API_BASE_URL+`/get-referrals`).then(res => {
      console.log("all referrals ", res.data)
      setReferrals(res.data.referrals)
    })
  }, [])

  const getReferrals = async (req, res) => {
    await axios.get(process.env.REACT_APP_API_BASE_URL+`/get-referrals`).then(res => {
        console.log("all referrals ", res.data)
        setReferrals(res.data.referrals)
      })
  }

  const datatableInfo = {
    title: "Referral Settings",
    shortDescription: "All Referral Settings",
    longDescription: "This settings displayed below will be the percentages and levels payed out to the user for referrals.",
  }

  const onClickAddSetting = () => {
      console.log("we should now show the modal")
    setReferralSettingModal(true);
  }

    const handleCloseClick = () => {
        setReferralSettingModal(false);
    }

    const handleSaveSettingClick = () => {
        const referralSettingObj = 
        {
            level: newReferralSettings.level,
            percentage: newReferralSettings.percentage
        }
        console.log('referral obj', referralSettingObj)
        axios.post(process.env.REACT_APP_API_BASE_URL+`/create-referral`, referralSettingObj).then(res => {
            console.log("new referral ", res.data)
            if(res.data.status == 200)
            {
                toastr.success(res.data.message) 
                setReferralSettingModal(false);
                getReferrals();
            }else{
                toastr.error("Unable to create setting!") 
            }
          })
    }

  return (
    <React.Fragment>
        <ReferralSettingsModal
          show={referralSettingModal}
          onCloseClick={handleCloseClick}
          referralSettings={newReferralSettings}
          saveSetting={handleSaveSettingClick}
        />
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Referrals</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                      <Col lg="12">

                        <div className="referral-setting-btn-container">
                            <a onClick={()=>onClickAddSetting()} className="btn c1-primary c1-primary-inline-btn btn-block">New Referral Setting</a>
                        </div>
                        <Datatable
                          columns={columns}
                          productData={referrals}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

}
