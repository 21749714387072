import PropTypes from 'prop-types'
import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import Icon from "react-crypto-icons";

const MiniWidget = props => {

  return (
    
    <React.Fragment>

      {props.reports.length > 0 ? props.reports.map((report, key) => (
        <Col md="3" sm="6" key={key}>
          <Card className='c1-primary-dark-card'>
            <CardBody>
              <div className="text-muted mb-4">
                <Icon name={report.icon}  className={
                    " h2 text-" +
                    report.color +
                    " align-middle mb-0 me-3"
                  } size={25}  />{" "}
                {report.title}{" "}
              </div>

              <Row>
                <Col xs="12">
                  <div>
                    <h5>{report.title + ' ' + report.balance}</h5>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )) : ''}

    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array
}