import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import { Link, useParams, withRouter } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Form,
  Table
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// icons
// import Icon from "react-crypto-icons";

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"


// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const HandleExchange =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [currentPercentage, setCurrentPercentage] = React.useState('');
  const [foundProducts, setFoundProducts] = React.useState(false);
  const [newamount, setNewAmount] = React.useState('');
  const [allExchanges, setAllExchanges] = React.useState({
    excahnge_type: "",
    amount: "",
    userid: ""
  })
  
  let { id } = useParams()


const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])





    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])







const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}


React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    
  }

},[auth])

useEffect(async res =>{
  if(user !== 'undefined' && user !== null && !foundProducts)
  {
    console.log(allExchanges.amount)
    if(typeof allExchanges !== 'undefined' && allExchanges.amount == '')
      setExchangeInfo(id)

  }


})


function setExchangeInfo(userid) {

  console.log("Running exchange info")
  const ExchangeObject = {
    id: userid,
  }

  axios.post(process.env.REACT_APP_BASE + "api/view-exchanges",ExchangeObject).then(async res =>{

    if(res.data.status == 200){
      console.log('see al exchanges ', res.data)
      return setAllExchanges(res.data.exchanges)
    }else{
      
      toastr.error('Unable to view exchanges at the moment!')
      return window.location.href = "/allexchanges";
    }

  });
}

const exchangeBtn = async echangeId => {

      // Exchange Type
      // 1 - Buy
      // 2 - Sell

      // Amount From/To
      // 2 - ZAR
      // 1 - USDT


      // const exchangeObject = {
      //     userid: user.user.userid,
      //     excahnge_type: allExchanges.excahnge_type,
      //     new_excahnge_type: new_excahnge_type,
      //     newamount: newamount,
      //     oldamount: allExchanges.amount,
      //     exchangeid: id
      //   }

      var exchangeObject = {
        exchangeid: echangeId
      }

      await axios.post(process.env.REACT_APP_BASE + "api/handle-new-exchange",exchangeObject).then(async res2 =>{

          if(res2.data.status == 200)
          {
            toastr.options.onHidden = function () {
              window.location.href = "/allexchanges";
            }
              toastr.success(res2.data.message)
          }else{
              toastr.error("Unable to complete exchange!")
          }

      })


}

const declineExchangeBtn = async echangeId => {

  var exchangeObject = {
    exchangeid: echangeId
  }

  await axios.post(process.env.REACT_APP_BASE + "api/decline-new-exchange",exchangeObject).then(async res2 =>{

      if(res2.data.status == 200)
      {
        toastr.options.onHidden = function () {
          window.location.href = "/allexchanges";
        }
          toastr.success(res2.data.message)
      }else{
          toastr.error("Unable to complete exchange!")
      }

  })


}


var numRow = 1;

var exchangeOptions = {
  1: 'USD',
  2: 'ZAR',
  3: 'BTC',
}

var statusOptions = {
  0: 'Pending',
  1: 'Approved',
  2: 'Declined',
}

    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Add Product Interest</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="Exhcnages" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <h4 className="card-title mb-4">All Exchanges </h4>
                  <div className="crypto-buy-sell-nav">
                    <Row>
                      <Col lg="12">
                        <Table className="table table-dark mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Amount  From</th>
                              <th>Amount To</th>
                              <th>Exchange From</th>
                              <th>Exchange To</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {typeof allExchanges !== 'undefined' && allExchanges.length > 0 ?
                          <tbody>

                            {allExchanges.map((exchange, key) => (
                              <tr key={exchange.id}>
                                <th scope="row">{numRow++}</th>
                                <td>{exchange.from_amount}</td>
                                <td>{exchange.amount}</td>
                                <td>{exchangeOptions[exchange.amount_from]}</td>
                                <td>{exchangeOptions[exchange.amount_to]}</td>
                                <td>{statusOptions[exchange.status]}</td>
                                <td>{exchange.status == '0'? 
                                <div>
                                  <button className="btn btn-success" onClick={e => exchangeBtn(exchange.id)}>Approve</button>
                                  <button className="btn btn-danger" onClick={e => declineExchangeBtn(exchange.id)}>Decline</button>
                                </div>
                                  : '---'}
                                </td>
                              </tr>
                            ))}

                          </tbody>

                          : <tbody>
                            <tr><td>No Records!</td></tr>
                          </tbody>}
                        </Table>
                      </Col>
                    </Row>


                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}