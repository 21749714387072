import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// icons
// import Icon from "react-crypto-icons";

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const finvestzarwallet =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);

  const [withdrawalAddress, setWithdrawalAddress] = React.useState('');
  const [depositTXID, setDepositTXID] = React.useState('');
  

  // const series = [
  //   {
  //     data: [
  //       { x: new Date(15387786e5), y: [6629.81, 6650.5, 6623.04, 6633.33] },
  //       { x: new Date(15387804e5), y: [6632.01, 6643.59, 6620, 6630.11] },
  //       {
  //         x: new Date(15387822e5),
  //         y: [6630.71, 6648.95, 6623.34, 6635.65],
  //       },
  //       { x: new Date(1538784e6), y: [6635.65, 6651, 6629.67, 6638.24] },
  //       { x: new Date(15387858e5), y: [6638.24, 6640, 6620, 6624.47] },
  //       {
  //         x: new Date(15387876e5),
  //         y: [6624.53, 6636.03, 6621.68, 6624.31],
  //       },
  //       { x: new Date(15387894e5), y: [6624.61, 6632.2, 6617, 6626.02] },
  //       { x: new Date(15387912e5), y: [6627, 6627.62, 6584.22, 6603.02] },
  //       { x: new Date(1538793e6), y: [6605, 6608.03, 6598.95, 6604.01] },
  //       { x: new Date(15387948e5), y: [6604.5, 6614.4, 6602.26, 6608.02] },
  //       {
  //         x: new Date(15387966e5),
  //         y: [6608.02, 6610.68, 6601.99, 6608.91],
  //       },
  //       { x: new Date(15387984e5), y: [6608.91, 6618.99, 6608.01, 6612] },
  //       { x: new Date(15388002e5), y: [6612, 6615.13, 6605.09, 6612] },
  //       { x: new Date(1538802e6), y: [6612, 6624.12, 6608.43, 6622.95] },
  //       { x: new Date(15388038e5), y: [6623.91, 6623.91, 6615, 6615.67] },
  //       { x: new Date(15388056e5), y: [6618.69, 6618.74, 6610, 6610.4] },
  //       { x: new Date(15388074e5), y: [6611, 6622.78, 6610.4, 6614.9] },
  //       { x: new Date(15388092e5), y: [6614.9, 6626.2, 6613.33, 6623.45] },
  //       { x: new Date(1538811e6), y: [6623.48, 6627, 6618.38, 6620.35] },
  //       {
  //         x: new Date(15388128e5),
  //         y: [6619.43, 6620.35, 6610.05, 6615.53],
  //       },
  //       { x: new Date(15388146e5), y: [6615.53, 6617.93, 6610, 6615.19] },
  //       { x: new Date(15388164e5), y: [6615.19, 6621.6, 6608.2, 6620] },
  //       { x: new Date(15388182e5), y: [6619.54, 6625.17, 6614.15, 6620] },
  //       { x: new Date(153882e7), y: [6620.33, 6634.15, 6617.24, 6624.61] },
  //       { x: new Date(15388218e5), y: [6625.95, 6626, 6611.66, 6617.58] },
  //       { x: new Date(15388236e5), y: [6619, 6625.97, 6595.27, 6598.86] },
  //       { x: new Date(15388254e5), y: [6598.86, 6598.88, 6570, 6587.16] },
  //       { x: new Date(15388272e5), y: [6588.86, 6600, 6580, 6593.4] },
  //       { x: new Date(1538829e6), y: [6593.99, 6598.89, 6585, 6587.81] },
  //       { x: new Date(15388308e5), y: [6587.81, 6592.73, 6567.14, 6578] },
  //       { x: new Date(15388326e5), y: [6578.35, 6581.72, 6567.39, 6579] },
  //       {
  //         x: new Date(15388344e5),
  //         y: [6579.38, 6580.92, 6566.77, 6575.96],
  //       },
  //       { x: new Date(15388362e5), y: [6575.96, 6589, 6571.77, 6588.92] },
  //       { x: new Date(1538838e6), y: [6588.92, 6594, 6577.55, 6589.22] },
  //       { x: new Date(15388398e5), y: [6589.3, 6598.89, 6589.1, 6596.08] },
  //       { x: new Date(15388416e5), y: [6597.5, 6600, 6588.39, 6596.25] },
  //       { x: new Date(15388434e5), y: [6598.03, 6600, 6588.73, 6595.97] },
  //       { x: new Date(15388452e5), y: [6595.97, 6602.01, 6588.17, 6602] },
  //       { x: new Date(1538847e6), y: [6602, 6607, 6596.51, 6599.95] },
  //       {
  //         x: new Date(15388488e5),
  //         y: [6600.63, 6601.21, 6590.39, 6591.02],
  //       },
  //       { x: new Date(15388506e5), y: [6591.02, 6603.08, 6591, 6591] },
  //       { x: new Date(15388524e5), y: [6591, 6601.32, 6585, 6592] },
  //       { x: new Date(15388542e5), y: [6593.13, 6596.01, 6590, 6593.34] },
  //       { x: new Date(1538856e6), y: [6593.34, 6604.76, 6582.63, 6593.86] },
  //       {
  //         x: new Date(15388578e5),
  //         y: [6593.86, 6604.28, 6586.57, 6600.01],
  //       },
  //       {
  //         x: new Date(15388596e5),
  //         y: [6601.81, 6603.21, 6592.78, 6596.25],
  //       },
  //       { x: new Date(15388614e5), y: [6596.25, 6604.2, 6590, 6602.99] },
  //       { x: new Date(15388632e5), y: [6602.99, 6606, 6584.99, 6587.81] },
  //       { x: new Date(1538865e6), y: [6587.81, 6595, 6583.27, 6591.96] },
  //       { x: new Date(15388668e5), y: [6591.97, 6596.07, 6585, 6588.39] },
  //       { x: new Date(15388686e5), y: [6587.6, 6598.21, 6587.6, 6594.27] },
  //       { x: new Date(15388704e5), y: [6596.44, 6601, 6590, 6596.55] },
  //       { x: new Date(15388722e5), y: [6598.91, 6605, 6596.61, 6600.02] },
  //       { x: new Date(1538874e6), y: [6600.55, 6605, 6589.14, 6593.01] },
  //       { x: new Date(15388758e5), y: [6593.15, 6605, 6592, 6603.06] },
  //       { x: new Date(15388776e5), y: [6603.07, 6604.5, 6599.09, 6603.89] },
  //       { x: new Date(15388794e5), y: [6604.44, 6604.44, 6600, 6603.5] },
  //       { x: new Date(15388812e5), y: [6603.5, 6603.99, 6597.5, 6603.86] },
  //       { x: new Date(1538883e6), y: [6603.85, 6605, 6600, 6604.07] },
  //       { x: new Date(15388848e5), y: [6604.98, 6606, 6604.07, 6606] },
  //     ],
  //   },
  // ]

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])

// const getStartpackList = async() =>{

//     const data= await axios.get(process.env.REACT_APP_DATABASEURL+"api/starterpack-list")
//     console.log(data)
//     setStartpackList(data.data.starterpacks)
// }
//     React.useEffect(()=>{

//         getStartpackList()
//     },[])



    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])




//      const ricaSim = (userid) =>
// {
//     alert('We now have to rica ' + userid)
//     // Add api to rica sim
// }

const options = {
  chart: { toolbar: !1, zoom: { enabled: !0 } },
  plotOptions: {
    candlestick: { colors: { upward: "#34c38f", downward: "#f46a6a" } },
  },
  xaxis: { type: "datetime" },
  yaxis: { tooltip: { enabled: !0 } },
}

const notofications = [
  {
    date: "15 Mar",
    desc: "If several languages coalesce of the resulting.",
  },
  {
    date: "14 Mar",
    desc: "New common language will be more simple and regular than the existing.",
  },
  {
    date: "13 Mar",
    desc: "It will seem like simplified English as a skeptical Cambridge.",
  },
  { date: "13 Mar", desc: "To achieve this, it would be necessary." },
  { date: "12 Mar", desc: "Cum sociis natoque penatibus et magnis dis." },
  {
    date: "11 Mar",
    desc: "New common language will be more simple and regular than the existing.",
  },
  {
    date: "10 Mar",
    desc: "It will seem like simplified English as a skeptical Cambridge.",
  },
  { date: "9 Mar", desc: "To achieve this, it would be necessary." },
]

const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    
  }

},[auth])

useEffect(async res =>{
  if(user !== 'undefined' && user !== null)
  {
    getuserBalance(user.user.userid)

  }


})

const getuserBalance = async userid => {
  var balanceObject = {
    'userid': userid,
    'currency': '2'
  }



  await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceObject).then(async res =>{

    // console.log('set the balance to ', res.data)
    return setuserBalance(res.data.total);
 
  }).then(async res1 =>{
    var transactionObject = {
      'userid': userid,
      'currency': '2'
    }
    
  
    if(!checkedTransactions)
    {
        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then( res2 =>{

          if(res2.data.status == 200)
          {
            setAllTransactions(res2.data.transactions)
            console.log('transactions is ', viewAllTransactions)
          }
          setCheckedTransactions(true);
          
        })
    }
  })



}


function setDepositAmount(amount) {

  const depositObject = {
    userid: user.user.userid,
    txid: depositTXID,
    amount: userDeposit,
    tran_cat: '1',
    currency: '2',
    type: "Adding Deposit"
  }

  axios.post(process.env.REACT_APP_BASE + "api/create-transaction",depositObject).then(async res =>{

    setDeposit(0);
    toastr.success("Deposit request submitted successfull!") 

    var transactionObject = {
      userid: user.user.userid,
      'currency': '2'
    }

    // Get the list of all the transactions
    await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then((res)=>{
   
      if(res.data.status == 200)
      {
        setAllTransactions(res.data.transactions)
      }
      
    })
  });


}

function setWithdrawalAmount(amount) {

  var finalAmount = userWithdrawal * -1;
  const withdrawalObject = {
    userid: user.user.userid,
    withdrawal_address: withdrawalAddress,
    amount: finalAmount,
    tran_cat: '2',
    currency: '2',
    type: "Adding Withdrawal"
  }

  axios.post(process.env.REACT_APP_BASE + "api/create-transaction",withdrawalObject).then(async res =>{
    
    setWithdrawal(0);
    toastr.success("Withdrawal request submitted successfull!") 

    var transactionObject = {
      userid: user.user.userid,
      'currency': '2'
    }

        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then((res)=>{
          
          if(res.data.status == 200)
          {
            setAllTransactions(res.data.transactions)
          }
          
        })

  });
}




    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - My ZAR Wallet</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="My ZAR Wallet" />

          <Row>
            <Col lg="12">
              <Row>
                  <Col md="4" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              ZAR Wallet Value
                            </p>
                            <h4 className="mb-0">ZAR{userBalance}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle">
                              <i className="mdi mdi-36px mdi-alpha-r-circle-outline" />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
              </Row>
            </Col>
          </Row>

          <Row>

            <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Deposit / Withdraw</h4>

                <div className="mt-4 mb-3">
                  <Nav pills className="bg-light rounded" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Deposit
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Withdraw
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="mt-4">
                    <TabPane tabId="1" id="buy-tab">
                      <h5 className="font-size-14 mb-4">Deposit:</h5>

                      <div>
                        <div>
                          <Label>Add Amount :</Label>

                          <InputGroup className="mb-3">
                            <Label className="input-group-text">TXID</Label>
                            <Input type="text" onChange={(e)=>setDepositTXID(e.target.value)} value={depositTXID} className="form-control" />
                          </InputGroup>

                          <InputGroup className="mb-3">
                            <Label className="input-group-text">Total</Label>
                            <Input type="text" onChange={(e)=>setDeposit(e.target.value)} value={userDeposit} className="form-control" />
                          </InputGroup>
                        </div>

                        <div className="text-center">
                          <button
                            onClick={(e)=>setDepositAmount(e.target.value)}
                            type="button"
                            color="success"
                            className="w-md btn bg-success text-light"
                          >
                            Deposit
                          </button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2" id="sell-tab">
                      <h5 className="font-size-14 mb-4">Withdraw</h5>

                      <div>
                        <div>
                          <Label>Add Amount :</Label>

                          <InputGroup className="mb-3">
                            <Label className="input-group-text">Withdrawal Address</Label>
                            <Input type="text" onChange={(e)=>setWithdrawalAddress(e.target.value)} value={withdrawalAddress} className="form-control" />
                          </InputGroup>

                          <InputGroup className="mb-3">
                            <Label className="input-group-text">Total</Label>
                            <Input type="text" onChange={(e)=>setWithdrawal(e.target.value)} value={userWithdrawal} className="form-control" />
                          </InputGroup>
                        </div>

                        <div className="text-center">
                          <Button
                          onClick={(e)=>setWithdrawalAmount(e.target.value)}
                            type="button"
                            color="danger"
                            className="w-md"
                          >
                            Withdraw
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>

                  <h4 className="card-title mb-4">Transaction History</h4>

                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>

                        <tr>  
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>

                     
                        {viewAllTransactions.map((viewAllTransaction, key) => (
                            <tr key={viewAllTransaction.id}>
                              <td>{viewAllTransaction.createdAt}</td>
                              <td>{viewAllTransaction.status == '1' ? 
                                'Approved' : viewAllTransaction.status == '0' ? 'Pending' : 'Declined'}</td>
                              <td className={viewAllTransaction.amount < 0? 'text-danger': 'text-success'}>R {viewAllTransaction.amount}</td>
                            </tr>
                        ))}
  
                   

                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>

          {/* <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Order book</h4>

                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr className="text-center">
                          <th colSpan="3">Buy</th>
                          <th colSpan="3">Sell</th>
                        </tr>
                        <tr>
                          <th scope="col">Amount</th>
                          <th scope="col">Total</th>
                          <th scope="col">Price</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Total</th>
                          <th scope="col">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0.0412</td>
                          <td>0.0412</td>
                          <td>256.18</td>
                          <td>0.0201</td>
                          <td>0.0201</td>
                          <td>124.98</td>
                        </tr>
                        <tr>
                          <td>0.0301</td>
                          <td>0.0301</td>
                          <td>187.16</td>
                          <td>0.0165</td>
                          <td>0.0165</td>
                          <td>102.60</td>
                        </tr>
                        <tr>
                          <td>0.0523</td>
                          <td>0.0523</td>
                          <td>325.21</td>
                          <td>0.0348</td>
                          <td>0.0348</td>
                          <td>216.39</td>
                        </tr>
                        <tr>
                          <td>0.0432</td>
                          <td>0.0432</td>
                          <td>268.62</td>
                          <td>0.0321</td>
                          <td>0.0321</td>
                          <td>199.60</td>
                        </tr>
                        <tr>
                          <td>0.0246</td>
                          <td>0.0246</td>
                          <td>152.96</td>
                          <td>0.0403</td>
                          <td>0.0403</td>
                          <td>250.59</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Notifications</h4>

                  <SimpleBar style={{ maxHeight: "310px" }}>
                    <ul className="verti-timeline list-unstyled">
                      {notofications.map((notification, key) => (
                        <li key={key} className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18" />
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <h5 className="font-size-14">
                                {notification.date}{" "}
                                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                              </h5>
                            </div>
                            <div className="flex-grow-1">
                              <div>{notification.desc}</div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </SimpleBar>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
    )
}