import PropTypes from 'prop-types'
import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import icons from 'currency-icons';

const CurrencyWidget = props => {

    // console.log('icons is ', icons['ZAR'])
  return (
    <React.Fragment>
 
      {props.currencies.length > 0 ? props.currencies.map((currency, key) => (
        <Col md="3" sm="6" key={key}>
          <Card className='c1-primary-dark-card'>
            <CardBody>
              <div className="text-muted mb-4">

                <img src={icons[currency.icon].icon}  className={
                    " h2 text-" +
                    currency.color +
                    " align-middle "
                  }  
                  style={{backgroundColor:currency.color, borderRadius: "50%",
                  height: "25px", width: "25px"}}/>{" "}
                {currency.title}{" "}
              </div>

              <Row>
                <Col xs="12">
                  <div>
                    <h5>{currency.icon + ' ' + currency.balance}</h5>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )) : ''}

    </React.Fragment>
  )
}

export default CurrencyWidget

CurrencyWidget.propTypes = {
    currencies: PropTypes.array
}