import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"


import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  CardTitle,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'
// import bannerImage from "../../assets/images/digital-asset-moderate.png";

import ReactApexChart from "react-apexcharts"
import DonutChart from "../AllCharts/apex/dountchart"

//Simple bar
import SimpleBar from "simplebar-react"

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Bar } from "react-chartjs-2"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const userSavingsPocket12Info =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userInvest, setInvest] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [viewAllProductSales, setAllProductSales] = React.useState([]);
  const [viewAllProductInterests, setAllProductInterests] = React.useState([]);
  
  
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);
  const [userProductTotal, setProductTotal] = React.useState('');
  const [userOpenProductTotal, setProductOpenTotal] = React.useState('');
  const [userClosedProductTotal, setProductClosedTotal] = React.useState('');
  const [userOpenProductAmount, setProductOpenAmount] = React.useState('');
  const [usertotalInvestments, setusertotalInvestments] = React.useState('');
  const [usertotalInvestmentsAmount, setusertotalInvestmentsAmount] = React.useState('');
  const currentDate = new Date();
  
  
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])




const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    
  }

},[auth])

useEffect(async res =>{
  if(user !== 'undefined' && user !== null)
  {
    getuserBalance(user.user.userid)
    getUserProducts('17', user.user.userid)
    getUserOpenProducts('17', user.user.userid)
    getUserClosedProducts('17', user.user.userid)

  }


},[auth])

const getuserBalance = async userid => {
  var balanceObject = {
    'userid': userid
  }



  await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceObject).then(async res =>{

    // console.log('set the balance to ', res.data)
    return setuserBalance(res.data.total);
 
  }).then(async res1 =>{
    var transactionObject = {
      'userid': userid,
      'productid': '17'
    }
    
  
    if(!checkedTransactions)
    {
        // Get All ProductSale
        await axios.post(process.env.REACT_APP_BASE + "api/view-product-sale",transactionObject).then( res2 =>{

            if(res2.data.status == 200)
            {
              setAllProductSales(res2.data.sales)
              setAllProductInterests(res2.data.productInterests)
              console.log('all product sales ', res2.data)
            
            }
            setCheckedTransactions(true);
                    
        })
    }
  })



}


function setInvestAmount(amount) {

    // const userid = req.body.userid
    // const amount = req.body.amount
    // const productid = req.body.productid
    console.log(parseFloat(userBalance), parseFloat(userInvest))
    if(userBalance && parseFloat(userInvest) > 0 && parseFloat(userInvest) > 70 && parseFloat(userBalance) > parseFloat(userInvest))
    {
        const investObject = {
            userid: user.user.userid,
            amount: userInvest,
            productid: '17',
          }
        console.log('continue with purchase')
          axios.post(process.env.REACT_APP_BASE + "api/create-productsale",investObject).then(async res =>{
        
            setInvest(0);
            // toastr.options.onHidden = function() { window.location.href="/digital-moderate" } 
            toastr.success("Product Successfully purchased!") 
        
          });
    }else{
        if(parseFloat(userInvest) < 0 || parseFloat(userInvest) < 70)
        {
            toastr.error("Insure Amount Meets Minimum requirement!") 
        }else{
            toastr.error("Insufficient Funds!") 
        }

    }

}

const getUserProducts = async (productid, userid) => {
  
    var totalObject = {
      'userid': userid,
      'productid': productid,
      currency: '1'
    }

    console.log('getUserProducts is called ', totalObject)
  
    await axios.post(process.env.REACT_APP_BASE + "api/productid-total",totalObject).then(async res =>{
        console.log('amount to see', res.data)
        setusertotalInvestments(res.data.amount);
      return setProductTotal(res.data.total);
   
    })

    await axios.post(process.env.REACT_APP_BASE + "api/user-productinterests",totalObject).then(async res =>{
        console.log('interests amount to see', res.data)
      return setusertotalInvestmentsAmount(res.data.amount);
   
    })
  
  }

  const getUserOpenProducts = async (productid, userid) => {
    var totalObject = {
      'userid': userid,
      'productid': productid,
      currency: '1'
    }
  
    await axios.post(process.env.REACT_APP_BASE + "api/productid-open-total",totalObject).then(async res =>{
        setProductOpenAmount(res.data.amount)
     
      return setProductOpenTotal(res.data.total);
   
    })
  
  }

  const getUserClosedProducts = async (productid, userid) => {
    var totalObject = {
      'userid': userid,
      'productid': productid,
      'currency': '1',
    }
  
    await axios.post(process.env.REACT_APP_BASE + "api/productid-closed-total",totalObject).then(async res =>{

      return setProductClosedTotal(res.data.total);
   
    })
  
  }


  const seriesDonut = [userOpenProductTotal, userClosedProductTotal]
  const optionsDonut = {
    labels: ["Open Plan", "Closed Plan"],
    colors: ["#34c38f", "#556ee6"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }



  const columns = [{
    dataField: 'plan',
    text: 'Plan ID'
  },{
    dataField: 'amount',
    text: 'Amount Entered'
  },{
    dataField: 'invest_d',
    text: 'Contribution Date'
  }, {
    dataField: 'start_d',
    text: 'Start Date'
  },{
    dataField: 'expire_d',
    text: 'Expire Date'
  }, {
    dataField: 'month1',
    text: 'Month 1-4'
  },{
    dataField: 'month2',
    text: 'Month 5-8'
  },{
    dataField: 'month3',
    text: 'Month 9-12'
  },{
    dataField: 'status',
    text: 'Status'
  }];

  // Table Data
const productData = [];

var x = 0;

var labelData = [];
var barData = [];
viewAllProductSales.forEach(productsale => {
    var numid = x.toString();
//=================  Donut chart ===========================================================
    var transtatus = '---';
    if(productsale.status == '1')
    {
     transtatus = 'Open'
    }else if(productsale.status == '2'){
     transtatus = 'Closed'
    }else{
     transtatus = 'Closed'
    }

    var month1 = '$0'
    var month2 = '$0'
    var month3 = '$0'
    
    console.log("seeing this at least 1 ", productsale.planid + '_1')
    console.log("seeing this at least ", viewAllProductInterests)
    if(typeof viewAllProductInterests[productsale.planid + '_1'] !== 'undefined')
    {
   
      month1 = '$' + viewAllProductInterests[productsale.planid + '_1']['amount']
    }

    
    if(typeof viewAllProductInterests[productsale.planid + '_2'] !== 'undefined')
      month2 = '$' + viewAllProductInterests[productsale.planid + '_2']['amount']

    if(typeof viewAllProductInterests[productsale.planid + '_3'] !== 'undefined')
      month3 = '$' + viewAllProductInterests[productsale.planid + '_3']['amount']
 
     var productDataObj = {
         id: numid,
         amount: productsale.amount,
         plan: productsale.planid,
         invest_d: productsale.invest_date,
         start_d: productsale.start_date,
         expire_d: productsale.expire_date,
         month1: month1,
         month2: month2,
         month3: month3,
         t_value: '$' + productsale.amount,
         status: transtatus,
     };
     productData.push(productDataObj)

     //================= End Donut chart ===========================================================

     //=================  Bar chart ===========================================================
     labelData.push(productsale.expire_date)
     barData.push(Number(productsale.amount))

     //================= End Donut chart ===========================================================


     x = x+1;
 });

//  viewAllProductSales.forEach(productsale => {
//     var numid = x.toString();
// //=================  Bar chart ===========================================================


//      //================= End Donut chart ===========================================================
//      x = x+1;
// });


const dataBar = {
    labels: labelData,
    datasets: [
      {
        label: "Sales Analytics",
        backgroundColor: "rgba(52, 195, 143, 0.8)",
        borderColor: "rgba(52, 195, 143, 0.8)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
        hoverBorderColor: "rgba(52, 195, 143, 0.9)",
        data: barData,
      },
    ],
  }

  const optionBar = {
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
  }

    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 Holdings - USDT Savings Pocket (12 Months)</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="C1 Holdings Savings Pocket (12 Months)" />

          <Row>
            <Col lg="12">

                  <Row>

                      <Col sm="4">
                              <a href="./my-usdt-wallet">
                              <Card className="mini-stats-wid rounded c1-primary-dark-card">
                              <CardBody>
                                  <div className="d-flex">
                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">Open Plans</p>
                                      <h5 className="text-left mb-0">
                                      {userOpenProductTotal} 
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>

                          <Col sm="4">
                              <a href="./my-usdt-wallet">
                              <Card className="mini-stats-wid rounded c1-primary-dark-card">
                              <CardBody>
                                  <div className="d-flex">
                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">Closed Plans</p>
                                      <h5 className="text-left mb-0">
                                      {userClosedProductTotal} 
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>

                          <Col sm="4">
                              <a href="./my-usdt-wallet">
                              <Card className="mini-stats-wid rounded c1-primary-dark-card">
                              <CardBody>
                                  <div className="d-flex">
                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">Total Plans</p>
                                      <h5 className="text-left mb-0">
                                      {userProductTotal} 
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>

                          <Col sm="4">
                              <a href="./my-usdt-wallet">
                              <Card className="mini-stats-wid rounded c1-primary-dark-card">
                              <CardBody>
                                  <div className="d-flex">

                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">Total Overall Investments</p>
                                      <h5 className="text-left mb-0">
                                      ${usertotalInvestments} 
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>

                          <Col sm="4">
                              <a href="./my-usdt-wallet">
                              <Card className="mini-stats-wid rounded c1-primary-dark-card">
                              <CardBody>
                                  <div className="d-flex">

                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">Total Overall Returns</p>
                                      <h5 className="text-left mb-0">
                                      ${usertotalInvestmentsAmount}
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>

                          <Col sm="4">
                              <a href="./my-usdt-wallet">
                              <Card className="mini-stats-wid rounded c1-primary-dark-card">
                              <CardBody>
                                  <div className="d-flex">
                                  <div className="flex-grow-1">
                                      <p className="text-muted mb-2">Total Open Investment</p>
                                      <h5 className="text-left mb-0">
                                      ${userOpenProductAmount} 
                                      </h5>
                                  </div>
                                  </div>
                              </CardBody>
                              </Card>
                              </a>
                          </Col>
                      
                          <Col lg="6" sm="6">
                        <Card>
                            <CardBody>
            
                            <ReactApexChart
                            options={optionsDonut}
                            series={seriesDonut}
                            type="donut"
                            height="380"
                            className="apex-charts"
                            />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={6}>
                        <Card>
                            <CardBody>
                            <CardTitle className="mb-4">Capital Return</CardTitle>

                            <Bar width={474} height={300} data={dataBar} options={optionBar} />
                            </CardBody>
                        </Card>
                    </Col>

                  </Row>

            </Col>
          </Row>

          <Row>

            <Col xl="12">
                <Card>
                    <CardBody>

                        <h4 className="card-title mb-4">Activities </h4> 

                        <ul className="nav nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: activeTab === "1",
                            })}
                            onClick={() => {
                                toggleTab("1")
                            }}
                            >
                            All
                            </NavLink>
                        </NavItem>
                    
                        </ul>
                        <div className="mt-4">
                        <BootstrapTable keyField='id' data={ productData } columns={ columns } pagination={ paginationFactory() } />
                        </div>
                    </CardBody>
                </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}