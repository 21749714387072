import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import "./ProductOverviewCard.styles.scss"
import axios from "axios"

const ProductOverviewCard = ({ user, productId }) => {
  const [checkedTransactions, setCheckedTransactions] = React.useState(false)
  const [userBalance, setUserBalance] = React.useState("")
  const [userProductTotal, setProductTotal] = React.useState("")
  const [userOpenProductTotal, setProductOpenTotal] = React.useState("")
  const [userClosedProductTotal, setProductClosedTotal] = React.useState("")
  const [userOpenProductAmount, setProductOpenAmount] = React.useState("")
  const [userTotalInvestments, setUsertotalInvestments] = React.useState("")
  const [userTotalInvestmentsAmount, setUsertotalInvestmentsAmount] =
    React.useState("")
  const [viewAllTransactions, setAllTransactions] = React.useState([])
  const [viewAllProductSales, setAllProductSales] = React.useState([])

  const getUserProducts = async (productid, userid) => {
    var totalObject = {
      userid: userid,
      productid: productid,
    }

    await axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/productid-total",
        totalObject
      )
      .then(async res => {
        setUsertotalInvestments(res.data.amount)
        return setProductTotal(res.data.total)
      })

    await axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/user-productinterests",
        totalObject
      )
      .then(async res => {
        return setUsertotalInvestmentsAmount(res.data.amount)
      })
  }

  const getUserBalance = async userid => {
    var balanceObject = {
      userid: userid,
    }

    await axios
      .post(process.env.REACT_APP_API_BASE_URL + "/user-balance", balanceObject)
      .then(async res => {
        // console.log('set the balance to ', res.data)
        return setUserBalance(res.data.total)
      })
      .then(async res1 => {
        var transactionObject = {
          userid: userid,
          productid: productId,
        }

        if (!checkedTransactions) {
          // Get All ProductSale
          await axios
            .post(
              process.env.REACT_APP_API_BASE_URL + "/view-product-sale",
              transactionObject
            )
            .then(res2 => {
              if (res2.data.status == 200) {
                setAllProductSales(res2.data.sales)
              }
              setCheckedTransactions(true)
            })
        }
      })
  }

  const getUserOpenProducts = async (productid, userid) => {
    var totalObject = {
      userid: userid,
      productid: productid,
    }

    await axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/productid-open-total",
        totalObject
      )
      .then(async res => {

        setProductOpenAmount(res.data.amount)

        return setProductOpenTotal(res.data.total)
      })
  }

  const getUserClosedProducts = async (productid, userid) => {
    var totalObject = {
      userid: userid,
      productid: productid,
    }

    await axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/productid-closed-total",
        totalObject
      )
      .then(async res => {
        return setProductClosedTotal(res.data.total)
      })
  }

  useEffect(async res => {
    if (user !== "undefined" && user !== null) {
      //   getUserBalance(user.userid)
      getUserProducts(productId, user.userid)
      getUserOpenProducts(productId, user.userid)
      getUserClosedProducts(productId, user.userid)
    }
  })

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Row>
            <Col sm="4">
              <a href="./my-usdt-wallet">
                <Card className="mini-stats-wid rounded c1-primary-dark-card">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-2">Open Plans</p>
                        <h5 className="text-left mb-0">
                          {userOpenProductTotal}
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col sm="4">
              <a href="./my-usdt-wallet">
                <Card className="mini-stats-wid rounded c1-primary-dark-card">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-2">Closed Plans</p>
                        <h5 className="text-left mb-0">
                          {userClosedProductTotal}
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col sm="4">
              <a href="./my-usdt-wallet">
                <Card className="mini-stats-wid rounded c1-primary-dark-card">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-2">Total Plans</p>
                        <h5 className="text-left mb-0">{userProductTotal}</h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>
            <Col sm="4">
              <a href="./my-usdt-wallet">
                <Card className="mini-stats-wid rounded c1-primary-dark-card">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-2">Total Open Investment</p>
                        <h5 className="text-left mb-0">
                          ${userOpenProductAmount}
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col sm="4">
              <a href="./my-usdt-wallet">
                <Card className="mini-stats-wid rounded c1-primary-dark-card">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-2">Total Overall Returns</p>
                        <h5 className="text-left mb-0">
                          ${userTotalInvestmentsAmount}
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col sm="4">
              <a href="./my-usdt-wallet">
                <Card className="mini-stats-wid rounded c1-primary-dark-card">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-2">
                          Total Overall Investments
                        </p>
                        <h5 className="text-left mb-0">
                          ${userTotalInvestments}
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

ProductOverviewCard.propTypes = {
  user: PropTypes.object,
  productId: PropTypes.string,
}

export default ProductOverviewCard
