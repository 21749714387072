import React, { Component,useEffect, useState } from 'react'
import MetaTags from "react-meta-tags"
import { Row, Button, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroup

} from "reactstrap";
import { Link } from "react-router-dom"

import loginlogo from "../../assets/images/backgroundomc.jpeg";

import profileImg from "../../assets/images/c1_favicon.png";
import logo from "../../assets/images/c1logo.png";
import logoImg from "../../assets/images/c1_favicon.png";

import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"



const emailAccountVerification = props => {

  const queryParams = new URLSearchParams(window.location.search);

  const [activeTab, setActiveTab] = useState("1")

  const user = {
    name: "Christian",
  }

  function verifyEmail(e){
    const getUserToken = queryParams.get('userToken');

  
 
    if(getUserToken !== '' && getUserToken !== null) {
      console.log('usertoken is ', getUserToken)

      var userObject = 
      {
        userToken: getUserToken
      }
      axios.post(process.env.REACT_APP_BASE + "api/verify-email-token",userObject).then(async res =>{
        console.log('resdata is ', res.data)
        if(res.data.status == 200)
        {
          toastr.options.onHidden = function() { window.location.href="/login" } 
          toastr.success("Successfully verified email"); 
          return false;
  
        }else{
          toastr.error(res.data.message); 
        }
     
      });
    }else{
      toastr.error("Invalid Link"); 
      return false;
    }
  };


  return (

<React.Fragment>
          <MetaTags>
            <title>C1 Holdings - Confirmation</title>
          </MetaTags>
          <Breadcrumbs title="Email Confirmation" breadcrumbItem="Email" />
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="fas fa-home h2" />
            </Link>
          </div>
          <div className="account-pages" >
          <Container className="pt-sm-5 mw-100 fvw-login-container" 
          // style={{
          //     backgroundImage: `url(${loginlogo})`,
          //     boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 20%)"
          //   }}
            >
            <Row className="justify-content-center select-user-content" style={{
            position: "absolute",
            left: "40%",
            top: "34%",
            transform: "translate(-50%, -50%)",
            }}>
    
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden fwv-card-usertype" >
                    <CardBody className="pt-0">
                      <div>
                      <Link to="/login" className="auth-logo-light">
                          <div className="w-100 profile-user-wid mb-4">
                            <span className="avatar-title ">
                              <img
                                src={logo}
                                alt=""
                                className="mt-2 fvw-login-logo"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
    
    
                      <div className="mt-4 mb-3">

                      {/* user */}
                      <TabContent activeTab={activeTab} className="mt-4">
                        <TabPane tabId="1" id="buy-tab">
                          <div>
                          <div className="p-2">
                        <Form
                          className="form-horizontal fvw-login-form"

                        >
                      
                          <Row>
                            <Col className='col-12'>

                            <Row>
                              {/* <Col className='col-2'>
                              </Col> */}

                                <Col className='col-12'>

                                  <Card className="cardType-fill">
                                    <CardBody className="text-center">
                                      
                                        <i
                                          className="bx bx-envelope text-center bx-lg mt-1 font-size-48"
                                          id="edittooltip"
                                          
                                        ></i>
                                        < br />
                                        <h5 className="text-center mt-2">
Please confirm your email address by clicking the link below.</h5>

                                        <a href="#" 
                                      onClick={(e)=>verifyEmail(e.target.value)}
                                      style={{
                                          fontFamily:
                                            "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                          fontSize: "14px",
                                          lineHeight: "2em",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          display: "inline-block",
                                          borderRadius: "5px",
                                          textTransform: "capitalize",
                                          margin: "0",
                                         
                                        }} className="btn c1-primary c1-primary-inline-btn btn-block">Confirm email address</a>
                                      

                                    </CardBody>
                                  </Card>

                                </Col>

                              {/* <Col className='col-2'>
                              </Col> */}
                            </Row>

                            </Col>

                          </Row>               
    
                        </Form>
                      </div>
                            {/* Company */}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
    
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>


















    // <React.Fragment>
    //   <Container fluid>
    //     <Breadcrumbs title="Email Template" breadcrumbItem="Alert Email" />

    //     <Row className="email-template">
    //       <Col md={12}>
    //         <table
    //           className="body-wrap"
    //           style={{
    //             fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    //             boxSizing: "border-box",
    //             fontSize: "14px",
    //             width: "100%",
    //             backgroundColor: "#f6f6f6",
    //             margin: "0",
    //           }}
    //           bgcolor="#f6f6f6"
    //         >
    //           <tbody>
    //             <tr
    //               style={{
    //                 fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                 boxSizing: "border-box",
    //                 fontSize: "14px",
    //                 margin: "0",
    //               }}
    //             >
    //               <td
    //                 style={{
    //                   fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                   boxSizing: "border-box",
    //                   fontSize: "14px",
    //                   verticalAlign: "top",
    //                   margin: "0",
    //                 }}
    //                 valign="top"
    //               ></td>
    //               <td
    //                 className="container"
    //                 width="600"
    //                 style={{
    //                   fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                   boxSizing: "border-box",
    //                   fontSize: "14px",
    //                   verticalAlign: "top",
    //                   display: "block",
    //                   maxWidth: "600px",
    //                   clear: "both",
    //                   margin: "0 auto",
    //                 }}
    //                 valign="top"
    //               >
    //                 <div
    //                   className="content"
    //                   style={{
    //                     fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                     boxSizing: "border-box",
    //                     fontSize: "14px",
    //                     maxWidth: "600px",
    //                     display: "block",
    //                     margin: "0 auto",
    //                     padding: "20px",
    //                   }}
    //                 >
    //                   <table
    //                     className="main"
    //                     width="100%"
    //                     cellPadding="0"
    //                     cellSpacing="0"
    //                     style={{
    //                       fontFamily:
    //                         "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                       boxSizing: "border-box",
    //                       fontSize: "14px",
    //                       borderRadius: "7px",
    //                       backgroundColor: "#fff",
    //                       margin: "0",
    //                       boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)",
    //                     }}
    //                   >
    //                     <tbody>
    //                       <tr
    //                         style={{
    //                           fontFamily:
    //                             "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                           boxSizing: "border-box",
    //                           fontSize: "14px",
    //                           margin: "0",
    //                         }}
    //                       >
    //                         <td
    //                           className="alert alert-warning"
    //                           style={{
    //                             fontFamily:
    //                               "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                             boxSizing: "border-box",
    //                             fontSize: "16px",
    //                             verticalAlign: "top",
    //                             color: "#fff",
    //                             fontWeight: "500",
    //                             textAlign: "center",
    //                             borderRadius: "3px 3px 0px 0px",
    //                             backgroundColor: "#556ee6",
    //                             margin: "0",
    //                             padding: "20px",
    //                           }}
    //                           align="center"
    //                           valign="top"
    //                         >
    //                           Account verification
    //                         </td>
    //                       </tr>
    //                       <tr
    //                         style={{
    //                           fontFamily:
    //                             "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                           boxSizing: "border-box",
    //                           fontSize: "14px",
    //                           margin: "0",
    //                         }}
    //                       >
    //                         <td
    //                           className="content-wrap"
    //                           style={{
    //                             fontFamily:
    //                               "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                             boxSizing: "border-box",
    //                             fontSize: "14px",
    //                             verticalAlign: "top",
    //                             margin: "0",
    //                             padding: "20px",
    //                           }}
    //                           valign="top"
    //                         ></td>
    //                       </tr>
    //                     </tbody>
    //                   </table>
    //                   <table
    //                     className="main"
    //                     width="100%"
    //                     cellPadding="0"
    //                     cellSpacing="0"
    //                     itemProp="action"
    //                     itemScope
    //                     itemType="http://schema.org/ConfirmAction"
    //                     style={{
    //                       fontFamily:
    //                         "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                       boxSizing: "border-box",
    //                       fontSize: "14px",
    //                       borderRadius: "7px",
    //                       margin: "0px",
    //                       border: "none",
    //                     }}
    //                   >
    //                     <tbody>
    //                       <tr
    //                         style={{
    //                           fontFamily:
    //                             "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                           boxSizing: "border-box",
    //                           fontSize: "14px",
    //                           margin: "0px",
    //                         }}
    //                       >
    //                         <td
    //                           className="content-wrap"
    //                           style={{
    //                             fontFamily:
    //                               "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                             boxSizing: "border-box",
    //                             fontSize: "14px",
    //                             verticalAlign: "top",
    //                             margin: "0px",
    //                             padding: "30px",
    //                             borderRadius: "7px",
    //                             backgroundColor: "#fff",
    //                             boxShadow:
    //                               "0 0.75rem 1.5rem rgba(18,38,63,.03)",
    //                           }}
    //                           valign="top"
    //                         >
    //                           <meta
    //                             itemProp="name"
    //                             content="Confirm Email"
    //                             style={{
    //                               fontFamily:
    //                                 "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                               boxSizing: "border-box",
    //                               fontSize: "14px",
    //                               margin: "0px",
    //                             }}
    //                           />

    //                           <table
    //                             width="100%"
    //                             cellPadding="0"
    //                             cellSpacing="0"
    //                             style={{
    //                               fontFamily:
    //                                 "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                               boxSizing: "border-box",
    //                               fontSize: "14px",
    //                               margin: "0px",
    //                             }}
    //                           >
    //                             <tbody>
    //                               <tr
    //                                 style={{
    //                                   fontFamily:
    //                                     "Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                   boxSizing: "border-box",
    //                                   fontSize: "14px",
    //                                   margin: "0px",
    //                                 }}
    //                               >
    //                                 <td
    //                                   className="content-block"
    //                                   style={{
    //                                     fontFamily:
    //                                       "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                     boxSizing: "border-box",
    //                                     fontSize: "14px",
    //                                     verticalAlign: "top",
    //                                     margin: "0px",
    //                                     padding: "0 0 20px",
    //                                   }}
    //                                   valign="top"
    //                                 >
    //                                   Please confirm your email address by
    //                                   clicking the link below.
    //                                 </td>
    //                               </tr>

    //                               <tr
    //                                 style={{
    //                                   fontFamily:
    //                                     "Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                   boxSizing: "border-box",
    //                                   fontSize: "14px",
    //                                   margin: "0px",
    //                                 }}
    //                               >
    //                                 <td
    //                                   className="content-block"
    //                                   style={{
    //                                     fontFamily:
    //                                       "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                     boxSizing: "border-box",
    //                                     fontSize: "14px",
    //                                     verticalAlign: "top",
    //                                     margin: "0px",
    //                                     padding: "0 0 20px",
    //                                   }}
    //                                   valign="top"
    //                                 >
    //                                   We may need to send you critical
    //                                   information about our service and it is
    //                                   important that we have an accurate email
    //                                   address.
    //                                 </td>
    //                               </tr>

    //                               <tr
    //                                 style={{
    //                                   fontFamily:
    //                                     "Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                   boxSizing: "border-box",
    //                                   fontSize: "14px",
    //                                   margin: "0px",
    //                                 }}
    //                               >
    //                                 <td
    //                                   className="content-block"
    //                                   itemProp="handler"
    //                                   itemScope
    //                                   itemType="http://schema.org/HttpActionHandler"
    //                                   style={{
    //                                     fontFamily:
    //                                       "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                     boxSizing: "border-box",
    //                                     fontSize: "14px",
    //                                     verticalAlign: "top",
    //                                     margin: "0px",
    //                                     padding: "0 0 20px",
    //                                   }}
    //                                   valign="top"
    //                                 >
    //                                   {/* <Link
    //                                     to="#"
    //                                     color="primary"
    //                                     itemProp="url"
    //                                     style={{
    //                                       fontFamily:
    //                                         "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                       boxSizing: "border-box",
    //                                       fontSize: "14px",
    //                                       color: "#FFF",
    //                                       textDecoration: "none",
    //                                       lineHeight: "2em",
    //                                       fontWeight: "bold",
    //                                       textAlign: "center",
    //                                       cursor: "pointer",
    //                                       display: "inline-block",
    //                                       borderRadius: "5px",
    //                                       textTransform: "capitalize",
    //                                       backgroundColor: "#34c38f",
    //                                       margin: "0",
    //                                       borderColor: "#34c38f",
    //                                       borderStyle: "solid",
    //                                       borderWidth: "8px 16px",
    //                                     }}
    //                                   >
                                        
    //                                   </Link> */}
    //                                   <a href="#" 
    //                                   onClick={(e)=>verifyEmail(e.target.value)}
    //                                   style={{
    //                                       fontFamily:
    //                                         "'Helvetica Neue',Helvetica,Arial,sans-serif",
    //                                       fontSize: "14px",
    //                                       lineHeight: "2em",
    //                                       fontWeight: "bold",
    //                                       textAlign: "center",
    //                                       cursor: "pointer",
    //                                       display: "inline-block",
    //                                       borderRadius: "5px",
    //                                       textTransform: "capitalize",
    //                                       margin: "0",
                                         
    //                                     }} className="btn c1-primary c1-primary-inline-btn btn-block">Confirm email address</a>
    //                                 </td>
    //                               </tr>

    //                               <tr
    //                                 style={{
    //                                   fontFamily:
    //                                     "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                                   boxSizing: "border-box",
    //                                   fontSize: "14px",
    //                                   margin: "0",
    //                                 }}
    //                               >
    //                                 <td
    //                                   className="content-block"
    //                                   style={{
    //                                     fontFamily:
    //                                       "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                                     boxSizing: "border-box",
    //                                     fontSize: "14px",
    //                                     verticalAlign: "top",
    //                                     margin: "0",
    //                                     padding: "0 0 20px",
    //                                   }}
    //                                   valign="top"
    //                                 >
    //                                   <b>Finvest Wealth Group</b>
    //                                   <p>Support Team</p>
    //                                 </td>
    //                               </tr>

    //                               <tr
    //                                 style={{
    //                                   fontFamily:
    //                                     "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                                   boxSizing: "border-box",
    //                                   fontSize: "14px",
    //                                   margin: "0",
    //                                 }}
    //                               >
    //                                 <td
    //                                   className="content-block"
    //                                   style={{
    //                                     textAlign: "center",
    //                                     fontFamily:
    //                                       "Helvetica Neue,Helvetica,Arial,sans-serif",
    //                                     boxSizing: "border-box",
    //                                     fontSize: "14px",
    //                                     verticalAlign: "top",
    //                                     margin: "0",
    //                                     padding: "0",
    //                                   }}
    //                                   valign="top"
    //                                 >
    //                                   © {new Date().getFullYear()} Finvest
    //                                   Wealth Group
    //                                 </td>
    //                               </tr>
    //                             </tbody>
    //                           </table>
    //                         </td>
    //                       </tr>
    //                     </tbody>
    //                   </table>
    //                 </div>
    //               </td>
    //             </tr>
    //           </tbody>
    //         </table>
    //       </Col>
    //     </Row>
    //   </Container>
    // </React.Fragment>
  )
}

export default emailAccountVerification
