import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useParams } from "react-router-dom"
import  { Redirect } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  FormGroup,
  Button,
} from "reactstrap"
import Select from "react-select"
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"



//Import Images
import axios from "axios"

export const UserProfile = () => {
  const optionGroup = [
    { label: "A", value: "A" },
    { label: "P", value: "P" },
  ]
  const ficaStatus = [
    { label: "N", value: "N" },
    { label: "P", value: "P" },
    { label: "A", value: "A" },
  ]
  const ficaOption = [
    { label: "N", value: "N" },
    { label: "B", value: "B" },
    { label: "F", value: "F" },
  ]
  const paymentOption = [
    { label: "N", value: "N" },
    { label: "P", value: "P" },
    { label: "A", value: "A" },
  ]
  const vipOption = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]

  const [userProfile, setUserProfile] = useState({
    img: "",
    name: "",
    designation: "",
    phone: "",
    email: "",
    location: "",
  })

  const [userInfoProfile, setUserInfoProfile] = useState([])

  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedFicaGroup, setselectedFicaGroup] = useState(null)
  const [selectedFicaOptionGroup, setselectedFicaOptionGroup] = useState(null)
  const [selectedu_Status, setSelectedu_Status] = useState('')
  const [selectedFicaStatus, setSelectedFicaStatus] = useState('')
  const [selectedFicaOption, setSelectedFicaOption] = useState('')
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('')
  const [userUserid, setUserUserid] = useState('')
  const [userMainId, setUserMainId] = useState('')

  const [vipStatus, setVIPStatus] = useState('')
  

  const [idupload, setIDUpload] = React.useState('')
const [poaupload, setPOAUpload] = React.useState('')
const [cipcupload, setCIPCupload] = React.useState('')

  

  let { id } = useParams()

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_BASE_URL + `/find-one-user-by-id`, {
        id: id,
      })
      .then(res => {

        console.log('user data received ', res.data)
        setUserProfile(res.data.user)
        setUserInfoProfile(res.data.userinfo)
        let status = res.data.user.u_status
        let ficastatus = res.data.user.fica_status
        let ficaoption = res.data.user.fica_option
        let paymentstatus = res.data.user.payment_status
        setSelectedu_Status(status)
        setSelectedFicaStatus(ficastatus)
        setSelectedFicaOption(ficaoption)
        setUserMainId(res.data.user.id)
        setSelectedPaymentStatus(paymentstatus)

        let vip = res.data.user.vip
        setVIPStatus(vip)

        // Part of images

        setIDUpload(res.data.userinfo.idupload)
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
        if (res.data.userinfo.poaupload && !res.data.userinfo.poaupload.includes('.png') && !res.data.userinfo.poaupload.includes('.jpeg') && !res.data.userinfo.poaupload.includes('.jpg')) {
   
          console.log('it is not an image ')
        }else{
          console.log("it is a image")
        }

        setPOAUpload(res.data.userinfo.poaupload)
        console.log('check this result1 ', isFileImage(res.data.userinfo.idupload))
        console.log('check this result2 ', isFileImage(res.data.userinfo.poaupload))
        console.log('check this result3 ', isFileImage(res.data.userinfo.cipcupload))

        if(res.data.userinfo.cipcupload !== '')
        {
          setCIPCupload(res.data.userinfo.cipcupload)
        }

        function isFileImage(file) {
          const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
       
          return file && acceptedImageTypes.includes(file['type'])
      }

//         const  fileType = file['type'];
// const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
// if (!validImageTypes.includes(fileType)) {

        // ================================

        console.log(ficastatus);
        console.log(ficaoption);

        setselectedGroup({ label: status, value: status })
        setselectedFicaGroup({ label: ficastatus, value: ficastatus })
        setselectedFicaOptionGroup({ label: ficaoption, value: ficaoption })
        setUserUserid(res.data.user.userid)
        // setselectedPaymentOptionGroup({ label: paymentstatus, value: paymentstatus })
      })
  }, [])

  const handleSubmit = () => {

        const updatedInfoObj = {
          status: selectedu_Status,
          ficastatus: selectedFicaStatus,
          ficaoption: selectedFicaOption,
          userid: userUserid,
          paymentStatus: selectedPaymentStatus,
          vip: vipStatus
        }

        axios.post(process.env.REACT_APP_BASE + "api/update-status",updatedInfoObj).then(async res =>{
        
          if(res.data.status == 200)
          {
            // toastr.options.onHidden = function() { window.location.href="/user-list" } 
              toastr.success(res.data.message) 
          }
          else
              toastr.error(res.data.message) 
              return false
        });

  }


  // const handleSubmit = () => {
  //   axios
  //     .post(process.env.REACT_APP_API_BASE_URL + `/update-status`, {
  //       status: selectedGroup,
  //       ficastatus: selectedFicaGroup,
  //       ficaoption: selectedFicaOptionGroup,
  //       userId: userProfile.userid,
  //     })
  //     .then(res => {
  //       console.log(res.data)
  //       return <Redirect to='/user-list'  />
  //     })
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Profile</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="User List" breadcrumbItem="Profile" />
          <Row>
            <Col xl="10">
              <Card>
                <CardBody>
                  <div className="mb-2">
                    <span>
                      <a href="/user-list" className="btn badge bg-dark badge-pill mb-1 font-size-16" style={{margin: "0 5px"}}>All Users</a>
                      <a href={"/admin-user-vault-info/" + userMainId} className="btn badge bg-dark badge-pill mb-1 font-size-16" style={{margin: "0 5px"}}>Products</a>
                    </span>
                  </div>
                
                  <CardTitle className="mb-4">User Information</CardTitle>

                  <p className="text-muted mb-4">
                    {userProfile.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <FormGroup className="mb-3">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{userProfile.name + ' ' + userProfile.lastname}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{userProfile.cell}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{userProfile.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">ID / Passport Number :</th>
                            <td>{userInfoProfile.id_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">DOB :</th>
                            <td>{userInfoProfile.dob}</td>
                          </tr>
                          <tr>
                            <th scope="row">Location :</th>
                            <td>{userInfoProfile.address}</td>
                          </tr>
                          <tr>
                            <th scope="row">Country :</th>
                            <td>{userInfoProfile.country}</td>
                          </tr>
                          <tr>
                            <th scope="row">Source Of Funds :</th>
                            <td>{userInfoProfile.sourceFunds}</td>
                          </tr>
                          <tr>
                            
                            <th scope="row">Uploads</th>
                       
                            <p>ID Upload:</p>
                            
                            {idupload && !idupload.includes('.png') && !idupload.includes('.jpeg') && !idupload.includes('.jpg')?
                          <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+idupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                         :
                         <Zoom><img src={process.env.REACT_APP_BASE + 'assets/'+idupload} className="w-25 img-thumbnail"/></Zoom>
                          } 
                        
                         <p>Proof Of Address Upload:</p>
                         {poaupload && !poaupload.includes('.png') && !poaupload.includes('.jpeg') && !poaupload.includes('.jpg')?
                          <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+poaupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                         :
                          <Zoom><img src={process.env.REACT_APP_BASE + 'assets/'+poaupload} className="w-25 img-thumbnail"/></Zoom>
                          }

                          {userProfile.usertype == '1' || userProfile.usertype == 1?
                          <div>
                          <p>CIPC:</p>
                         {cipcupload && !cipcupload.includes('.png') && !cipcupload.includes('.jpeg') && !cipcupload.includes('.jpg')?
                          <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                         :
                          <Zoom><img src={process.env.REACT_APP_BASE + 'assets/'+cipcupload} className="w-25 img-thumbnail"/></Zoom>
                          }
                          </div>
                          : ''}
                          </tr>
                          <tr>
                            <th scope="row">Status :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                  {/* <select
                                    className="form-control"
                                    value={selectedGroup?.value}
                                    onChange={e => {
                                      const value = optionGroup.find(
                                        o => o.value === e.target.value
                                      )
                                      setselectedGroup(value)
                                    }}
                                  >
                                    {optionGroup.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select> */}

                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setSelectedu_Status(e.target.value)}
                                        value={selectedu_Status}
                                        // value={newCurrency}
                                    >
                                      {optionGroup.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>

                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">KYC Status :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                  {/* <select
                                    className="form-control"
                                    value={selectedGroup?.value}
                                    onChange={e => {
                                      const value = ficaStatus.find(
                                        o => o.value === e.target.value
                                      )
                                      setselectedFicaGroup(value)
                                    }}
                                  >
                                    {ficaStatus.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select> */}

                                  <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setSelectedFicaStatus(e.target.value)}
                                        value={selectedFicaStatus}
                                        // value={newCurrency}
                                    >
                                      {ficaStatus.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">KYC Option :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                  {/* <select
                                    className="form-control"
                                    value={selectedGroup?.value}
                                    onChange={e => {
                                      const value = ficaOption.find(
                                     
                                        o => o.value === e.target.value
                                      )
                                      setselectedFicaOptionGroup(value)
                                    }}
                                  >
                                    {ficaOption.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select> */}

                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setSelectedFicaOption(e.target.value)}
                                        value={selectedFicaOption}
                                        // value={newCurrency}
                                    >
                                      {ficaOption.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>


                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Payment Status :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                  {/* <select
                                    className="form-control"
                                    value={selectedGroup?.value}
                                    onChange={e => {
                                      const value = ficaOption.find(
                                     
                                        o => o.value === e.target.value
                                      )
                                      setselectedFicaOptionGroup(value)
                                    }}
                                  >
                                    {ficaOption.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select> */}

                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setSelectedPaymentStatus(e.target.value)}
                                        value={selectedPaymentStatus}
                                        // value={newCurrency}
                                    >
                                      {paymentOption.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>


                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">VIP :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                  {/* <select
                                    className="form-control"
                                    value={selectedGroup?.value}
                                    onChange={e => {
                                      const value = ficaOption.find(
                                     
                                        o => o.value === e.target.value
                                      )
                                      setselectedFicaOptionGroup(value)
                                    }}
                                  >
                                    {ficaOption.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select> */}

                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setVIPStatus(e.target.value)}
                                        value={vipStatus}
                                        // value={newCurrency}
                                    >
                                      {vipOption.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>


                                </div>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </Table>
                      
                      <Button
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                        className="btn c1-primary btn-block "
                      >
                        Submit form
                      </Button>
                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
