import React, { Component } from 'react'
import PersonalDetails from './PersonalDetails'
import InfoIntro from './info-intro'
import PinfoStage1 from './pinfo-stage1'
import PinfoStage2 from './pinfo-stage2'
import Confirmation from './Confirmation'
// import PropTypes from "prop-types"

export class Signup extends Component {
    


    //We now need to create the state
    state = {
        step: 1,
        usertype: '',
        email: '',
        name: '',
        last: '',
        company_name: '',
        cipc: '',
        cell: '',
        compliance: 'false',
        id_num: '',
        password: '',
    }

    //We now need to create our methods that will be used to take us to the new pages
    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    // proceed to the next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    nextStepCompany = () => {
      const { step } = this.state;
      this.setState({ step: step + 2 });
    }

    goToBeginning = () => {
      const { step } = this.state;
      this.setState({ step: 1 });
    }

    

    // handle field change
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    }

  render() {

    const { step } = this.state;
    const { usertype, email, name, last, cell, terms, compliance, id_num, company_name, cipc, password } = this.state;
    const values = { usertype, email, name, last, cell, terms, compliance, company_name, cipc, id_num, password }


    switch (step) {
        case 1:
          return (
            <InfoIntro 
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            values={ values }
            />
          )
        case 2: 
          return (
            <PinfoStage1 
                prevStep={ this.prevStep }
                nextStep={ this.nextStep }
                handleChange={ this.handleChange }
                values={ values }
            />
          )
        case 3: 
          return (
            <Confirmation 
            values={ values }
        />
          )
        // never forget the default case, otherwise VS code would be mad!
        default: 
           // do nothing
      }


    return (
      <div>Signup</div>
    )
  }
}

// Signup.propTypes = {
//     prevStep: PropTypes.any,
//     nextStep: PropTypes.any,
//     handleChange: PropTypes.any,
//     values: PropTypes.any,
//   }

// Signup.propTypes = {

//       prevStep: React.PropTypes.object,
//     nextStep: React.PropTypes.object,
//     handleChange: React.PropTypes.object,
//     values: React.PropTypes.object,
// };

export default Signup;
