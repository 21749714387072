import React, { Component,useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags";
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import { Row, Button, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroup,
  Modal,
  ModalBody
} from "reactstrap";

  import { UserContext } from "../../UserContext";
  
import Dropzone from "react-dropzone"
import termsPDF from "../Authentication/Terms.pdf";
import popiPDF from "../../assets/attachments/C1_POPI_AGREEMENT.pdf"
import { Link } from "react-router-dom"

const LoginTermsModal = ({ modalView, onCloseClick, onDoneModalTermsClick }) => {
    const [termsa, setTerms] = React.useState(false);
    const [popi, setPOPI] = React.useState(false);
    const { auth, user } = React.useContext(UserContext)
    const [userUserid, setuserUserid] = React.useState('');

    React.useEffect(async res =>{

      if(typeof user !== 'undefined' && user !== null && auth)
      {
        setuserUserid(user.user.userid); 
        console.log("we have set the user ", user)
      }
    
    },[auth])

   
    function canContinue(e){
        if(!termsa || !popi)
          {
            toastr.error("Please agree to the terms and conditions and POPI"); 
            return false;
          }
          const userObject =
          {
            userid: userUserid,
          }

          console.log(userObject);
     

          axios.post(process.env.REACT_APP_DATABASEURL + "api/update-login-modal-terms", userObject).then(async res =>{
            console.log('accepted terms and popi', res.data)
            if(res.data.status === 200)
            {
              toastr.success("Successfully updated!"); 
              onDoneModalTermsClick()
              window.location.href = "/dashboard" 
            }else{
              toastr.error("Unable to update information"); 
            }
          })
      };
      
      function toggle(value){
        console.log('npo value ', !value)
        setTerms(!value)
       
        return !value;
      }
      
      function popiToggle(value){
        setPOPI(!value)
        console.log(!value)
        return !value;
      }

    return (
        <div>
       
        <Modal isOpen={modalView}  centered={true}>
          <ModalBody className="py-3 px-5" style={{ backgroundColor: "lightgray" }}>
    
            <div>
    
                <div className="p-1">
                    <Col className='col-12'>
                        <h2 className="text-center">Terms & Conditions</h2>
                    </Col>
    
                    <Form className="form-horizontal fvw-login-form">
                    <div className="mb-3">
                                    <div className="row">
                                      <div className="col-md-10">
                                        <Label className="loginLabel">
                                          By Logging in you agree to the C1 Holdings{" "}
                                          <a  rel="noreferrer"  href={termsPDF} target="_blank" className="c1-primary-no-fill"> Terms 
                                          </a>
                                        </Label>
                                      </div>
                                      <div className="col-md-2">                               
                                        <Input
                                            style={{width: "15px"}}
                                            name="terms"
                                            autoComplete="new-password"
                                            className="form-control fwv-input-control mt-1"
                                            placeholder="Terms"
                                            type="checkbox"
                                            checked={termsa}
                                            onClick={() => toggle(termsa)}
                                            // onChange={() => setTerms(toggle)}
                                            // onChange={setTerms}
                                            // value={terms}
                                          
                                          />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <div className="row">
                                      <div className="col-md-10">
                                        <Label className="loginLabel">
                                          POPI{" "}
                                          <a  rel="noreferrer"  href={popiPDF} target="_blank" className="c1-primary-no-fill">
                                            Agreement
                                          </a>
                                        </Label>
                                      </div>
                                      <div className="col-md-2">                               
                                        <Input
                                            style={{width: "15px"}}
                                            name="terms"
                                            autoComplete="new-password"
                                            className="form-control fwv-input-control mt-1"
                                            placeholder="POPI"
                                            type="checkbox"
                                            checked={popi}
                                            onClick={() => popiToggle(popi)}
                                          />
                                      </div>
                                    </div>
                                  </div>
                                  <Col className='col-6 text-right' style={{textAlign: "right"}}>
                                <Button
                      
                                variant="contained"
                                className="btn c1-primary c1-primary-inline-btn btn-block"
                                // onClick={canContinue}
                                onClick={(e)=>canContinue(e.target.value)}
                                >Continue<span><i className="bx bxs-right-arrow" style={{float: "right",
                                marginTop: "5px"}}></i></span></Button>
                              </Col>
                    </Form>
                </div>
            </div>
          </ModalBody>
        </Modal>    
        </div>
      )
}
LoginTermsModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDoneModalTermsClick: PropTypes.any,
    modalView: PropTypes.any,
}

export default LoginTermsModal