import React from "react"
import { Container, Row, Col } from "reactstrap"
import loginlogo from "assets/images/backgroundomc.jpeg";
import termsPDF from "../../assets/attachments/Terms.pdf";
import risk from "../../assets/attachments/Risk.pdf";
import privacy from "../../assets/attachments/Privacy.pdf";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" >
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © C1 Holdings PTY LTD.</Col>
            <Col md={6}>
              <p className="mb-0 text-muted">
                <a style={{ padding: "0 10px", color: "#74788d"}} rel="noreferrer"  href={termsPDF} target="_blank"><strong>Terms and Conditions</strong></a>
                <a style={{ padding: "0 10px", color: "#74788d"}} className="pl-2 pr-2" rel="noreferrer"  href={risk} target="_blank"><strong>Risk Disclosure</strong></a>
                <a style={{ padding: "0 10px", color: "#74788d"}} className="pl-2 pr-2" rel="noreferrer"  href={privacy} target="_blank"><strong>Privacy Policy</strong></a>
              </p>
            </Col>
            {/* <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Themesbrand
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
