import React, { Component, useState, useEffect } from "react"
import axios from "axios"
import MetaTags from "react-meta-tags"


import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  CardTitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ProductOverviewCard from "components/ProductOverview/ProductOverviewCard"
import { useParams } from "react-router-dom"
import CapitalReturnChart from "components/Charts/CapitalReturnChart"
import PlanOverviewChart from "components/Charts/PlanOverviewChart"
import ProductTableCard from "components/ProductTable/ProductTableCard"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const DigitalAsset = () => {
  let { userId, productId } = useParams()
  const [viewAllProductSales, setAllProductSales] = React.useState([])
  const [userBalance, setUserBalance] = React.useState("")
  const [checkedTransactions, setCheckedTransactions] = React.useState(false)
  const [userOpenProductTotal, setProductOpenTotal] = React.useState(0)
  const [userClosedProductTotal, setProductClosedTotal] = React.useState(0)
  const [userProfile, setUserProfile] = useState({
    img: "",
    name: "User",
    designation: "",
    phone: "076465464",
    email: "email",
    location: "location",
  })

  
  useEffect(async res => {
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + `/find-one-user-by-id`, {
        id: userId,
      })
      .then(async res => {
        console.log("profile user ", res.data)
        setUserProfile(res.data.user)
        await axios
          .post(process.env.REACT_APP_API_BASE_URL + "/view-product-sale", {
            userid: res.data.user.userid,
            productid: productId,
          })
          .then(async res2 => {
            if (res2.data.status == 200) {
              setAllProductSales(res2.data.sales)
            }
            setCheckedTransactions(true)
            await axios
              .post(
                process.env.REACT_APP_API_BASE_URL + "/productid-open-total",
                {
                  userid: res.data.user.userid,
                  productid: productId,
                }
              )
              .then(async res3 => {
                setProductOpenTotal(res3.data.total)

                await axios
                  .post(
                    process.env.REACT_APP_API_BASE_URL +
                      "/productid-closed-total",
                    {
                      userid: res.data.user.userid,
                      productid: productId,
                    }
                  )
                  .then(async res4 => {
                    return setProductClosedTotal(res4.data.total)
                  })
              })
          })
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 Holdings - Digital Asset Moderatee</title>
        </MetaTags>
      </div>

      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs title="Crypto" breadcrumbItem="Digital Asset Moderate" />

        <ProductOverviewCard
          key={userProfile.id}
          user={userProfile}
          productId={productId}
        />

        <Row>
          <Col lg={6}>
            <PlanOverviewChart
              openProducts={userOpenProductTotal}
              closedProducts={userClosedProductTotal}
            />
          </Col>
          

          <Col lg={6}>
            <CapitalReturnChart viewAllProductSales={viewAllProductSales} />
          </Col>
        </Row>
        <ProductTableCard viewAllProductSales={viewAllProductSales} />
      </Container>
    </React.Fragment>
  )
}
