import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import bgimage from "assets/images/c1_favicon.png";

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const finvestotc =()=>{
    const [viewAllTransactions, setAllTransactions] = React.useState([]);

    const{auth,user} = React.useContext(UserContext)
    const[StartpackList,setStartpackList] = React.useState([])

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])




const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

    if(user !== 'undefined' && user !== null)
    {
        await axios.get("https://finvest-backend.herokuapp.com/api/pending-deposits").then((res)=>{
            console.log('all deposits ', res.data)
            setAllTransactions(res.data.deposits)
        });
    }

},[auth])

 const approveDeposit = (depositId) => {

    const updateDepObj = {
        transactionId: depositId,
        status: '1'
    }
    
    axios.post("https://finvest-backend.herokuapp.com/api/update-deposit", updateDepObj).then((res)=>{
        console.log('updated deposits ', res.data)
        if(res.data.status == 200)
        {
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.success(res.data.message); 
        }else{
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.warning("Unable to update deposit"); 
        }
    });
 }

 const declineDeposit = (depositId) => {
 

    const updateDepObj = {
        transactionId: depositId,
        status: '2'
    }

    axios.post("https://finvest-backend.herokuapp.com/api/update-deposit", updateDepObj).then((res)=>{
        console.log('updated deposits ', res.data)
        if(res.data.status == 200)
        {
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.success(res.data.message); 
        }else{
            toastr.options.onHidden = function() { window.location.href="/pending-deposits" } 
            toastr.danger("Unable to update deposit"); 
        }
    });

 }

 

    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - OTC Coming Soon</title>
        </MetaTags>
        <Container fluid >
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="OTC Coming Soon" />

          <Row>

            <Col xl="12">
              <Card>
                <CardBody 
                // style={{
                //     // backgroundImage: `url("https://static.wixstatic.com/media/72d069_9528afcc52fb4d2f8cd2c561c457ba11~mv2.jpg/v1/fill/w_1241,h_635,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/72d069_9528afcc52fb4d2f8cd2c561c457ba11~mv2.jpg")` ,
                //     backgroundImage: `url(${bgimage})`,
                //     height: "80vh",
                //     margin: "0 0",
                //     backgroundPosition: "center",
                //     backgroundRepeat: "no-repeat",
                //     backgroundSize: "cover",
                //     boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 50%)"
                //     }}
                    >
                  {/* <h4 className="card-title mb-4">Coming Soon</h4> */}
                    <img src={bgimage} alt="Coming Soon" className="w-100" />
            
                </CardBody>
              </Card>
            </Col>
        </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}