import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import axios from "axios"

//i18n
import { withTranslation } from "react-i18next";
import { UserContext } from "UserContext";
const SidebarContent = props => {
  const {user,setUser,auth} = React.useContext(UserContext)
  const[role,setRole] = React.useState(0)

  const [userStatus, setUserStatus] = React.useState('');
  const [ficaStatus, setFicaStatus] = React.useState('');
  const [mainParent, setUserMainParent] = React.useState(0);
  const [adminThirdParty, setAdminThirdParty] = React.useState(false)
  const [userUserid, setUserUserid] = React.useState('');
  const [userReferral, setUserReferral] = React.useState('');
  

  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByClassName("a");
  
      for (let i = 0; i < items.length; ++i) {

        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(async ()=>{

    if(user!=null ){

      if(!(Object.keys(user).length === 0 && user.constructor === Object)){
    setRole(user.user.urole)
    setUserStatus(user.user.u_status)
    setFicaStatus(user.user.fica_status)
    setUserMainParent(user.user.mainParent)
    setUserUserid(user.user.userid)
    setUserReferral(user.user.referral)
    var isAdminThridParty = {
      userid: user.user.userid,
      company_id: user.user.mainParent
    }

    await axios.post(process.env.REACT_APP_BASE + "api/is-user-part-of-thirdparties",isAdminThridParty).then(async res =>{
      if(res.data.status == 200)
      {
        setAdminThirdParty(true)
      }
    });
  }
  }
  },[auth])


  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
 
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100 leftsidebarCenter"  ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
          {(role == 'USR' || role == 'USD' || role == 'ADM')?<>
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li className="p-1 finvestwealth-tab-primary-fill-round nav-primary-pill-container">

              {userStatus == 'A' || ficaStatus == 'P' ?
              <Link to="/#" className="nav-primary-pill">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
              : '' }
              {/* <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard">{props.t("Default")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-saas">{props.t("Saas")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-crypto">{props.t("Crypto")}</Link>
                </li>
                <li>
                  <Link to="/blog">{props.t("Blog")}</Link>
                </li>
              </ul> */}
            </li>

            {userStatus == 'A' || ficaStatus == 'P' ?
            <li className="p-1 nav-primary-pill-container">
              <Link to="/my-account" className="nav-primary-pill">
                <i className="bx bx-user"></i>
                <span>{props.t("My Account")}</span>
              </Link>
            </li>
            : ''}

            {/* <li className="menu-title">{props.t("Apps")}</li> */}

            {userStatus == 'A' ?
            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-wallet"></i>
                <span>{props.t("Assets")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="nav-primary-pill-container">
                  <Link className="anotherLink" to="/wallet-overview">{props.t("My Wallet")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link className="anotherLink" to="/my-usdt-wallet?type=deposit">{props.t("Deposit")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link className="anotherLink" to="/my-usdt-wallet?type=withdraw">{props.t("Withdraw")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link className="anotherLink" to="/wallet-exchange">{props.t("Exchange")}</Link>
                </li> 
                {/* <li className="nav-primary-pill-container">
                  <Link className="anotherLink" to="/otc">{props.t("OTC")}</Link>
                </li> */}
              </ul>
            </li>
            : ''}


            {userStatus == 'A' ?
            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-wallet"></i>
                <span>{props.t("Reporting")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="nav-primary-pill-container">
                  <Link className="anotherLink" to="/my-reports">{props.t("My Reports")}</Link>
                </li>
              </ul>
            </li>
            : ''}


            {/* <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-bar-chart"></i>
                <span>{props.t("Digital Asset Income")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="nav-primary-pill-container">
                  <Link  to="/digital-moderate">{props.t("Moderate Fund")}</Link>
                </li >
                
              </ul>
            </li> */}

            {userStatus == 'A' ?
            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-bar-chart"></i>
                <span>{props.t("Products")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="nav-primary-pill-container">
                  <Link  to="/buy-products">
                    {/* <i className="bx bx-trending-up"></i> */}
                <span>{props.t("Fee Based Vault")}</span></Link>
                </li >

                {userReferral === 'C10000001' ?

                <li className="">
                  <Link to="/#" className="has-arrow nav-primary-pill">
                    <span>{props.t("Savings Pocket")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                    <li className="nav-primary-pill-container">
                      <Link  to="/savings-pocket-6">
                        <span>{props.t("6 Months")}</span>
                      </Link>
                    </li >
                    <li className="nav-primary-pill-container">
                      <Link  to="/savings-pocket-12">
                        <span>{props.t("12 Months")}</span>
                      </Link>
                    </li >
                  </ul>

                </li>
                : ''}

                {/* <li className="">
                  <Link to="/#" className="has-arrow nav-primary-pill">
                    <span>{props.t("Vault Index Fund")}</span>
                  </Link>

                  <ul className="sub-menu" aria-expanded="false">
                 
                    <li className="nav-primary-pill-container">
                      <Link  to="/vif-6">
                        <span>{props.t("6 Months")}</span>
                      </Link>
                    </li >
                    <li className="nav-primary-pill-container">
                      <Link  to="/vif-12">
                        <span>{props.t("12 Months")}</span>
                      </Link>
                    </li >
                  </ul>

                </li> */}
                
              </ul>
            </li>
            : ''}

            {userStatus == 'A' ?
            // <li className="p-1 nav-primary-pill-container">
            //   <Link to="/my-referrals" className="nav-primary-pill">
            //     <i className="bx bx-user"></i>
            //     <span>{props.t("My Referrals")}</span>
            //   </Link>
            // </li>
            mainParent == 1 && adminThirdParty ? 
            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-store"></i>
                <span>{props.t("Referrals")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="p-1 nav-primary-pill-container">
                  <Link to="/my-referrals" className="nav-primary-pill">
                    <i className="bx bx-user"></i>
                    <span>{props.t("My Referrals")}</span>
                  </Link>
                </li>
                <li className="p-1 nav-primary-pill-container">
                  <Link to="/referral-payments" className="nav-primary-pill">
                    <i className="bx bx-user"></i>
                    <span>{props.t("Referral Payments")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            : ''
            : ''}


            {userStatus == 'A' && userUserid == 'C10000002' ?
            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-store"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                  <li className="p-1 nav-primary-pill-container">
                  <Link to="/b2bproductsales" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Product Sales")}</span>
                  </Link>
                </li>
                <li className="p-1 nav-primary-pill-container">
                  <Link to="/b2bproductinterest" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("ProductInterest")}</span>
                  </Link>
                </li>
                <li className="p-1 nav-primary-pill-container">
                  <Link to="/b2balldeposits" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("All Deposits")}</span>
                  </Link>
                </li>
                
              </ul>
            </li>
            : ''}


            </>:''}

          {(role == 'ADM')?<>

          {userStatus == 'A' && userUserid == 'C10000001' ?
            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-wallet"></i>
                <span>{props.t("Report")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="nav-primary-pill-container">
                  <Link className="anotherLink" to="/user-balances">{props.t("User Report")}</Link>
                </li>
              </ul>
            </li>
            : ''}

            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("User Info")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li className="nav-primary-pill-container">
                  <Link to="/user-list">{props.t("Users")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link to="pending-members">{props.t("Pending MemberInfo")}</Link>
                </li>

              </ul>
            </li>

            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-store"></i>
                <span>{props.t("Transactions")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="nav-primary-pill-container">
                  <Link to="/pending-memberships">{props.t("Pending Memberships")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link to="/pending-deposits">{props.t("Pending Deposits")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link to="/pending-withdrawals">{props.t("Pending Withdrawals")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link to="/all-transactions">{props.t("All Transactions")}</Link>
                </li>
              </ul>
            </li>

            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-store"></i>
                <span>{props.t("Product Interests")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li className="nav-primary-pill-container">
                  <Link to="/new-product-interest">{props.t("Add Product Interest")}</Link>
                </li>
                <li className="nav-primary-pill-container">
                  <Link to="#">{props.t("All Interests")}</Link>
                </li>

              </ul>
            </li>

            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-store"></i>
                <span>{props.t("Exchanges")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li className="nav-primary-pill-container">
                  <Link to="/allexchanges">{props.t("All Exchanges")}</Link>
                </li>
              </ul>
            </li>

            <li className="p-1 ">
              <Link to="/#" className="has-arrow nav-primary-pill">
                <i className="bx bx-store"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                  <li className="p-1 nav-primary-pill-container">
                  <Link to="/product-sales" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Product Sales")}</span>
                  </Link>
                </li>
                <li className="p-1 nav-primary-pill-container">
                  <Link to="/product-interest" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("ProductInterest")}</span>
                  </Link>
                </li>
                <li className="p-1 nav-primary-pill-container">
                  <Link to="/deposits" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("All Deposits")}</span>
                  </Link>
                </li>

                <li className="p-1 nav-primary-pill-container">
                  <Link to="/custom-reports" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Custom Reports")}</span>
                  </Link>
                </li>

                {/* <li className="p-1 nav-primary-pill-container">
                  <Link to="/custom-reports" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Custom Reports")}</span>
                  </Link>
                </li>

                <li className="p-1 nav-primary-pill-container">
                  <Link to="/custom-reports" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Summary Proceeds Report")}</span>
                  </Link>
                </li>

                <li className="p-1 nav-primary-pill-container">
                  <Link to="/custom-reports" className="nav-primary-pill">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("B2B Reports")}</span>
                  </Link>
                </li> */}
                
              </ul>
            </li>


          </>:''}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
