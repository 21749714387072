import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import { UserContext } from "../../UserContext";

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import Swal from 'sweetalert2'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"
import axios from "axios"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const allexchanges2 =()=>{

  const{auth,user} = React.useContext(UserContext)
  const[StartpackList,setStartpackList] = React.useState([])


  React.useEffect(async ()=>{
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    if(auth!=null)
    {
     if(!auth) {
      Swal.fire({
        title: 'Error!',
        text: 'Your session has expired, click below to start a new session ',
        icon: 'error',
        confirmButtonText: 'Login',
        confirmButtonColor: '#29a927',
        allowOutsideClick:false
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.href = "/login" 
        }
      })
     
     }else{
      await axios.get(process.env.REACT_APP_API_BASE_URL+`/all-exchanges`).then(res => {
        console.log("all exchanges ", res.data)
        setAllUserInfo(res.data.users)
        setExchanges(res.data.exchanges)
      })
     }
    }
   },[auth])

   
   const [viewAllUserInfo, setAllUserInfo] = React.useState([]);
  const [exchanges, setExchanges] = useState([])
  const [contact, setContact] = useState()

   
  const setTypeText = type => {
    if(type == '2')
    {
      return "From ZAR"
    }else{
      return "From USDT(TRC20)"
    }
  }

  const setTypeText2 = type => {
    if(type == '2')
    {
      return "To ZAR"
    }else{
      return "To USDT(TRC20)"
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "menu",
      text: "Username",
      sort: true,
      formatter: (cellContent, exchange) => (
        <div className="d-flex gap-3">
          {viewAllUserInfo[exchange.userid]}
        </div>
      ),
    },
    {
      dataField: "userid",
      text: "User ID",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "amount_from",
      text: "From",
      sort: true,
    },
    {
      dataField: "amount_to",
      text: "To",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, exchange) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to={`/handle-exchange/${exchange.id}`}>
            <i
              className="bx bx-edit bx-sm mt-1 font-size-18"
              id="edittooltip"
              onClick={() => handletableclick(exchange.id)}
            ></i>
          </Link>

        </div>
      ),
      // eslint-disable-next-line react/display-name

    },
  ]

  const handleUserClick = arg => {
    const exchange = arg

    setContact({
      id: exchange.id,
      name: exchange.name,
      status: exchange.status,
    })
  }

  function handletableclick(data) {
    return "/pathToRoute=" + data;
}

  const redirectToUserProducts = state => {
    console.log("clicked to products")
  }

  const datatableInfo = {
    title: "Exchange List",
    shortDescription: "List of all exchanges",
    longDescription: `1 = USDT(TRC20)\n , 2 = ZAR, 3 = BTC`,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Exchanges</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={exchanges}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}