import PropTypes from "prop-types"
import React from "react"
import { useEffect } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import 'bootstrap/dist/css/bootstrap.min.css';

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { UserContext } from "UserContext"
import axiosApp from "./axiosConfig"

// Activating fake backend
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.                      ,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  const [user, setUser] = React.useState(null)
  var [auth, setAuth] = React.useState(null)
  var [company, setCompany] = React.useState('5')
  const isAuthenticated = () => {

    var isAdmin = true
    //  (`https://dashboardapi.c1holdings.com/api//test`
    axiosApp
      .get(`${process.env.REACT_APP_API_BASE_URL}/verifyToken/${isAdmin}`, { withCredentials: true })
      .then(async response => {
 
        setUser({ ...response.data.user })


        if(auth)
        {
          console.log('user data issss ', response.data.user.user.mainParent)
          setCompany(response.data.user.user.mainParent)
        }

        setAuth(response.data.auth)

   
      })
  }
  const getUserCompany = async () => {


}
  useEffect(() => {
    isAuthenticated()

 
  }, [])
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <UserContext.Provider
          value={{ company, setCompany, user, setUser, auth, setAuth, isAuthenticated }}
        >
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={auth}
                exact
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={auth}
                exact
              />
            ))}{" "}
          </Switch>
        </UserContext.Provider>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
