import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"

import { Link } from "react-router-dom"

import Dropzone from "react-dropzone"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import Accordion from "react-bootstrap/Accordion";


import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

// Redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { editProfile, resetProfileFlag } from "../../store/actions";

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import ReactSpeedometer from "react-d3-speedometer"


// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const myAccount =()=>{
  // export class myAccount extends Component {

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [idNumber, setIDNumber] = React.useState('');
  const [cellNumber, setCellNumber] = React.useState('');
  const [newAddress, setNewAddress] = React.useState('');
  const [useridUpload, setuseridUpload] = useState([])
  const [userpoaupload, setuserpoaupload] = useState([])
  const [dob, setDOB] = React.useState('');
  const [requireMoreInfo, setRequireMoreInfo] = React.useState(false);
  const [selectedFiles, setselectedFiles] = useState([])
  const [userStatus, setUserStatus] = React.useState('');
  
  

  


  const [userBalance, setuserBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [currentPercentage, setCurrentPercentage] = React.useState('');
  const [foundProducts, setFoundProducts] = React.useState(false);
  const [getProductid, setCurrentProductId] = React.useState('');
  const [newUserName, setNewUsername] = React.useState('');
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [userInfo, setNewUserInfo] = React.useState([]);


  const [totalQScore, setTotalQScore] = React.useState(0);
 
  

  const [newVerificationCode, setNewVerificationCode] = React.useState('');
  

  const [qrurl, setqrurl] = React.useState('');
  const [authid, setauthid] = React.useState('');
  const [verifyScreenDisplay, setVerifyScreenDisplay] = React.useState(false);
  
  const [AuthAlreadySet, setAuthAlreadySet] = React.useState(false);


  const [isReferrer, setIsReferrer] = React.useState(false);
  const [referrer, setReferrer] = React.useState('');
  const [sponsorCode, setSponsorCode] = React.useState('');
  const [currentKYCStatus, setCurrentKYCStatus] = React.useState('');
  const [mainParent, setUserMainParent] = React.useState(0);
  
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])





    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
        setUserMainParent(user.user.mainParent)
        if(user.user.fica_status == "N")
        {
          setCurrentKYCStatus("Require KYC")
        }else if(user.user.fica_status == "P")
        {
          setCurrentKYCStatus("Pending")
        }else if(user.user.fica_status == "A")
        {
          setCurrentKYCStatus("Active")
        }

       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])







const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    setNewUsername(user.user.name)
    setNewUserInfo(user.user)
    setSponsorCode(user.user.sponsor_code)
    getAuthQR()


    if(user.user.authid && user.user.authid != null)
    {

      setAuthAlreadySet(true)

    }

      // Lets get userinfo from userinfo
      const userInfoObj = {
        email: user.user.email
      }
      axios.post(process.env.REACT_APP_BASE + "api/get-userinfo",userInfoObj).then(async res =>{
        
        if(res.data.status == 200)
        {

          setuserName(res.data.user.name);
          setuserEmail(res.data.user.email);
          setIDNumber(res.data.user.id_num);
          setCellNumber(res.data.user.cell_no);
          setNewAddress(res.data.user.address);
          setUserStatus(res.data.user.u_status);
   
          
          setDOB(res.data.user.dob);

          

          if(res.data.user.id_num != undefined && res.data.user.cell_no != undefined && res.data.user.address != undefined)
          {
         
            setRequireMoreInfo(true)
          }

        }
        
      });
    
      
    
  }

},[auth])


const dispatch = useDispatch();
const [email, setemail] = useState("");
const [name, setname] = useState("");
const [idx, setidx] = useState(1);


const { error, success } = useSelector(state => ({
  error: state.Profile.error,
  success: state.Profile.success,
}));

useEffect(() => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      setname(obj.displayName);
      setemail(obj.email);
      setidx(obj.uid);
    } else if (
      process.env.REACT_APP_DEFAULTAUTH === "fake" ||
      process.env.REACT_APP_DEFAULTAUTH === "jwt"
    ) {
      setname(obj.username);
      setemail(obj.email);
      setidx(obj.uid);
    }
    setTimeout(() => {
      dispatch(resetProfileFlag());
    }, 3000);
  }
}, [dispatch, success]);

const validation = useFormik({
  // enableReinitialize : use this flag when initial values needs to be changed
  enableReinitialize: true,

  initialValues: {
    username: name || '',
    idx : idx || '',
  },
  validationSchema: Yup.object({
    username: Yup.string().required("Please Enter Your UserName"),
  }),
  onSubmit: (values) => {
    dispatch(editProfile(values));
  }
});


// const [userName, setuserName] = React.useState('');
// const [userEmail, setuserEmail] = React.useState('');
// const [userUserid, setuserUserid] = React.useState('');
// const [idNumber, setIDNumber] = React.useState('');
// const [cellNumber, setCellNumber] = React.useState('');
// const [newAddress, setNewAddress] = React.useState('');
// const [useridUpload, setuseridUpload] = useState([])
// const [userpoaupload, setuserpoaupload] = useState([])
// const [dob, setDOB] = React.useState('');
function setNewUserUpdate(name) {

   
    if(user !== 'undefined' && user !== null && newUserName != '')
    {

    var formD = new FormData();
    formD.append('userid',user.user.userid)
    formD.append('email',user.user.email)
    formD.append('newusername',newUserName)
    formD.append('dob',dob)
    formD.append('idNumber',idNumber)
    formD.append('cellNumber', cellNumber)
    formD.append('newAddress',newAddress)
    formD.append("idupload",useridUpload[0])
    formD.append("poaupload",userpoaupload[0])


       
          axios.post(process.env.REACT_APP_BASE + "api/update-username",formD).then(async res =>{
        
            if(res.data.status == 200)
            {
                setuserName(newUserName);
                setRequireMoreInfo(true)
                toastr.success(res.data.message) 
            }
            else
                toastr.error(res.data.message) 
          });
    }else{

        toastr.error("Please ensure that the user name is not blank!") 
    }

}



function setNewPasswordUpate(name) {

   
    if(user !== 'undefined' && user !== null && currentPassword != '' && newPassword != '')
    {
        const investObject = {
            userid: user.user.userid,
            currentPassword: currentPassword,
            newPassword: newPassword
          }
     
          axios.post(process.env.REACT_APP_BASE + "api/update-password",investObject).then(async res =>{
        
            if(res.data.status == 200)
            {
                setCurrentPassword("");
                setNewPassword("");
                toastr.success(res.data.message) 
            }
            else
                toastr.error(res.data.message) 
          });
    }else{

        toastr.error("Please ensure that the new password is not blank!") 
    }

}

function getAuthQR() {

        axios.post(process.env.REACT_APP_BASE + "api/generate-auth").then(async res =>{
      
          if(res.data.status == 200)
          {
         
              // QRCode.toDataURL(res.data.tempUrl, function (err, data_url) {
         
                setqrurl(res.data.tempUrl)
                setauthid(res.data.id)
            // });
          
          }
          else
              toastr.error(res.data.message) 
        });


}

function setVerifyScreen() {
  setVerifyScreenDisplay(true);
}

function VerifyNow() {

  const verifyObject = {
    "userid": authid,
    "token": newVerificationCode
}


  axios.post(process.env.REACT_APP_BASE + "api/verify-auth", verifyObject).then(async res =>{
      
    if(res.data.verified == true)
    {
   
      

        var updateUserObj = {
          userid: user.user.userid,
          authid: authid,
          secret: res.data.secret.base32
        }

        // Now lets update the user authid
        axios.post(process.env.REACT_APP_BASE + "api/update-user-auth", updateUserObj).then(async res =>{
          setAuthAlreadySet(true)
          toastr.success("Successfully Verified") 
        });
    
    }
    else
        toastr.error("Unable to verify!") 
  });
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}


function handleAcceptedFiles(files) {

  files.map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size)
    })
  )
  setselectedFiles(files)
  setuseridUpload(files)
  
}

function handleAcceptedPOAFiles(files) {

  files.map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size)
    })
  )

  setuserpoaupload(files)
  
}

function deactivate2FA(){


  const deactivateObject = {
    "userid": userUserid
}


  axios.post(process.env.REACT_APP_BASE + "api/deactivateauth", deactivateObject).then(async res =>{

    if(res.data.status == 200)
    {
      toastr.options.onHidden = function() { window.location.href="/my-account" } 
      toastr.success("Successfully Deactivated 2FA Authentication") 
  
    }
    else
        toastr.error("Unable to Deactivate, Please try again later!") 
  });
}




    return(
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>C1 Holdings - My Account</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Edit Profile" breadcrumbItem="My Account" />
            
            <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>

                  <div className="row">
                    <div className="col-5 border-end">
                      <h2>{userName}</h2>
                      <p>Joined: <span className="text-muted">{userInfo.createdAt}</span></p>
                      <p>Email: <span className="text-muted">{userEmail}</span></p>
                      <p>Userid: <span className="text-muted">#{userUserid}</span></p>
                      <p>KYC Status: {currentKYCStatus} </p>
                    </div>
                    <div className="col-7 padding-left-sm">
                      <h2>KYC Information</h2>
                      <p>Date Of Birth: <span className="text-muted">{dob}</span></p>
                      <p>ID: <span className="text-muted">{idNumber}</span></p>
                      <p>Address: <span className="text-muted">{newAddress}</span></p>
                      <p>Cell: <span className="text-muted">{cellNumber}</span></p>
                      {requireMoreInfo? 
                      <div>
                      <a href="#" className="btn c1-primary c1-primary-inline-btn btn-block">Uploaded ID</a>
                      <a href="#" className="btn c1-primary c1-primary-inline-btn btn-block ml-2">Uploaded POA</a>
                      </div>
                      : ''}
                      </div>
                  </div>


                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ======================================================================================================== */}

            <Row>

              <Col xl="12">

                <Card>
                <CardBody>
                  <h4 className="card-title mb-4">User Information</h4>
                  <div className="crypto-buy-sell-nav">
                    <Nav tabs className="nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          Settings
                        </NavLink>
                      </NavItem>
                      {!requireMoreInfo?<NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          Personal Information
                        </NavLink>
                      </NavItem>:''}
                      
                    </Nav>

                    <TabContent
                      activeTab={activeTab}
                      className="crypto-buy-sell-nav-content p-4"
                    >
                      <TabPane tabId="1" id="buy">
                        {/* <form> */}
                          <div className="mb-2">

                            <Row>
                                <Col xl="12">
                                    <Row>   

                                      <div className="col-12">
                                        <Label>Change Password:</Label>
                                        <div className="form-group">
                                          {/* <Label className="form-label">Current Password</Label> */}
                                          <Input
                                              name="currentpassword"
                                              // value={name}
                                              className="form-control"
                                              placeholder="Enter Current Password"
                                              type="password"
                                              onChange={(e)=>setCurrentPassword(e.target.value)}
                                          />

                                          {/* <Label className="form-label">New Password</Label> */}
                                          <Input
                                              name="newpassword"
                                              // value={name}
                                              className="form-control"
                                              placeholder="Enter New Password"
                                              type="password"
                                              onChange={(e)=>setNewPassword(e.target.value)} 
                                          />
                                        </div>

                                        <div className="text-center mt-4">
                                            {/* <Button
                                                onClick={(e)=>setNewPasswordUpate(e.target.value)}
                                                type="button"
                                                color="success"
                                                className="w-md bg-danger"
                                            >
                                                Update Password
                                            </Button> */}
                                            <a onClick={(e)=>setNewPasswordUpate(e.target.value)} className="btn c1-primary c1-primary-inline-btn btn-block">Update Password</a>
                                        </div>

                                      </div>

                                      <hr style={{marginTop: "15px"}} />

                                    <Label>Settings:</Label>
                                      {/* 2FA Verification */}
                                      {/* <Accordion defaultActiveKey="0">

                                        <a href="../../assets/attachments/Terms.pdf">Terms</a>
                                      
                                        <h3 className="panel-title">2FA Authentication</h3>

                                          {!AuthAlreadySet?

                                          <div className="myaccount-info-wrapper">
                                            <div className="account-info-wrapper">
                                              <img src={qrurl} />
                                            </div>
                                            <div className="row">
                                              <div className="col-lg-12 col-md-12">
                                                <div className="billing-info">
                                                  <div className="billing-back-btn">
                                                    <div className="billing-btn">
                                                      <a onClick={()=>setVerifyScreen()} className="btn c1-primary c1-primary-inline-btn btn-block">Continue</a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>:
                                          <div className="billing-btn">
                                            <h5>Enabled</h5>
                                          
                                            <a onClick={()=>deactivate2FA()} className="btn c1-primary c1-primary-inline-btn btn-block">Disable 2FA</a>
                                          </div>
                                          }

                    
                                        {(verifyScreenDisplay)?
                                        <div className="mt-2">
                                              <h5 className="panel-title"><span>2 .</span> Verify Authentication</h5>

                                              <div className="myaccount-info-wrapper">
                                                <div className="account-info-wrapper">
                                                  <h5>Please enter verification code</h5>
                                                  <Input
                                                    name="newverifylink"
                                                  
                                                    className="form-control"
                                                    placeholder="Verification Code"
                                                    type="password"
                                                    onChange={(e)=>setNewVerificationCode(e.target.value)} 
                                                  />

                                                  <div className="billing-back-btn">
                                                    <div className="billing-btn">
                                                      <a onClick={()=>VerifyNow()} className="btn c1-primary c1-primary-inline-btn btn-block">Verify</a>
                                                    </div>
                                                  </div>

                                                </div> 
                                              </div>
                                        </div>:''}
                                      </Accordion> */}

                                    </Row>
                                </Col>
                            </Row>

                          </div>

                     
                      </TabPane>

                      {/* <TabPane tabId="2">
                  
                          <div className="mb-2">
                            <Label>Personal Information:</Label>

                            <Row>    
                              <h4 className="card-title">Personal Details</h4>
                              <div className="mt-4 mb-3">
                                  <div className="form-group mb-3">
                                      <Label className="form-label">User Name</Label>
                                      <Input
                                          name="username"
                                          className="form-control"
                                          placeholder="Enter User Name"
                                          type="text"
                                          onChange={(e)=>setNewUsername(e.target.value)} value={newUserName}
                                      />

                                  </div>

                                  <div className="form-group mb-3">
                                      <Label className="form-label">Date Of Birth</Label>
                                      <Input
                                          name="dob"
                                          className="form-control"
                                          placeholder="Enter Date Of Birth"
                                          type="date"
                                          value={dob}
                                          onChange={(e) => setDOB(e.target.value)}
                                         
                                      />

                                  </div>

                                  <div className="form-group mb-3">
                                      <Label className="form-label">ID / Passport Number</Label>
                                      <Input
                                          name="idnumber"
                                          className="form-control"
                                          placeholder="Enter ID / Passport Number"
                                          type="text"
                                          onChange={(e)=>setIDNumber(e.target.value)} value={idNumber}
                                      />

                                  </div>

                                  <div className="form-group mb-3">
                                      <Label className="form-label">Cell Number</Label>
                                      <Input
                                          name="cellNumber"
                                          className="form-control"
                                          placeholder="Enter Cell Number"
                                          type="text"
                                          onChange={(e)=>setCellNumber(e.target.value)} value={cellNumber}
                                      />

                                  </div>

                                  <div className="form-group mb-3">
                                      <Label className="form-label">Address</Label>
                                      <Input
                                          name="address"
                                          className="form-control"
                                          placeholder="Enter Address"
                                          type="text"
                                          onChange={(e)=>setNewAddress(e.target.value)} value={newAddress}
                                      />

                                  </div>

                                  <div className="form-group mb-3">

                                    <Label className="form-label">Upload ID</Label>
                                    <Dropzone
                                      onDrop={acceptedFiles => {
                                        handleAcceptedFiles(acceptedFiles)
                                      }}>
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} type="file" name="userUpload" />
                                              <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </Dropzone>
                                  </div>
                                  <div className="dropzone-previews mt-3" id="file-previews">
                                  {useridUpload.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    )
                                  })}
                                </div>

                                  <div className="form-group mb-3">
                                    <Label className="form-label">Upload Proof Of Address</Label>
                                    <Dropzone
                                      onDrop={acceptedFiles => {
                                        handleAcceptedPOAFiles(acceptedFiles)
                                      }}>
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} type="file" name="userUpload" />
                                              <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </Dropzone>
                                  </div>

                                    

                                  <div className="text-center mt-4">
                                    <a
                                        onClick={(e)=>setNewUserUpdate(e.target.value)}
                                        type="button"
                                        color="success"
                                        className="btn c1-primary c1-primary-inline-btn btn-block"
                                      >
                                        Update User Details
                                      </a>
                                  </div>

                   
                              </div>
                            </Row>


                          </div>
          
                      </TabPane> */}

                    </TabContent>
                  </div>
                </CardBody>
                </Card>

              </Col>

            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
}