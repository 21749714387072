import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { Card, CardBody, CardTitle } from "reactstrap"
import axios from "axios"

import { Bar } from "react-chartjs-2"
import ReactApexChart from "react-apexcharts"

const PlanOverviewChart = ({openProducts, closedProducts}) => {
 
  const seriesDonut = [openProducts, closedProducts]
  const optionsDonut = {
    labels: ["Open Plan", "Closed Plan"],
    colors: ["#34c38f", "#556ee6"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Plan Overview</CardTitle>

          <ReactApexChart
            options={optionsDonut}
            series={seriesDonut}
            type="donut"
            height="380"
            className="apex-charts"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

PlanOverviewChart.propTypes = {
    openProducts: PropTypes.number,
    closedProducts: PropTypes.number,
}

export default PlanOverviewChart
