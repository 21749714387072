import React, { Component,useEffect, useState } from 'react'
import MetaTags from "react-meta-tags";
import axios from 'axios';
// import PropTypes from "prop-types"

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'


import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  Button,
  NavItem,
  NavLink,
  InputGroup,
  Spinner,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { UserContext } from "../../../UserContext";

// action


//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images


import PropTypes from "prop-types"
import classnames from "classnames"



// Add datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// const UserDetails = ({ nextStep, handleChange, values}) => {
    // export class UserDetails extends Component {
        // function UserDetails({nextStep, handleChange, values}) {
        const DepositConfirmationView = ({ depositAprovedResult, depositDeclinedResult, walletOption, continueModal }) => {

            const{auth,user} = React.useContext(UserContext)

            console.log('depositAprovedResult is' , depositAprovedResult)
            console.log('the depositDeclinedResult result is' , depositDeclinedResult)
        
   
    // render() {
        const [isMenu, setisMenu] = useState(false)
        const [activeTab, setActiveTab] = useState("1")

        const [userBalance, setuserBalance] = React.useState('');

        useEffect(async res =>{
            if(user !== 'undefined' && user !== null)
            {
            //   getuserBalance(user.user.userid) 
            }
          
          
          })
          

        
      
      
        const dispatch = useDispatch();
      

    return (
      <React.Fragment>
        <div>

            <Col className='col-12'>
                <Col md="12"  style={{marginTop: '15px', marginBottom: '10px'}}>
                    {depositAprovedResult ?
                        <div className="my-c1-success-card">
                            <h2 className="c1-card-title">Successfull!</h2>
                        </div>
                    : 

                    walletOption['product'] == '15' ?
                    
                        (parseFloat(1000) > parseFloat(walletOption['amount'])) ?
                            <div className="my-c1-error-card">
                                <h2 className="c1-card-title">Amount Less Than $1000!</h2>
                            </div>
                            : depositDeclinedResult ?
                                <div className="my-c1-error-card">
                                    <h2 className="c1-card-title">Insufficient Funds1!</h2>
                                </div>
                                :
                                <div className="m-auto d-flex align-items-center justify-content-center">
                                    <Spinner className="ms-2 text-center" color="dark" />
                                </div>

                    : 
                        walletOption['currency'] == 'zar' ?
                            (parseFloat(1000) > parseFloat(walletOption['amount'])) ?
                            <div className="my-c1-error-card">
                                <h2 className="c1-card-title">Amount Less Than R1000!</h2>
                            </div>
                            : depositDeclinedResult ?
                                <div className="my-c1-error-card">
                                    <h2 className="c1-card-title">Insufficient Funds!</h2>
                                </div>
                                :
                                <div className="m-auto d-flex align-items-center justify-content-center">
                                    <Spinner className="ms-2 text-center" color="dark" />
                                </div>
                        :
                            (parseFloat(65) > parseFloat(walletOption['amount'])) ?
                                <div className="my-c1-error-card">
                                    <h2 className="c1-card-title">Amount Less Than $65!</h2>
                                </div>
                                : depositDeclinedResult ?
                                    <div className="my-c1-error-card">
                                        <h2 className="c1-card-title">Insufficient Funds!</h2>
                                    </div>
                                    :
                                    <div className="m-auto d-flex align-items-center justify-content-center">
                                        <Spinner className="ms-2 text-center" color="dark" />
                                    </div>

                    }
                </Col>
            </Col>
        </div>
      </React.Fragment>
    );
//   }
}


DepositConfirmationView.propTypes = {
    depositAprovedResult: PropTypes.any,
    depositDeclinedResult: PropTypes.any,
    continueModal: PropTypes.func,
    walletOption: PropTypes.any,
  }


export default DepositConfirmationView;