import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Spinner,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import io from "socket.io-client"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { UserContext } from "../../../UserContext";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { CSVLink, CSVDownload } from "react-csv";
import Datatable from "components/Datatable/datatable.component"
import axios from "axios"
import {UserProfile} from "pages/Admin/UserProfilePage/UserProfile"

export const B2BProductSales = () => {
  const [sales, setAllSales] = useState([])
  const [viewAllProductSales, setAllProductSales] = React.useState([]);

  const{auth,user} = React.useContext(UserContext)
  const [displaySpinner, setDisplaySpinner] = useState(false)
  // const columns = [
  //   {
  //     dataField: "userid",
  //     text: "UserID",
  //     sort: true,
  //   },
  //   {
  //     dataField: "planid",
  //     text: "PlanID",
  //     sort: true,
  //   },
  //   {
  //     dataField: "transaction_id",
  //     text: "TransactionID",
  //     sort: true,
  //   },
  //   {
  //     dataField: "invest_date",
  //     text: "InvestDate",
  //     sort: true,
  //   },
  //   {
  //     dataField: "start_date",
  //     text: "StartDate",
  //     sort: true,
  //   },
  //   {
  //     dataField: "expire_date",
  //     text: "ExpireDate",
  //     sort: true,
  //   },
  //   {
  //       dataField: "amount",
  //       text: "Amount",
  //       sort: true,
  //     },
  //     {
  //       dataField: "status",
  //       text: "Status",
  //       sort: true,
  //     },
  //     {
  //       dataField: "product_id",
  //       text: "ProductID",
  //       sort: true,
  //     },
  // ]

  React.useEffect(async res =>{
    setDisplaySpinner(true);

    if(user !== 'undefined' && user !== null)
    {
        // await axios.get(process.env.REACT_APP_BASE + "api/find-all-productsales").then((res)=>{
        //     console.log('all productsales ', res.data)
        //     console.log('all productsales array sales ', res.data.sales)
        //     setAllSales(res.data.sales)
        // });

        var transactionObject = {
          'productid': '15',
          admin: 'C10000002'
        }

                // Get All ProductSale
                await axios.post(process.env.REACT_APP_BASE + "api/b2b-view-product-sale-all",transactionObject).then( res2 =>{
                  console.log("sales is ", res2.data)
                  if(res2.data.status == 200)
                  {
                    setAllProductSales(res2.data.productInterests)     
                    setDisplaySpinner(false);
                  }
                          
              })
    }

},[auth])

const socket = io(process.env.REACT_APP_BASE, {});
window.socket = socket;
React.useEffect(() =>{


  return () => {
    console.log('removed listener');
    socket.off('get productsales', getProductSales)
  }
}, [])

const getProductSales = (data) => {
  console.log('getting this one ', data)

};

  const datatableInfo = {
    title: "Product Sales View",
    shortDescription: "List of product sales",
    longDescription: "This shows a list of all product sales",
  }




  const columns = [{
    dataField: 'userid',
    text: 'Userid',
    sort: true,
  },{
    dataField: 'planid',
    text: 'PlanID',
    sort: true,
  },{
    dataField: 'transaction_id',
    text: 'TransId',
    sort: true,
  },{
    dataField: 'amount',
    text: 'Amount'
  }, {
    dataField: 'contributionDate',
    text: 'Inv Date'
  },{
    dataField: 'start_date',
    text: 'Start Date'
  },{
    dataField: 'expire_date',
    text: 'End Date'
  },{
    dataField: 'currency',
    text: 'Currency'
  },{
    dataField: 'status',
    text: 'Status'
  },{
    dataField: 'month1',
    text: 'M1'
  },{
    dataField: 'month2',
    text: 'M2'
  },{
    dataField: 'month3',
    text: 'M3'
  }];

  // Table Data
const productData = [];

var x = 0;

var labelData = [];
var barData = [];
if(typeof viewAllProductSales !== 'undefined' && viewAllProductSales.length > 0) {
viewAllProductSales.forEach(productsale => {
    var numid = x.toString();
//=================  Donut chart ===========================================================
    var transtatus = '---';
    if(productsale.status == '1')
    {
     transtatus = 'Open'
    }else if(productsale.status == '2'){
     transtatus = 'Closed'
    }else{
     transtatus = 'Closed'
    }

    var month1 = '$0'
    var month2 = '$0'
    var month3 = '$0'

    var pers1 = '0'
    var pers2 = '0'
    var pers3 = '0'
    
  
    // productsale.interests[0].forEach(interestPlan => {

    // console.log(productsale.interests[0], productsale.interests[0])
     
    if(typeof productsale.interests[0][productsale.planid + '_1'] !== 'undefined')
    {
      month1 = '$' + productsale.interests[0][productsale.planid + '_1']['amount']
      pers1 = '$' + productsale.interests[0][productsale.planid + '_1']['persentage'] + '%'
    }
      
    
    if(typeof productsale.interests[0][productsale.planid + '_2'] !== 'undefined')
    {
      month2 = '$' + productsale.interests[0][productsale.planid + '_2']['amount']
      pers2 = '$' + productsale.interests[0][productsale.planid + '_2']['persentage'] + '%'
    }

    if(typeof productsale.interests[0][productsale.planid + '_3'] !== 'undefined')
    {
      month3 = '$' + productsale.interests[0][productsale.planid + '_3']['amount']
      pers3 = productsale.interests[0][productsale.planid + '_3']['persentage'] + '%'
    }
    // }
 
     var productDataObj = {
        userid: productsale.userid,
        planid: productsale.planid,
        transaction_id: productsale.transaction_id,
        amount: productsale.amount,
        contributionDate: productsale.contributionDate,
        start_date: productsale.start_date,
        expire_date: productsale.expire_date,
        currency: productsale.currency,
        status: transtatus,
         month1: month1,
         month2: month2,
         month3: month3,
         pers1: pers1,
         pers2: pers2,
         pers3: pers3,
     };

     productData.push(productDataObj)

     x = x+1;
 });
}

const onGenerateCSVFileClick = async() => {
  console.log('added listener');
  socket.on('get productsales', getProductSales);
  socket.emit('get productsales', {});
}

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - ProductSales</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                    {displaySpinner ? 
                          <Spinner className="ms-2" color="success" />
                          :
                        <div>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={productData}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                      {/* <a onClick={()=>onGenerateCSVFileClick()} className="btn c1-primary c1-primary-inline-btn btn-block text-center">Download me</a> */}

                      <CSVLink data={productData} filename={"product-sales.csv"} className="btn btn-primary" target="_blank">Download me</CSVLink>
                      </div>
                        }
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
// ProductSales.propTypes = {
//     viewAllProductSales: PropTypes.array,
// }

export default B2BProductSales