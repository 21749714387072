import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { Badge, Card, CardBody, Col, UncontrolledTooltip } from "reactstrap"
import images from "assets/images"
import companies from "assets/images/companies"
//import bannerImage from "../../assets/images/digial-asset-income-high.png"
import "./ProductCard.styles.scss"

const ProductCard = ({ user, product }) => {
  return (
    <React.Fragment>
      <Col xl="4" sm="6" key={product.id}>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="avatar-md me-5">
                <img src={product.img} alt="" height="100" />
              </div>

              <div className="flex-grow-2" style={{ paddingLeft: "10%" }}>
                <h5 className="text-truncate font-size-15">
                  <Link
                    to={`/products-overview/${product.id}`}
                    className="text-dark"
                  >
                    {product.name}
                  </Link>
                </h5>
                <p className="text-muted mb-4">{product.description}</p>

                <div className="flex-grow-2">
                  <p className="text-muted mb-2">Wallet Value</p>
                  <h5 className="mb-0">$ {product.walletValue}</h5>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-2">Product Plans</p>
                  <h5 className="mb-0">$</h5>
                </div>
              </div>
            </div>
          </CardBody>
          <div className={`px-4 py-3 border-top ${"bg-" + product.color}`}>
            <ul className="list-inline mb-0">
              <Link
                to={`/dashboard-digital-asset/${user.id}/${product.id}`}
                className="text-dark"
              >
                <li className="list-inline-item me-3">More information</li>
              </Link>
            </ul>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  )
}

ProductCard.propTypes = {
  user: PropTypes.object,
  product: PropTypes.object,
}

export default ProductCard
