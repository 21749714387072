import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import BTCImage from "../../assets/images/qr2btc.jpeg"
import USDTImage from "../../assets/images/qr1.jpeg"

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import Dropzone from "react-dropzone"

import MiniWidget from "../Dashboard-crypto/mini-widget"
import CurrencyWidget from "../Dashboard-crypto/currency-widget"

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

import { Link } from "react-router-dom"

// icons
// import Icon from "react-crypto-icons";

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'

// KYC Modals
import ThankyouModal from "./ThankyouModal"
import FicaPopupModal1 from "./FicaPopupModal1"

import OTPModal from "./OTPModal"


export const finvestusdtwallet =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [depositTXID, setDepositTXID] = React.useState('');
  
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);
  const [withdrawalAddress, setWithdrawalAddress] = React.useState('');

  const [displaySpinner, setDisplaySpinner] = useState(false)
  


  const [cryptoOptions, setCryptoOptions] = useState([])
  const [currencyOptons, setCurrencyOptons] = useState([])
  const [allCurrencies, setAllCurrencies] = useState([])
  const [qType, setQType] = useState('deposit')
  const [typeClicked, setTypeClicked] = useState(false)
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])


    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])

     React.useEffect(async res =>{

      if(user !== 'undefined' && user !== null)
      {
        const queryParams = new URLSearchParams(window.location.search);
        console.log("on this change ", queryParams.get('type'))
        if(queryParams.get('type'))
        {
          var getQType = queryParams.get('type');
          console.log('inside if ', getQType)
          if(getQType == 'withdraw')
          {
            console.log('withdraw selected')
            setQType('withdraw');
            setActiveTab('2')
          }else{
            console.log('deposit selected')
            setQType('deposit');
            setActiveTab('1')
          }
        }else{
          console.log('deposit selected')
          setQType('deposit');
          setActiveTab('1')
        }
        
        if(user.user.fica_status == 'N')
        {
          $("[data-rmiz-wrap]").css("justify-content","center");
          $("[data-rmiz-overlay]").css("text-align","center");
          Swal.fire({
            title: "KYC Required",
            text: "In order to do your first deposit, KYC (Know Your Client) will be required.",
            icon: "info",
            confirmButtonText: "Begin Process",
            confirmButtonColor: "#1b75bb",
            allowOutsideClick: false,
          }).then(result => {
         
            if (result.isConfirmed) {
              // window.location.href = "/my-account"
              startKYCProcess()
            }
          })
        }else if(user.user.fica_status == 'P')
        {
          $("[data-rmiz-wrap]").css("justify-content","center");
          $("[data-rmiz-overlay]").css("text-align","center");
          Swal.fire({
            title: "KYC Pending",
            text: "Our support team is reviewing all KYC documents and you will be notified via Email once this is done.",
            icon: "info",
            confirmButtonText: "Okay",
            confirmButtonColor: "#1b75bb",
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = "/wallet-overview"
            }
          })
        }
        getAllCurrencies()
      }
    },[auth])

    React.useEffect(async res =>{

      if(!typeClicked)
      {

        const queryParams = new URLSearchParams(window.location.search);
        console.log("on this change ", queryParams.get('type'))
        if(queryParams.get('type'))
        {
          var getQType = queryParams.get('type');
          console.log('inside if ', getQType)
          if(getQType == 'withdraw')
          {
            console.log('withdraw selected')
            setQType('withdraw');
            setActiveTab('2')
          }else{
            console.log('deposit selected')
            setQType('deposit');
            setActiveTab('1')
          }
        }else{
          console.log('deposit selected')
          setQType('deposit');
          setActiveTab('1')
        }

      }
  });
    



const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

// KYC Process
const [kycStep, setKYCStep] = useState(0)
const [kycOption, setKYCOption] = React.useState({ 1: 0, idNumber: '',  dob: '', country: '', address: '', sourceFunds: '', useridUpload: [], userpoaupload: [], userficaupload: [], country: '', usertype: ''});
const [modalView, setModalView] = useState(true)

// OTP Process
const [otpStep, setOTPStep] = useState(0)
const [otpOption, setOTPOption] = React.useState({ otp: '', type: ''});
const [otpModalView, setOTPModalView] = useState(false)

const [displayCurrency, setDisplayCurrency] = React.useState({ "1": 'USDT', "2": 'ZAR', "3": "BTC"});



const [getAmount, setAmount] = React.useState('');
const [newOtpOption, setNewOtpOption] = useState('1')
const [newWithdrawalCurrency, setNewWithdrawalCurrency] = useState('1')
const [currencyOptionGroup, setCurrencyOptionGroup] = useState([])

const [addedBankingDetails, setAddedBankingDetails] = useState(false)
const [bankDetails, setBankDetails] = useState('')

const [bankName, setBankName] = useState('')
const [branchCode, setBranchCode] = useState('')
const [accountNumber, setAccountNumber] = useState('')
const [accountType, setAccountType] = useState('')
const [userBankDetailsUpload, setUserBankDetailsUpload] = useState([])
const [selectedFiles, setselectedFiles] = useState([])

const [canUserWithdraw, setCanUserWithdraw] = useState(false)


const toggleTab = tab => {
  setTypeClicked(true)
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}


React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserName(user.user.name);
    setuserEmail(user.user.email);
    setuserUserid(user.user.userid);
    kycOption['usertype'] = user.user.usertype

    const cryptoBalance = {
      userid: user.user.userid,
      crypto: 'true'
    }
  
      await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",cryptoBalance).then(async res =>{
        console.log('crypto options is ', res.data.currencyInfo)
        setCryptoOptions(res.data.currencyInfo)
        res.data.currencyInfo.map((report, key) => (
          allCurrencies.push(report)
        ))
       
      })
    
      const currencyBalance = {
        userid: user.user.userid,
        crypto: 'false'
      }
  
      await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",currencyBalance).then(async res =>{
        setCurrencyOptons(res.data.currencyInfo)
        res.data.currencyInfo.map((report, key) => (
          allCurrencies.push(report)
        ))
      })

      const userBankObj = {
        userid: user.user.userid,
        meta_key: 'banking'
      }
  
      await axios.post(process.env.REACT_APP_BASE + "api/get-meta",userBankObj).then(async res =>{
        if(res.data.status == 200)
        {
          setAddedBankingDetails(true)
          console.log("the bank details ", res.data.body.bankDetails)
          setBankDetails(res.data.body.bankDetails)
        }
      })
  
      resetCurrencies()
      getuserBalance(user.user.userid)

      confirmWithdrawalDate()
    
  }

},[auth])

var reports = cryptoOptions

var currencies = currencyOptons


function resetCurrencies() {
  reports = cryptoOptions
  
  currencies = currencyOptons
  console.log("all currencies data is ", allCurrencies)
}

// KYC Modals
const startKYCProcess = () => {
  kycOption[1] = 1;
  // setKYCProcess(true)
  // if(kycStep == 0)
    setKYCStep(1)
    setModalView(true)
}


const nextStepFica = () => {

    if(kycStep == 1 )
    {
      // Now we need to save the details and also update the RICA status
      const updateUserData = updateUserFicaDetails();
       
    }else{
      setModalView(true)
      setKYCStep(kycStep + 1)
    }
    
}

const prevStepFica = () => {


  window.location.href = "https://dashboard.c1holdings.com/wallet-overview"
// }

}


  function updateUserFicaDetails()
  {
    // 1: 0, idNumber: '', currency: '', amount: '', txid: '',  dob: '', country: '', address: '', sourceFunds: '', useridUpload: [], userpoaupload: [], country: ''

      // Full Fica
      if(kycOption['idNumber'] !== '' && kycOption['dob'] !== '' && kycOption['address'] !== '' && kycOption['country'] !== '' && kycOption['sourceFunds'] !== '' && typeof kycOption['useridUpload'] !== 'undefined' && kycOption['useridUpload'].length !== 0 && typeof kycOption['userpoaupload'] !== 'undefined' && kycOption['userpoaupload'].length !== 0)
      {

       
        // const ficaObj = {
        //   userid: user.user.userid,
        //   idNumber: kycOption['idNumber'],
        //   dob: kycOption['dob'],
        //   address: kycOption['address'],
        //   country: kycOption['country'],
        //   sourceFunds: kycOption['sourceFunds'],
        //   idUpload: kycOption['useridUpload'][0],
        //   poaUpload: kycOption['userpoaupload'][0],
        // }

        var formD = new FormData();
        formD.append('userid',user.user.userid)
        formD.append('country',kycOption['country'])
        formD.append('sourceFunds',kycOption['sourceFunds'])
        formD.append('dob',kycOption['dob'])
        formD.append('idNumber',kycOption['idNumber'])
        formD.append('address',kycOption['address'])
        formD.append("idupload",kycOption['useridUpload'][0])
        formD.append("poaupload",kycOption['userpoaupload'][0])
        if(kycOption['usertype'] == '1' || kycOption['usertype'] == 1)
        {
          if(typeof kycOption['userficaupload'][0] !== 'undefined' && kycOption['userficaupload'].length !== 0)
            formD.append("userficaupload",kycOption['userficaupload'][0])
          else{
            toastr.error("Require CIPC Document!") 
            return false
          }

        }


        axios.post(process.env.REACT_APP_BASE + "api/update-ficadata",formD).then(async res =>{
        
          if(res.data.status == 200)
          {
            // toastr.options.onHidden = function() { window.location.href="/dashboard" } 
              toastr.success(res.data.message) 
              setKYCStep(kycStep + 1)
          }
          else
              toastr.error(res.data.message) 
              return false
        });

      }else{
        toastr.error("Require missing details for full fica!") 
        return false
      }
  }




// End KYC Modals








const getuserBalance = async userid => {
  var balanceObject = {
    'userid': userid, 
    'currency': '1'
  }



  await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceObject).then(async res =>{

   
    return setuserBalance(res.data.total);
 
  }).then(async res1 =>{
    var transactionObject = {
      'userid': userid,
      'currency': '1'
    }
    
  
    if(!checkedTransactions)
    {
        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_BASE + "api/view-user-transaction",transactionObject).then( res2 =>{

          if(res2.data.status == 200)
          {
            setAllTransactions(res2.data.transactions)
          
          }
          setCheckedTransactions(true);
          
        })
    }
  })



}


function setDepositAmount(amount) {

  setDisplaySpinner(true);

  if(user.user.fica_status != 'A')
  {
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    Swal.fire({
      title: "KYC Pending",
      text: "Our support team is reviewing all KYC documents and you will be notified via Email once this is done.",
      icon: "info",
      confirmButtonText: "Okay",
      confirmButtonColor: "#1b75bb",
      allowOutsideClick: false,
    }).then(result => {
      if (result.isConfirmed) {
        window.location.href = "/wallet-overview"
      }
    })

    return false
  }



  const depositObject = {
    userid: user.user.userid,
    txid: depositTXID,
    amount: userDeposit,
    tran_cat: '1',
    currency: newOtpOption,
    type: "Adding Deposit"
  }

  
  axios.post(process.env.REACT_APP_BASE + "api/create-transaction",depositObject).then(async res =>{
    toastr.options.onHidden = function() { window.location.href="/my-usdt-wallet?type=deposit" } 
    setDeposit(0);
    toastr.success("Deposit request submitted successfull!") 

    var transactionObject = {
      userid: user.user.userid,
      'currency': newOtpOption
    }

    // Get the list of all the transactions
    await axios.post(process.env.REACT_APP_BASE + "api/view-user-transaction",transactionObject).then((res)=>{
   
      if(res.data.status == 200)
      {
        setAllTransactions(res.data.transactions)
      }
      
    })
  });


}

function getUserBalanceWithdrawal (id) {
  const currencies = allCurrencies;

  for (const currency of currencies) {
    // currency
    if(currency.id == id)
    {
      console.log('new balance is ', currency.balance)
      return parseFloat(currency.balance);
    }
  }
}


function setWithdrawalAmount(amount) {
  setDisplaySpinner(true);
  // var balanceAmount = 0.00;
  // var newBalanceAmount = await parseFloat(getUserBalanceWithdrawal(newWithdrawalCurrency)).toFixed(2);
  // if(newBalanceAmount <= 0  && finalAmount < newBalanceAmount)
  // {
  //   toastr.error("Insufficient Funds!") 
  //   return false
  // }



  if(user.user.fica_status != 'A')
  {
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    Swal.fire({
      title: "KYC Pending",
      text: "Our support team is reviewing all KYC documents and you will be notified via Email once this is done.",
      icon: "info",
      confirmButtonText: "Okay",
      confirmButtonColor: "#1b75bb",
      allowOutsideClick: false,
    }).then(result => {
      if (result.isConfirmed) {
        window.location.href = "/wallet-overview"
      }
    })

    return false
  }

  var finalAmount = userWithdrawal * -1;

  var newWithdraAddress=  ''

  if(newWithdrawalCurrency == '2')
  {
    newWithdraAddress = bankDetails
  }else{
    newWithdraAddress = withdrawalAddress
  }
  
  const withdrawalObject = {
    userid: user.user.userid,
    amount: finalAmount,
    withdrawal_address: withdrawalAddress,
    tran_cat: '2',
    currency: newWithdrawalCurrency,
    type: "Adding Withdrawal"
  }

  axios.post(process.env.REACT_APP_BASE + "api/create-transaction",withdrawalObject).then(async res =>{

    if(res.data.status == 200)
    {
      toastr.options.onHidden = function() { window.location.href="/my-usdt-wallet?type=withdraw" }
    setWithdrawal(0);

    toastr.success("Withdrawal request submitted successfull!") 

    var transactionObject = {
      userid: user.user.userid,
      currency: newWithdrawalCurrency
    }

        // Get the list of all the transactions
        axios.post(process.env.REACT_APP_BASE + "api/view-user-transaction",transactionObject).then((res)=>{
          
          if(res.data.status == 200)
          {
            setAllTransactions(res.data.transactions)
          }
          
        })

 

      }else{
        toastr.error("Unable to create withdrawal!") 
      }

  });
}

const setWithdrawalAmountBank = async(amount) => {

  setDisplaySpinner(true);

  if(user.user.fica_status != 'A')
  {
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    Swal.fire({
      title: "KYC Pending",
      text: "Our support team is reviewing all KYC documents and you will be notified via Email once this is done.",
      icon: "info",
      confirmButtonText: "Okay",
      confirmButtonColor: "#1b75bb",
      allowOutsideClick: false,
    }).then(result => {
      if (result.isConfirmed) {
        window.location.href = "/wallet-overview"
      }
    })

    return false
  }

  var finalAmount = userWithdrawal * -1;

  var userid = user.user.userid
  console.log("the userid in mention is " + userid)

  // bankName == '' || branchCode == '' || accountNumber == '' || accountType == '' || userBankDetailsUpload.length <= 0 ||  selectedFiles.length <= 0
  
  // Update metadata first
  var metaValueObj = {
    status: 0,
    bankDetails: bankName + ', ' + accountNumber + ', ' + branchCode + ', ' + accountType,
    // uploadedDoc: userBankDetailsUpload[0].filename
  }
  console.log("uploaded doc ", userBankDetailsUpload[0])
  var formUpload = new FormData();
  formUpload.append('userid',userid)
  formUpload.append('meta_key', 'banking')
  formUpload.append('meta_value',JSON.stringify(metaValueObj))
  formUpload.append('uploadeddoc', userBankDetailsUpload[0])
  
  await axios.post(process.env.REACT_APP_BASE + "api/create-meta-upload",formUpload).then(async res =>{
    console.log("getting back is ", res.data)
      if(res.data.status !== 200)
      {
        toastr.error("Unable to create withdrawal at the moment!")
        return false;
      }
  });


  const withdrawalObject = {
    userid: user.user.userid,
    amount: finalAmount,
    withdrawal_address: bankName + ', ' + accountNumber + ', ' + branchCode + ', ' + accountType,
    tran_cat: '2',
    currency: newWithdrawalCurrency,
    type: "Adding Withdrawal"
  }

  axios.post(process.env.REACT_APP_BASE + "api/create-transaction",withdrawalObject).then(async res =>{
      console.log("getting back from the transaction bank is ", res.data)
    if(res.data.status == 200)
    {
      toastr.options.onHidden = function() { window.location.href="/my-usdt-wallet?type=withdraw" }
    setWithdrawal(0);
    toastr.success("Withdrawal request submitted successfull!") 

    var transactionObject = {
      userid: userid,
      currency: newWithdrawalCurrency
    }

        // Get the list of all the transactions
        axios.post(process.env.REACT_APP_BASE + "api/view-user-transaction",transactionObject).then((res)=>{
          
          if(res.data.status == 200)
          {
            setAllTransactions(res.data.transactions)
          }
          
        })

      }else{
        toastr.error("Unable to create withdrawal!") 
      }

  });
}

function handleOtp(){
  
  var otpObj = {
    userid: user.user.userid,
    otp: otpOption['otp']
  }
  
  axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then((res)=>{
          
    if(res.data.status == 200)
    {
      setOTPStep(0)
      if(otpOption['type'] == 'deposit')
        setDepositAmount(userDeposit)
      else
      {
        if(!addedBankingDetails)
        {
          setWithdrawalAmountBank(userWithdrawal)
        }else{
          setWithdrawalAmount(userWithdrawal)
        }
        
      }
        

    }else{
      toastr.error("Incorrect OTP, we have sent a new OTP Pin") 
      axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
          
        
        
      })
    }
    
  })

 
}

function checkOTP(amount){

  if(userDeposit == '' || userDeposit == '0' || userDeposit == 0 || depositTXID == '')
  {
    toastr.error("Please enter amount and transaction details") 
    return false;
  }

  otpOption['type'] = 'deposit'
  var otpObj = {
    userid: user.user.userid
  }
  
  axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
          
    if(res.data.status == 200)
    {
      toastr.success("OTP Pin Successfully Sent!") 
    }else{
      toastr.error("Unable To send OTP!") 
    }
    
  })

  setOTPModalView(true)
 
  setOTPStep(1)

}

function checkOTPWithdraw(amount){

  var newBalanceAmount = getUserBalanceWithdrawal(newWithdrawalCurrency);
  console.log('new balance is ', newBalanceAmount)
  if(parseFloat(userWithdrawal) > parseFloat(newBalanceAmount))
  {
    toastr.error("Insufficient Funds!") 
    return false;
  }

  if(newWithdrawalCurrency == '2')
  {
//     bankName, setBankName] = useState('')
// const [branchCode, setBranchCode] = useState('')
// const [accountNumber, setAccountNumber] = useState('')
// const [accountType, setAccountType] = useState('')
// const [userBankDetailsUpload, setUserBankDetailsUpload] = useState([])
// const [selectedFiles, setselectedFiles] = useState([])
if(!addedBankingDetails)
{
  if(bankName == '' || branchCode == '' || accountNumber == '' || accountType == '' || userBankDetailsUpload.length <= 0 ||  selectedFiles.length <= 0)
  {
    toastr.error("Pleasse ensure that you have filled in all bank details!") 
    return false
  }
}

  }else if(withdrawalAddress == '' || withdrawalAddress == '0' || withdrawalAddress == 0 || userWithdrawal == '')
  {
    toastr.error("Please enter amount and transaction details") 
    return false;
  }

 


  otpOption['type'] = 'withdraw'
  var otpObj = {
    userid: user.user.userid
  }
  
  axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
          
    if(res.data.status == 200)
    {
      toastr.success("OTP Pin Successfully Sent!") 
    }else{
      toastr.error("Unable To send OTP!") 
    }
    
  })

  setOTPModalView(true)

  setOTPStep(1)

}

const confirmWithdrawalDate = () => {
  const now = new Date();
  const dayOfMonth = now.getDate();

  if (dayOfMonth >= 1 && dayOfMonth <= 7) {
    setCanUserWithdraw(true);
    console.log("Can withdraw");
  } else {
    setCanUserWithdraw(false)
    console.log("false withdraw");
  }
}

function handleCloseClick(){
  setOTPStep(0)
}


function handleOTPChange(e) {
    console.log("handle change is ", e.target.value)
    setNewOtpOption(e.target.value);
}

function handleWithdrawalCurrencyChange(e) {
  console.log("handle withdrawal change is ", e.target.value)
  setNewWithdrawalCurrency(e.target.value);
}

function getCurrencyName(currency) {
  var currencyObj=  {
    currency: currency
  }
  axios.post(process.env.REACT_APP_BASE + "api/get-currency",currencyObj).then((res)=>{  
    console.log(res.data.currencyInfo.name)
      return res.data.currencyInfo.name;  
  })

}

function getAllCurrencies() {

  axios.get(process.env.REACT_APP_BASE + "api/get-currencies").then((res)=>{  
    res.data.currencies.map((currency, key) => (
      currencyOptionGroup[currency.id] = currency.name
    ))
  })
  console.log('currency group is ', currencyOptionGroup)

}

function handleAcceptedFiles(files) {
  console.log(files)
  console.log(URL)
  files.map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size)
    })
  )
  setselectedFiles(files)
  setUserBankDetailsUpload(files)
  
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}



    return(
      <React.Fragment>
      {kycStep == 1?
        <FicaPopupModal1 
          nextStep={ kycStep}
          prevStepFica={prevStepFica}
          ContinueNextStep ={ nextStepFica }
          option={ kycOption }
        />
        : kycStep == 2?
          <ThankyouModal 
          prevStepFica={prevStepFica}
          modalView={modalView}
        />
        : ''}
        {otpStep ==1?
          <OTPModal 
          handleOtp={handleOtp}
          otpOption={otpOption}
          closeClick={handleCloseClick}
          />
        : ''}
      <div className="page-content">
        <MetaTags>
          <title>C1 Holdings - My USDT(TRC20) Wallet</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="My USDT(TRC20) Wallet" />

          <Row>
            <Col xl="12" md="12" className="m-2">

              {/* Wallet Information */}
              <Row>
                <MiniWidget reports={reports} />
              {/* </Row>

              <Row> */}
                <CurrencyWidget currencies={currencies} />
              </Row>
           
            {/* <ProfileBalanceWidget userProfile={user?.user} /> */}
            </Col>

          </Row>

          <Row>

            <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Deposit / Withdraw</h4>

                <div className="mt-4 mb-3">
                  <Nav pills className="bg-light rounded" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames("dark-c1-nav",{
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Deposit
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames("dark-c1-nav",{
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Withdraw
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {/* qType */}
                  <TabContent activeTab={activeTab}  className="mt-4">
                    <TabPane tabId="1" id="buy-tab">
                      <h5 className="font-size-14 mb-4">Deposit:</h5>
                      {displaySpinner ? 
                          <Spinner className="ms-2" color="success" />
                          :
                      <div>
                        <div>
                          <Label>Please select deposit currency:</Label>
                          <br />          
                          {allCurrencies.length > 0 ? allCurrencies.map((currency, key) => (
                            <div className="btn-group-horizontal" key={key} style={{display: "inline", margin: "0 2px"}}>
                              <input type="radio" className="btn-check" name={currency.title} checked={newOtpOption === currency.id.toString()}  onClick={(e) => handleOTPChange(e)} value={currency.id} id={currency.title} />
                              <label className="btn c1-button-outline" htmlFor={currency.title}>{currency.title}</label>
                            </div>
                          )): ''}
                          
                          <br />
                          <br />
                          <Label>Add Amount (Please note that we require the TXID for all BTC/USDT TRC20 payments) :</Label>
                          <br />
                          <InputGroup className="mb-3">
                            <Label className="input-group-text">Amount</Label>
                            <Input type="number" onChange={(e)=>setDeposit(e.target.value)} value={userDeposit} className="form-control" />
                          </InputGroup>

                            <InputGroup className="mb-3">
                              <Label className="input-group-text">{newOtpOption == '2' ? 'Payment Reference Used' : 'TXID'} </Label>
                              <Input type="text" onChange={(e)=>setDepositTXID(e.target.value)} value={depositTXID} className="form-control" />
                            </InputGroup>



                          {newOtpOption == '3'? 
                            <div>
                              <p>Cryptocurrency (BTC)</p>
                              <p style={{color:"#424242"}}>Provided on request</p>
                            </div>
                          : newOtpOption == '2' ?
                            <div>
                              <p>Bank Transfer (ZAR - SA Only)</p>
                              <p >
                                Account Name: C1 HOLDINGS (PTY) LTD<br />
                                Account Type: GOLD BUSINESS ACCOUNT<br />
                                Account Number: 62857474747<br />
                                Branch Name: Wellington<br />
                                Bank: FNB<br />
                                Branch Code: 200710<br />
                                SWIFT Address: FIRNZAJJ<br />
                                Ref: Name & Surname<br />
                                Please send proof of payment to info@c1holdings.com</p>
                            </div>
                          : 
                            <div>
                              <p>Cryptocurrency (USDT)</p>
                              <p style={{color:"#424242"}}>Provided on request</p>
                            </div>
                          }

                        </div>

                        <div className="text-center">
                          <button
                            onClick={(e)=>checkOTP(e.target.value)}
                            type="button"
                            color="success"
                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                          >
                            Deposit
                          </button>
                        </div>
                      </div>
}
                    </TabPane>
                    <TabPane tabId="2" id="sell-tab">
                      <h5 className="font-size-14 mb-4">Withdraw</h5>
                      {displaySpinner ? 
                          <Spinner className="ms-2" color="success" />
                          :
                      <div>
                        <div>
                          {!canUserWithdraw ? <div><span className="small text-danger">Unfortunately withdrawal dates is only from the 1st to 7th of each month</span><br /></div> : ''}
                          <Label>Please select withdrawal currency:</Label>
                          <br />          
                          {allCurrencies.length > 0 ? allCurrencies.map((currency, key) => (
                            <div className="btn-group-horizontal" key={key} style={{display: "inline", margin: "0 2px"}}>
                              <input type="radio" className="btn-check" name={'withdraw' + currency.title} checked={newWithdrawalCurrency === currency.id.toString()}  onClick={(e) => handleWithdrawalCurrencyChange(e)} value={currency.id} id={'withdraw' + currency.title} />
                              <label className="btn c1-button-outline" htmlFor={'withdraw' + currency.title}>{currency.title}</label>
                            </div>
                          )): ''}


                          {newWithdrawalCurrency != '2' ?
                          <InputGroup className="mb-3">
                            <Label className="input-group-text">Withdrawal Address </Label>
                            <Input type="text" disabled={!canUserWithdraw ? true : false} onChange={(e)=>setWithdrawalAddress(e.target.value)} value={withdrawalAddress} className="form-control" />
                          </InputGroup>

                          :

                          
                            addedBankingDetails ? 
                              <InputGroup className="mb-3">
                                <Label className="input-group-text">Bank Details:</Label>
                                <Input type="text" disabled value={bankDetails} className="form-control" />
                              </InputGroup>
                            : 
                              <div className="mb-3">

                                  <Label className="input-group-text">Bank Name:</Label>
                                  <Input type="text"  disabled={!canUserWithdraw ? true : false} onChange={(e)=>setBankName(e.target.value)} value={bankName} className="form-control" />

                                  <Label className="input-group-text">Account Number:</Label>
                                  <Input type="text" disabled={!canUserWithdraw ? true : false} onChange={(e)=>setAccountNumber(e.target.value)} value={accountNumber} className="form-control" />

                                  <Label className="input-group-text">Branch Code:</Label>
                                  <Input type="text" disabled={!canUserWithdraw ? true : false} onChange={(e)=>setBranchCode(e.target.value)} value={branchCode} className="form-control" />

                                  <Label className="input-group-text">Account Type:</Label>
                                  <Input type="text" disabled={!canUserWithdraw ? true : false} onChange={(e)=>setAccountType(e.target.value)} value={accountType} className="form-control" />

              

                                <Label className="form-label">Upload Proof of Bank Details</Label>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                {userBankDetailsUpload.map((f, i) => {
                                    return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                            />
                                            </Col>
                                            <Col>
                                            <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                            >
                                                {f.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                            </Col>
                                        </Row>
                                        </div>
                                    </Card>
                                    )
                                })}
                                </div>
                                <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles)
                                }}>
                                    {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                        <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                        >
                                        <input {...getInputProps()} type="file" name="userUpload" />
                                        <div className="dz-message needsclick">
                                            <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4>Drop files here or click to upload.</h4>
                                        </div>
                                        </div>
                                    </div>
                                    )}
                                </Dropzone>
                              </div>
                          
                          }

                          <InputGroup className="mb-3">
                            <Label className="input-group-text">Total Amount</Label>
                            <Input type="number" onChange={(e)=>setWithdrawal(e.target.value)} value={userWithdrawal} className="form-control" />
                          </InputGroup>
                          
                        </div>

                        <div className="text-center">
                          <Button
                          onClick={(e)=>checkOTPWithdraw(e.target.value)}
                            type="button"
                            color="danger"
                            className="w-md btn c1-primary c1-primary-inline-btn btn-block"
                            disabled={!canUserWithdraw ? true : false}
                          >
                            Withdraw
                          </Button>
                        </div>
                      </div>
                      }
                    </TabPane>
                  </TabContent>
                </div>

                  <h4 className="card-title mb-4">Transaction History</h4>

                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>

                        <tr>  
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Amount</th>
                          <th scope="col">TxId</th>
                        </tr>
                      </thead>
                      <tbody>

                     
                        {viewAllTransactions.map((viewAllTransaction, key) => (
                            <tr key={viewAllTransaction.id}>
                              <td>{viewAllTransaction.createdAt}</td>
                              <td>{viewAllTransaction.status == '1' ? 
                                'Approved' : viewAllTransaction.status == '0' ? 'Pending' : 'Declined'}</td>
                              <td>{displayCurrency[viewAllTransaction.currency.toString()]}</td>
                              <td className={viewAllTransaction.amount < 0? 'text-danger': 'text-success'}>{viewAllTransaction.amount}</td>
                              <td className={viewAllTransaction.txid < 0? 'text-danger': 'text-success'}>{viewAllTransaction.txid}</td>
                            </tr>
                        ))}
  
                   

                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}