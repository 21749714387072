import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import axios from "axios"

//redux
import { useSelector } from "react-redux"

import { withRouter, Link, useLocation } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "../../assets/images/c1_favicon.png";
import logo from "assets/images/c1logo.png";
import loginlogo from "assets/images/backgroundomc.jpeg";

import toastr from "toastr"
import "toastr/build/toastr.min.css"

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const ResetPasswordPage = props => {
  let query = useQuery()
  let userToken = query.get("userToken");
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      passwordConfirm: "",
    },

    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your new Password"),
      passwordConfirm: Yup.string()
        .required("Please confirm your password")
        .oneOf([Yup.ref("password"), null], "Passwords don't match."),
    }),

    onSubmit: values => {
      //   API call reset password link
        axios.post(process.env.REACT_APP_API_BASE_URL + `/reset-password`,{password: values.password, userToken: userToken}).then((res)=>{
          console.log(res.data)
          if (res.data.status == 200) {
            toastr.options.onHidden = function () {
              window.location.href = "/login"
            }
            toastr.success(res.data.message)
          } else {
            toastr.error(res.data.message)
          }
        })
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          C1 Holdings - Update Password
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/login" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages" >
        <Container className="pt-sm-5 mw-100 fvw-login-container" 
        // style={{backgroundImage: `url(${loginlogo})`,
        // boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 20%)"
        // }}
        >
         {/* <> {(auth)?window.location.href="/dashboard":''}</> */}

          <Row className="justify-content-center" style={{
            position: "absolute",
            left: "40%",
            top: "34%",
            transform: "translate(-50%, -50%)",
        }}>
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden fwv-card-login" style={{
                // borderRadius: "50%",
                // width: "600px",
                // height: "600px",
                // paddingTop: "50px",
                // background: "rgba(25, 24, 24, 0.32)"
              }}>
                <CardBody className="pt-0">
                  <div className="mb-4">
                    <Link to="/login" className="auth-logo-light">
                      <div className="w-100 profile-user-wid">
                        <span className="avatar-title ">
                          <img
                            src={logo}
                            alt=""
                            className="mt-2 fvw-login-logo"
                            // height="100"
                            // width="180"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <Row className="mb-2 text-center">
                    <Col className="col-12">
                      <h2 className="">Update Password</h2>
                    </Col>
                  </Row>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="loginLabel">New Password</Label>
                        <Input
                          name="password"
                          className="form-control fwv-input-control"
                          placeholder="Enter new valid password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="loginLabel">Confirm Password</Label>
                        <Input
                          name="passwordConfirm"
                          className="form-control fwv-input-control"
                          placeholder="Confirm Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.passwordConfirm || ""}
                          invalid={
                            validation.touched.passwordConfirm &&
                            validation.errors.passwordConfirm
                              ? true
                              : false
                          }
                        />
                        {validation.touched.passwordConfirm &&
                        validation.errors.passwordConfirm ? (
                          <FormFeedback type="invalid">
                            {validation.errors.passwordConfirm}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                        <div className="mt-4 d-grid" style={{height: "50px"}}>
                          <button
                            className="btn c1-primary fwv-primary-btn btn-block "
                            type="submit"
                          >
                            Reset
                          </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPasswordPage)
