import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  TabContent,
  TabPane,
  Label,
  NavItem,
  NavLink,
  Input,

} from "reactstrap"

import 'jspdf-autotable';


import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import c1Logo from '../../assets/images/c1logo.png'
// Redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "react-datepicker/dist/react-datepicker.css";


// import { PDFDownloadLink } from '@react-pdf/renderer'
// import AnnualProcceedsReport from "components/PDF/AnnualProcceedsReport";
// im
// import html2canvas from 'html2canvas';
// import PDFHTML from "./PDFHTML";
// import { useRef } from "react";

import { Document, Page, View } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


import html2pdf from 'html2pdf.js';

// import { PDFExport } from '@progress/kendo-react-pdf';

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'




export const MyReports =()=>{
  // export class myAccount extends Component {

  const [userNauserUseridme, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [idNumber, setIDNumber] = React.useState('');
  const [cellNumber, setCellNumber] = React.useState('');
  const [newAddress, setNewAddress] = React.useState('');
  const [dob, setDOB] = React.useState('');
  const [requireMoreInfo, setRequireMoreInfo] = React.useState(false);
  const [userInfo, setNewUserInfo] = React.useState([]);
  const [currentKYCStatus, setCurrentKYCStatus] = React.useState('');
  const [allTransactions, setTransactions] = React.useState([]);
  const [depUSDT, setDepUSDT] = React.useState(0);
  const [depZAR, setDepZAR] = React.useState(0);
  const [depBTC, setDepBTC] = React.useState(0);
  const [withUSDT, setWithUSDT] = React.useState(0);
  const [withBTC, setWithBTC] = React.useState(0);
  const [withZAR, setWithZAR] = React.useState(0);
  
  const [productInterest, setProductInterest] = React.useState(0);
  const [pdfLoading, setpdfLoading] = React.useState(true);
  

const{auth,user} = React.useContext(UserContext)

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {

       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])


const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}


React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserUserid(user.user.userid);
    setNewUserInfo(user.user)   
    setuserName(user.user.name)
    generateAnnualProcceedsCertificate(user.user.userid)
    handleDownload()
   
  }

},[auth])

function generatePDF() {
  // const pdfContent = (
  //   <Document>
  //     <Page>
  //       <p>My PDF content goes here</p>
  //     </Page>
  //   </Document>
  // );

  return (
    <p >test</p>
  );
  // console.log(pdfContent);
  // return pdfContent;
}

const generateAnnualProcceedsCertificate = async(userid) => {
  var userObj = {
    fromDate: '2022/03/01',
    toDate: '2023/03/06',
    userid: userid
  }
  await axios.post(process.env.REACT_APP_BASE + "api/get-user-annual-procceds-certificate", userObj).then((res)=>{
    console.log('all transactions ', res.data)
    setTransactions(res.data.transactions)
    if(res.data.usdtDep && res.data.usdtDep > 0)
      setDepUSDT(res.data.usdtDep)

      if(res.data.btcDep && res.data.btcDep > 0)
        setDepBTC(res.data.btcDep)

      if(res.data.zarDep && res.data.zarDep > 0)
        setDepZAR(res.data.zarDep)

      if(res.data.usdtWith && res.data.usdtWith > 0)
        setWithUSDT(res.data.usdtWith)

      if(res.data.zarWith && res.data.zarWith > 0)
        setWithZAR(res.data.zarWith)

      if(res.data.btcWith && res.data.btcWith > 0)
        setWithBTC(res.data.btcWith)

      if(res.data.productInterest && res.data.productInterest > 0)
        setProductInterest(res.data.productInterest)
    // setAllTransactions(res.data.transactions)
    // setAllUserInfo(res.data.users)
    // setDisplaySpinner(false)

    setpdfLoading(false)
});
}

// const data = [
//   { name: 'John', age: 30, city: 'New York' },
//   { name: 'Jane', age: 25, city: 'Los Angeles' },
//   { name: 'Bob', age: 40, city: 'Chicago' },
// ];

// const tableRef = useRef(null);

// const generatePDF = () => {
//   const data = [
//     { name: 'John', age: 30, city: 'New York' },
//     { name: 'Jane', age: 25, city: 'Los Angeles' },
//     { name: 'Bob', age: 40, city: 'Chicago' },
//   ];

//   const table = PDFHTML(data);
//   tableRef.current.appendChild(table);

//   setTimeout(() => {
//     html2canvas(table)
//       .then((canvas) => {
//         const imgData = canvas.toDataURL('image/png');
//         const pdf = new jsPDF();
//         pdf.addImage(imgData, 'PNG', 0, 0);
//         pdf.save('myPDF.pdf');
//       })
//       .finally(() => {
//         tableRef.current.removeChild(table);
//       });
//   }, 100);
// };

// const pageRef = useRef(null);

// const generatePDFOLD = () => {
//   // const elementToHide = pageRef.current;
//   // elementToHide.style.display = 'none';

// // html2canvas(pageRef.current).then((canvas) => {
// //     // const imgData = canvas.toDataURL('image/png');
// //     // console.log(imgData);
// //     // const pdf = new jsPDF();
// //     // pdf.addImage(imgData, 'PNG', 0, 0);
// //     // pdf.save('myPDF.pdf');

// //     // elementToHide.style.display = ''; // Unhide the element after PDF is generated


// //     const imgData = canvas.toDataURL('image/png');
    
// //     const pdf = new jsPDF();
// //     const imgProps= pdf.getImageProperties(imgData);
// //     const pdfWidth = pdf.internal.pageSize.getWidth();
// //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
// //     // pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
// //     pdf.addImage('data:image/jpeg;base64,' + imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
// //     pdf.save('myPDF.pdf');

// //   });

//   html2canvas(pageRef.current).then((canvas) => {
//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF();
//     const imgProps= pdf.getImageProperties(imgData);
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
//     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//     pdf.save("Annual Proceeds Certificate-" + new Date());
//   });
// };

// var pdfExportComponent = React.createRef();


// exportPDF = () => {
//   pdfExportComponent.current.save();
// }



const [pdf, setPdf] = React.useState(null);

// const handleDownload = async() => {
//   const generatedPDF = await generatePDF();
//   setPdf(generatedPDF);


// }

const handleDownload = async() => {
  const generatedPDF = await generatePDF();
  const pdfBlob = new Blob([generatedPDF], { type: 'application/pdf' });
  setPdf(pdfBlob);
}
var title='Annual Proceeds Certifiacte 28 February 2023'
var date = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' }).split(',')[0].replace('/', '-').replace('/', '-');

const content = `
<html>

<style>

h1{
  text-align: center;

}

p {
  font-size: 14px;
  line-height: 1.5;
}
table {
  width: 100%;
}
th, td {
 
  padding: 5px;
  text-align: left;
}

.transactionsTable{
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.transactionsTable th, .transactionsTable td {
  border: 1px solid black;
  padding: 5px;
  text-align: left;
}

.imgIMG{
  display: flex;
  alignItems: center;
  justifyContent: center;
  marginBottom: 20px;
  width: 100px;
  maxWidth: 100px;
  margin: auto;
}

.headerImage {
  width: 100px;
   maxWidth: 100px;
    margin: auto;
     marginBottom: 50px;
}

</style>

  <body style="padding: 30px">
  <div  style="padding: 30px; page-break-inside: avoid;">
  <div style="margin-bottom: 20px">
    <h1>C1 Holdings</h1>
  </div>
  <div class="imgIMG" >
    <img class="headerImage" src="${c1Logo}" alt="Placeholder" />
  </div>

  <h5 style="text-align: center; margin-top: 20px;">${title}</h5>

  <div>
  <p style="margin: unset">C1 Holdings</p>
        <p style="margin: unset">Company Registration: 2022 / 837320 / 07</p>
        <p style="margin: unset">The Old Tannery</p>
      <p style="margin: unset">Block C</p>
      <p style="margin: unset">Hermon Road</p>
      <p style="margin: unset">Wellington</p>
      <p style="margin: unset">7654</p>
  </div>

  <hr />
  <table style="width: 100%; border-collapse: collapse; font-size: 12px; ">

    <tbody>
      <tr>
        <td style="padding: 5px;"><strong>Customer ID:</strong></td>
        <td style="padding: 5px;">${userUserid}</td>
     
      </tr>
      <tr>
        <td style="padding: 5px;"><strong>Customer Name:</strong></td>
        <td style="padding: 5px;">${userNauserUseridme}</td>
      </tr>
      <tr>
        <td style="padding: 5px;"><strong>Date:</strong></td>
        <td style="padding: 5px;">${date}</td>
      </tr>
      <tr>
        <td style="padding: 5px;"><strong>Deposits:</strong> </td>
        <td style="padding: 5px;">USDT: ${depUSDT}</td>
        <td style="padding: 5px;">BTC: ${depBTC}</td>
        <td style="padding: 5px;">ZAR: ${depZAR}</td>
        </td>

      </tr>
      <tr>
        <td style="padding: 5px;"><strong>Withdrawals:</strong> </td>
          <td style="padding: 5px;">USDT: ${withUSDT}</td>
          <td style="padding: 5px;">BTC: ${withBTC}</td>
          <td style="padding: 5px;">ZAR: ${withZAR}</td>

      
      </tr>
      <tr>
        <td style="padding: 5px;"><strong>Product Interests:</strong></td>
        <td style="padding: 5px;">USDT: ${productInterest}</td>
      </tr>
    </tbody>
  </table>


<table style="width: 100%; border-collapse: collapse; font-size: 12px; " class="transactionsTable">
    <thead>
      <tr>
   
                <th  >Userid</th>
                <th >Type</th>
                <th>Currency</th>
                <th >Amount</th> 
              <th >Transaction ID</th>
              <th >Date</th>
         

      </tr>
    </thead>
    <tbody>
    ${allTransactions.map((transaction,key) => `
    <tr>

        <td key=${key} style={{  padding: '5px' }}>${transaction.userid}</td>
        <td >${transaction.tran_type}</td>
        <td >${transaction.currency === '1' ? 'USDT': transaction.currency === '2' ? 'ZAR' : 'BTC'}</td>
        <td >${transaction.amount}</td>
        <td >${transaction.trans_id}</td>
        <td >${transaction.createdAt}</td>
        </tr>

  
  `)}
    </tbody>
  </table>
</div>
  </body>
</html>
`;


const options = {
  filename: 'Annual-Proceeds-Certificate.pdf',
  html2canvas: { scale: 2 },
  jsPDF: { orientation: 'portrait' },
};

const handleDownloads = () => {
  html2pdf().set(options).from(content).save();
};


    return(
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>C1 Holdings - My Reports</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Edit Profile" breadcrumbItem="My Reports" />
            
            <Row>
              <Col lg="6">

                <Card>
                  <CardBody>
                    <div>
                    <h4 className="text-center">Annual Proceeds Certificate</h4>
                    <br />
                    <p>The Annual Proceeds Certificate is a professional financial document that provides a comprehensive summary of all financial transactions that have taken place within a specific year. 
                      It includes a detailed record of all deposits, proceeds, and sales of products, and is presented in a PDF format. 
                      This report is typically generated once a year and serves as an essential document for various purposes such as tax filing, auditing, and financial planning. 
                      The Annual Proceeds Certificate is prepared by financial professionals who possess the expertise and knowledge required to accurately record and summarize the financial transactions in a clear and concise manner.</p>
                  
                    {/* <br /> */}
                    {/* {PDFHTML(data)} */}
              
                    {/* <button className="btn btn-success text-center m-auto" onClick={generatePDF}>Download Report</button> */}
                   {/* <button className="btn btn-success text-center m-auto" onClick={this.exportPDF}>Export PDF</button> */}
                    {/* <div ref={tableRef}></div> */}
                    {/* <div ref={pageRef} >
                        <PDFHTML transactions={allTransactions} userid={userUserid} name={userNauserUseridme} />
                      </div> */}

                      {/* <PDFExport ref={this.pdfExportComponent}>
                      <PDFHTML transactions={allTransactions} userid={userUserid} name={userNauserUseridme} />
                      </PDFExport> */}

                    {/* <PDFDownloadLink document={<PDFHTML  />} fileName={"Annual Proceeds Certificate-" + new Date()} >
                        {({loading }) => (loading ? "Loading..." : <button  className="btn btn-success text-center m-auto" type="button">
                        Download Report
                        </button>)}
                    </PDFDownloadLink> */}

{/* <div>
      {pdf && (
        <a
          href={URL.createObjectURL(pdf)}
          download="my-pdf-file.pdf"
          onClick={() => URL.revokeObjectURL(pdf)}
        >
          Click here to download the PDF
        </a>
      )}
    </div> */}
      {pdfLoading ? 
      <p>Loading...</p>
      :
      <div>
  
        <button onClick={handleDownloads}>Download PDF</button>
      </div>}
                    </div>
                   
                  </CardBody>
                </Card>
              </Col>

              {/* <Col lg="6">

                <Card>
                  <CardBody>

                  </CardBody>
                </Card>
              </Col> */}
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
}