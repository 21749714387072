import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"
import axios from "axios"
import {UserProfile} from "pages/Admin/UserProfilePage/UserProfile"

export const PendingMembers = () => {
  const [users, setUsers] = useState([])
  const [contact, setContact] = useState()

  const columns = [
    {
      className: 'dt-control',
      orderable: false,
      data: null,
      defaultContent: '',
  },
    {
      dataField: "unique",
      text: "Unique Number",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "lastname",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "usertype",
      text: "Type",
      sort: true,
    },
    {
      dataField: "progress",
      text: "Status",
      sort: true,
    },
    {
        dataField: "compliance",
        text: "Compliance",
        sort: true,
      },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to={`/user-pending-profile/${user.unique}`}>
            <i
              className="bx bx-user-circle bx-sm mt-1 font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(user)}
            ></i>
          </Link>
          {/* <Link className="text-info" to={`/user-product-list/${user.id}`}>
            <i
              className="bx bx-detail bx-sm mt-1 font-size-18"
              id="deletetooltip"
              // onClick={() => onClickDelete(user)}
            ></i>
          </Link> */}
        </div>
      ),
    },
  ]

  const handleUserClick = arg => {
    const user = arg

    setContact({
      id: user.id,
      name: user.name,
      status: user.u_status,
    })
  }

  const redirectToUserProducts = state => {
    console.log("clicked to products")
  }

  useEffect(async res => {
    var keyObj = {
        meta_key: "signup"
    }
    await axios.post(process.env.REACT_APP_API_BASE_URL+`/get-meta-from-key`, keyObj).then(res => {

        if(res.data.status == 200)
        {
        
            var userdata = res.data.body
            console.log("all data back ", res.data.body)
            setUsers(userdata)
        }

    })
  }, [])

  const datatableInfo = {
    title: "Users list view",
    shortDescription: "List of users in the system",
    longDescription: "This shows a list of users in the system long text",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Pending Members</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={users}
                          datatableInfo={datatableInfo}
                          wrapperClasses="table-responsive"
                        />
                        
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

}