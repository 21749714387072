import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import Icon from "react-crypto-icons";

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

import feeBasedImage from "../../../assets/attachments/feeBasedVault.jpeg"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// icons
// import Icon from "react-crypto-icons";

//Import Images
import avatar from "../../../assets/images/users/avatar-1.jpg"

// Product Modal
import ProductModal from "./dep-product";


// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const buyProduct =()=>{


  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userInvest, setInvest] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);
  const [userProductTotal, setProductTotal] = React.useState('');

  
  const [isLoading, setIsLoading] = React.useState(false);


//   This is used for the wallet
const [walletOptions, setWalletOptions] = React.useState({ currency: "", product:"15", amount: 0, otp: "", otpSent: false});
const [displayScreen, setDisplayScreen] = React.useState(1);
const [getDepositResult, setDepositResult] = React.useState(false);
const [getDepositDeclinedResult, setDepositDeclinedResult] = React.useState(false);


  
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])

// ProductModal
const [getproductModal, setProductModal] = useState(false);

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])



     React.useEffect(async res =>{

      if(user !== 'undefined' && user !== null)
      {
        if(user.user.fica_status == 'N')
        {
          $("[data-rmiz-wrap]").css("justify-content","center");
          $("[data-rmiz-overlay]").css("text-align","center");
          Swal.fire({
            title: "KYC Required",
            text: "In order to do your first deposit, KYC (Know Your Client) will be required. This can be done on the deposit/withdrawal page.",
            icon: "info",
            confirmButtonText: "Take Me There",
            confirmButtonColor: "#1b75bb",
            allowOutsideClick: false,
          }).then(result => {
            console.log('result is', result);
            if (result.isConfirmed) {
              window.location.href = "/my-usdt-wallet"
              // startKYCProcess()
            }
          })
        }else if(user.user.fica_status == 'P')
        {
          $("[data-rmiz-wrap]").css("justify-content","center");
          $("[data-rmiz-overlay]").css("text-align","center");
          Swal.fire({
            title: "KYC Pending",
            text: "Our support team is reviewing all KYC documents and you will be notified via Email once this is done.",
            icon: "info",
            confirmButtonText: "Okay",
            confirmButtonColor: "#1b75bb",
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = "/dashboard"
            }
          })
        }
        
      }
    
    },[auth])




const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{

  if(user !== 'undefined' && user !== null)
  {
    setuserUserid(user.user.userid);
    
  }

},[auth])

useEffect(async res =>{
  if(user !== 'undefined' && user !== null)
  {
    getuserBalance(user.user.userid)
    getUserProducts('15', user.user.userid)

  }


})

const getuserBalance = async userid => {
  var balanceObject = {
    userid: userid,
    currency: '1'
  }



  await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{

    console.log('set the balance to ', res.data)
    return setuserBalance(res.data.total);
 
  }).then(async res1 =>{
    var transactionObject = {
      'userid': userid
    }
    
  
    if(!checkedTransactions)
    {
        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_API_BASE_URL+"/view-transaction",transactionObject).then( res2 =>{

          if(res2.data.status == 200)
          {
            setAllTransactions(res2.data.transactions)
            console.log('transactions is ', viewAllTransactions)
          }
          setCheckedTransactions(true);
          
        })
    }
  })



}


function setInvestAmount(amount) {
  setIsLoading(true)
    // const userid = req.body.userid
    // const amount = req.body.amount
    // const productid = req.body.productid
    // console.log('the investment amount is', walletOptions['amount'])

    console.log(userBalance && parseFloat(walletOptions['amount']) > 0 && parseFloat(userBalance) >= parseFloat(walletOptions['amount']) && parseFloat(1000) <= parseFloat(walletOptions['amount']))

    if(userBalance && parseFloat(walletOptions['amount']) > 0 && parseFloat(userBalance) >= parseFloat(walletOptions['amount']) && parseFloat(1000) <= parseFloat(walletOptions['amount']))
    {
        const investObject = {
            userid: user.user.userid,
            amount: walletOptions['amount'],
            productid: '15',
            currency: '1'
          }
        console.log('continue with purchase')
          axios.post(process.env.REACT_APP_API_BASE_URL+"/create-productsale",investObject).then(async res =>{
            setDepositResult(true)
            setInvest(0);
            // toastr.options.onHidden = function() { window.location.href="/digital-moderate" } 
            toastr.success("Product Successfully purchased!") 
            return true;
          });


    }else{
      setDepositDeclinedResult(true)
      setDepositResult(false)
        return false;

    }

}

const getUserProducts = async (productid, userid) => {
    var totalObject = {
      'userid': userid,
      'productid': productid,
      currency: '1'
    }
  
    await axios.post(process.env.REACT_APP_API_BASE_URL+"/productid-total",totalObject).then(async res =>{
  
      return setProductTotal(res.data.total);
   
    })
  
  }


  const buyProductModal = () => {

    setProductModal(true);
  };

  const handleNextView = () => {
    // First see if the screen is at last
    // Then see if we need to deposit
    // The  show next slide
    
    
    console.log('the modal screen is', nextModalView)
    if(displayScreen == 3)
    {
        setDisplayScreen(1)
        walletOptions['currency'] = '';
        walletOptions['amount'] = 0;
        walletOptions['otpSent'] = false
        setProductModal(false);
    }else if(displayScreen == 2)
    {
        if(walletOptions['currency'] != '' && walletOptions['amount'] != 0)
        {

          var otpObj = {
            userid: user.user.userid,
            otp: walletOptions['otp']
          }
          
          axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then((res)=>{
                  
            if(res.data.status == 200)
            {

            // We now need to do set the deposit to userInvest
            setInvest(walletOptions['amount'])

            // We now need to calculations and determine the screen modal for final screen
           
            var investmentAmount = setInvestAmount(userInvest)
            // console.log('the investment amount is', setInvestAmount(userInvest))
            // setDepositResult(investmentAmount)
        
            
            var nextModalView = displayScreen + 1;
            setDisplayScreen(nextModalView)
            walletOptions['otpSent'] = false

        
            }else{
              toastr.error("Incorrect OTP, we have sent a new OTP Pin") 
              axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
                  
                console.log("new pin sent")
                
              })
            }
            
          })
        }
    }else{
        var nextModalView = displayScreen + 1;
        setDisplayScreen(nextModalView)
    }

   
  }
  
  const handlePrevView = () => {

    if(displayScreen == 1)
    {
        setProductModal(false);
    }else{
        var prevView = displayScreen - 1;
    
        setDisplayScreen(prevView)
    }
  

  
  }
  var report = {
    title: "USDT",
    icon: "usdt",
    color: "white"
  }


    return(
      <React.Fragment>
        <ProductModal
          show={getproductModal}
          displayscreen={displayScreen}
          onNextClick={handleNextView}
          onPrevClick={handlePrevView}
          wallet={walletOptions}
          depositAprovedResult={getDepositResult}
          depositDeclinedResult={getDepositDeclinedResult}
        />

      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Products</title>
        </MetaTags>
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="C1 HOLDINGS (PTY) LTD Fee Based Vault" />

          <Row>
            
            <Col xl="4" md="4" sm="6">
              <Card className='c1-primary-dark-card'>
                <CardBody>
                  <div className="text-muted mb-4">
                    <Icon name={report.icon}  className={
                        " h2 text-" +
                        report.color +
                        " align-middle mb-0 me-3"
                      } size={25}  />{" "}
                    {report.title}{" "}
                  </div>

                  <Row>
                    <Col xs="12">
                      <div>
                        <h5>{report.title + ' ' + userBalance}</h5>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl="4" md="4" sm="6">
              <Card className='c1-primary-dark-card'>
                <CardBody>
                  <div className="text-muted mb-4">
                    Vault Entries{" "}
                  </div>

                  <Row>
                    <Col xs="12">
                      <div>
                        <h5>Total: {userProductTotal}</h5>
                        {parseInt(userProductTotal) > 0 ? 
                        <a href="./vault-product-info" className="referralLink btn btn-success btn-block">View All</a>
                        : ""}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>

          {/* <Row>

            <Col xl="4" md="4">
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                    <h4 className="card-title mb-4">Total Wallet Amount</h4>

                    <div className="mt-4 mb-3">

                        <Row>
                            <Col xl="12">
                                <Col md="3">
                                <a href="./my-usdt-wallet">
                                      <a onClick={()=>buyProductModal()} className="btn c1-primary c1-primary-inline-btn btn-block">USDT(TRC20) Wallet
                                      <h5 className="text-left mb-0">
                                          Total: $ {userBalance} 
                                          </h5>
                                      </a>
                                  </a>
                                </Col>
                            </Col>
                        </Row>

    
                    </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xl="4" md="4">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Total Number of Vault entries</h4>

                <div className="mt-4 mb-3">

                    <Row>
                        <Col xl="12">
                            <Col md="3">
                            <a href="./vault-product-info">
                                  <a onClick={()=>buyProductModal()} className="btn c1-primary c1-primary-inline-btn btn-block">My Fee Based Vault
                                  <h5 className="text-left mb-0">
                                      {userProductTotal} 
                                      </h5>
                                  </a>
                              </a>
                            </Col>
                        </Col>
                    </Row>

 
                </div>
                </CardBody>
              </Card>
            </Col>

          </Row> */}

          <Row>

            <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">New Vault Entry</h4>

                <div className="mt-4 mb-3">

                    <Row>
                        <Col xl="12">
                            <Col md="3">
                                <Button onClick={()=>buyProductModal()} className="btn c1-primary c1-primary-inline-btn btn-block">New Vault Entry</Button>
                            </Col>
                        </Col>
                    </Row>

 
                </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}