import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import Icon from "react-crypto-icons";

// icons
// import Icon from "react-crypto-icons";

//Import Images
import avatar from "../../../assets/images/users/avatar-1.jpg"

// Product Modal
import ProductModal from "./dep-vif-vault-12";

import MiniWidget from "../../Dashboard-crypto/mini-widget"
import CurrencyWidget from "../../Dashboard-crypto/currency-widget"


// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const vaultIndex12 =()=>{


  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userInvest, setInvest] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);
  const [userProductTotal, setProductTotal] = React.useState('');

  
  const [isLoading, setIsLoading] = React.useState(false);


//   This is used for the wallet
const [walletOptions, setWalletOptions] = React.useState({ currency: "", product:"20", amount: 0, otp: "", otpSent: false});
const [displayScreen, setDisplayScreen] = React.useState(1);
const [getDepositResult, setDepositResult] = React.useState(false);
const [getDepositDeclinedResult, setDepositDeclinedResult] = React.useState(false);

const [cryptoOptions, setCryptoOptions] = useState([])
const [currencyOptons, setCurrencyOptons] = useState([])
  

const [userProductTotalZar, setProductTotalZar] = React.useState(0);

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])

// ProductModal
const [getproductModal, setProductModal] = useState(false);


    React.useEffect(async ()=>{

      const urlParams = new URLSearchParams(window.location.search);
      // const queryParams = new URLSearchParams(window.location.search);


      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }else if(user !== 'undefined' && user !== null)
       {

        if(user.user.fica_status == 'N')
        {
          $("[data-rmiz-wrap]").css("justify-content","center");
          $("[data-rmiz-overlay]").css("text-align","center");
          Swal.fire({
            title: "KYC Required",
            text: "In order to do your first deposit, KYC (Know Your Client) will be required. This can be done on the deposit/withdrawal page.",
            icon: "info",
            confirmButtonText: "Take Me There",
            confirmButtonColor: "#1b75bb",
            allowOutsideClick: false,
          }).then(result => {
            console.log('result is', result);
            if (result.isConfirmed) {
              window.location.href = "/my-usdt-wallet"
              // startKYCProcess()
            }
          })
        }else if(user.user.fica_status == 'P')
        {
          $("[data-rmiz-wrap]").css("justify-content","center");
          $("[data-rmiz-overlay]").css("text-align","center");
          Swal.fire({
            title: "KYC Pending",
            text: "Our support team is reviewing all KYC documents and you will be notified via Email once this is done.",
            icon: "info",
            confirmButtonText: "Okay",
            confirmButtonColor: "#1b75bb",
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = "/dashboard"
            }
          })
        }



         console.log("we are inside if")
         const cryptoBalance = {
           userid: user.user.userid,
           crypto: 'true'
         }
       
           await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",cryptoBalance).then(async res =>{
             console.log('crypto options is ', res.data.currencyInfo)
             setCryptoOptions(res.data.currencyInfo)
           })
         
           const currencyBalance = {
             userid: user.user.userid,
             crypto: 'false'
           }
       
           await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",currencyBalance).then(async res =>{
       
             
             setCurrencyOptons(res.data.currencyInfo)
        
           })
       
           resetCurrencies()
 
           setuserUserid(user.user.userid);
           getuserBalance(user.user.userid)
           getUserProducts('20', user.user.userid)
           getUserProductsZar('20', user.user.userid)
          }
      }else if(user !== 'undefined' && user !== null)
      {
        console.log("we are inside if")
        const cryptoBalance = {
          userid: user.user.userid,
          crypto: 'true'
        }
      
          await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",cryptoBalance).then(async res =>{
            console.log('crypto options is ', res.data.currencyInfo)
            setCryptoOptions(res.data.currencyInfo)
          })
        
          const currencyBalance = {
            userid: user.user.userid,
            crypto: 'false'
          }
      
          await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",currencyBalance).then(async res =>{
      
            
            setCurrencyOptons(res.data.currencyInfo)
       
          })
      
          resetCurrencies()

          setuserUserid(user.user.userid);
          getuserBalance(user.user.userid)
          getUserProducts('20', user.user.userid)
          getUserProductsZar('20', user.user.userid)
        


      }
     },[auth])








const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}




// Deprecated
async function getuserBalanceCurrency() {
  if(walletOptions['currency'] == 'zar')
  {
    var balanceObject = {
      userid: userUserid,
      currency: '2'
    }
  }else{
    var balanceObject = {
      userid: userUserid,
      currency: '1'
    }
  }

  await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{

    console.log('set the balance to ', res.data)
    return res.data.total;
  })



}

const getuserBalance = async userid => {

  var transactionObject = {
    'userid': userid,
  }
  
  if(!checkedTransactions)
  {
      // Get the list of all the transactions
      await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then( res2 =>{

        if(res2.data.status == 200)
        {
          setAllTransactions(res2.data.transactions)
        }
        setCheckedTransactions(true);
        
      })
  }


}


async function setInvestAmount(amount) {

  walletOptions['product'] = "20";
  setIsLoading(true)
    // const userid = req.body.userid
    // const amount = req.body.amount
    // const productid = req.body.productid
    // console.log('the investment amount is', walletOptions['amount'])

    var walletAmount = 0

    if(walletOptions['currency'] == 'zar')
    {
      var balanceObject = {
        userid: userUserid,
        currency: '2'
      }
    }else{
      var balanceObject = {
        userid: userUserid,
        currency: '1'
      }
    }
  
    await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{
  
      console.log('set the balance to ', res.data)
      walletAmount = res.data.total
    }).then(async result =>{
      console.log('walletAmount is', walletAmount)

    // First see the currency amount
    if(walletOptions['currency'] == 'zar')
    {
      console.log('in the zar if ', parseFloat(walletOptions['amount']), parseFloat(1000) <= parseFloat(walletOptions['amount']))
      console.log(walletAmount && parseFloat(walletOptions['amount']) > 0 && parseFloat(walletAmount) >= parseFloat(walletOptions['amount']) && parseFloat(1000) <= parseFloat(walletOptions['amount']))
      if(walletAmount && parseFloat(walletOptions['amount']) > 0 && parseFloat(walletAmount) >= parseFloat(walletOptions['amount']) && parseFloat(1000) <= parseFloat(walletOptions['amount']))
      {
          const investObject = {
              userid: user.user.userid,
              amount: walletOptions['amount'],
              productid: '20',
              currency: '2'
            }
          console.log('continue with purchase')
            axios.post(process.env.REACT_APP_API_BASE_URL+"/create-productsale",investObject).then(async res =>{
              setDepositResult(true)
              setInvest(0);
              toastr.options.onHidden = function() { window.location.href="/vif-12" }  
              toastr.success("Product Successfully purchased!") 
              return true;
            });
  
  
      }else{
        setDepositDeclinedResult(true)
        setDepositResult(false)
          return false;
  
      }
    }else{
      if(walletAmount && parseFloat(walletOptions['amount']) > 0 && parseFloat(walletAmount) >= parseFloat(walletOptions['amount']) && parseFloat(500) <= parseFloat(walletOptions['amount']))
      {
          const investObject = {
              userid: user.user.userid,
              amount: walletOptions['amount'],
              productid: '20',
              currency: '1'
            }
          console.log('continue with purchase')
            axios.post(process.env.REACT_APP_API_BASE_URL+"/create-productsale",investObject).then(async res =>{
              setDepositResult(true)
              setInvest(0);
              toastr.options.onHidden = function() { window.location.href="/vif-12" } 
              toastr.success("Product Successfully purchased!") 
              return true;
            });
  
  
      }else{
        setDepositDeclinedResult(true)
        setDepositResult(false)
          return false;
  
      }
    }

    })




}

const getUserProducts = async (productid, userid) => {
    var totalObject = {
      'userid': userid,
      'productid': productid, 
      currency: '1'
    }
  
    await axios.post(process.env.REACT_APP_API_BASE_URL+"/productid-total",totalObject).then(async res =>{
  
      return setProductTotal(res.data.total);
   
    })
  
  }

  const getUserProductsZar = async (productid, userid) => {
    var totalObject = {
      'userid': userid,
      'productid': productid, 
      currency: '2'
    }
  
    await axios.post(process.env.REACT_APP_API_BASE_URL+"/productid-total",totalObject).then(async res =>{
  
      return setProductTotalZar(res.data.total);
   
    })
  
  }


  const buyProductModal = () => {
    setProductModal(true);
  };

  const handleNextView = () => {
    // First see if the screen is at last
    // Then see if we need to deposit
    // The  show next slide
    
    
    console.log('the modal screen is', nextModalView)
    if(displayScreen == 3)
    {
        setDisplayScreen(1)
        walletOptions['currency'] = '';
        walletOptions['amount'] = 0;
        walletOptions['otpSent'] = false
        setProductModal(false);
    }else if(displayScreen == 2)
    {
        if(walletOptions['currency'] != '' && walletOptions['amount'] != 0)
        {

          var otpObj = {
            userid: user.user.userid,
            otp: walletOptions['otp']
          }
          
          axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then((res)=>{
                  
            if(res.data.status == 200)
            {

            // We now need to do set the deposit to userInvest
            setInvest(walletOptions['amount'])

            // We now need to calculations and determine the screen modal for final screen
           
            var investmentAmount = setInvestAmount(userInvest)
            // console.log('the investment amount is', setInvestAmount(userInvest))
            // setDepositResult(investmentAmount)
        
            
            var nextModalView = displayScreen + 1;
            setDisplayScreen(nextModalView)
            walletOptions['otpSent'] = false

        
            }else{
              toastr.error("Incorrect OTP, we have sent a new OTP Pin") 
              axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
                  
                console.log("new pin sent")
                
              })
            }
            
          })
        }
    }else{
        var nextModalView = displayScreen + 1;
        setDisplayScreen(nextModalView)
    }

   
  }
  
  const handlePrevView = () => {

    if(displayScreen == 1)
    {
        setProductModal(false);
    }else{
        var prevView = displayScreen - 1;
    
        setDisplayScreen(prevView)
    }
  

  
  }

// var report = {
//   title: "USDT",
//   icon: "usdt",
//   color: "white"
// }

// var reportzar = {
//   title: "ZAR",
//   icon: "zar",
//   color: "white"
// }

var reports = cryptoOptions

var currencies = currencyOptons

function resetCurrencies() {
  console.log("reset currencies called")
  reports = cryptoOptions
  
  currencies = currencyOptons
}

    return(
      <React.Fragment>
        <ProductModal
          show={getproductModal}
          displayscreen={displayScreen}
          onNextClick={handleNextView}
          onPrevClick={handlePrevView}
          wallet={walletOptions}
          depositAprovedResult={getDepositResult}
          depositDeclinedResult={getDepositDeclinedResult}
        />

      <div className="page-content">
        <MetaTags>
          <title>C1 Holdings- Vault Index Fund (12 months)</title>
        </MetaTags>
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs title="Vault Index Fund" breadcrumbItem="C1 HoldingsVault Index Fund (12 Months)" />

          <Row>

              <MiniWidget reports={reports} />
              {/* </Row>

              <Row> */}
              <CurrencyWidget currencies={currencies} />


            <Col xl="4" md="4" sm="6">
              <Card className='omc-primary-dark-card'>
                <CardBody>
                  <div className="text-muted mb-4">
                    Vault Index Fund (USDT) (12 Months){" "}
                  </div>

                  <Row>
                    <Col xs="12">
                      <div>
                        <h5>Total: {userProductTotal}</h5>
                        {parseInt(userProductTotal) > 0 ? 
                        <a href="./vif-12-info" className="referralLink btn btn-success btn-block">View All</a>
                        : ""}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>

          <Row>

            <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Vault Index Fund (USDT) (12 Months){" "}</h4>

                <div className="mt-4 mb-3">

                    <Row>
                        <Col xl="12">
                            <Col md="3">
                                <button onClick={()=>buyProductModal()} className="btn c1-primary c1-primary-inline-btn btn-block">New Vault Index Fund</button>
                            </Col>
                        </Col>
                    </Row>

 
                </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}