import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import PropTypes from "prop-types"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"
import axios from "axios"

import { UserContext } from "../../../../UserContext";

import Swal from 'sweetalert2'


const Level2 = ({ userDetails }) => {
  let { id } = useParams()

  const [referrals, setReferrals] = useState([])
  const [referralSetting, setReferralSetting] = useState(false)
  const [userUserid, setuserUserid] = React.useState('');
  const [newReferralSettings, setNewReferralSettings] = React.useState({ level: 0, percentage: '0'});
  const [user, setUser] = React.useState('');
  const{auth} = React.useContext(UserContext)

  React.useEffect(async res =>{

    axios
    .post(process.env.REACT_APP_API_BASE_URL + `/find-one-user-by-id`, {
      id: id,
    }).then(res => {
      setUser(res.data.user)

    if(res.data.user !== 'undefined' && res.data.user !== null)
    {
      setuserUserid(res.data.user.userid); 
      console.log("we have set the user ", res.data.user.userid)
    }
  });
  
  },[auth])

  React.useEffect(()=>{
    if(!referralSetting && userUserid)
    {
        setReferralSetting(true)
        getReferrals();
    }
   })

  const columns = [
    {
      dataField: "userid",
      text: "Userid",
      sort: true,
    },
    {
      dataField: "level",
      text: "Level",
      sort: true,
    },

  ]


//   useEffect(async res => {
//     var userObj = 
//     {
//         userid:userUserid,
//         level: 1
//     }
//     await axios.post(process.env.REACT_APP_API_BASE_URL+`/show-myreferrals`, userObj).then(res => {
//       console.log("all referrals ", res.data)
//       setReferrals(res.data.referrals)
//     })
//   }, [])

  const getReferrals = async () => {

    console.log('user we need to get is ', userUserid)
    var userObj = 
    {
        userid: user,
        level: 2
    }
    await axios.post(process.env.REACT_APP_API_BASE_URL+`/show-myreferrals-admin`, userObj).then(res => {
        console.log('getting ', res.data.users)
        if(res.data.status == 200)
            setReferrals(res.data.users)
      })
  }

  const datatableInfo = {
    title: "My Referrals",
    shortDescription: "Indirect Referrals",
    longDescription: "This table displays all of your indirect referrals",
  }



  return (
    <React.Fragment>

        <Datatable
          columns={columns}
          productData={referrals}
          datatableInfo={datatableInfo}
        />

    </React.Fragment>
  )

}

Level2.propTypes = {
    userDetails: PropTypes.any,
}

export default Level2
