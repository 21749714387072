import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useParams } from "react-router-dom"
import  { Redirect } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  FormGroup,
  Button,
} from "reactstrap"
import Select from "react-select"
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"



//Import Images
import axios from "axios"

export const AdminPendingProfile = () => {
  const optionGroup = [
    { label: "A", value: "A" },
    { label: "P", value: "P" },
  ]
  const ficaStatus = [
    { label: "N", value: "N" },
    { label: "P", value: "P" },
    { label: "A", value: "A" },
  ]
  const ficaOption = [
    { label: "N", value: "N" },
    { label: "B", value: "B" },
    { label: "F", value: "F" },
  ]
  const paymentOption = [
    { label: "N", value: "N" },
    { label: "P", value: "P" },
    { label: "A", value: "A" },
  ]
  const vipOption = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]

  const [userProfile, setUserProfile] = useState({
    img: "",
    name: "",
    designation: "",
    phone: "",
    email: "",
    location: "",
  })

  const [userInfoProfile, setUserInfoProfile] = useState([])

  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedFicaGroup, setselectedFicaGroup] = useState(null)
  const [selectedFicaOptionGroup, setselectedFicaOptionGroup] = useState(null)
  const [selectedu_Status, setSelectedu_Status] = useState('')
  const [selectedFicaStatus, setSelectedFicaStatus] = useState('')
  const [selectedFicaOption, setSelectedFicaOption] = useState('')
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('')
  const [userUserid, setUserUserid] = useState('')
  const [userMainId, setUserMainId] = useState('')
  const [userInfo, setUserInfo] = useState([])

  const [vipStatus, setVIPStatus] = useState('')

  const [referralStatus, setReferralStatus] = useState('C10000002')
  

  const [idupload, setIDUpload] = React.useState('')
const [poaupload, setPOAUpload] = React.useState('')
  

  let { id } = useParams()

  useEffect(async () => {
    console.log('the id is  ', id)
    var usrOb = {
        meta_key: 'signup',
        userid: '#' + id
    }
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + `/get-meta`, usrOb)
      .then(res => {

        console.log('user data received ', res.data.body)
        if(res.data.status == 200)
        {
            setUserInfo(res.data.body)
        }
        // setselectedGroup({ label: status, value: status })
        // setselectedFicaGroup({ label: ficastatus, value: ficastatus })
        // setselectedFicaOptionGroup({ label: ficaoption, value: ficaoption })
        // setUserUserid(res.data.user.userid)
        // setselectedPaymentOptionGroup({ label: paymentstatus, value: paymentstatus })
      })
  }, [])



  function progressChange(change) {
    console.log("Change is ", change)
    userInfo.progress = change
  }

  function onChangeReferral(referral) {
    console.log("Change is ", referralStatus)
    setReferralStatus(referral)
  }


  const handleSaveData = async() => {
    const UserMetaData = {
        userid: "#" + userInfo.unique,
        meta_key: "signup",
        meta_value: JSON.stringify(userInfo)
    }
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + `/update-meta`, UserMetaData)
      .then(res => {
        console.log(res.data)
        return <Redirect to='/user-list'  />
      })
  }

  const handleCreateUser = async() => {
    userInfo.status = "1"
    const UserMetaData = {
        userid: "#" + userInfo.unique,
        meta_key: "signup",
        meta_value: JSON.stringify(userInfo)
    }
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + `/update-meta`, UserMetaData)
      .then(res => {
        console.log(res.data)
        return <Redirect to='/user-list'  />
      })

        //We now need to register this user
        const userObject =
        {
        usertype: userInfo.usertype,
        password: userInfo.password,
        email: userInfo.email,
        name: userInfo.name,
        last: userInfo.last,
        cell: userInfo.cell,
        terms: userInfo.terms,
        popi: userInfo.popi,
        ucode: referralStatus
        }
        
        await axios.post(process.env.REACT_APP_BASE + "api/create-user",userObject).then(async res =>{

        if(res.data.status == 200)
        {
            toastr.success("User Created!!"); 
            return false;

        }else{
            toastr.error(res.data.message); 
        }

        });

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Profile</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="User List" breadcrumbItem="Profile" />
          <Row>
            <Col xl="10">
              <Card>
                <CardBody>
                
                  <CardTitle className="mb-4">User Informations</CardTitle>

                  <div className="table-responsive">
                    <FormGroup className="mb-3">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Company :</th>
                            <td>{userInfo.company_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">CIPC :</th>
                            <td>{userInfo.cipc}</td>
                          </tr>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{userInfo.name + ' ' + userInfo.last}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{userInfo.cell}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{userInfo.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Compliance :</th>
                            <td>{userInfo.compliance}</td>
                          </tr>
                          <tr>
                            <th scope="row">ID / Passport Number :</th>
                            <td>{userInfo.id_num}</td>
                          </tr>

                          <tr>
                            <th scope="row">Status :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                    <select className="form-control" onChange= {(e) => progressChange(e.target.value)} value={userInfo.progress}>
                                        <option value="Signed Up">Singed Up</option>
                                        <option value="Waiting Contract">Waiting Contract</option>
                                        <option value="Signed">Signed</option>
                                    </select>
                                  {/* <select
                                    className="form-control"
                                    value={selectedGroup?.value}
                                    onChange={e => {
                                      const value = optionGroup.find(
                                        o => o.value === e.target.value
                                      )
                                      setselectedGroup(value)
                                    }}
                                  >
                                    {optionGroup.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select> */}

                                    {/* <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setSelectedu_Status(e.target.value)}
                                        value={selectedu_Status}
                                        // value={newCurrency}
                                    >
                                      {optionGroup.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select> */}

                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Link To C10000002 :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                    <select className="form-control" onChange= {(e) => onChangeReferral(e.target.value)} value={referralStatus}>
                                        <option value="C10000002">Yes</option>
                                        <option value="C10000001">No</option>
                                    </select>

                                </div>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </Table>
                      
                      <Button
                        color="primary"
                        type="submit"
                        onClick={handleSaveData}
                        className="btn c1-primary btn-block "
                      >
                        Save
                      </Button>
                    </FormGroup>
                    <FormGroup>
                    <Button
                        color="primary"
                        type="submit"
                        onClick={handleCreateUser}
                        className="btn c1-primary btn-block "
                      >
                        Create User
                      </Button>
                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
