import PropTypes from 'prop-types'
import React, {useState} from "react"

import CountryDropdown from 'country-dropdown-with-flags-for-react';  

import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    Button,
    NavItem,
    NavLink,
    InputGroup,
    Modal,
    ModalBody

  } from "reactstrap";

  import { UserContext } from "../../UserContext";

  
  import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"

const FicaPopupModal1 = ({ nextStep, option, ContinueNextStep, prevStepFica }) => {

    const{auth,user} = React.useContext(UserContext)

    // const [riskQuestionNumber, setRiskQuestionNumber] = React.useState(1);
    const [riskQuestion, setRiskQuestion] = React.useState('');
    const [displayQuestion, setDisplayQuestion] = React.useState(false);
    const [idNumber, setIdNumber] = React.useState('');
    const [dob, setDOB] = React.useState('');
    const [country, setCountry] = React.useState('South Africa');
    const [address, setAddress] = React.useState('');
    const [sourceFunds, setSourceFunds] = React.useState('Savings');
    
    
    const [useridUpload, setuseridUpload] = useState([])
    const [userpoaupload, setuserpoaupload] = useState([])
    const [userficaupload, setuserficaupload] = useState([])
    const [selectedFiles, setselectedFiles] = useState([])

    // const Example = () => {
    //     const handleSelect = (country) => {
    //       console.log(country)
    //       /* returns the details on selected country as an object
    //           {
    //             name: "United States of America", 
    //             code: "US", 
    //             capital: "Washington, D.C.", 
    //             region: "Americas", 
    //             latlng: [38, -97]
    //           }
    //       */
    //     }
    //     return (
    //       <div>
    //         <ReactCountryDropdown onSelect={handleSelect} countryCode='IN' />
    //       </div>
    //     )
    //   }

    React.useEffect(async res =>{

        

        // if(user !== 'undefined' && user !== null)
        // {
            // if(!displayQuestion)
            // {
                // getQuestions()
                // setDisplayQuestion(true)
            // }
 
        // }
      
      })

      const onOptionClick = (newmodalView) => {

        if(useridUpload !== undefined && useridUpload.length != 0 && userpoaupload !== undefined && userpoaupload.length != 0 && idNumber != ''
        && dob != '' && address != '' && sourceFunds != '' && country != '') {
        option['idNumber'] = idNumber;
        option['dob'] = dob;
        option['address'] = address;
        option['sourceFunds'] = sourceFunds;
        option['useridUpload'] = useridUpload;
        option['userpoaupload'] = userpoaupload;
        option['userficaupload'] = userficaupload;
        option['country'] = country;

        ContinueNextStep()
        }else{
            toastr.error("Please fill in all required information") 
        }
      }


      function handleAcceptedFiles(files) {
        console.log(files)
        console.log(URL)
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
        setselectedFiles(files)
        setuseridUpload(files)
        
      }

      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

      function handleAcceptedPOAFiles(files) {
        console.log(files)
        console.log(URL)
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
      
        setuserpoaupload(files)
        
      }

      function handleAcceptedFicaFiles(files) {
        console.log(files)
        console.log(URL)
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
      
        setuserficaupload(files)
        
      }

      
      


  return (
    <div>
   
    <Modal isOpen={true}  centered={true}>
      <ModalBody className="py-3 px-5">

        <div>

            <div className="p-1">
                <Col className='col-12'>
                    <h2 className="text-center">Step {nextStep}</h2>
                </Col>

                <Form className="form-horizontal fvw-login-form">

                    <Row>
                        <div>
                            <Row>
                            <Col xl="12" className='col-12'>
                                <Row>
                                    <h4>KYC Process</h4>
                                    <Col md="12"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                        <label>{option['usertype'] == '1' || option['usertype'] == 1 ? 'Director ' : ''} ID / Passport Number</label>
                                        <Input
                                            name="id"
                                            className="form-control"
                                            placeholder="ID / Passport Number"
                                            type="text"
                                            onChange={(e)=>setIdNumber(e.target.value)}
                                            value={idNumber}
                                        />
                                        <br/>

                                        <label>{option['usertype'] == '1' || option['usertype'] == 1 ? 'Director ' : ''} Date Of Birth</label>
                                        <Input
                                            name="dob"
                                            className="form-control"
                                            placeholder="Date Of Birth"
                                            type="date"
                                            onChange={(e)=>setDOB(e.target.value)}
                                            value={dob}
                                        />
                                        <br/>

                                        <label>Address</label>
                                        <Input
                                            name="dob"
                                            className="form-control"
                                            placeholder="Address"
                                            type="text"
                                            onChange={(e)=>setAddress(e.target.value)}
                                            value={address}
                                        />
                                        <br/>

                                        <label>{option['usertype'] == '1' || option['usertype'] == 1 ? 'Director ' : ''} Country Of Residence</label>
                                        {/* <div>{Example}</div> */}
                                        {/* <Input
                                            name="country"
                                            className="form-control"
                                            placeholder="Country"
                                            type="text"
                                            onChange={(e)=>setCountry(e.target.value)}
                                            value={country}
                                        /> */}

                                        {/* <select
                                        name="dob"
                                        className="form-control"
                                        placeholder="Country"
                                        type="date"
                                        onChange={(e)=>setCountry(e.target.value)}
                                        value={country}
                                        >
                                            <option value=''>Select Country</option>
                                            <option value='rsa'>South Africa</option>
                                        </select> */}

<CountryDropdown  id="countryID" className='countryClass' preferredCountries={['za', 'us']}  value=""  handleChange={e => setCountry(e.target.value)} ></CountryDropdown> 

                                        <br/>

                                        <label>Description of source funds</label>
                                        {/* <Input
                                            name="dob"
                                            className="form-control"
                                            placeholder="Source of Funds"
                                            type="text"
                                            onChange={(e)=>setSourceFunds(e.target.value)}
                                            value={sourceFunds}
                                        /> */}

                                        <select name="source" className="form-control" onChange={(e)=>setSourceFunds(e.target.value)} value={sourceFunds} >
                                            <option value="Savings">Savings</option>
                                            <option value="Pension Fund">Pension Fund</option>
                                            <option value="Sale of shares">Sale of shares</option>
                                            <option value="Sale of property">Sale of property</option>
                                            <option value="Inheritance">Inheritance</option>
                                            <option value="Dividends">Dividends</option>
                                            <option value="Trading">Trading</option>
                                            <option value="Winnings">Winnings</option>
                                            <option value="Compensation">Compensation</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <br/>

                                        <Label className="form-label">{option['usertype'] == '1' || option['usertype'] == 1 ? 'Director ' : ''} Upload ID / Passport</Label>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                        {useridUpload.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        </div>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles)
                                        }}>
                                            {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                                >
                                                <input {...getInputProps()} type="file" name="userUpload" />
                                                <div className="dz-message needsclick">
                                                    <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                        </Dropzone>
                                        <br />

                                        <Label className="form-label">{option['usertype'] == '1' || option['usertype'] == 1 ? 'Director ' : ''} Upload Proof Of Address</Label>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                        {userpoaupload.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        </div>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedPOAFiles(acceptedFiles)
                                        }}>
                                            {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                                >
                                                <input {...getInputProps()} type="file" name="userUpload" />
                                                <div className="dz-message needsclick">
                                                    <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                        </Dropzone>

                                        
                                        {option['usertype'] == '1' || option['usertype'] == 1 ?
                                        <div>
                                        <Label className="form-label">{option['usertype'] == '1' || option['usertype'] == 1 ? 'Director ' : ''} CIPC Documents</Label>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                        {userficaupload.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        </div>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFicaFiles(acceptedFiles)
                                        }}>
                                            {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                                >
                                                <input {...getInputProps()} type="file" name="userUpload" />
                                                <div className="dz-message needsclick">
                                                    <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                        </Dropzone>
                                        </div>

                                        : ''}
                                        
                                        

                                    </Col>

                                </Row>
                            </Col>

                            <Row>
                        
                                <Col md="1"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>
                                <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}>

                                    <a href="#" onClick={prevStepFica} className="btn c1-primary btn-block">Back To My Wallet</a>
                               </Col>
                                <Col md="2"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>
                                <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}>
                                    <a href="#" onClick={()=>onOptionClick()} className="btn c1-primary btn-block">Save</a>
                                          
                                </Col>
                                <Col md="1"  style={{marginTop: '15px', marginBottom: '10px'}}></Col>

                            </Row>

                            </Row>
                        </div>
                    </Row>

                    <Row>
                    <Col className='col-12'>

                    <Row className="mt-3 mb-2"> 

                        {/* <Col className='col-6'>
                            <Button
                            className="ml-2 btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"

                            variant="contained"
                            // onClick={canContinue}
                            // onClick={(e)=>goToPrevious(e.target.value)}
                            onClick={onPrevClick}
                            ><span><i className="bx bxs-left-arrow" style={{float: "left",
                            marginTop: "5px"}}></i></span>Back</Button>
                        </Col> */}
                        {/* <Col className='col-6 text-right' style={{textAlign: "right"}}>
                        {question == 7 ?
                            <Button

                            variant="contained"
                            className="btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"
                            // onClick={canContinue}
                            // onClick={(e)=>canContinue(e.target.value)}
                            onClick={onNextClick}
                            >Continue<span><i className="bx bxs-right-arrow" style={{float: "right",
                            marginTop: "5px"}}></i></span></Button>
                            : 
                            <Button

                            variant="contained"
                            className="btn c1-primary c1-primary-inline-btn fwv-button-content btn-block"
                            // onClick={canContinue}
                            // onClick={(e)=>canContinue(e.target.value)}
                            onClick={onNextClick}
                            >Next<span><i className="bx bxs-right-arrow" style={{float: "right",
                            marginTop: "5px"}}></i></span></Button>
                            }
                        </Col> */}

                    </Row> 

                    </Col>
                    </Row>               

                </Form>
            </div>
        </div>
      </ModalBody>
    </Modal>

    </div>
  )
}

FicaPopupModal1.propTypes = {
    nextStep: PropTypes.any,
    option: PropTypes.any,
    ContinueNextStep: PropTypes.func,
    prevStepFica: PropTypes.func,
}

export default FicaPopupModal1