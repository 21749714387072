import React, { Component,useEffect, useState } from 'react'
import MetaTags from "react-meta-tags";
import axios from 'axios';
// import PropTypes from "prop-types"

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'


import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  Button,
  NavItem,
  NavLink,
  InputGroup

} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { UserContext } from "../../../UserContext";

// action


//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import Icon from "react-crypto-icons";

import PropTypes from "prop-types"
import classnames from "classnames"



// Add datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MiniWidget from "../../Dashboard-crypto/mini-widget"
import CurrencyWidget from "../../Dashboard-crypto/currency-widget"

import icons from 'currency-icons';

// const UserDetails = ({ nextStep, handleChange, values}) => {
    // export class UserDetails extends Component {
        // function UserDetails({nextStep, handleChange, values}) {
        const DepositAmountView = ({ walletOption, continueModal }) => {

            const{auth,user} = React.useContext(UserContext)

            console.log('walletOption is' , walletOption)
        
   
    // render() {
        const [isMenu, setisMenu] = useState(false)
        const [alreadySent, setalreadySent] = useState(false)
        const [userUserid, setUserid] = useState("")
        
        const [activeTab, setActiveTab] = useState("1")

        const [userBalance, setuserBalance] = React.useState('');
        const [vaultStartDate, setVaultStartDate] = React.useState('');
        const [vaultEndDate, setVaultEndDate] = React.useState('');
        const [cryptoOptions, setCryptoOptions] = useState([])
const [currencyOptons, setCurrencyOptons] = useState([])
const [viewAllTransactions, setAllTransactions] = React.useState([]);
const [checkedTransactions, setCheckedTransactions] = React.useState(false);
        

        useEffect(async res =>{
            if(user !== 'undefined' && user !== null)
            {
              
             
              getuserBalance(user.user.userid) 
              setUserid(user.user.userid)
              console.log('otpsent ', walletOption['otpSent'])
  
              if(!walletOption['otpSent'])
              {
                walletOption['otpSent'] = true
                sendNewOTP()
                
       
              }
              getDates()

            }
          
          
          },[auth])
          
          // const getuserBalance = async userid => {

          //   var transactionObject = {
          //     'userid': userid,
          //   }
            
          //   if(!checkedTransactions)
          //   {
          //       // Get the list of all the transactions
          //       await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then( res2 =>{
          
          //         if(res2.data.status == 200)
          //         {
          //           setAllTransactions(res2.data.transactions)
          //         }
          //         setCheckedTransactions(true);
                  
          //       })
          //   }
          
          
          // }
          
          const getuserBalance = async userid => {

            if(walletOption['currency'] == 'zar')
            {
              var balanceObject = {
                userid: userid,
                currency: '2'
              }
            }else{
              var balanceObject = {
                userid: userid,
                currency: '1'
              }
            }

          
            await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{
          
              console.log('set the balance to ', res.data)
              return setuserBalance(res.data.total);
           
            })
          
          }

          const sendNewOTP = () => {
            walletOption['otpSent'] = true
            var otpObj = {
              userid: user.user.userid
            }
            
            axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
                    
              if(res.data.status == 200)
              {
                toastr.success("OTP Pin Successfully Sent!") 
              }else{
                toastr.error("Unable To send OTP!") 
              }
              
            })
          
          }
        
      
      
        const dispatch = useDispatch();
      
        function canContinueModal(amount){
            console.log('amount to set is' , amount)
            walletOption['amount'] = amount;
            walletOption['product'] = '17';
            console.log('walletOption is' , walletOption)
            return false;
          };

          function setOTP(otp){
            console.log('otp to set is' , otp)
            walletOption['otp'] = otp;
            console.log('walletOption is' , walletOption)
            return false;
          };

          function getDates()
          {
            console.log("get dates is now called")
            var invest_date = new Date().toISOString().split("T")[0];
            var current_dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
          var current_date = new Date(current_dateNow);
          
            var dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
          
            var date = new Date(dateNow);
              console.log(date);
            var y = date.getFullYear(),
              m = date.getMonth(),
              d = date.getDate(),
              h = date.getHours();

                console.log(current_date.getDate());
                var start_date = new Date(y, m, d+1).toISOString().split("T")[0];
                var expire_date = new Date(y, m + 12, d+1).toISOString().split("T")[0];
                

            setVaultStartDate(start_date)
            setVaultEndDate(expire_date)
            return true;
          }
      

          var report = {
            title: "USDT",
            icon: "usdt",
            color: "white"
          }

          var zarReport = {
            title: "ZAR",
            icon: "zar",
            color: "white"
          }


    return (
      <React.Fragment>
        <div>
{/* 
        <Col className='col-12 mt-2'>
            <p>Balance: {userBalance} USDT</p>
            <p>Start Date: {vaultStartDate}</p>
            <p>End Date: {vaultEndDate}</p>
        </Col> */}

<Col className='col-12 mt-2'>
            {/* <p>Balance: {userBalance} USDT</p> */}




           <Row>


           {walletOption['currency'] == 'zar' ?
              <Col xl="6" md="6" sm="6">
                <Card className='c1-primary-dark-card'>
                  <CardBody>
                    <div className="text-muted mb-4">
                      <Icon name={zarReport.icon}  className={
                          " h2 text-" +
                          zarReport.color +
                          " align-middle mb-0 me-3"
                        } size={25}  />{" "}
                      {zarReport.title}{" "}
                    </div>

                    <Row>
                      <Col xs="12">
                        <div>
                          <h5>{zarReport.title + ' ' + userBalance}</h5>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              :
              <Col xl="6" md="6" sm="6">
                <Card className='c1-primary-dark-card'>
                  <CardBody>
                    <div className="text-muted mb-4">
                      <Icon name={report.icon}  className={
                          " h2 text-" +
                          report.color +
                          " align-middle mb-0 me-3"
                        } size={25}  />{" "}
                      {report.title}{" "}
                    </div>

                    <Row>
                      <Col xs="12">
                        <div>
                          <h5>{report.title + ' ' + userBalance}</h5>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
        }

              
            </Row>

            <div className="c1-container" style={{  display: "flex", alignItems: "baseline" ,height: "2rem" }}>
        
              <h2 style={{ fontFamily: "sans-serif", lineHeight: 1, fontSize: "1rem", margin: 0, padding: "10px 5px", background: "#3f3f3f", color: "#ffffff", borderRadius: "5px" }}>Start Date: {vaultStartDate}</h2>
           
            </div>

            <div className="c1-container" style={{  display: "flex", alignItems: "baseline" ,height: "2rem" }}>

              <h2 style={{ fontFamily: "sans-serif", lineHeight: 1, fontSize: "1rem", margin: 0, padding: "10px 5px", background: "#3f3f3f", color: "#ffffff",  borderRadius: "5px", marginTop: "10px"}}>End Date: {vaultEndDate}</h2>
       
            </div>

            {/* <p>Start Date: {vaultStartDate}</p>
            <p>End Date: {vaultEndDate}</p> */}
        </Col>

            <Col className='col-12'>
                <Col md="12"  style={{marginTop: '15px', marginBottom: '10px'}}>

                <Label className="loginLabel">Enter OTP:</Label>
                    <Input
                      style={{color: "#000000 !important;"}}
                      name="depositAmount"
                      autoComplete="new-password"
                      className="form-control fwv-input-control text-dark"
                      placeholder="Enter OTP"
                      type="number"
                      min="0"
                      max="1000000"
                      step="0.5"
                      onChange={(e)=>setOTP(e.target.value)}
                    //   value={walletOption['amount']}
                    
                    />
                    <br />


                    <Label className="loginLabel">Entry Amount ({walletOption['currency']})</Label>
                    <Input
                      style={{color: "#000000 !important;"}}
                      name="depositAmount"
                      autoComplete="new-password"
                      className="form-control fwv-input-control text-dark"
                      placeholder="Entry Amount"
                      type="number"
                      min="0"
                      max="1000000"
                      step="0.5"
                      onChange={(e)=>canContinueModal(e.target.value)}
                    //   value={walletOption['amount']}
                    
                    />
                </Col>
            </Col>
        </div>
      </React.Fragment>
    );
//   }
}


DepositAmountView.propTypes = {
    walletOption: PropTypes.any,
    continueModal: PropTypes.func,
  }


export default DepositAmountView;