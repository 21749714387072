import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Spinner
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import { CSVLink, CSVDownload } from "react-csv";

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import Datatable from "components/Datatable/datatable.component"

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const allTransactions =()=>{
    const [viewAllTransactions, setAllTransactions] = React.useState([]);

    const{auth,user} = React.useContext(UserContext)
    const[StartpackList,setStartpackList] = React.useState([])
    const [viewAllUserInfo, setAllUserInfo] = React.useState([]);

    const [displaySpinner, setDisplaySpinner] = useState(false)

    React.useEffect(()=>{
      $("[data-rmiz-wrap]").css("justify-content","center");
      $("[data-rmiz-overlay]").css("text-align","center");
      if(auth!=null)
      {
       if(!auth) {
        Swal.fire({
          title: 'Error!',
          text: 'Your session has expired, click below to start a new session ',
          icon: 'error',
          confirmButtonText: 'Login',
          confirmButtonColor: '#29a927',
          allowOutsideClick:false
        }).then((result)=>{
          if(result.isConfirmed){
            window.location.href = "/login" 
          }
        })
       
       }
      }
     },[auth])




const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

React.useEffect(async res =>{
  setDisplaySpinner(true)
    if(user !== 'undefined' && user !== null)
    {
        await axios.get(process.env.REACT_APP_BASE + "api/all-transactions").then((res)=>{
            console.log('all transactions ', res.data)
            setAllTransactions(res.data.transactions)
            setAllUserInfo(res.data.users)
            setDisplaySpinner(false)
        });
    }

},[auth])

var transactionTypes = {
  '1': 'Deposit',
  '2': 'Withdrawal',
  '3': 'Product Sale',
  '4': 'Interest Paid',
  '5': 'Membership Fee',
  '6': 'Ref Comms Paid',
  '7': 'Product Term Expired',
  '8': 'Exchange Added!'
}

const datatableInfo = {
  title: "All Transactions",
  shortDescription: "List of all Transactions",
}




const columns = [{
  dataField: 'userid',
  text: 'Userid',
  sort: true,
},{
  dataField: 'user',
  text: 'User',
  sort: true,
},{
  dataField: 'trans_id',
  text: 'TransId',
  sort: true,
},{
  dataField: 'amount',
  text: 'Amount'
}, {
  dataField: 'tran_type',
  text: 'Type'
},{
  dataField: 'status',
  text: 'Status'
},{
  dataField: 'createdAt',
  text: 'Date'
}];

// Table Data
const productData = [];

var x = 0;

var labelData = [];
var barData = [];
if(typeof viewAllTransactions !== 'undefined' && viewAllTransactions.length > 0) {
  viewAllTransactions.forEach(transaction => {
  var numid = x.toString();
//=================  Donut chart ===========================================================
  var transtatus = 'Pending';
  if(transaction.status == '1')
  {
   transtatus = 'Approved'
  }else if(transaction.status == '2'){
   transtatus = 'Declined'
  }



   var productDataObj = {
      userid: transaction.userid,
      user: viewAllUserInfo[transaction.userid],
      trans_id: transaction.trans_id,
      amount: transaction.amount,
      tran_type: transactionTypes[transaction.tran_cat],
      status: transtatus,
      createdAt: transaction.createdAt,
      
   };

   productData.push(productDataObj)

   x = x+1;
});
}

    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 Holdings- All Transactions</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="All Transactions" />

          <Row>

          <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                    {displaySpinner ? 
                          <Spinner className="ms-2" color="success" />
                          :
                        <div>
                          <Col md="12">
                            <CSVLink data={productData} filename={"all-transactions.csv"} className="btn btn-primary btn btn-outline-success" target="_blank">Download me</CSVLink>
                          </Col>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={productData}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                     
                      </div>
                        }
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* <Col xl="12">
              <Card className="receiveBGCards">
                <CardBody>
                  <h4 className="card-title mb-4">Transaction History</h4>

                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>

                        <tr>
                        <th scope="col">UserInfo</th>
                          <th scope="col">Userid</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Type</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                        </tr>

                      </thead>
                      <tbody>

                     
                        {viewAllTransactions.map((viewAllTransaction, key) => (
                             <tr key={viewAllTransaction.id}>
                              <td>{viewAllTransaction.userid ? 
                                viewAllUserInfo[viewAllTransaction.userid]: ''}</td>
                               <td>{viewAllTransaction.userid ? 
                                viewAllTransaction.userid: ''}</td>
                                <td>{viewAllTransaction.currency == '1' ? 
                                'USDT(TRC20)' + viewAllTransaction.amount: viewAllTransaction.currency == '2' ? 'ZAR' + viewAllTransaction.amount:
                                'BTC' + viewAllTransaction.amount}</td>

                                <td  >{viewAllTransaction.tran_cat == '5' ? parseFloat(viewAllTransaction.amount) > 0 ? transactionTypes[viewAllTransaction.tran_cat] + ' Deposit' : transactionTypes[viewAllTransaction.tran_cat] + ' Payment' : transactionTypes[viewAllTransaction.tran_cat]}</td>
                                
                                <td className={viewAllTransaction.status == '1' ? 'bg-success text-light' : 'bg-danger text-light'}>{viewAllTransaction.status == '1' ? 
                                 'Approved' : viewAllTransaction.status == '2'? 'Declined' : 'Pending'}</td>
                                <td>{viewAllTransaction.createdAt ? 
                                 viewAllTransaction.createdAt : ''}</td>
                    
                          </tr>
                        ))}
  
                   

                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
        </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}