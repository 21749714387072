import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Form,
  Spinner,
} from "reactstrap"

import classnames from "classnames"
  import { UserContext } from "../../UserContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// icons
// import Icon from "react-crypto-icons";

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"

import MiniWidget from "../Dashboard-crypto/mini-widget"
import CurrencyWidget from "../Dashboard-crypto/currency-widget"

import io from "socket.io-client"

import Icon from "react-crypto-icons";

// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const WalletExchange =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userBalanceTo, setUserBalanceTo] = React.useState('0');
  
  const [userZarBalance, setuserZarBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [checkedTransactions, setCheckedTransactions] = React.useState(false);

  const [exchangebuy1, setExchangebuy1] = React.useState('');
  const [exchangebuy2, setExchangebuy2] = React.useState('');
  const [exchangesell1, setExchangesell1] = React.useState('');
  const [exchangesell2, setExchangesell2] = React.useState('');

  const [cryptoOptions, setCryptoOptions] = useState([])
  const [currencyOptons, setCurrencyOptons] = useState([])
  const [allCurrencies, setAllCurrencies] = useState([])
  const [jsonCurrencyData, setJsonCurrencyData] = useState({})
  const [exchangeFee, setExchangeFee] = useState(0)
  
  
  const [exchangeObjectOptions, setExchangeObjectOptions] = React.useState({'12': 0, '13': 0, '21': 0, '23': 0, '31': 0, '32': 0});
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])

const [newOtpOption, setNewOtpOption] = useState('1')
const [newOtpOptionTo, setNewOtpOptionTo] = useState('2')

const [currencyOptionGroup, setCurrencyOptionGroup] = useState([])
const [selectedBalance, setSelectedBalance] = useState('0')

const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")
const [displaySpinner, setDisplaySpinner] = useState(false)

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}

const socket = io(process.env.REACT_APP_BASE, {});
window.socket = socket;

  React.useEffect(async ()=>{
    $("[data-rmiz-wrap]").css("justify-content","center");
    $("[data-rmiz-overlay]").css("text-align","center");
    if(auth!=null)
    {
     if(!auth) {
      Swal.fire({
        title: 'Error!',
        text: 'Your session has expired, click below to start a new session ',
        icon: 'error',
        confirmButtonText: 'Login',
        confirmButtonColor: '#29a927',
        allowOutsideClick:false
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.href = "/login" 
        }
      })
     
     }else{
      console.log(auth)
      if(user !== undefined || user !== null)
      {
       
        console.log(user)
        setuserName(user.user.name);
        setuserEmail(user.user.email);
        setuserUserid(user.user.userid);


        setNewOtpOption('1');
        getuserBalance('1')
        // getuserBalance(user.user.userid)

        const cryptoBalance = {
          userid: user.user.userid,
          crypto: 'true'
        }
      
          await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",cryptoBalance).then(async res =>{
            console.log('crypto options is ', res.data.currencyInfo)
            setCryptoOptions(res.data.currencyInfo)
            res.data.currencyInfo.map((report, key) => (
              allCurrencies.push(report)
            ))
           
          })
        
          const currencyBalance = {
            userid: user.user.userid,
            crypto: 'false'
          }
      
          await axios.post(process.env.REACT_APP_BASE + "api/get-user-currencies",currencyBalance).then(async res =>{
            setCurrencyOptons(res.data.currencyInfo)
            res.data.currencyInfo.map((report, key) => (
              allCurrencies.push(report)
            ))
          })
      
          resetCurrencies()
          getAllCurrencies()

      }
     }
    }
   },[auth])


const getuserBalance = async (currency) => {
  var userid = userUserid
  var balanceUSDTObject = {
    'userid': userid,
     'currency': currency
  }

  await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceUSDTObject).then(async res =>{

    // console.log('set the balance to ', res.data)
    return setuserBalance(res.data.total);
 
  }).then(async res1 =>{
    var transactionObject = {
      'userid': userid,
    }
    
    if(!checkedTransactions)
    {
        // Get the list of all the transactions
        await axios.post(process.env.REACT_APP_BASE + "api/view-transaction",transactionObject).then( res2 =>{

          if(res2.data.status == 200)
          {
            setAllTransactions(res2.data.transactions)
            console.log('transactions is ', viewAllTransactions)
          }
          setCheckedTransactions(true);
          
        })
    }
  })

  var balanceZARObject = {
    'userid': userid,
     'currency': '2'
  }

  await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceZARObject).then(async res =>{

    // console.log('set the balance to ', res.data)
    return setuserZarBalance(res.data.total);
 
  })

}

const getuserBalanceTo = async (currency) => {
  var userid = userUserid
  var balanceUSDTObject = {
    'userid': userid,
     'currency': currency
  }

  await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceUSDTObject).then(async res =>{

    // console.log('set the balance to ', res.data)
    return setUserBalanceTo(res.data.total);
 
  })


}

function getAllCurrencies() {

  axios.get(process.env.REACT_APP_BASE + "api/get-currencies").then((res)=>{  
    res.data.currencies.map((currency, key) => (
      currencyOptionGroup[currency.id] = currency.name
    ))
  })
  console.log('currency group is ', currencyOptionGroup)

}

const buyBtn = async e => {
  setDisplaySpinner(true)
  console.log(typeof userBalance !== 'undefined', parseFloat(userBalance).toFixed(2) > 0, parseFloat(exchangebuy2).toFixed(2) > 0, parseFloat(userBalance) >= parseFloat(exchangebuy1));
   console.log(parseFloat(userBalance), parseFloat(exchangebuy1), parseFloat(userBalance) >= parseFloat(exchangebuy1))
  if(typeof userBalance !== 'undefined' && parseFloat(userBalance).toFixed(2) > 0 && parseFloat(exchangebuy2).toFixed(2) > 0 && parseFloat(userBalance) >= parseFloat(exchangebuy1))
    {

      

        // const userid = req.body.userid
        // const excahnge_type = req.body.excahnge_type
        // const amount_from = req.body.userid
        // const amount_to = req.body.excahnge_type
        // const status = req.body.status

        // Exchange Type
        // 1 - Buy
        // 2 - Sell



        setExchangebuy2(parseFloat(totalAmountTobuy).toFixed(2))

          var amountTobuy = exchangebuy1 * exchangeObjectOptions[newOtpOption + newOtpOptionTo]
          var feeTotal = (amountTobuy * 3) / 100
          var totalAmountTobuy = amountTobuy - feeTotal
          
          // exchange_rate: { type:DataTypes.DOUBLE },
          // exchange_amount: { type:DataTypes.DOUBLE },
          // fee: { type:DataTypes.DOUBLE },
          
  
          const exchangeObject = {
              userid: user.user.userid,
              excahnge_type: '1',
              amount_from: newOtpOption,
              amount_to: newOtpOptionTo,
              status: '0',
              amount: parseFloat(totalAmountTobuy).toFixed(2),
              from_amount: parseFloat(exchangebuy1).toFixed(2),
              exchange_rate: parseFloat(exchangeFee).toFixed(2),
              exchange_amount: amountTobuy,
              fee: feeTotal,
            }

          console.log('it is enough ', userBalance, exchangeObject)

        await axios.post(process.env.REACT_APP_BASE + "api/create-exchange",exchangeObject).then(async res2 =>{

            if(res2.data.status == 200)
            {
              getuserBalance(newOtpOption)
              
              // var balanceZARObject = {
              //   'userid': user.user.userid,
              //    currency: '2'
              // }
              // await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceZARObject).then(async resBal =>{
              //   // console.log('set the balance to ', res.data)
              //   return setuserZarBalance(resBal.data.total);
              // })


              toastr.options.onHidden = function() { window.location.href="/wallet-exchange" } 
                toastr.success(res2.data.message)
            }else{
              toastr.options.onHidden = function() { window.location.href="/wallet-exchange" } 
                toastr.error("Unable to create exchange at the moment!")
            }

        })


    }else{
      console.log('not enough')
        toastr.error("Insufficient funds!")
        setDisplaySpinner(false)
    }

}

const buySell = async e => {
    if(userBalance >= exchangesell1)
    {
        
        // console.log(exchangesell1, exchangesell2)
        // const userid = req.body.userid
        // const excahnge_type = req.body.excahnge_type
        // const amount_from = req.body.userid
        // const amount_to = req.body.excahnge_type
        // const status = req.body.status

        // Exchange Type
        // 1 - Buy
        // 2 - Sell

        // Amount From/To
        // 2 - ZAR
        // 1 - USDT

        const exchangeObject = {
            userid: user.user.userid,
            excahnge_type: '2',
            amount_from: '1',
            amount_to: '2',
            status: '0',
            amount: exchangesell1
          }

        await axios.post(process.env.REACT_APP_BASE + "api/create-exchange",exchangeObject).then( async res2 =>{

            if(res2.data.status == 200)
            {
              var balanceUSDTObject = {
                'userid': user.user.userid,
                 'currency': '1'
              }
              await axios.post(process.env.REACT_APP_BASE + "api/user-balance",balanceUSDTObject).then(async resBal =>{
                return setuserBalance(resBal.data.total);
              })
              toastr.options.onHidden = function() { window.location.href="/wallet-exchange" } 
                toastr.success(res2.data.message)
            }else{
              toastr.options.onHidden = function() { window.location.href="/wallet-exchange" } 
                toastr.success("Insufficient funds!")
            }

        })
    }else{
        toastr.success("Insufficient funds!")
    }
    
}

var reports = cryptoOptions

var currencies = currencyOptons

function handleOTPChange(e) {
  setExchangebuy2(0)
  setExchangebuy1(0)
  console.log("handle change is ", e.target.value)
  setNewOtpOption(e.target.value);
  getuserBalance(e.target.value)
}

function handleOTPChangeTo(e) {
  setExchangebuy2(0)
  setExchangebuy1(0)
  console.log("handle change is ", e.target.value)
  setNewOtpOptionTo(e.target.value);
  getuserBalanceTo(e.target.value)
}



function resetCurrencies() {
  reports = cryptoOptions
  
  currencies = currencyOptons
  console.log("all currencies data is ", allCurrencies)
}

React.useEffect(() =>{
  console.log('added listener');
  socket.on('currency update', onCurrencyUpdate);
  socket.emit('currency update', {});
 

  return () => {
    console.log('removed listener');
    socket.off('currency update', onCurrencyUpdate)
  }
}, [])

const onCurrencyUpdate = (data) => {
  console.log('getting this one ', data)
  console.log('Custom Data', data);
  setJsonCurrencyData(data);
  console.log(data.customData.btc2usd)
  if(typeof data.customData.btc2usd == 'undefined' || typeof data.customData.btc2zar == 'undefined' || typeof data.customData.zar2usd == 'undefined' )
  {
    Swal.fire({
      title: 'Error!',
      text: 'Oops, we are unable to process the exchanges at the moment. Please try again in a couple of minutes. If this error persist, please contact support.',
      icon: 'error',
      confirmButtonText: 'Reload',
      confirmButtonColor: '#29a927',
      allowOutsideClick:false
    }).then((result)=>{
      if(result.isConfirmed){
        window.location.href = "/dashboard" 
      }
    })
    return false
  }

  var btcTousdt = data.customData.btc2usd['5. Exchange Rate']
  var btcTozar = data.customData.btc2zar['5. Exchange Rate']
  var zarTousd = data.customData.zar2usd['5. Exchange Rate']

  console.log(parseFloat(btcTousdt), 1 /  parseFloat(btcTousdt))
  var usdTobtc = 1 / parseFloat(btcTousdt)
  var usdTozar = 1 / parseFloat(zarTousd)
  var zarTobtc = 1 / parseFloat(btcTozar)


  // '12': 0, '13': 0, '21': 0, '23': 0, '31': 0, '32': 0
  exchangeObjectOptions['12'] = parseFloat(usdTozar);
  exchangeObjectOptions['13'] = parseFloat(usdTobtc);
  exchangeObjectOptions['21'] = parseFloat(zarTousd);
  exchangeObjectOptions['23'] = parseFloat(zarTobtc);
  exchangeObjectOptions['31'] = parseFloat(btcTousdt);
  exchangeObjectOptions['32'] = parseFloat(btcTozar);
  console.log('echange data is ', exchangeObjectOptions)
};

function handleExchange(e)
{
  setExchangebuy1(e)
  var amountTobuy = e * exchangeObjectOptions[newOtpOption + newOtpOptionTo]
  var feeTotal = (amountTobuy * 3) / 100
  var totalAmountTobuy = amountTobuy - feeTotal
  setExchangebuy2(parseFloat(totalAmountTobuy).toFixed(2))
  console.log('exchange from ' + newOtpOption, 'exchange to ' + newOtpOptionTo)
  // console.log(parseFloat(e * exchangeObjectOptions[newOtpOption + newOtpOptionTo]))
  // exchangebuy1
  // console.log('check e ', jsonCurrencyData)
  // JSON.parse
  setExchangeFee(exchangeObjectOptions[newOtpOption + newOtpOptionTo])
  console.log('exchange fee is from ', exchangeObjectOptions[newOtpOption + newOtpOptionTo])
}



    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Exchange</title>
        </MetaTags>



        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="Exchange" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <div className="float-end">
                    <Dropdown
                      isOpen={isMenu}
                      toggle={toggleMenu}
                    >
                      <DropdownToggle
                        type="button"
                        tag="button"
                        className="btn btn-light"
                      >
                        <i className="mdi mdi-wallet me-1" />
                        <span className="d-none d-sm-inline-block">
                          Wallet Balance <i className="mdi mdi-chevron-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                        <div className="dropdown-item-text">
                          <div>
                            <p className="text-muted mb-2">Available Balance</p>
                            <h5 className="mb-0">ZAR {userZarBalance}</h5>
                          </div>
                        </div>

                        <DropdownItem divider />

                        <DropdownItem href="#">
                          USDT(TRC20) : ${userBalance}
                        </DropdownItem>

                        <DropdownItem divider />

                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                  <h4 className="card-title mb-4">Buy/Sell Crypto</h4>
                  <div className="crypto-buy-sell-nav">
                    <Nav tabs className="nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames("dark-c1-nav",{
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          Exchange
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames("dark-c1-nav",{
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          Sell
                        </NavLink>
                      </NavItem> */}
                    </Nav>

                    <TabContent
                      activeTab={activeTab}
                      className="crypto-buy-sell-nav-content p-4"
                    >
                      <TabPane tabId="1" id="buy">
                        <form>

                          
                          {displaySpinner ? 
                            <Spinner className="ms-2" color="success" />
                            :
                            <div>
                    
                          <div className="mb-2">
                            <Label>Currency From :</Label>

                            <Row>
                              <Col xl="12" md="12" className="m-2">

                                {/* Wallet Information */}
     
                                <div>
                                  {allCurrencies.length > 0 ? allCurrencies.map((report, key) => (
                                    // report.id == newOtpOptionTo ? (
                                    //   ''):
                                    <div className="btn-group-horizontal" key={key} style={{display: "inline", margin: "0 2px"}}>
                                      <input type="radio" className="btn-check" name={report.title} checked={newOtpOption === report.id.toString()}  onClick={(e) => handleOTPChange(e)} value={report.id} id={report.title} />
                                      <label className="btn c1-button-outline" htmlFor={report.title}>{report.title}</label>
                                    </div>
                                    
                                  )) : ''}
                                  </div>

                            
                              {/* <ProfileBalanceWidget userProfile={user?.user} /> */}
                              </Col>

                            </Row>

                            <Row>
                                <Col xl="12">
                                    <Row>

                                    <Col sm="4">
                                    <div className="mb-3">
                                        <label className="card-radio-label mb-2">
                                            <input
                                            type="radio"
                                            name="currency"
                                            id="buycurrencyoption1"
                                            className="card-radio-input"
                                            defaultChecked
                                            readOnly
                                            />

                                            <div className="card-radio">
                                            <div>
                                                <h5 className="text-left mb-0">
                                                    Total: {currencyOptionGroup[newOtpOption] + ' ' + userBalance} 
                                                </h5>
                                            </div>
                                            </div>
                                        </label>

                                       
                                    </div>
                                    </Col>        

                                    </Row>
                                </Col>
                            </Row>

                          </div>

                          <div className="mb-2">
                            <Label>Currency To <small className="text-danger">(Please note that a 3% exchange fee has been added)</small> :</Label>

                            <Row>
                              <Col xl="12" md="12" className="m-2">

                                {/* Wallet Information */}
     
                                <div>
                                  {allCurrencies.length > 0 ? allCurrencies.map((currency, key) => (
                                    // currency.id == newOtpOption ? (
                                    //   ''):
                                    <div className="btn-group-horizontal" key={key} style={{display: "inline", margin: "0 2px"}}>
                                      <input type="radio" className="btn-check" name={currency.title} checked={newOtpOptionTo === currency.id.toString()}  onClick={(e) => handleOTPChangeTo(e)} value={currency.id} id={currency.title+'-to'} />
                                      <label className="btn c1-button-outline" htmlFor={currency.title+'-to'}>{currency.title}</label>
                                    </div>
                                    
                                    )) : ''}
                                  </div>

                            
                              {/* <ProfileBalanceWidget userProfile={user?.user} /> */}
                              </Col>

                            </Row>

                            <Row>
                                <Col xl="12">
                                    <Row>

                                    <Col sm="4">
                                    <div className="mb-3">
                                        <label className="card-radio-label mb-2">
                                            <input
                                            type="radio"
                                            name="currency"
                                            id="buycurrencyoption1"
                                            className="card-radio-input"
                                            defaultChecked
                                            readOnly
                                            />

                                            <div className="card-radio">
                                            <div>
                                                <h5 className="text-left mb-0">
                                                    Total: {currencyOptionGroup[newOtpOptionTo] + ' ' + userBalanceTo} 
                                                </h5>
                                            </div>
                                            </div>
                                        </label>

                                       
                                    </div>
                                    </Col>        

                                    </Row>
                                </Col>
                            </Row>

                          </div>

                          <div className="mb-3">
                            <Label>Add value :</Label>

                            <Row>
                              <Col sm="6">
                                <InputGroup className="mb-2 currency-value" onChange={(e)=>handleExchange(e.target.value)} value={exchangebuy1}>
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">{currencyOptionGroup[newOtpOption]}</span>
                                  </div>
                                  <input type="text" className="form-control form-control" value={exchangebuy1} />
                                </InputGroup>
                              </Col>
                              {/* <Col sm="2">
                              <i className="fa-solid fa-repeat"></i>
                              </Col> */}
                              <Col sm="6">
                                <InputGroup className="mb-2" onChange={(e)=>setExchangebuy2(e.target.value)} value={exchangebuy2}>
                                  <input type="text" className="form-control text-sm-end form-control" value={exchangebuy2} disabled />
                                  <span className="input-group-text">{currencyOptionGroup[newOtpOptionTo]}</span>
                                </InputGroup>
                              </Col>
                            </Row>
                          </div>
                          <div className="text-center mt-4">
                          <a href="#" onClick={e => buyBtn(e)} className="btn c1-primary c1-primary-inline-btn btn-block">Buy {currencyOptionGroup[newOtpOptionTo]}</a>
                          </div>
                          </div>

                            }

                        </form>
                      </TabPane>

                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}