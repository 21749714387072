import React, { Component, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  Alert,
  Form,
  FormFeedback,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Spinner,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Route, Switch, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { UserContext } from "../../../UserContext";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { CSVLink, CSVDownload } from "react-csv";
import Datatable from "components/Datatable/datatable.component"
import axios from "axios"
import {UserProfile} from "pages/Admin/UserProfilePage/UserProfile"

export const B2BDeposits = () => {
  const [deposit, setAllDeposits] = useState([])
  const [displaySpinner, setDisplaySpinner] = useState(false)
  const{auth,user} = React.useContext(UserContext)
  const columns = [
    {
      dataField: "userid",
      text: "UserID",
      sort: true,
    },
    {
        dataField: "amount",
        text: "Amount",
        sort: true,
    },
    {
        dataField: "remarks",
        text: "Remarks",
        sort: true,
    },
    {
        dataField: "trans_id",
        text: "TransactionID",
        sort: true,
    },
    {
        dataField: "txid",
        text: "TxID",
        sort: true,
    },
    {
        dataField: "status",
        text: "Status",
        sort: true,
    },
    {
        dataField: "currency",
        text: "Currency",
        sort: true,
    },
  ]

  React.useEffect(async res =>{

    setDisplaySpinner(true);
    if(user !== 'undefined' && user !== null)
    {
        await axios.get(process.env.REACT_APP_BASE + "api/b2b-find-all-deposits").then((res)=>{
            console.log('all deposits ', res.data)
            console.log('all deposits array ', res.data.deposit)
            setAllDeposits(res.data.deposit)
            setDisplaySpinner(false);
        });
    }

},[auth])

  const datatableInfo = {
    title: "Deposits View",
    shortDescription: "List of deposits",
    longDescription: "This shows a list of all deposits",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD - Deposits</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="mt-4 mb-3">
                    <Row>
                    {displaySpinner ? 
                          <Spinner className="ms-2" color="success" />
                          :
                        <div>
                      <Col lg="12">
                        <Datatable
                          columns={columns}
                          productData={deposit}
                          datatableInfo={datatableInfo}
                        />
                      </Col>
                      <CSVLink data={deposit} filename={"all-deposits.csv"} className="btn btn-primary" target="_blank">Download me</CSVLink>
                      </div>
                      }
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
// ProductSales.propTypes = {
//     viewAllProductSales: PropTypes.array,
// }

export default B2BDeposits