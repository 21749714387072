import PropTypes from 'prop-types'
import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import Swal from 'sweetalert2'


import { UserContext } from "../../../UserContext";

import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    Button,
    NavItem,
    NavLink,
    InputGroup,
    Modal,
    ModalBody
  
  } from "reactstrap";



  import WalletModalView from './wallet'
  import DepositVIFView12 from './amountvif-12'
  import DepositConfirmationView from './confirmation'
  

//   show={getproductModal}
//   displayscreen={displayScreen}
//   onNextClick={handleNextView}
//   onPrevClick={handlePrevView}
//   wallet={walletCurrency}

const VIFProductModal12 = ({ show, displayscreen, onNextClick, onPrevClick, wallet, depositAprovedResult, depositDeclinedResult }) => {

  const{auth,user} = React.useContext(UserContext)
  const[userUserid, setuserUserid] = React.useState('');

  React.useEffect(async res =>{

    if(user !== 'undefined' && user !== null)
    {
      setuserUserid(user.user.userid)
      getModalView()
    }

  })

    // const [riskQuestionNumber, setRiskQuestionNumber] = React.useState(1);
    const [riskQuestion, setRiskQuestion] = React.useState('');
    const [displayQuestion, setDisplayQuestion] = React.useState(false);
    const [questionText, setQuestionText] = React.useState('');

    // Modals
    const [screen, setScreen] = React.useState(1);
    const [walletOption, setWalletOption] = React.useState(1);

    function getModalView() {
        console.log("get the view that we should show ", displayscreen)
    switch (displayscreen) {
        case 1:
            
            return (
                setScreen(1)
            )
        case 2: 
        
            return (

                setScreen(2)
            )
        case 3: 

            return (
                setScreen(3)
            )
        case 4: 
          
            return (
                setScreen(4)
            )
        
        default: 
            return (
                setScreen(1)
            )
        }
    }


      const onUSSD = (thisQScore) => {

        console.log("the question text is ", questionText)
        qScore[questionText] = thisQScore
        console.log("this score is ", qScore)
        // qScore = thisQScore
        score += thisQScore

        console.log("overall score is ", score)
        onNextClick()
        
      }

      const HandleNextModal = () => {

        onNextClick()
        
      }


  return (
    <Modal isOpen={show}  centered={true}>
      <ModalBody className="py-3 px-5">
        {/* <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>Are you sure?</h2>
              <h4>{"You won't be able to revert this!"}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onDeleteClick}
              >
                Yes, delete it!
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row> */}

        <div>

            <Row className="mb-2 text-center">

                <Col className="col-12">
                    <h3 className="">Vault Index Fund (12 Months)</h3>
                </Col>

            </Row>

            <div className="p-1">
                <Form
                className="form-horizontal fvw-login-form">

                    <Row>
                    <Col className='col-12'>
                        
                   

           
                    {screen == 1 ? 
                     <Row>
                        <WalletModalView
                            walletOption={wallet}
                            continueModal={HandleNextModal}
                        />

                        </Row>
                    : screen == 2 ?
                    <Row>
                    <DepositVIFView12
                        walletOption={wallet}
                        continueModal={HandleNextModal}
                    />

                    </Row>
                    : screen == 3 ?
                    <Row>
                    <DepositConfirmationView
                        depositAprovedResult={depositAprovedResult}
                        depositDeclinedResult={depositDeclinedResult}
                        walletOption={wallet}
                        continueModal={HandleNextModal}
                    />

                    </Row>
                    : ''}


                    <Row className="mt-3 mb-2"> 

                        <Col className='col-6'>
                         
                            <Button
                            className="ml-2 btn omc-primary omc-primary-inline-btn fwv-button-content btn-block"

                            variant="contained"
                            // onClick={canContinue}
                            // onClick={(e)=>goToPrevious(e.target.value)}
                            onClick={onPrevClick}
                            ><span><i className="bx bxs-left-arrow" style={{float: "left",
                            marginTop: "5px"}}></i></span>{displayscreen == 1 ? 'Close' : 'Back'}</Button>
                        </Col>
                        <Col className='col-6 text-right' style={{textAlign: "right"}}>
                 
                            {displayscreen == 2 ? 
                            <Button
                            variant="contained"
                            className="btn omc-primary omc-primary-inline-btn fwv-button-content btn-block"
                            // onClick={canContinue}
                            // onClick={(e)=>canContinue(e.target.value)}
                            onClick={onNextClick}
                            >Enter<span><i className="bx bxs-right-arrow" style={{float: "right",
                            marginTop: "5px"}}></i></span></Button>
                            : displayscreen == 3 ? 
                            <Button
                            variant="contained"
                            className="btn omc-primary omc-primary-inline-btn fwv-button-content btn-block"
                            // onClick={canContinue}
                            // onClick={(e)=>canContinue(e.target.value)}
                            onClick={onNextClick}
                            >Close<span><i className="bx bxs-right-arrow" style={{float: "right",
                            marginTop: "5px"}}></i></span></Button>
                            : '' }
                            
                        </Col>

                    </Row> 

                    </Col>
                    </Row>               

                </Form>
            </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

VIFProductModal12.propTypes = {
    show: PropTypes.any,
    displayscreen: PropTypes.any,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  wallet: PropTypes.any,
  depositAprovedResult: PropTypes.any,
  depositDeclinedResult: PropTypes.any,
}

export default VIFProductModal12