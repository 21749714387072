import React, { Component,useEffect, useState } from 'react'
import MetaTags from "react-meta-tags";
import axios from 'axios';
// import PropTypes from "prop-types"

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'


import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  Button,
  NavItem,
  NavLink,
  InputGroup

} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action


//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images


import PropTypes from "prop-types"
import classnames from "classnames"



// Add datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// const UserDetails = ({ nextStep, handleChange, values}) => {
    // export class UserDetails extends Component {
        // function UserDetails({nextStep, handleChange, values}) {
        const WalletModalView = ({ walletOption, continueModal }) => {
            console.log('walletOption is' , walletOption)
        
   
    // render() {
        const [isMenu, setisMenu] = useState(false)
        const [activeTab, setActiveTab] = useState("1")

      
      
        const dispatch = useDispatch();
      
        function canContinueModal(currency){
            walletOption['currency'] = currency;
            console.log('walletOption is' , walletOption)
            continueModal();
            return false;
          };
      

    return (
      <React.Fragment>
        <div>
        <Col className='col-12 mt-2'>
            Select Currency
        </Col>

            <Col className='col-12'>
                <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}>
                    <Button onClick={()=>canContinueModal('usdt')} className="btn c1-primary c1-primary-inline-btn btn-block">
                                  <h2 className="text-left mb-0">
                                      USDT
                                      </h2>
                                  </Button>
                </Col>
                {walletOption['product'] != '15' && walletOption['product'] != '18' && walletOption['product'] != '19' && walletOption['product'] != '20'?
                <Col md="4"  style={{marginTop: '15px', marginBottom: '10px'}}>
                    <Button  onClick={()=>canContinueModal('zar')} className="btn c1-primary c1-primary-inline-btn btn-block">
                        <h2 className="text-left mb-0">
                          ZAR
                        </h2>
                    </Button>
                </Col>
                : ''}
            </Col>
        </div>
      </React.Fragment>
    );
//   }
}


WalletModalView.propTypes = {
    walletOption: PropTypes.any,
    continueModal: PropTypes.func,
  }


export default WalletModalView;