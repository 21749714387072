import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import {useState,useEffect} from "react";
import axios from 'axios'
import { Row, Col, CardBody, Card, Alert, Container, Form, Modal, Input, ModalBody, FormFeedback, Label } from "reactstrap";


// import LoginAuthModal from "../../components/Common/LoginAuthModal";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import loginlogo from "assets/images/backgroundomc.jpeg";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/c1_favicon.png";
import logo from "assets/images/c1logo.png";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
//Import config
import { facebook, google } from "../../config";
import {UserContext} from '../../UserContext'

const ForgetPasswordPage = props => {

  
  const [alreadySentFirstEmail, setAlreadySentFirstEmail] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      // API call forgot password link
      axios.post(process.env.REACT_APP_API_BASE_URL + `/forgot-password`,{email: values.email}).then((res)=>{
  
        if(res.data.status == 200)
        {
          // toastr.options.onHidden = function () {
          //   window.location.href = "/login"
          // }
          toastr.success("Email has been successfully sent")
          setAlreadySentFirstEmail(true)
        }else{
          toastr.error('Failed to send email.')
        }
      })
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  function resendEmail(email)
  {
    console.log("We are sending it to ", validation.values.email)

    axios.post(process.env.REACT_APP_BASE + "api/forgot-password-alternative",{email: validation.values.email}).then(async res =>{

      if(res.data.status == 200)
      {
        toastr.success("We have successfully sent another email!"); 
        setAlreadySentFirstEmail(true)

      }else{
        toastr.error(res.data.message); 
      }
   
    });
  }

  return (
    <React.Fragment>
      {/* <LoginAuthModal
        show={loginAuthModal}
        newVerificationCode={setAuthTokenData}
        onDeleteClick={handleLoginAuthModal}
        onCloseClick={() => setLoginAuthModal(false)}
      /> */}
      <MetaTags>
        <title>C1 HOLDINGS (PTY) LTD - Reset</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/login" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages" >
        <Container className="pt-sm-5 mw-100 fvw-login-container" 
        // style={{backgroundImage: `url(${loginlogo})`,
        // boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 20%)"
        // }}
        >
         {/* <> {(auth)?window.location.href="/dashboard":''}</> */}

          <Row className="justify-content-center" style={{
            position: "absolute",
            left: "40%",
            top: "34%",
            transform: "translate(-50%, -50%)",
        }}>
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden fwv-card-login" style={{
                // borderRadius: "50%",
                // width: "600px",
                // height: "600px",
                // paddingTop: "50px",
                // background: "rgba(25, 24, 24, 0.32)"
              }}>
                <CardBody className="pt-0">
                  <div className="mb-4">
                    <Link to="/login" className="auth-logo-light">
                      <div className="w-100 profile-user-wid">
                        <span className="avatar-title ">
                          <img
                            src={logo}
                            alt=""
                            className="mt-2 fvw-login-logo"
                            // height="100"
                            // width="180"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <Row className="mb-2 text-center">
                    <Col className="col-12">
                      <h2 className="">Forgot Password</h2>
                    </Col>
                  </Row>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                      <Label className="loginLabel">Email</Label>
                        <Input
                          name="email"
                          className="form-control fwv-input-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                        
                      {alreadySentFirstEmail ?
                      <Row className="mb-2">
                        <Col>
                          <div className="mt-4 text-center">
                            <p className="mb-0 text-muted">
                              <a  rel="noreferrer"  href="#" onClick={resendEmail} className="c1-primary-no-fill mb-0 text-muted">
                                I haven&apos;t received my email
                              </a>
                            </p>
                          </div>
                        </Col>
                      </Row>
                      : ''}

                      {alreadySentFirstEmail ?
                      <Row className="mb-3">
                        <Col className="text-end">
                          <div className="mt-4 d-grid" style={{height: "50px"}}>
                            <a
                              className="btn c1-primary fwv-primary-btn btn-block "
                              href="/login"
                              //onClick={adminLogin}
                            >
                              Take Me To Login
                            </a>                     
                          </div>
                        </Col>
                      </Row>
                      :
                      <Row className="mb-3">
                        <Col className="text-end">
                          <div className="mt-4 d-grid" style={{height: "50px"}}>
                            <button
                              className="btn c1-primary fwv-primary-btn btn-block "
                              type="submit"
                              //onClick={adminLogin}
                            >
                              Reset
                            </button>                     
                          </div>
                        </Col>
                      </Row>
                      }

                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
  // return (
  //   <React.Fragment>
  //     <MetaTags>
  //       <title>
  //         Forget Password | Skote - React Admin & Dashboard Template
  //       </title>
  //     </MetaTags>
  //     <div className="home-btn d-none d-sm-block">
  //       <Link to="/" className="text-dark">
  //         <i className="fas fa-home h2" />
  //       </Link>
  //     </div>
  //     <div className="account-pages my-5 pt-sm-5">
  //       <Container>
  //         <Row className="justify-content-center">
  //           <Col md={8} lg={6} xl={5}>
  //             <Card className="overflow-hidden">
  //               <div className="bg-primary bg-softbg-soft-primary">
  //                 <Row>
  //                   <Col xs={7}>
  //                     <div className="text-primary p-4">
  //                       <h5 className="text-primary">Welcome Back !</h5>
  //                       <p>Sign in to continue to Skote.</p>
  //                     </div>
  //                   </Col>
  //                   <Col className="col-5 align-self-end">
  //                     <img src={profile} alt="" className="img-fluid" />
  //                   </Col>
  //                 </Row>
  //               </div>
  //               <CardBody className="pt-0">
  //                 <div>
  //                   <Link to="/">
  //                     <div className="avatar-md profile-user-wid mb-4">
  //                       <span className="avatar-title rounded-circle bg-light">
  //                         <img
  //                           src={logo}
  //                           alt=""
  //                           className="rounded-circle"
  //                           height="34"
  //                         />
  //                       </span>
  //                     </div>
  //                   </Link>
  //                 </div>
  //                 <div className="p-2">
  //                   {forgetError && forgetError ? (
  //                     <Alert color="danger" style={{ marginTop: "13px" }}>
  //                       {forgetError}
  //                     </Alert>
  //                   ) : null}
  //                   {forgetSuccessMsg ? (
  //                     <Alert color="success" style={{ marginTop: "13px" }}>
  //                       {forgetSuccessMsg}
  //                     </Alert>
  //                   ) : null}

  //                   <Form
  //                     className="form-horizontal"
  //                     onSubmit={e => {
  //                       e.preventDefault()
  //                       validation.handleSubmit()
  //                       return false
  //                     }}
  //                   >
  //                     <div className="mb-3">
  //                       <Label className="form-label">Email</Label>
  //                       <Input
  //                         name="email"
  //                         className="form-control"
  //                         placeholder="Enter email"
  //                         type="email"
  //                         onChange={validation.handleChange}
  //                         onBlur={validation.handleBlur}
  //                         value={validation.values.email || ""}
  //                         invalid={
  //                           validation.touched.email && validation.errors.email
  //                             ? true
  //                             : false
  //                         }
  //                       />
  //                       {validation.touched.email && validation.errors.email ? (
  //                         <FormFeedback type="invalid">
  //                           {validation.errors.email}
  //                         </FormFeedback>
  //                       ) : null}
  //                     </div>
  //                     <Row className="mb-3">
  //                       <Col className="text-end">
  //                         <button
  //                           className="btn btn-primary w-md "
  //                           type="submit"
  //                         >
  //                           Reset
  //                         </button>
  //                       </Col>
  //                     </Row>
  //                   </Form>
  //                 </div>
  //               </CardBody>
  //             </Card>
  //             <div className="mt-5 text-center">
  //               <p>
  //                 Go back to{" "}
  //                 <Link to="login" className="font-weight-medium text-primary">
  //                   Login
  //                 </Link>{" "}
  //               </p>
  //               <p>
  //                 © {new Date().getFullYear()} Skote. Crafted with{" "}
  //                 <i className="mdi mdi-heart text-danger" /> by Themesbrand
  //               </p>
  //             </div>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </div>
  //   </React.Fragment>
  // )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
