import React, { Component,useEffect, useState } from 'react'
import MetaTags from "react-meta-tags";
import axios from 'axios';
// import PropTypes from "prop-types"

import toastr from 'toastr';
import 'toastr/build/toastr.min.css'


import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  Button,
  NavItem,
  NavLink,
  InputGroup

} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import termsPDF from "./Terms.pdf";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/c1_favicon.png";
import logo from "assets/images/c1logo.png";
import logoImg from "../../assets/images/c1_favicon.png";

import PropTypes from "prop-types"
import classnames from "classnames"

import loginlogo from "assets/images/backgroundomc.jpeg";

import Swal from "sweetalert2"

// Add datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// const UserDetails = ({ nextStep, handleChange, values}) => {
    // export class UserDetails extends Component {
        // function UserDetails({nextStep, handleChange, values}) {
     

    const infoIntro = props => {
    
        console.log('props is' , props)
    

   
    // render() {
        const [isMenu, setisMenu] = useState(false)
        const [activeTab, setActiveTab] = useState("1")
        
      
      
        const dispatch = useDispatch();
      
        // Detail for Users
        const [apassword,setPassword] = useState('')
        const [firstname,setName] = useState('')
        const [lastname,setLast] = useState('')
        const [dob,setDOB] = useState(new Date())
        const [idnumber,setIdnumber] = useState('')
        const [street,setStreet] = useState('')
        const [address,setAddress] = useState('')
        const [emailaddress,setEmail] = useState('')
        const [cell_no,setCell] = useState('')
        const [useridUpload, setuseridUpload] = useState([])
        const [userpoaupload, setuserpoaupload] = useState([])
        const [foundSponsorUserid, setFoundSponsorUserid] = useState(false)
        const [haveSearched, setHaveSearched] = useState(false)

        function canContinueUser(e){
            props.values.usertype = '0';
            props.nextStep();
            return false;
          };
      

    return (
      <React.Fragment>
        <MetaTags>
          <title>C1 HOLDINGS (PTY) LTD</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/login" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages" >
          <Container className="pt-sm-5 mw-100 fvw-login-container" 
        //   style={{
        //       backgroundImage: `url(${loginlogo})`,
        //       boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 20%)"
        //     }}
            >
            <Row className="justify-content-center select-user-content" style={{
            position: "absolute",
            left: "40%",
            top: "34%",
            transform: "translate(-50%, -50%)",
            }}>
  
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden fwv-card-login">
                  <CardBody className="pt-0">
                    <div>
                    <Link to="/register" className="auth-logo-light">
                        <div className="w-100 profile-user-wid mb-4">
                          <span className="avatar-title ">
                            <img
                              src={logo}
                              alt=""
                              className="mt-2 fvw-login-logo"
                              // height="100"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
  
  
                    <div className="mt-4 mb-3">

                    {/* user */}
                    <TabContent activeTab={activeTab} className="mt-4">
                      <TabPane tabId="1" id="buy-tab">
                        <div>

                        <Row className="mb-2 text-center">
                          <Col className="col-12">
                            <h2 className="intro-text">Welcome to <span className="c1-primary-no-fill">C1 HOLDINGS (PTY) LTD</span></h2>
                          </Col>

                        </Row>
                        <Col className="col-12">
                            <h3 className="text-center mt-3 intro-text">To become part of the C1 HOLDINGS (PTY) LTD team we would require some information.</h3>
                          </Col>
                        <div className="p-1">
                      <Form
                        className="form-horizontal fvw-login-form">
                    
                        <Row>
                          <Col className='col-12'>
                            
                          <Row>


                            <Col sm={12} md={4} lg={4} xl={4} className='col-xl-3 col-sm-12'>

                            </Col>

                            <Col sm={12} md={4} lg={4} xl={4} className='text-center m-auto col-xl-3 col-sm-12'>
                            <a className="text-success" onClick={(e)=>canContinueUser(e.target.value)} >
                                    <Card className="cardType">
                                    <CardBody className="text-center">
                                        
                                        <i
                                            className="bx bx-user-circle c1-primary-no-fill text-center bx-lg mt-1 font-size-48"
                                            id="edittooltip"
                                            
                                        ></i>
                                        < br />
                                        <h5 className="text-center mt-2">Begin Process</h5>
                                        

                                    </CardBody>
                                    </Card>
                                </a>
                            </Col>

                            <Col sm={12} md={4} lg={4} xl={4} className='col-xl-3 col-sm-12'>

                            </Col>


                          </Row>

                          </Col>
                        </Row>               
  
  
                        <div className="mt-4 text-center">
                          <p className="mb-0 text-muted">

                            By registering you agree to the {" "}
                            <a  rel="noreferrer"  href={termsPDF} target="_blank" className="c1-primary-no-fill"> Terms
                            </a>
                          </p>
                          <br />
                          <p className="mb-0 text-muted">
                            I already have an account{" "}
                            <a  rel="noreferrer"  href="/login"  className="c1-primary-no-fill">
                              Login
                            </a>
                          </p>
                        </div>
                      </Form>
                    </div>
                          {/* Company */}
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
//   }
}
infoIntro.propTypes = {
    // prevStep: PropTypes.any,
    nextStep: PropTypes.any,
    nextStepCompany: PropTypes.any,
    handleChange: PropTypes.any,
    values: PropTypes.any,
  }


export default infoIntro;

